import React from "react";
import { connect } from "react-redux";


import {v1 as uuidv1} from "uuid";import { 
  VIEW_TYPE_EXTENSION_MANAGER,
  VIEW_TYPE_HOME_CONTROL_PANEL,
  VIEW_TYPE_CUSTOMER_PANEL,
  VIEW_TYPE_HOME_PASSES_PANEL,
  VIEW_TYPE_EVENTS_PANEL,
  VIEW_TYPE_SUBSCRIPTIONS_PANEL,
  VIEW_TYPE_MANDATES_PANEL,
  VIEW_TYPE_INVOICE,
  VIEW_TYPE_REPORTS_PANEL,
  VIEW_TYPE_SCHEDULES_PANEL,
  VIEW_TYPE_QUESTIONAIRE_PANEL,
  VIEW_TYPE_POLICY_PANEL,
 } from './redux/types';

 import  {
  EVENT_APP_CACHE_STATE_DEFAULT,
  EVENT_APP_CACHE_STATE_WIZARD_0,
  EVENT_APP_CACHE_STATE_WIZARD_1,
  EVENT_APP_CACHE_STATE_WIZARD_2,
  EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP,
  EVENT_APP_CACHE_STATE_WIZARD_2_A,
  EVENT_APP_CACHE_STATE_WIZARD_2_B,
  EVENT_APP_CACHE_STATE_WIZARD_2_C,
  EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE,
  EVENT_APP_CACHE_STATE_WIZARD_2_MANUALLY_ADJUST_DATES,

  EVENT_APP_CACHE_STATE_WIZARD_3,
  EVENT_APP_CACHE_STATE_WIZARD_4,
  EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT,
  EVENT_APP_CACHE_STATE_WIZARD_4_DISCOUNTS,
  
  EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS,
  EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES,
  EVENT_APP_CACHE_STATE_WIZARD_SUMMARY,

  EVENT_APP_CACHE_STATE_CLONE_SELECT_MODE,
  EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE,
  EVENT_APP_CACHE_STATE_CLONE_SETUP_VIRTUAL,
  EVENT_APP_CACHE_STATE_CLONE_MANUAL,
  EVENT_APP_CACHE_STATE_CLONE_WEEKLY,
  EVENT_APP_CACHE_STATE_CLONE_MONTHLY,
  EVENT_APP_CACHE_STATE_CLONE_SAVE

} from './redux/types';

import ExtensionsManager from './extensions/extensions-manager';
import HomeControlPanel from './core/dashboard';
import CustomersManager from './customers/customers';
import PassesManager from './passes/passes';

import CustomersPanelManagerModule from './customers/customers-panel';
import EventsManager from './events/events-manager';

import EventsStep0   from './events/events-step-0';
import EventsStep1   from './events/events-step-1';
import EventsStep2   from './events/events-step-2';
import EventsStep2VirtualSetup from './events/events-step-2-virtual-setup';
import EventsStep2A   from './events/events-step-2-a';
import EventsStep2B   from './events/events-step-2-b';
import EventsStep2C   from './events/events-step-2-c';
import EventsStep2BookType from './events/events-step-2-book-type'
import EventsStep3   from './events/events-step-3';
import EventsStep4   from './events/events-step-4';
import EventsStep4PassSelect   from './events/events-step-4-pass-select';
import EventsStep4Discount     from './events/events-step-4-discounts';
import EventsStep5Actions   from './events/events-step-5-actions';
import EventsStep5Properties   from './events/events-step-5-properties';
import EventsStepSummaryModule from './events/events-step-summary';

import EventsSelectMode      from './events/events-step-clone-select-mode';
import EventsCloneSelectType from './events/events-clone-select-type';
import EventCloneVirtualSetup from './events/events-step-clone-virtual-setup';
import EventsCloneManual from './events/events-step-clone-manual';
import EventCloneAutomatedWeekly from './events/events-step-clone-automated-weekly';
import EventCloneAutomatedMonthly from './events/events-step-clone-automated-monthly';
import EventsCloneSave from './events/events-step-clone-save';
import SubscriptionsManager from './subscriptions/subscriptions';
import MandatesManager from './subscriptions/mandates';
import Invoice from './subscriptions/invoice';
import EventsStepEditDatesManual from './events/events-step-2-edit-dates-manual';
import ReportsManager from './reports/reports-manager';
import SchedulesManager from './schedules/schedules-manager';
import QuestionairesManager from './questionaires/manager';
import PolicyManager from './setup/policy';
const mapStateToProps = state => {
   // //console.log('this state');
    console.log("Builder mapStateToProps", state);
    return { 
            view: state.settings.view, 
            data: state.data, 
            security: state.security,
            app_cache: state.app_cache,
            refresh_state: state.refresh_state
          };
}


const BuildUI = ( view, data, security, app_cache ) => {
  let x   = [];
  console.log('BuildUI', app_cache, data, security, view );

  switch( view )
  {
    case VIEW_TYPE_HOME_CONTROL_PANEL:
    x.push(<HomeControlPanel key={uuidv1()} data={data} security={security} />);
    break;

    case VIEW_TYPE_EXTENSION_MANAGER:
      x.push(<ExtensionsManager key={uuidv1()} />);
      break;

    case VIEW_TYPE_CUSTOMER_PANEL:
      x.push(<CustomersManager key={uuidv1()} data={data} security={security} />);
      if(app_cache.customer.manage === true)
      {

        x.push(<CustomersPanelManagerModule 
                key={uuidv1()} 
                customer={app_cache.customer} 
                data={data} 
                security={security} 
                open={true} />);
        
      }
      break;
      case VIEW_TYPE_HOME_PASSES_PANEL:
        x.push(<PassesManager key={uuidv1()}  data={data}  security={security} />);

      console.log("Passes panel", app_cache.passes.manage);
      if(app_cache.passes.manage === true)
      {
        console.log("writing new panel", app_cache, data);

        /*
        x.push(<CustomersPanelManagerModule 
                key={uuidv1()} 
                passes={data.events} 
                security={security} 
                open={true} />);
        */
      }
      break;


      case VIEW_TYPE_REPORTS_PANEL:
      {
        x.push(<ReportsManager  security={security} />)
      }
      break;

      case VIEW_TYPE_SCHEDULES_PANEL:
      {
        x.push(<SchedulesManager  key={uuidv1()}  data={data}  security={security} />)
      }
      break;

      case VIEW_TYPE_QUESTIONAIRE_PANEL:
      {
        x.push(<QuestionairesManager  key={uuidv1()}  data={data}  security={security} />)
      }
      break;


      case VIEW_TYPE_EVENTS_PANEL:
        console.log(app_cache);
        
        if(app_cache.state === EVENT_APP_CACHE_STATE_DEFAULT)
        {
          x.push(<EventsManager key={uuidv1()} refresh={app_cache.refresh}  data={data}  security={security} />);
        }
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_0)
        {
          x.push(<EventsStep0 key={uuidv1()}  data={data}  security={security} />);
        }
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_1)
        {
          x.push(<EventsStep1 key={uuidv1()}  data={data} cache={app_cache.basic}  security={security} />);
        }
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_2)
        {
          x.push(<EventsStep2 key={uuidv1()}  data={data} cache={app_cache.scheduling} mode={app_cache.mode} virtual={app_cache.virtual} security={security} />);
        }
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP)
        {
          x.push(<EventsStep2VirtualSetup key={uuidv1()}  data={data} scheduling={app_cache.scheduling} is_edit={app_cache.edit} cache={app_cache.virtual} security={security} />);
        }

        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_2_A)
        {
          x.push(<EventsStep2A key={uuidv1()}  data={data} cache={app_cache.scheduling.configuration}  mode={app_cache.mode}  virtual={app_cache.virtual} security={security} />);
        }
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_2_B)
        {
          x.push(<EventsStep2B key={uuidv1()}  data={data} cache={app_cache.scheduling.configuration} security={security} />);
        }        
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_2_C)
        {
          x.push(<EventsStep2C key={uuidv1()}  data={data} cache={app_cache.scheduling.configuration} security={security} />);
        }

        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_2_MANUALLY_ADJUST_DATES)
        {
          x.push(<EventsStepEditDatesManual key={uuidv1()}  data={data} cache={app_cache.scheduling.configuration}  mode={app_cache.mode}  virtual={app_cache.virtual} security={security} />);
          
        }

        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE)
        {
          x.push(<EventsStep2BookType key={uuidv1()}  data={data} cache={app_cache.scheduling.configuration} security={security} />);
        }        
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_3)
        {
          x.push(<EventsStep3 key={uuidv1()}  data={data} cache={app_cache.payment} security={security} />);
        }    
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_4)
        {
          x.push(<EventsStep4 key={uuidv1()}  data={data} cache={app_cache.payment.configuration} mode={app_cache.payment.mode}  security={security} />);
        }
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_4_DISCOUNTS)
        {
          console.log("rendering EventsStep4PassSelect" );
          x.push(<EventsStep4Discount key={uuidv1()}  data={data} cache={app_cache.payment} mode={app_cache.payment.mode}  security={security} />);
        }         
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT)
        {
          console.log("rendering EventsStep4PassSelect" );
          x.push(<EventsStep4PassSelect key={uuidv1()}  data={data} cache={app_cache.payment} mode={app_cache.payment.mode}  security={security} />);
        } 
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS)
        {
          x.push(<EventsStep5Actions key={uuidv1()}  data={data} cache={app_cache.actions}   security={security} />);
        }  
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES)
        {
          x.push(<EventsStep5Properties key={uuidv1()}  data={data} cache={app_cache.properties}   security={security} />);
        } 
        else if(app_cache.state === EVENT_APP_CACHE_STATE_WIZARD_SUMMARY)
        {
          x.push(<EventsStepSummaryModule key={uuidv1()}  data={data} cache={app_cache} mode={app_cache.mode}  security={security} />);
        } 
        
        else if(app_cache.state === EVENT_APP_CACHE_STATE_CLONE_SELECT_MODE)
        {
          x.push(<EventsSelectMode key={uuidv1()}  mode={app_cache.mode}  virtual={app_cache.virtual} data={data} cache={app_cache.scheduling} security={security} />);
        } 

        else if(app_cache.state === EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE)
        {
          x.push(<EventsCloneSelectType key={uuidv1()}   data={data} cache={app_cache.scheduling} mode={app_cache.mode} virtual={app_cache.virtual}  security={security} />);
        }   
        else if(app_cache.state === EVENT_APP_CACHE_STATE_CLONE_SETUP_VIRTUAL)
        {
          x.push(<EventCloneVirtualSetup key={uuidv1()}  data={data} scheduling={app_cache.scheduling} cache={app_cache.virtual} security={security} />);
        }          
        
        else if(app_cache.state === EVENT_APP_CACHE_STATE_CLONE_MANUAL)
        {
          x.push(<EventsCloneManual key={uuidv1()}  data={data} mode={app_cache.mode}  virtual={app_cache.virtual} cache={app_cache.scheduling.configuration} security={security} />);
        }
        else if(app_cache.state === EVENT_APP_CACHE_STATE_CLONE_WEEKLY)
        {
          x.push(<EventCloneAutomatedWeekly key={uuidv1()}  data={data} cache={app_cache.scheduling.configuration} security={security} />);
        }
        else if(app_cache.state === EVENT_APP_CACHE_STATE_CLONE_MONTHLY)
        {
          x.push(<EventCloneAutomatedMonthly key={uuidv1()}  data={data} cache={app_cache.scheduling.configuration} security={security} />);
        }        
        else if(app_cache.state === EVENT_APP_CACHE_STATE_CLONE_SAVE)
        {
          x.push(<EventsCloneSave key={uuidv1()}  data={data} cache={app_cache} mode={app_cache.mode} security={security} />);
        } 
      
      break;


      case VIEW_TYPE_SUBSCRIPTIONS_PANEL:
      {
        x.push(<SubscriptionsManager key={uuidv1()}  data={data} cache={app_cache} security={security} />);
      }
      break;
      case VIEW_TYPE_MANDATES_PANEL:
      {
        x.push(<MandatesManager key={uuidv1()}  data={data} cache={app_cache} security={security} />);
      }
      break;
      case VIEW_TYPE_INVOICE:
      {
        x.push(<Invoice key={uuidv1()}  data={data} cache={app_cache} security={security} />);
      }
      break;

      case VIEW_TYPE_POLICY_PANEL:{
        x.push(<PolicyManager key={uuidv1()}  data={data} cache={app_cache} security={security} />);

      }
      break;
      

      default:
        console.log("Unsupported build view", view);
      break;
  }

  return x;

}

const ConnectedViewRenderer = (
    { 
        view,
        data,
        security,
        app_cache
     }) => (
    <div>
        {
          BuildUI( view, data, security, app_cache )
          //questionaire.map( el =>(ParseElements(el)))
        }
    </div>
);




  
const Builder = connect(mapStateToProps)(ConnectedViewRenderer);
export default Builder;