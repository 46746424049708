import React, { Component } from 'react';
import { connect } from "react-redux";


import { withStyles } from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Typography from '@material-ui/core/Typography';

import Hidden from '@material-ui/core/Hidden';



const styles = {
    root: {
        flexGrow: 1,
      },
      formControl: {
//        margin: theme.spacing(1),
        minWidth: 120,
      },             
  };

function mapDispatchToProps(dispatch) {
    return {
      
    };
  }

const steps = ['Define event', 'Add schedule information', 'Set payment options', 'Advanced settings', 'Save event'];

class StepperElement extends Component{

    constructor( props )
    {
        super( props );
        console.log('TopNav', props);
        this.state = {
            state: props.state
        };


//        this.onDateChange   = this.onDateChange.bind(this);
    }


    setActiveStep(step){

    }

    isStepOptional(step){
        return step === 3;
    }   

    handleStep( step ) {
        //setActiveStep(step);
    }    
   
    isStepComplete(step) {
        //return completed.has(step);
        return false;
    }

    isStepSkipped = step => {
        //return skipped.has(step);
        return false;
    }

 
      

    render()
    {
      
        var activeStep = this.props.step;
        var classes = this.props.classes;

        console.log("Props ", this.props, activeStep);

        //console.log('item render ',t, this.props.data.time);
        return (
            <div className={classes.root}>
                <Hidden smDown>
                    <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => {
                            const stepProps = {};
                            const buttonProps = {};
                            if (this.isStepOptional(index)) {
                                buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                            }
                            if (this.isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepButton onClick={this.handleStep(index)} completed={this.isStepComplete(index)} {...buttonProps} >
                                        {label}
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Hidden>

            </div>            
        );
    }

};



const StepperWidget = connect(null, mapDispatchToProps)(StepperElement);
export default withStyles(styles)(StepperWidget);
