import React, { Component } from 'react';
import { connect } from "react-redux";


import Grid from '@material-ui/core/Grid';

import { Bar } from 'react-chartjs-2';

import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';

import { withStyles } from '@material-ui/core/styles';

import { Divider } from '@material-ui/core';


import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExtensionWidget from './widgets'
import SwapHorizOutlinedIcon from '@material-ui/icons/SwapHorizOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import './extension.css';
import ZoomManagerDialogModule from './zoom-manager-dialog';



const styles = {
    root: {
        flexGrow: 1,
      },
      title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
      },      
      
  };

function mapDispatchToProps(dispatch) {
    return {

      
    };
  }


class ExtensionsManagerModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);
       /*
https://zoom.us/oauth/authorize?response_type=code&client_id=7lstjK9NTyett_oeXtFiEQ&redirect_uri=https://yourapp.com
*/
        this.state = {
            zoom_oauth: window.setup.zoom_oauth,
            zoom_key: window.setup.zoom_key,
            zoom_redirect: window.setup.zoom_redirect,
            zoom_active: window.setup.zoom_active,
            zoom_manage: false,
            dialog_load: false,
            security: {name: window.setup.security_name, token: window.setup.security_value }
        };

        this.zoomActivate   = this.zoomActivate.bind(this);
        this.zoomDeactivate   = this.zoomDeactivate.bind(this);
        this.zoomCancel     = this.zoomCancel.bind(this);
        
       // console.log("Final TopNav",this.state);
    }


    zoomCancel()
    {
        this.setState({'zoom_manage': false});
    }


    zoomActivate( current_state )
    {
        if(current_state === 0)
        {
            window.open(this.state.zoom_oauth + '&client_id=' + this.state.zoom_key + '&redirect_uri=' + this.state.zoom_redirect);
        }
        else
        {
            this.setState({'zoom_manage': true});
        }
    }


    zoomDeactivate(account)
    {
        var _this = this;
        var chain = '';
        chain = chain +  "&account=" + encodeURIComponent( account );
 
        this.setState({'dialog_load': true}, function(){

            fetch("/zoom/ajax_zoom_revoke", {
                method: "POST",
                mode: 'same-origin', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'include', // include, *same-origin, omit                            
                body: this.state.security.name + '=' + this.state.security.token + chain,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": 'XMLHttpRequest',
                    "X_REQUESTED_WITH": 'xmlhttprequest'
                }
            })
            .then(res => {
                return res.json();
            })
            .then(response => {
                //console.log(data);
                if(response.status === 'OK')
                {
                    _this.setState({zoom_manage: false, dialog_load: false}, function(){
                        window.location.reload();
                    });
                }
                else
                {
                    _this.setState({zoom_manage: false, dialog_load: false}, function(){
                        window.location.reload();
                    });
    
                    //this.setState({cust_add: false});
                }
            }).catch(function(err){
                _this.setState({zoom_manage: false, dialog_load: false}, function(){
                    window.location.reload();
                });
                console.error('Failed to archive the event', err);
            }); 

        })
    }

 

   

    render()
    {
        var classes = this.props.classes;
        var data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
              {
                label: 'Charges',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
//                data: [65, 59, 80, 81, 56, 55, 40]
                data: []
              },
              /*
              {
                label: 'Paid',
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                hoverBorderColor: 'rgba(255,99,132,1)',
//                data: [65, 59, 80, 81, 56, 55, 40]
                data: []
              }
              */              

            ]
        };


      //  console.log('render ',this.props);
        return (
            <div className={classes.root}>


                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        {window.vocab.topbar}
                    </Typography>
                    <Button color="inherit" variant="outlined" color="primary" disabled={true}>Payments</Button>

                    </Toolbar>
                </AppBar>


                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                <h2>Payments and charges</h2>
                                <Divider />
                                <div className="element">
                                    <Bar
                                    data={data}
                                    width={100}
                                    height={200}
                                    options={{ maintainAspectRatio: false }} 
                                    />
                                    </div>
                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>

        

                    <Grid item xs={12}>
                        <div className="section"></div>
                    </Grid> 
                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                <h2>{window.vocab.services}</h2>
                                <Divider />
                                <Grid container  justify="flex-start" alignItems="stretch" spacing={2}>
                                <Grid item xs={6}>
                                        <ExtensionWidget 
                                            icon={<SwapHorizOutlinedIcon />} 
                                            label={'Zoom'} 
                                            descriptor={'meeting integration'}
                                            description={'This service enables you to connect reservie to your Zoom account and manage Zoom meetings from within reservie.'} 
                                            cost={'Extension Price: Free'} 
                                            status={this.state.zoom_active}
                                            available={true}
                                            onclick={this.zoomActivate}
                                            />
                                        
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ExtensionWidget 
                                            icon={<SwapHorizOutlinedIcon />} 
                                            label={'mailchimp'} 
                                            descriptor={'exchange'}
                                            description={'This service enables you to connect reservie to your mailchimp account and to allow customer\'s details to be shared between the two platforms.'} 
                                            cost={'Price: £0.02 per transaction'} 
                                            status={false}
                                            available={false}
                                            />
                                        
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ExtensionWidget 
                                            icon={<SmsOutlinedIcon />} 
                                            label={'SMS'} 
                                            descriptor={'manager'}
                                            description={'This service enables you to send SMS messages to your clients.'} 
                                            cost={'Number rental: £1.00 per month. SMS: £0.10 per SMS'} 
                                            status={false}
                                            available={false}
                                            />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ExtensionWidget 
                                            icon={<QuestionAnswerOutlinedIcon />} 
                                            label={'questionaire'} 
                                            descriptor={'manager'}
                                            description={'This service enables you create a questionaire for your customers to complete.'} 
                                            cost={'Price: £0.05 per completed, stored questionaire'} 
                                            status={false}
                                            available={false}
                                            />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ExtensionWidget 
                                            icon={<AddShoppingCartOutlinedIcon />} 
                                            label={'shopping'} 
                                            descriptor={'cart'}
                                            description={'This service enables you to add a shopping cart service to your account'} 
                                            cost={'Price: free'} 
                                            status={false}
                                            available={false}
                                            />
                                    </Grid>                                    
                                </Grid> 
                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>
                {this.state.zoom_manage === true && (
                    <ZoomManagerDialogModule    accounts={window.setup.integrations.zoom} 
                                                ondelete={this.zoomDeactivate} 
                                                onactivate={this.zoomActivate}
                                                onCancel={this.zoomCancel}
                                                loading={this.state.dialog_load} />
                )}

            </div>            
        );
    }

};



const ExtensionsManager = connect(null, mapDispatchToProps)(ExtensionsManagerModule);
export default withStyles(styles)(ExtensionsManager);
