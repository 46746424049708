import React, { Component, forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';

import MaterialTable, {MTableToolbar} from 'material-table';
import TableCell from '@material-ui/core/TableCell';
import Fab from '@material-ui/core/Fab';
import TableRow from '@material-ui/core/TableRow';

import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Button from '@material-ui/core/Button';


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CustomersQuestionairePanelManager from './customer-view-questionaire';
import StarIcon from '@material-ui/icons/Star';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';


import './customers.css';
import QuestionnaireAddDrawer from './Questionnaires/Questionnaire-add-drawer';
import QuestionnaireEditDrawer from './Questionnaires/Questionnaire-edit-drawer';

class ViewButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            object: props.data,
            callback: props.callback,
            doview: false,
            security: props.security,
            disabled: false,

        }

        this.onInitiateView = this.onInitiateView.bind(this);
        this.doClose = this.doClose.bind(this);

        //console.log('Generating the disable button', this.state);

    }
    onInitiateView = () => {
        this.setState({doview: true});
    }



    doClose() {
        this.setState({doview: false});
    }

    render() {
        var classes= this.props.classes;
        return (
            <div>
                <Fab className={classes.fabGo}><VisibilityIcon className={classes.fabIcon} onClick={this.onInitiateView}/></Fab>                        
                <CustomersQuestionairePanelManager state={this.state.doview} data={this.state.object} onClose={this.doClose}/>
            </div>
        );
    }
}


class QuestionaireCustomerTablePanel extends Component{


    
    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            security: props.security,
            loading: true,
            data: props.questionaires||[],
            questionnaire_templates: props.questionnaire_templates,
            rows_per_page: 5,
            page: 0,
            refresh: false,
            customer: props.customer,
            selected: [],
            edit: false

        };

        this.handleChangePage           = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage    = this.handleChangeRowsPerPage.bind(this);
        this.state.loading              = false;
        this.getData                     = this.getData.bind(this);
        this.isSelected                 = this.isSelected.bind(this);
        this.setItemChecked             = this.setItemChecked.bind(this);
        this.onEditSchedule             = this.onEditSchedule.bind(this);
          
    }

    onEditSchedule()
    {
        this.setState({'edit': true});
    }


    setItemChecked(o, checked)
    {
        const a = [];
        console.log("final", a);
        if(checked)
        {
            a.push(o);
        }
        this.setState({selected: a});

    }

    isSelected( id ){
         const selected = this.state.selected.find( i => i.id === id);
         //console.log("Is selected", !!selected);
         return !!selected;
    }



    handleChangeRowsPerPage(event)
    {
        this.setState({'rows_per_page': parseInt(event.target.value, 10)});

    }
    handleChangePage(event, newPage)
    {
        this.setState({'page': parseInt(newPage, 10)});

    }

    getData()
    {
        //console.log('initial object', this.state.data);
        
        return this.state.data.map( d => {
            let x = Object.assign({}, d);
            x.date = moment(d.date).format("ll"); // "Sunday, February 14th 2010, 3:25:50 pm"
            x.expires = moment(d.expires).format("ll"); // "Sunday, February 14th 2010, 3:25:50 pm"
            //console.log('Adjusted object', x);
            return x;
        }) || [];

    }



    render()
    {
        console.log('Loading customer data', this.state);
        var classes = this.props.classes;
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };

        const l = this.getData().length;
        const c = [];
        if(l <= 25)
        {
            c.push(l);
        }
        if(l > 25)
        {
            c.push(25);
        }
        if(l > 50)
        {
            c.push(50);
        }  
        if(l > 100)
        {
            c.push(100);
        }  
        if(l > 200)
        {
            c.push(200);
        }  
        return (
            <div>
                <Grid container justify="flex-start" alignItems="stretch">
                    <Grid item xs={12} md={12}>
                        <MaterialTable icons={tableIcons} title=""
                                // other props
                            data={this.getData()}
                            columns={[
                                    { title: 'ID', field: 'id', width: "80px" },
                                    { title: 'Important', field: 'important', Width: "60px", },
                                    { title: 'Questionnaire', field: 'questionaire', maxWidth: "150px", align:"center"},
                                    { title: 'Completed', field: 'completed' },
                                    { title: 'Expires', field: 'expires' },
                                    { title: 'View', field: 'view', sorting: false,  maxWidth: "50px"  },
                            ]}
                            options={{
                                selection: false,
                                pageSize: l,
                                pageSizeOptions: c,
                                padding: 'dense',
                                search: this.props.fullwidth,
                            }}
                            components={{
                                Container: props => <div>{props.children}</div>, 
                                Toolbar: props => (
                                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "flex-end", alignItems:"center" }} > 
                                        <QuestionnaireEditDrawer  disabled={this.state.selected.length === 0} questionnaire_templates={this.state.questionnaire_templates} questionnaire={this.state.selected} open={true} customer={this.state.customer} refresh={this.props.refresh}/>
                                        <QuestionnaireAddDrawer questionnaire_templates={this.state.questionnaire_templates} customer={this.state.customer} refresh={this.props.refresh}/>                                     
                                      <MTableToolbar {...props} />
                                    </div>), 

                                Row: ({ data }) => {
                                    return (
                                        <React.Fragment>
                                            <TableRow key={data.id}>  
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={this.isSelected(data.id)}
                                                        inputProps={{ 'aria-labelledby': data.id }}
                                                        onClick={(e) => this.setItemChecked( data, e.target.checked)}
                                                    /> {data.id}                                                                                     
                                                </TableCell>
                                                <TableCell>
                                                {
                                                    !!data.important_active  &&
                                                    <Tooltip title="Important questionnaire data added">
                                                        <IconButton className={classes.fabDisabled}> <StarIcon className={classes.fabStar} /></IconButton>
                                                    </Tooltip>
                                                }                                                    
                                                </TableCell>
            
                                                <TableCell align="center">{data.quid}</TableCell>
                                                <TableCell>{data.date}</TableCell>
                                                <TableCell>{data.expires}</TableCell>
                                                <TableCell><ViewButton data={data} classes={classes}/></TableCell>
                                            </TableRow>
                    
                                        </React.Fragment>
                                    )
                                }
                            }}
                            
                        />
                    </Grid>
                </Grid>

            </div>
        );
    }
}


export default QuestionaireCustomerTablePanel;
