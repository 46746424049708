import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';


import '../core/dashboard.css';


//import Link from '@material-ui/core/Link';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CodeIcon from '@material-ui/icons/Code';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {setStep, reloadData, setCloneState} from '../redux/actions';
import RefreshIcon from '@material-ui/icons/Refresh';
import CoreDialog  from '../core/dialog'
import CodeDialog  from '../core/code-dialog';
import Loading from '../core/loading';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import moment from 'moment'
import Moment from 'react-moment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EventBasicDetailDialog from './event-basic-detail-dialog';
import GroupIcon from '@material-ui/icons/Group';
import EventRegisterDialog from './event-register-dialog';
import GroupAddRoundedIcon from '@material-ui/icons/GroupAddRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { red, grey } from '@material-ui/core/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import WarningIcon from '@material-ui/icons/Warning';
import  {
    EVENT_APP_CACHE_STATE_DEFAULT,
    EVENT_MANAGER_VIEW_DATA_MODE_COURSE,
    EVENT_MANAGER_VIEW_DATA_MODE_WEEK,
} from '../redux/types';

import 'react-dates/lib/css/_datepicker.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,       
    },   
    date:{
        backgroundColor: 'rgba(0,0,0,0.1)',
        color: 'rgba(0,0,0,0.8)',
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontWeight: '600',
        marginTop: '10px',
        marginBottom: '10px',
    },
    idCell:{
        width: '8.333%', //100% divided by 12
        textAlign:'center'
    },
    dateCell:{
        textAlign:'center'
    },
    timeCell:{
        textAlign:'center'
    },      
    editCell:{
        textAlign:'center'
    }, 
    typeCell:{
        width: '8.333%', //100% divided by 12
        textAlign:'center'
    },
    soldCell:{
        width: '8.333%', //100% divided by 12
        textAlign:'center'
    },
    infoCell:{
        width: '8.333%', //100% divided by 12
        textAlign:'center'
    },    
    codeCell:{
        width: '8.333%', //100% divided by 12
        textAlign:'center'
    },  
    cloneCell:{
        width: '8.333%', //100% divided by 12
        textAlign:'center'
    },      
    deleteCell:{
        width: '8.333%', //100% divided by 12
        textAlign:'center'
    },          
    nameCell:{
        width: '16.66%'
    },
    cellSize1:{
        width: '8.333%', //100% divided by 12
    },  
    cellSize2:{
        width: '16.666%', //100% divided by 12
    },  
    cellSize3:{
        width: '16.666%', //100% divided by 12
    },           
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    danger: {
        color: '#b80000',
        fontSize: '0.8em'
    },
    fabEdit:{
        backgroundColor: '#3c8dbc',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#346785',
        }
    },
    fabCode:{
        backgroundColor: '#9e9e9e',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#858585',
        }
    },
    fabReg:{
        backgroundColor: 'rgba(83, 120, 138, 1)',
        color: '#fff',
        '&:hover':{
            backgroundColor: 'rgba(83, 120, 138, 0.5)',
        }
    },    
    fabDelete:{
        backgroundColor: red[500],
        color: '#fff',
        '&:hover':{
            backgroundColor: red[600],
        },
        '&:disabled':{
            color: '#fff',
            backgroundColor: grey[300],
        }
    },
    fabClone:{
        backgroundColor: '#FFA500',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#ea9700',
        }
    },             
    fabIcon:{
        '& .MuiSvgIcon-root':{
            fill: '#fff',
            color: '#fff',
            '&:hover':{
                color: '#ffe',
            }
        }
    }, 
    fabAction:{
        backgroundColor: '#FFA500',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#ea9700',
        }
    }, 

});

class EditButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('EditButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="editbutton" className={classes.fabEdit} aria-label="Manage customer" onClick={this._handleClick}>
                <EditRoundedIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}

class CodeButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('CodeButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="manage" className={classes.fabCode} aria-label="Manage customer" onClick={this._handleClick}>
                <CodeIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}


class InfoButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            events: props.events,
            callback: props.callback
        }

        console.log('CodeButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data, this.state.events);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="register" className={classes.fabReg} aria-label="View event registers" onClick={this._handleClick}>
                <GroupIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}

class DeleteButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('DeleteButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="delete" className={classes.fabDelete} aria-label="Delete event" onClick={this._handleClick}>
                <DeleteForeverIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}


class CancelButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }


        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="delete" disabled={parseInt(this.state.data.sold)>0} className={classes.fabDelete} aria-label="Delete event" onClick={this._handleClick}>
                <WarningIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}

class CloneButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="clone" className={classes.fabClone} aria-label="Clone event" onClick={this._handleClick}>
                <FileCopyIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}

                                                                
class ActionButton extends React.Component {

    constructor(props) {
        super(props);
  
        this.state = {
            data: props.data,
            events: props.events,
            callback: props.callback
        }
  
        console.log('ActionButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }
  
    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data, this.state.events);
    }
  
    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="clone" className={classes.fabAction} aria-label="event register" onClick={this._handleClick}>
                <GroupAddRoundedIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
  }



function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        reloadData: spec => dispatch(reloadData(spec)),
        setCloneState: spec => dispatch(setCloneState(spec))
    };
  }


  


class EventsManagerModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            obj: props.data.obj,
            vouchers: props.data.vouchers,
            start_date: moment(),
            end_date: moment().add(7, 'days'),
            focusedInput: null,
            security: {},
            refresh: false,
            event_edit: false,
            event_delete: false,
            event_focus: null,
            event_code: false,
            event_info: false,
            event_register: false,
            event_cancel: false,
            edit_data: {},
            loading: false,
            reload: props.refresh,
            all_events: [],
            orientation: window.matchMedia("(max-width: 700px)").matches ? 'vertical' : 'horizontal'
        };

        if(this.state.obj.start)
        {
            this.state.start_date = moment(this.state.obj.start);
        }
        if(this.state.obj.end)
        {
            this.state.end_date =  moment(this.state.obj.end);
        }
        for(var i = 0; i < this.state.obj.events.length; i++)
        {
            this.state.obj.events[i].open = false;
        }


        
        console.log("EventsManagerModule", this.state);
        

        this.state.security = props.security;


        this.onAddEventDialogClose = this.onAddEventDialogClose.bind(this);
        this.onAddEvent            = this.onAddEvent.bind(this);
        this.onEditEvent          = this.onEditEvent.bind(this);
        this.onDeleteEvent        = this.onDeleteEvent.bind(this);
        this.onCancelEvent        = this.onCancelEvent.bind(this);
        this.onCompleteDelete     = this.onCompleteDelete.bind(this);
        this.onCompleteCancel     = this.onCompleteCancel.bind(this);
        this.onCancelDelete       = this.onCancelDelete.bind(this);
        this.onEventCodeClose      = this.onEventCodeClose.bind(this);
        this.onCodeSelect         = this.onCodeSelect.bind(this);
        this.onRefresh            = this.onRefresh.bind(this);
        this.onDoRefresh            = this.onDoRefresh.bind(this);
        this.onDateChange         = this.onDateChange.bind(this);
        this.onCloneEvent          = this.onCloneEvent.bind(this);
        this.onOpen                = this.onOpen.bind(this);
        this.onInfoEvent           = this.onInfoEvent.bind(this);
        this.onEventInfoView       = this.onEventInfoView.bind(this);
        this.onEventInfoCancel       = this.onEventInfoCancel.bind(this);
        this.onEventRegisterClose   = this.onEventRegisterClose.bind(this);
        this.onActionEvent     = this.onActionEvent.bind(this);

     //   alert(props.match.params);
        
       // console.log("Final TopNav",this.state);

    }

    onActionEvent(data, events)
    {
        //alert('click');
        var obj = {id: data.id, date: data.next, basic: data.basic, payments: data.payment, mode: data.mode};
        this.setState({event_register: true, event_focus: obj, all_events: events});
    }


    onEventInfoView(id, basic, data, payments, mode)
    {
        const d = Object.assign({}, data);
        const b = Object.assign({}, basic);
        const p = Object.assign({}, payments);

        var obj = {id: id, date: d, basic: b, payments: p, mode: mode};
        this.setState({event_register: true, event_focus: obj}, function(){} );
    }

    onEventInfoCancel()
    {
        this.setState({event_info: false, event_focus: null});
    }

    onEventRegisterClose(data)
    {
        this.setState({event_register: false, event_focus: null});
    }    

    onInfoEvent(data, events)
    {
        this.setState({event_info: true, event_focus: data, all_events: events});
    } 

    onEditEvent(data)
    {
        var schedule = Object.assign({}, data.scheduling);

        for(var i = 0; i < schedule.configuration.dates.length; i++)
        {
            schedule.configuration.dates[i].iteration = i + 1;
        }


        var o = { scheduling:
            {
              create: false,
              data: {
                  id: data.id,
                  mode: data.mode,
                  basic: data.basic,
                  scheduling: schedule,
                  payment: data.payment,
                  actions: data.actions,
                  properties: data.properties,
                  virtual: data.virtual
              }
            },
            state: EVENT_APP_CACHE_STATE_DEFAULT
        };
        this.props.setStep(o);           
    }

    onCloneEvent(data)
    {

        console.log('onCloneEvent', data);

        var o = { scheduling:
            {
              data: {
                  id: data.id,
                  basic: data.basic,
                  scheduling: data.scheduling,
                  payment: data.payment,
                  actions: data.actions,
                  properties: data.properties
              }
            },
            state: EVENT_APP_CACHE_STATE_DEFAULT
        };
        this.props.setCloneState(o);           
    }

    onDeleteEvent(data)
    {
        this.setState({event_delete: true, event_focus: data});
    }


    onCancelEvent(data)
    {
        const obj = {id: data.id, date: data.next, basic: data.basic, payments: data.payment, mode: data.mode};
        this.setState({event_cancel: true, event_focus: obj});
    }


    onCancelDelete()
    {
        this.setState({event_delete: false, event_cancel: false, event_focus: {}});

    }
    onOpen( id, state )
    {
        var x = Object.assign({}, this.state.obj);


        for(var i = 0; i < x.events.length; i++)
        {
            if(x.events[i].id === id)
            {
                x.events[i].open = state;
                this.setState({obj: x, reload: !this.state.reload});
                return;
            }
        }

    }

    onCompleteDelete()
    {

        var id = this.state.event_focus.id ||  null;
        var _this = this;
        var chain = '';
        chain = chain +  "&id=" + id;
 
        fetch("/events/ajax_events_archive", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({event_delete: false, event_focus: {}, reload: true }, function(){
                    _this.onRefresh();
                });
            }
            else
            {
            }
        }).catch(function(err){
            _this.setState({event_delete: false, event_focus: {}});
            console.error('Failed to archive the event', err);
        });      



    }

    
    onCompleteCancel()
    {

        var _this = this;
        var chain = '';
        chain = chain +  "&id=" + this.state.event_focus.id;
        chain = chain +  "&did="  + this.state.event_focus.date.id;
 
        fetch("/events/ajax_event_date_archive", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({event_delete: false,event_cancel: false, event_focus: {}, reload: true }, function(){
                    _this.onRefresh();
                });
            }
            else
            {
                _this.setState({event_delete: false, event_cancel: false, event_focus: {}});
            }
        }).catch(function(err){
            _this.setState({event_delete: false, event_cancel: false, event_focus: {}});
            console.error('Failed to archive the event', err);
        });      



    }


    onAddEvent(event)
    {
        var o = { scheduling:
            {
              create: true,
              id: null,
            },
            state: EVENT_APP_CACHE_STATE_DEFAULT};
        this.props.setStep(o);        
    }





    onAddEventDialogClose( refresh )
    {
        this.setState({pass_add: false, event_edit:false, reload: refresh}, function(){
            if(refresh)
            {
                this.onRefresh()
            }
        });

    }    
    
    onEventCodeClose()
    {
        this.setState({event_code: false, event_focus: {}});
    }
    onCodeSelect(data)
    {
        this.setState({event_code: true, event_focus: data});

    }

    onDoRefresh( )
    {
        this.onRefresh(true);
    }

    onRefresh( refresh )
    {
        var _this = this;
        this.setState({'loading': true});
        var chain = 'start=' + this.state.start_date.valueOf();
        chain = chain + '&end=' + this.state.end_date.valueOf();
        chain = chain + '&type=' + window.setup.subview
        fetch("/backenddata/ajax_fetch_events", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + '&refresh=' + refresh + '&' + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(!response.hasOwnProperty("error"))
            {
              let o = {
                'settings': response.data
              };
              console.log("loading ajax", o);
              _this.props.reloadData( o );
              _this.setState({'loading': false, 'reload': false});
            }
            else
            {
               /* handle error */
               _this.setState({'loading': false, 'reload': false});
            }
        }).catch(function(err){
            console.error('Failed to load the pass', err);
            _this.setState({'loading': false, 'reload': false});
        });     
    }

    onDateChange(obj)
    {
        var _this = this;
        if(obj.startDate !== null && obj.endDate !== null)
        {
            this.setState({'start_date': obj.startDate, 'end_date': obj.endDate}, function(){
                _this.onRefresh( true );
            });
        }

    }


 

    componentDidMount()
    {        
        var _this = this;
        if(this.state.reload)
        {
            this.setState({'reload': false}, function(){
                this.onRefresh( true );
            })
        }
        if(window.action !== null)
        {
            var action = Object.assign({}, window.action);
            window.action = null;
            if(action.type === 'edit')
            {
                var chain = 'eid=' + action.eid;
                fetch("/backenddata/ajax_fetch_event", {
                    method: "POST",
                    mode: 'same-origin', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'include', // include, *same-origin, omit                            
                    body: this.state.security.name + '=' + this.state.security.token + '&' + chain,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-Requested-With": 'XMLHttpRequest',
                        "X_REQUESTED_WITH": 'xmlhttprequest'
                    }
                })
                .then(res => res.json())
                .then(
                  (response) => {
                    if(!response.hasOwnProperty("error"))
                    {
                        _this.onEditEvent(response.data.obj.event);     
                    }
                    else
                    {
                        console.error('Communications response', response);                                
                    }
                  },
                  // Note: it's important to handle errors here
                  // instead of a catch() block so that we don't swallow
                  // exceptions from actual bugs in components.
                  (error) => {
                    console.error('Communications error', error)                                
                  }
                )
            }
            else if(action.type === 'info')
            {
                var chain = 'eid=' + action.eid;
                chain += '&did=' + action.did;

                fetch("/backenddata/ajax_fetch_event", {
                    method: "POST",
                    mode: 'same-origin', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'include', // include, *same-origin, omit                            
                    body: this.state.security.name + '=' + this.state.security.token + '&' + chain,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-Requested-With": 'XMLHttpRequest',
                        "X_REQUESTED_WITH": 'xmlhttprequest'
                    }
                })
                .then(res => res.json())
                .then(
                  (response) => {
                    if(!response.hasOwnProperty("error"))
                    {

                        var obj = {
                                    id:       response.data.obj.event.id, 
                                    date:     response.data.obj.event.next, 
                                    basic:    response.data.obj.event.basic, 
                                    payments: response.data.obj.event.payment, 
                                    mode:     response.data.obj.event.mode
                                  };


                        this.setState({event_register: true, event_focus: obj}, function(){
                            console.log("Updated state", this.state);
                        });      
                    
                    }
                    else
                    {
                        console.error('Communications response', response);                                
                    }
                  },
                  // Note: it's important to handle errors here
                  // instead of a catch() block so that we don't swallow
                  // exceptions from actual bugs in components.
                  (error) => {
                    console.error('Communications error', error)                                
                  }
                )
            }
            else if(action.type === 'create')
            {
                this.onAddEvent();                                              
            }

        } 

    }

    componentDidUpdate( props )
    {
       
    }
   

  

    render()
    {
        var classes = this.props.classes;
        console.log('edit data', this.state.edit_data);

        var events = this.state.obj.events;
        var customers = this.state.obj.customers;
        var waiting = this.state.obj.waiting || [];
      

        if(window.setup.subview === EVENT_MANAGER_VIEW_DATA_MODE_WEEK)
        {
            var objs = {};
            for(var i = 0; i < this.state.obj.events.length; i++)
            {
                for(var j = 0; j < this.state.obj.events[i].events.length; j++)
                {
                    objs[this.state.obj.events[i].events[j].id] = Object.assign({}, this.state.obj.events[i].events[j]);
                }
            }
            events = Object.values(objs);
        }

        return (
            <div className={classes.root}>


                <AppBar position="sticky" className="appBar">
                    <Toolbar>
         
                    <Grid container direction="row" justify="flex-end" alignContent="flex-start" alignItems="stretch" spacing={2} >
                        <Grid item>
                            <Button  variant="outlined" className="button-green space-right" onClick={this.onAddEvent}>Add <Hidden smDown>Event</Hidden></Button>
                        </Grid>
                        <Grid item>
                            <Button  variant="outlined" color="primary" onClick={this.onDoRefresh}><RefreshIcon/>
                                <Hidden smDown>
                                    Refresh
                                </Hidden>
                            </Button>
                        </Grid>
                    </Grid>

                    </Toolbar>
                </AppBar>


                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >

                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <Grid container justify="flex-start" alignItems="stretch">
                                        <Grid item xs={12} md={2}>
                                            {window.setup.subview === EVENT_MANAGER_VIEW_DATA_MODE_COURSE && (          
                                                <h2>Course view</h2>   
                                            )}
                                            {window.setup.subview === EVENT_MANAGER_VIEW_DATA_MODE_WEEK && (          
                                                <h2>Week view</h2>   
                                            )}                                            
                                            </Grid>
                                        <Grid item xs={12} md={10}>
                                            <div className="drp_picker">
                                                <DateRangePicker
                                                isOutsideRange={day => (false)}
                                                startDate={this.state.start_date} // momentPropTypes.momentObj or null,
                                                startDateId="startDate" // PropTypes.string.isRequired,
                                                endDate={this.state.end_date} // momentPropTypes.momentObj or null,
                                                endDateId="endDate" // PropTypes.string.isRequired,
                                                onDatesChange={this.onDateChange} // PropTypes.func.isRequired,
                                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                withPortal={true}
                                                noBorder={true}
                                                orientation={this.state.orientation}   
                                                navPrev={
                                                    <div className="rvButtonLeft">
                                                        <IconButton aria-label="back"><ArrowBackIcon /></IconButton>
                                                    </div>
                                                }
                                                navNext={
                                                    <div className="rvButtonRight">
                                                        <IconButton aria-label="forward"><ArrowForwardIcon /></IconButton>
                                                    </div>
                                                }
                    
                                                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                                displayFormat="DD MMM YYYY"
                                                />  
                                            </div>
                                          
                                        </Grid>



                                        {window.setup.subview === EVENT_MANAGER_VIEW_DATA_MODE_COURSE && (          
                                        <Grid item xs={12} md={12}>
                                            <TableContainer>                                
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>
                                                                ID
                                                            </TableCell> 
                                                            <TableCell className={classes.dateCell}>
                                                                Date
                                                            </TableCell> 
                                                            <TableCell className={classes.timeCell}>
                                                                Time
                                                            </TableCell>                                                             
                                                            <TableCell className={classes.nameCell}>
                                                                Name
                                                            </TableCell> 
                                                            <TableCell className={classes.soldCell}>
                                                                Type
                                                            </TableCell> 
                                                            <Hidden smDown>
                                                                <TableCell className={classes.soldCell}>
                                                                    Sold
                                                                </TableCell> 
                                                            </Hidden>
                                                            <TableCell className={classes.editCell}>
                                                                Info
                                                            </TableCell> 
                                                            <TableCell className={classes.editCell}>
                                                                Edit
                                                            </TableCell> 
                                                            <TableCell className={classes.codeCell}>
                                                                Code
                                                            </TableCell>  
                                                            <Hidden smDown>
                                                                <TableCell className={classes.cloneCell}>
                                                                    Clone
                                                                </TableCell>                                                              
                                                            </Hidden>
                                                            <TableCell className={classes.deleteCell}>
                                                                Archive
                                                            </TableCell>                                                                                                                                                                           
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {this.state.loading && (
                                                        <TableRow key={uuidv1()}>
                                                            <TableCell colSpan={11}>
                                                                <Loading />
                                                            </TableCell> 
                                                        </TableRow>
                    
                                                    )}
                                                    {this.state.loading === false && this.state.obj.events.map(el => (
                                                        <React.Fragment key={uuidv1()}>
                                                        <TableRow>        
                                                             
                                                            <TableCell className={classes.idCell}>
                                                                {el.id}
                                                            </TableCell>  
                                                            <TableCell className={classes.dateCell}>
                                                                <Moment format="ll">{el.next.date}</Moment> &nbsp;
                                                            </TableCell>
                                                            <TableCell className={classes.timeCell}>
                                                                <Moment format="HH:mm">{el.next.date + 'T'+ el.next.time}</Moment>
                                                            </TableCell>                                                            
                                                            <TableCell className={classes.nameCell}>
                                                                {el.basic.name}
                                                            </TableCell> 
                                                            <TableCell className={classes.soldCell}>
                                                                {el.mode.charAt(0).toUpperCase() + el.mode.slice(1).toLowerCase()}
                                                            </TableCell>                                                              
                                                            <Hidden smDown>
                                                                <TableCell className={classes.soldCell}>
                                                                {el.sold} of {el.stock} 
                                                                <div>  
                                                                    {el.dsold > 0 && (
                                                                    <Tooltip title="Indicates additional places that have been purchased for pricing options that are now disabled">
                                                                    <i className={classes.danger}>Warning {"+"}{el.dsold}</i>
                                                                    </Tooltip>
                                                                    )}
                                                                </div>
                                                                </TableCell>  
                                                            </Hidden>                                                                                                                                                                                           
                                                            <TableCell className={classes.editCell}>
                                                                <InfoButton classes={classes} data={el} events={events} callback={this.onInfoEvent} />
                                                            </TableCell>   
                                                            <TableCell className={classes.editCell}>
                                                                <EditButton classes={classes} data={el} callback={this.onEditEvent} />
                                                            </TableCell>                                         
                                                            <TableCell className={classes.codeCell}>
                                                                <CodeButton classes={classes} data={el} callback={this.onCodeSelect} />
                                                            </TableCell> 
                                                            <Hidden smDown>
                                                                <TableCell className={classes.cloneCell}>
                                                                    <CloneButton classes={classes} data={el} callback={this.onCloneEvent} />
                                                                </TableCell> 
                                                            </Hidden>

                                                            <TableCell className={classes.deleteCell}>
                                                                <DeleteButton classes={classes} data={el} callback={this.onDeleteEvent} />
                                                            </TableCell> 

                                                        </TableRow>
                                                        </React.Fragment>

                                                        ))}
                                                    </TableBody>

                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                        )}
                                        {window.setup.subview === EVENT_MANAGER_VIEW_DATA_MODE_WEEK && (    
                                            <React.Fragment>
                                                {this.state.loading && (
                                                    <Grid item xs={12} md={12} >
                                                        <Loading />
                                                    </Grid>
                                                )}                                                
                                                {this.state.loading === false && this.state.obj.events.map(fl => (
                                                <React.Fragment key={fl.id}>
                                                    <Grid item xs={12} md={12} className={classes.date}>
                                                        <Moment format="ddd, Do MMM YYYY">{fl.id}</Moment>
                                                    </Grid>
                                                    <Grid item xs={12} md={12}>
                                                        <TableContainer>                                
                                                            <Table>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell className={classes.idCell}>
                                                                            ID
                                                                        </TableCell> 
            
                                                                        <TableCell className={classes.timeCell}>
                                                                            Time
                                                                        </TableCell>                                                             
                                                                        <TableCell className={classes.nameCell}>
                                                                            Name
                                                                        </TableCell> 
                                                                        <TableCell className={classes.soldCell}>
                                                                            Type
                                                                        </TableCell> 
                                                                        <Hidden smDown>
                                                                            <TableCell className={classes.soldCell}>
                                                                                Sold
                                                                            </TableCell> 
                                                                        </Hidden>
                                                                        <TableCell className={classes.editCell}>
                                                                            Register
                                                                        </TableCell> 
                                                                        <TableCell className={classes.editCell}>
                                                                            Edit
                                                                        </TableCell> 
                                                                        <TableCell className={classes.codeCell}>
                                                                            Code
                                                                        </TableCell>  
                                                                        <Hidden smDown>
                                                                            <TableCell className={classes.cloneCell}>
                                                                                Clone
                                                                            </TableCell>                                                              
                                                                        </Hidden>
                                                                        <TableCell className={classes.deleteCell}>
                                                                                Cancel
                                                                        </TableCell>                                                                                                                                                                           
                                                                    </TableRow>
                                                                </TableHead>                                                        
                                                                <TableBody>
                                                                    {fl.events.map(el => (
                                                                        <React.Fragment key={uuidv1()}>
                                                                            <TableRow>        
                                                                                
                                                                                <TableCell className={classes.idCell}>
                                                                                    {el.id}
                                                                                </TableCell>  
                                                                                <TableCell className={classes.timeCell}>
                                                                                    <Moment format="HH:mm">{el.next.date + 'T'+ el.next.time}</Moment>
                                                                                </TableCell>                                                            
                                                                                <TableCell className={classes.nameCell}>
                                                                                    {el.basic.name}
                                                                                </TableCell> 
                                                                                <TableCell className={classes.soldCell}>
                                                                                    {el.mode.charAt(0).toUpperCase() + el.mode.slice(1).toLowerCase()}
                                                                                </TableCell>                                                              
                                                                                <Hidden smDown>
                                                                                    <TableCell className={classes.soldCell}>
                                                                                    {el.sold} of {el.stock} 
                                                                                    <div>  
                                                                                        {el.dsold > 0 && (
                                                                                            <Tooltip title="Indicates additional places that have been purchased for pricing options that are now disabled">
                                                                                                <i className={classes.danger}>warning: {"+"}{el.dsold}</i>
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    </div>
                                                                                    </TableCell>  
                                                                                </Hidden>                                                                                                                                                                                           
                                                                                <TableCell className={classes.editCell}>
                                                                                    <ActionButton classes={classes} data={el} events={events} callback={this.onActionEvent} />
                                                                                </TableCell>   
                                                                                <TableCell className={classes.editCell}>
                                                                                    <EditButton classes={classes} data={el} callback={this.onEditEvent} />
                                                                                </TableCell>                                         
                                                                                <TableCell className={classes.codeCell}>
                                                                                    <CodeButton classes={classes} data={el} callback={this.onCodeSelect} />
                                                                                </TableCell> 
                                                                                <Hidden smDown>
                                                                                    <TableCell className={classes.cloneCell}>
                                                                                        <CloneButton classes={classes} data={el} callback={this.onCloneEvent} />
                                                                                    </TableCell> 
                                                                                </Hidden>

                                                                                <Hidden>                                                                                                                                                             
                                                                                    <TableCell className={classes.deleteCell}>
                                                                                        <CancelButton classes={classes} data={el} callback={this.onCancelEvent} />
                                                                                    </TableCell> 
                                                                                </Hidden>

                                                                            </TableRow>
                                                                        </React.Fragment>
                                                                    ))}                                                               
                                                                </TableBody>
 
                                                            </Table>  
                                                        </TableContainer>                                                      
                                                    </Grid>                                                    



                                                </React.Fragment>      
                                                ))}
                                            </React.Fragment>      
                                        )}




                                    </Grid>
                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>




                {this.state.event_delete === true && (<CoreDialog title="Delete event" body="Warning! You are about to archive the entire event and all associated dates. Do you want to continue?" state={true} danger={true} onCancel={this.onCancelDelete} actions={[{label:"OK", action:this.onCompleteDelete}]}  />)}
                {this.state.event_cancel === true && (<CoreDialog title="Cancel event date" body="You are about to cancel this event date. Do you want to continue?" state={true} danger={true} onCancel={this.onCancelDelete} actions={[{label:"OK", action:this.onCompleteCancel}]}  />)}
                {this.state.event_code === true && (<CodeDialog callback={this.onEventCodeClose} data={this.state.event_focus} 
                                                        options={[{name:'Event purchase link', type:'address'}, 
                                                                  {name: 'Orange button widget', type:'obutton'}, 
                                                                  {name: 'Green button widget', type:'gbutton'},
                                                                  {name: 'Blue button widget', type:'bbutton'},
                                                                  {name: 'White button widget', type:'wbutton'},
                                                                ]}  /> )}
                {this.state.event_info === true && (<EventBasicDetailDialog onOK={this.onEventInfoView}  events={events}  onCancel={this.onEventInfoCancel} data={this.state.event_focus} state={true} />)}                                                
                {this.state.event_register === true && (<EventRegisterDialog
                                                            onCancel={this.onEventRegisterClose} 
                                                            data={this.state.event_focus} 
                                                            events={this.state.obj.future_events} 
                                                            open={true}
                                                            customers={customers}
                                                            waiting={waiting}
                                                            security={this.state.security} />)}                                                

            </div>            
        );
    }

};



const EventsManager = connect(null, mapDispatchToProps)(EventsManagerModule);
export default withStyles(styles)(EventsManager);
