 import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './events-clone-stepper';

import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import MenuItem from '@material-ui/core/MenuItem';
import SaveIcon from '@material-ui/icons/Save';

import  {
    EVENT_APP_CACHE_STATE_CLONE_SAVE,
    EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL,
    EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_WEEKLY,
    EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_MONTHLY     
 } from '../redux/types';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";


    const styles = theme => ({
        root: {
            flexGrow: 1,
        },
        progress:{
            color: 'rgba(0,0,0,0.5)',
            fontSize: '25px',
            textAlign: 'center'
        },
        progressIcon:{
            color: 'rgba(0,0,0,0.5)',

        },
        progressDone:{
            color: 'rgb(145,215, 104)',

        },  
        progressError:{
            color: 'rgba(199, 0, 0, 0.829)',

        },            
        title: {
              flexGrow: 1,
              color: '#fff',
              fontSize: '1.3rem'
        },                  
});



function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventCloneSaveModule extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            mode: props.mode,
            event: props.cache,
            saving: true,
            done: false,
            error: false,
            refresh: false,
            mode: props.mode,
            error_msg: '',
        }      


        obj = this.state;

        console.log('EventCloneSaveModule', this.state, props);

 


        this.state.security = props.security;
        this.myRef = React.createRef()  
        this.onSave   = this.onSave.bind(this); 
        this.onDoPrevious   = this.onDoPrevious.bind(this);
        this.onDoNext   = this.onDoNext.bind(this);

    }




    onDoPrevious(event)
    {
        var o = { 
            state: EVENT_APP_CACHE_STATE_CLONE_SAVE
        };
        this.props.rewindStep(o);        
    }


    
    onDoNext(event)
    {
        var x = Object.assign({}, this.state.errors);

        //if(regex.test(this.state.name))
   
        var o = {
                state: EVENT_APP_CACHE_STATE_CLONE_SAVE
            };
            this.props.setStep(o);

    }
  
    

    onSave()
    {
        var _this = this;

        console.log('data', this.state);
        _this.setState({'saving': true});
        _this.setState({'error': false});
        _this.setState({'done': false});

        var schedule  = {};
        var objs = [];
        schedule.mode = this.state.event.scheduling.mode;
        schedule.configuration = {};
        schedule.configuration.book_type = this.state.event.scheduling.configuration.book_type;

        if(this.state.event.scheduling.mode === EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL)
        {
            for(var i = 0; i < this.state.event.scheduling.configuration.dates.length; i++)
            {
                var obj = {};
                obj.duration = this.state.event.scheduling.configuration.dates[i].duration;
                obj.tz = this.state.event.scheduling.configuration.dates[i].tz;
                obj.date = this.state.event.scheduling.configuration.dates[i].date;
                obj.time = this.state.event.scheduling.configuration.dates[i].time;
                obj.invite = this.state.event.scheduling.configuration.dates[i].invite;
                obj.invite_id = this.state.event.scheduling.configuration.dates[i].invite_id;
                obj.invite_pass = this.state.event.scheduling.configuration.dates[i].invite_pass;
                objs.push(obj);
            }
            schedule.configuration.dates = objs;
        }
        else if(this.state.event.scheduling.mode === EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_WEEKLY)
        {
            schedule.configuration.active = this.state.event.scheduling.configuration.active;
            schedule.configuration.day_of_week = this.state.event.scheduling.configuration.day_of_week;
            schedule.configuration.duration = this.state.event.scheduling.configuration.duration;
            schedule.configuration.next_date = (this.state.event.scheduling.configuration.next_date === null? null:this.state.event.scheduling.configuration.next_date.format('YYYY-MM-DD'));
            schedule.configuration.end_date = (this.state.event.scheduling.configuration.end_date === null? null:this.state.event.scheduling.configuration.end_date.format('YYYY-MM-DD'));
            schedule.configuration.time = this.state.event.scheduling.configuration.time.format('HH:mm:00');
            schedule.configuration.tz = this.state.event.scheduling.configuration.tz;


        }
        else if(this.state.event.scheduling.mode === EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_MONTHLY)
        {

            schedule.configuration.active = this.state.event.scheduling.configuration.active;
            schedule.configuration.day_of_week = this.state.event.scheduling.configuration.day_of_week;
            schedule.configuration.week = this.state.event.scheduling.configuration.week;
            schedule.configuration.duration = this.state.event.scheduling.configuration.duration;
            schedule.configuration.next_date = (this.state.event.scheduling.configuration.next_date === null? null:this.state.event.scheduling.configuration.next_date.format('YYYY-MM-DD'));
            schedule.configuration.end_date = (this.state.event.scheduling.configuration.end_date === null? null:this.state.event.scheduling.configuration.end_date.format('YYYY-MM-DD'));
            schedule.configuration.time = this.state.event.scheduling.configuration.time.format('HH:mm:00');
            schedule.configuration.tz = this.state.event.scheduling.configuration.tz;
            
        }

        var chain =  'scheduling=' + JSON.stringify(schedule);
        chain +=  '&virtual=' + JSON.stringify(this.state.event.virtual);
        chain +=  '&id=' + this.state.event.id;
        chain +=  '&mode=' + this.state.mode;

        try{
            fetch("/events/do-ajax-event-clone-v2", {
              method: "POST",
              mode: 'same-origin', // no-cors, *cors, same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'include', // include, *same-origin, omit                            
              body: window.setup.security_name + '=' + window.setup.security_value + '&'+ chain,
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  "X-Requested-With": 'XMLHttpRequest',
                  "X_REQUESTED_WITH": 'xmlhttprequest'
              }
          })
          .then(res => {
              return res.json();
          })
          .then(response => {
              var x = Object.assign({}, window.setup);
              x.data = response.data;
              //console.log(data);
              if(!response.hasOwnProperty("error"))
              {
                _this.setState({'saving': false});
                _this.setState({'error': false});
                _this.setState({'done': true});
                setTimeout(function(){
                    _this.onDoNext();
                }, 2000);

  
              }
              else
              {
                /* handle error */
                _this.setState({'saving': false});
                _this.setState({'error': true});
                _this.setState({'done': false});
    
              }
          }).catch(function(err){
            _this.setState({'saving': false, 'error': true, 'done': false});
            console.error('Failed to save the event - connection', err);          
          });  

        }
        catch(e)
        {
            _this.setState({'saving': false});
            _this.setState({'error': true});
            _this.setState({'done': false});
            console.error('Failed to save the event', e);

        }  

    }


    componentDidMount()
    {
        this.onSave();
    }


  

    render()
    {
        var classes = this.props.classes;

        console.log('render', this.state);

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Scheduling and date management - manual scheduling
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="center" alignItems="center" spacing={2} >


                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="center" spacing={2}>
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">

                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        
                                        <Grid item xs={12} md={12}>
                                            <StepperWidget key={uuidv1()} step={2}/>

                                        </Grid>
                                        
                                        
                                        {this.state.saving === true && (<Grid item xs={12}>
                                            <div className={classes.progress}>
                                                <CircularProgress className={classes.progressIcon} style={{ height: 100, width: 100, textAlign: 'center' }}/>                                                                          
                                                <p>
                                                    Standby whilst we clone the event
                                                </p>
                                            </div>
                                        </Grid>)}

                                        {this.state.done === true && (<Grid item xs={12}>
                                            <div className={classes.progress}>
                                                <CheckCircleOutlineRoundedIcon className={classes.progressDone} style={{ height: 100, width: 100, textAlign: 'center' }}/>                                                                          
                                                <p>
                                                    Done
                                                </p>
                                            </div>
                                        </Grid>)}
                                        {this.state.error === true && (<Grid item xs={12}>
                                            <div className={classes.progress}>
                                                <ErrorOutlineRoundedIcon className={classes.progressError} style={{ height: 100, width: 100, textAlign: 'center' }}/>                                                                          
                                                <p>
                                                    Error {this.state.error_msg}
                                                </p>
                                            </div>
                                        </Grid>)}

                                        <Grid item xs={4} md={3}>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            size="large"
                                            className="rv-eng-next-button"
                                            startIcon={<NavigateBeforeIcon />}
                                            onClick={this.onDoPrevious}
                                            fullWidth={true}
                                            disabled={!this.state.error}
                                        >
                                            Back
                                        </Button>                          
                                        </Grid>
                                        <Grid item xs={4} md={6}></Grid>
                                        <Grid item xs={4} md={3}>                                          
                                        </Grid>                      
                                    </Grid>


                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>
            </div>            
        );
    }

};



const EventCloneSave = connect(null, mapDispatchToProps)(EventCloneSaveModule);
export default withStyles(styles)(EventCloneSave);
