import React, { Component } from 'react';
import { connect } from "react-redux";
import {Container} from '@material-ui/core';
import Builder from './builder';
import {loadData} from './redux/actions';
import './App.css';
import './index.scss';


import {
  VIEW_TYPE_HOME_CONTROL_PANEL,
  VIEW_TYPE_CUSTOMER_PANEL,
  VIEW_TYPE_HOME_PASSES_PANEL,
  VIEW_TYPE_EVENTS_PANEL,
  VIEW_TYPE_EXTENSION_MANAGER,
  VIEW_TYPE_SUBSCRIPTIONS_PANEL,
  VIEW_TYPE_MANDATES_PANEL,
  VIEW_TYPE_INVOICE,
  VIEW_TYPE_REPORTS_PANEL,
  VIEW_TYPE_SCHEDULES_PANEL,
  VIEW_TYPE_QUESTIONAIRE_PANEL,
  VIEW_TYPE_POLICY_PANEL,
  VIEW_TYPE_VIDEO_PANEL,
} from './redux/types';


import {
  CUSTOMER_APP_CACHE_TEMPLATE, 
  PASSES_APP_CACHE_TEMPLATE, 
  EVENTS_MANAGER_APP_CACHE_TEMPLATE,
  SUBSCRIPTIONS_MANAGER_APP_CACHE_TEMPLATE,
  REPORT_MANAGER_APP_CACHE_TEMPLATE,
  SCHEDULES_MANAGER_APP_CACHE_TEMPLATE,
  QUESTIONAIRE_MANAGER_APP_CACHE_TEMPLATE,
  MANDATES_MANAGER_APP_CACHE_TEMPLATE,
  INVOICE_APP_CACHE_TEMPLATE,
  POLICY_APP_CACHE_TEMPLATE,
  VIDEO_MANAGER_APP_CACHE_TEMPLATE
} from './redux/templates'


function mapDispatchToProps(dispatch) {
  return {
    loadData: spec => dispatch(loadData(spec)),
  };
}



class App extends Component {


  constructor( props )
  {
      super( props );
      this.state = {

      };

      

      //this.exportStore = this.exportStore.bind(this);
      console.log(this.props);
  }


  componentDidMount()
  {

    let o = {};
    switch(window.setup.view)
    {
        case VIEW_TYPE_HOME_CONTROL_PANEL:
          o = {
            'settings': window.setup,
            'app_cache': {}
          };
          this.props.loadData( o );


          if(window.setup.do_ajax === true)
          {
      
              fetch("/backenddata/ajax_fetch_dashboard", {
                  method: "POST",
                  mode: 'same-origin', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'include', // include, *same-origin, omit                            
                  body: window.setup.security_name + '=' + window.setup.security_value ,
                  headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                      "X-Requested-With": 'XMLHttpRequest',
                      "X_REQUESTED_WITH": 'xmlhttprequest'
                  }
              })
              .then(res => {
                  return res.json();
              })
              .then(response => {
                  var x = Object.assign({}, window.setup);
                  x.data = response.data;
                  //console.log(data);
                  if(!response.hasOwnProperty("error"))
                  {
                    let o = {
                      'settings': x,
                      'app_cache': {}
                    };
                    //console.log("loading ajax", o);
                    this.props.loadData( o );
      
                  }
                  else
                  {
                     /* handle error */
                  }
              });      
          }          
        break;

        case VIEW_TYPE_EXTENSION_MANAGER:
          o = {
            'settings': window.setup,
            'app_cache': {}
          };
          this.props.loadData( o );       
        break;


        case VIEW_TYPE_CUSTOMER_PANEL:

          o = {
            'settings': window.setup,
            'app_cache': CUSTOMER_APP_CACHE_TEMPLATE,
          };
          this.props.loadData( o );
          
        break;

        case VIEW_TYPE_HOME_PASSES_PANEL:
          o = {
            'settings': window.setup,
            'app_cache': PASSES_APP_CACHE_TEMPLATE,
          };
          /* Setup default */
          this.props.loadData( o );
          console.log('VIEW_TYPE_HOME_PASSES_PANEL', window.setup.do_ajax);

          if(window.setup.do_ajax === true)
          {
            try{
                  fetch("/backenddata/ajax-fetch-passes", {
                    method: "POST",
                    mode: 'same-origin', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'include', // include, *same-origin, omit                            
                    body: window.setup.security_name + '=' + window.setup.security_value ,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-Requested-With": 'XMLHttpRequest',
                        "X_REQUESTED_WITH": 'xmlhttprequest'
                    }
                })
                .then(res => {
                    return res.json();
                })
                .then(response => {
                    var x = Object.assign({}, window.setup);
                    x.data = response.data;
                    //console.log(data);
                    if(!response.hasOwnProperty("error"))
                    {
                      o = {
                        'settings': x,
                        'app_cache': PASSES_APP_CACHE_TEMPLATE,
                      };
                      //console.log("loading ajax", o);
                      this.props.loadData( o );
        
                    }
                    else
                    {
                      /* handle error */
                    }
                }).catch(function(err){
                  console.error('Failed to load customer data', err);
                });  

              }
              catch(e)
              {
                  console.error('Failed to load customer data', e);
              }              
          } 
                    
        break;        
        case VIEW_TYPE_EVENTS_PANEL:
          o = {
            'settings': window.setup,
            'app_cache': EVENTS_MANAGER_APP_CACHE_TEMPLATE,
          };
          this.props.loadData( o );
          if(window.setup.do_ajax === true)
          {
              var chain = 'type=' + window.setup.subview;
              fetch("/backenddata/ajax_fetch_events", {
                method: "POST",
                mode: 'same-origin', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'include', // include, *same-origin, omit                            
                body: window.setup.security_name + '=' + window.setup.security_value + '&' + chain ,
                  headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                      "X-Requested-With": 'XMLHttpRequest',
                      "X_REQUESTED_WITH": 'xmlhttprequest'
                  }
              })
              .then(res => {
                  return res.json();
              })
              .then(response => {
                  //console.log(data);
                  if(!response.hasOwnProperty("error"))
                  {
                    var x = Object.assign({}, window.setup);
                    x.data = response.data;

                    o = {
                      'settings': x,
                      'app_cache': EVENTS_MANAGER_APP_CACHE_TEMPLATE,
                    };                    
                    console.log("loading ajax", o);
                    this.props.loadData( o );
                    this.setState({'loading': false});
                  }
                  else
                  {
                    /* handle error */
                  }
              }).catch(function(err){
                  console.error('Failed to load the event data', err);
              }); 
          }
          else
          {
                            
          } 
        break;

        case VIEW_TYPE_SUBSCRIPTIONS_PANEL:
          o = {
            'settings': window.setup,
            'app_cache': SUBSCRIPTIONS_MANAGER_APP_CACHE_TEMPLATE,
          };
          this.props.loadData( o );
        break;


        case VIEW_TYPE_MANDATES_PANEL:
          o = {
            'settings': window.setup,
            'app_cache': MANDATES_MANAGER_APP_CACHE_TEMPLATE,
          };
          this.props.loadData( o );
          
        break;

        case VIEW_TYPE_POLICY_PANEL:
          o = {
            'settings': window.setup,
            'app_cache': POLICY_APP_CACHE_TEMPLATE,
          };
          this.props.loadData( o );
          
        break;
        

        case VIEW_TYPE_INVOICE:
          o = {
            'settings': window.setup,
            'app_cache': INVOICE_APP_CACHE_TEMPLATE,
          };
          this.props.loadData( o );
          
        break;        

        case VIEW_TYPE_REPORTS_PANEL:
          o = {
            'settings': window.setup,
            'app_cache': REPORT_MANAGER_APP_CACHE_TEMPLATE,
          };
          this.props.loadData( o );
          
        break;

        case VIEW_TYPE_SCHEDULES_PANEL:
          
          o = {
            'settings': window.setup,
            'app_cache': SCHEDULES_MANAGER_APP_CACHE_TEMPLATE,
          };
          this.props.loadData( o );
        break;          

        
        case VIEW_TYPE_QUESTIONAIRE_PANEL:
          o = {
            'settings': window.setup,
            'app_cache': QUESTIONAIRE_MANAGER_APP_CACHE_TEMPLATE,
          };
          this.props.loadData( o ); 
        break;
        
        case VIEW_TYPE_VIDEO_PANEL:
          o = {
            'settings': window.setup,
            'app_cache': VIDEO_MANAGER_APP_CACHE_TEMPLATE,
          };
          this.props.loadData( o ); 
        break;
        

        default:
          console.log('invalid type');


    }
 




  } 

  

  render(){
    return (
      <div className="App rvNS">
          <Container maxWidth="lg">
            <Builder />
          </Container>
      </div>
    );    
  }

}


export default connect(null, mapDispatchToProps)(App)
