
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Grid from '@material-ui/core/Grid';
import { red } from '@material-ui/core/colors';
import Moment from 'react-moment';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  root:{
    padding: theme.spacing(1),
  },
  wrapper:{
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '330px'
    },
    [theme.breakpoints.up('md')]: {
        width: '500px',
      },
    [theme.breakpoints.up('lg')]: {
        width: '800px',
    },      

  },
  h2Title:{
    color: red[500],
  },
  blocksTitle:{
    fontWeight: 'bold',
    fontSize: '1em',
    color: 'rgba(0,10,30,.75)'
  },  
}));


export default function SubscriptionDetailDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
      'top': props.where.top,
      'bottom': props.where.bottom,
      'left': props.where.left,
      'right': props.where.right,
  });
  const onClose = props.onClose;
  const subscription = props.subscription;

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
    onClose();
  };

  return (

    <div>
      {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
              <div className={classes.wrapper}>
                    <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                        <Grid item xs={12} >
                            <h2 className={classes.h2Title}>Subscription Overview</h2>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                                <Grid item xs={4}>
                                  <div className={classes.blocksTitle}>
                                      ID
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                    {subscription.id}
                                </Grid> 
                                <Grid item xs={4}>
                                  <div className={classes.blocksTitle}>
                                      Created
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                    <Moment format="LL">{subscription.created}</Moment>
                                </Grid>                                
                                <Grid item xs={4}>
                                  <div className={classes.blocksTitle}>
                                      Initial Price
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                  {subscription.currency} {subscription.initialgross || subscription.gross}
                                </Grid> 
                                <Grid item xs={4}>
                                  <div className={classes.blocksTitle}>
                                      Price
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                  {subscription.currency} {subscription.gross}
                                </Grid> 
                                <Grid item xs={4}>
                                  <div className={classes.blocksTitle}>
                                      Minimum contract term
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                    {subscription.term} days from signing mandate
                                </Grid>                                 
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <h2 className={classes.h2Title}>Association</h2>
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                                <Grid item xs={4}>
                                  <div className={classes.blocksTitle}>
                                    Type
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                    {subscription.association.type}
                                </Grid> 
                                <Grid item xs={4}>
                                  <div className={classes.blocksTitle}>
                                      ID
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                    {subscription.association.id}
                                </Grid>                                
                                <Grid item xs={4}>
                                  <div className={classes.blocksTitle}>
                                      Renews 
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                    {subscription.association.renews} days
                                </Grid>                                 
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <h2 className={classes.h2Title}>Mandates</h2>
                        </Grid>   
                        <Grid item xs={12} >
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                ID
                                            </TableCell>
                                            <TableCell>
                                                Customer
                                            </TableCell>  
                                            <TableCell>
                                                Status
                                            </TableCell>    
                                            <TableCell>
                                                Next Date
                                            </TableCell>                                                                                                                                 
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {subscription.mandates.length > 0 && subscription.mandates.map( el => (
                                      <React.Fragment key={el.id}>
                                          <TableRow>
                                              <TableCell>
                                                 {el.id}
                                              </TableCell>
                                              <TableCell>
                                                  <a href={"/customers/manage/"+el.customer_id} target="_blank"  rel="noreferrer">{el.customer_firstname} {el.customer_lastname}</a>                                          
                                              </TableCell>  
                                              <TableCell>
                                                  {el.status}                                          
                                              </TableCell>    
                                              <TableCell>
                                              <Moment format="LL">{el.next}</Moment>                                          
                                              </TableCell>
                                          </TableRow>  
                                      </React.Fragment> 
                                    )                                    
                                  )}
                                  </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>                     
                    </Grid>
              </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}