import React, { Component,useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import {v1 as uuidv1} from "uuid";

import MUIRichTextEditor from 'mui-rte';
import { stateToHTML } from 'draft-js-export-html';
import { TMUIRichTextEditorRef } from 'mui-rte';

import './editorStyles.css';
import PersonIcon from '@material-ui/icons/Person';
import ShopIcon from '@material-ui/icons/Shop';
import BusinessIcon from '@material-ui/icons/Business';
import HttpIcon from '@material-ui/icons/Http';
import GavelIcon from '@material-ui/icons/Gavel';
import { EditorState, Modifier } from 'draft-js'
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import FileManagerDialog from './files-dialog';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField:{
      marginBottom: '10px',
  },
  editor:{
      border: '1px solid rgba(0,0,0,0.1)',
      padding: '0px 5px 0px 5px',
      minHeight: '150px',
      marginTop: '20px',
      marginBottom: '20px',
  },
  fabDelete:{
    backgroundColor: '#b80000',
    color: '#fff',
    '&:hover':{
        backgroundColor: '#940000',
  }
},   
});

class DeleteButton extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          data: props.data,
          callback: props.callback,
          disabled: props.disabled|| false
      }

      this._handleClick = this._handleClick.bind(this);
  }

  _handleClick = () => {
      var x = Object.assign({}, this.state.customer);
      console.log('Manage button initiated',x);
      this.state.callback(this.state.data);
  }

  render() {
      var classes= this.props.classes;
      var theclass = classes.fabDelete;
      if(this.state.disabled)
      {
          theclass = classes.fabGrey;
      }
      return (
          <IconButton name="delete" className={theclass}  aria-label="Delete event" onClick={this._handleClick}>
              <DeleteForeverIcon className={classes.fabIcon} disabled={this.state.disabled}/>
          </IconButton>            
      )
  }
}



const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class EmailEditorDialog extends  Component{

  constructor( props )
  {
      super( props );
     // console.log('TopNav', props);


      this.state = {
        subject: '',
        body: '',
        subj_error_state: false,
        subj_error_msg: '',
        onCancel: props.onCancel,
        when: moment(),
        files: [],
        file_manager: false,
        security:  props.security || {'name': '', 'token': ''},
        subject_state: true,
        body_state: true,
        attachments: [],
        //editorState: EditorState.createEmpty() ,
      };

     // var ref = React.createRef();


      this.handleClose = this.handleClose.bind(this);
      this.onSubmit = this.onSubmit.bind(this);
      this.setSubject = this.setSubject.bind(this);
      this.setBody = this.setBody.bind(this);
      this.onFileManagerOpen = this.onFileManagerOpen.bind(this);
      this.onFileManagerCancel = this.onFileManagerCancel.bind(this);
      this.onFileManagerOK     = this.onFileManagerOK.bind(this);
      this.onFileDelete        = this.onFileDelete.bind(this);
      this.doSave=this.doSave.bind(this);  
      //this.handleReturn = this.handleReturn.bind(this);
      //this.onEditorChange = editorState => this.setState({editorState});
      console.log(EmailEditorDialog);
  }

  doSave( editor )
  {

    const html = stateToHTML(editor.getCurrentContent()) 
    console.log(html);
    var body_state = true;
    if(html.length > 0)
    {
      body_state = false;
    }
    this.setState({'body': html, 'body_state': body_state});

    //this.state.ref.current.save();
  }


  htmlEntities(str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;').replace(/’/g, '&apos;').replace(/“/g, '&quot;').replace(/”/g, '&quot;');
  }

  onSubmit()
  {
    if(this.state.subject.length > 0 && this.state.body.length > 0)
    {

      var x = [];

      for(var i = 0; i < this.state.attachments.length; i++)
      {
        x.push(this.state.attachments[i].id);
      }
      var body = window.btoa(this.htmlEntities(this.state.body));
      // body = body.replace(/[ ]/gi, "+");
      /* Send the email to the server */

      const  q = this.state.when.toISOString().slice(0, 10) + ' ' +  this.state.when.toISOString().slice(11, 16);

      var obj = {
        subject: this.state.subject,
        body: body,
        when: q,
        files: x,
      }

      /* tell parent we are done */
      this.props.actions[0].action( obj );
    }

  }

  onFileDelete( file)
  {
    var files = JSON.parse(JSON.stringify(this.state.attachments));

    for(var i = 0; i < files.length; i++)
    {
      if(file.id === files[i].id)
      {
        files.splice(i, 1);
        break;
      }

    }

    this.setState({'attachments': files});
  }

  onFileManagerOK( files )
  {
    this.setState({'file_manager': false, 'attachments': files});
  }

  onFileManagerCancel()
  {
    this.setState({'file_manager': false});
  }


  onFileManagerOpen(){
    this.setState({'file_manager': true});
  }

  handleClose = () => {
    this.state.onCancel();
  };



  setSubject(event)
  {
    var state = true;
    if(event.target.value.length > 0)
    {
      state = false;
    }
    this.setState({'subject': event.target.value, 'subject_state': state});
  }

  setBody(str)
  {
    this.setState({'body': str});
  }


  componentDidMount(){
   
  }


  

  render()
  {
    var classes = this.props.classes;

    
    return (
      <div>
        <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={true} fullWidth={true} maxWidth = {'lg'}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose} danger={(this.props.danger?this.props.danger:false)}>
            {this.props.title}
          </DialogTitle>
          <DialogContent dividers>
              <Grid container justify="flex-start" alignItems="flex-start" >
                  <Grid item xs={12} md={12}>
                      <TextField
                          id="email-name"
                          className={classes.textInput}
                          value={this.state.subject}
                          onChange={this.setSubject}
                          margin="none"
                          variant="outlined"
                          fullWidth
                          placeholder="Email subject."
                          InputLabelProps={{
                              shrink: true,
                          }}     
                          error={this.state.subj_error_state}                               
                          autoFocus={true}
                          label="Email subject"
                          helperText={this.state.subj_error_state? this.state.subj_error_msg: 'Enter email subject'}
                      />  
                  </Grid>
                  <Grid item xs={12} md={12}>
                      <div className={classes.editor}>
                        <MUIRichTextEditor 
                              onSave={this.setBody}
                              onChange={this.doSave}
                              controls={["bold", "italic", "underline", "undo", "redo", "link","numberList", "bulletList", "quote", "firstname", "booking-link", "company", "url", "toc"]}
                              label="Write the email body here" 
                              customControls={[
                                {
                                    name: "firstname",
                                    icon: <Tooltip title="Customer's firstname" aria-label="Customer's firstname"><PersonIcon /></Tooltip>,
                                    type: "callback",
                                      onClick: (editorState, name, anchor) => {

                                        let contentState = editorState.getCurrentContent();
                                        let targetRange = editorState.getSelection();
                                        let newContentState = Modifier.insertText(
                                              contentState,
                                              targetRange,
                                              ' {firstname} '
                                            );
                                            let neweditorState = EditorState.push(
                                              editorState,
                                              newContentState
                                            );

                                            return neweditorState;   
                                        
                                      }
                                },
                                {
                                  name: "booking-link",
                                  icon: <Tooltip title="Booking page link" aria-label="Booking page link"><ShopIcon /></Tooltip>,
                                  type: "callback",
                                    onClick: (editorState, name, anchor) => {

                                      let contentState = editorState.getCurrentContent();
                                      let targetRange = editorState.getSelection();
                                      let newContentState = Modifier.insertText(
                                            contentState,
                                            targetRange,
                                            ' {purchase-lnk} '
                                          );
                                          let neweditorState = EditorState.push(
                                            editorState,
                                            newContentState
                                          );

                                          return neweditorState;   
                                      
                                    }
                              },
                              {
                                name: "company",
                                icon: <Tooltip title="Company details" aria-label="Company details"><BusinessIcon /></Tooltip>,
                                type: "callback",
                                  onClick: (editorState, name, anchor) => {

                                    let contentState = editorState.getCurrentContent();
                                    let targetRange = editorState.getSelection();
                                    let newContentState = Modifier.insertText(
                                          contentState,
                                          targetRange,
                                          ' {company} '
                                        );
                                        let neweditorState = EditorState.push(
                                          editorState,
                                          newContentState
                                        );

                                        return neweditorState;   
                                    
                                  }
                            },  
                            {
                              name: "url",
                              icon: <Tooltip title="Company website" aria-label="Company website"><HttpIcon /></Tooltip>,
                              type: "callback",
                                onClick: (editorState, name, anchor) => {

                                  let contentState = editorState.getCurrentContent();
                                  let targetRange = editorState.getSelection();
                                  let newContentState = Modifier.insertText(
                                        contentState,
                                        targetRange,
                                        ' {url} '
                                      );
                                      let neweditorState = EditorState.push(
                                        editorState,
                                        newContentState
                                      );

                                      return neweditorState;   
                                  
                                }
                            }, 
                            {
                              name: "toc",
                              icon: <Tooltip title="Link to terms and conditions" aria-label="Link to terms and conditions"><GavelIcon /></Tooltip>,
                              type: "callback",
                                onClick: (editorState, name, anchor) => {

                                  let contentState = editorState.getCurrentContent();
                                  let targetRange = editorState.getSelection();
                                  let newContentState = Modifier.insertText(
                                        contentState,
                                        targetRange,
                                        ' {toc} '
                                      );
                                      let neweditorState = EditorState.push(
                                        editorState,
                                        newContentState
                                      );

                                      return neweditorState;   
                                  
                                }
                            },                             
                            

                            
                            ]}
                          />
                      </div>

                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="datetime-local"
                      label="Send date/time"
                      type="datetime-local"
                      defaultValue={this.state.when.format('YYYY-MM-DDTHH:mm:00')}
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="none"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                      <Grid container justify="flex-start" alignItems="flex-start" >
                          <Grid item xs={12} md={12}>
                              <div className="formSubTitle">Attachments</div>
                          </Grid>
                          <Grid item xs={12} md={12}>
                              {this.state.attachments.length > 0  && (
                                  <TableContainer>
                                  <Table>
                                      <TableHead>
                                          <TableRow>
                                              <TableCell>
                                                  ID
                                              </TableCell>
                                              <TableCell>
                                                  Name
                                              </TableCell>  
                                              <TableCell align="right">
                                                  Remove
                                              </TableCell>                                                                                                   
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                      {this.state.attachments.length > 0 && this.state.attachments.map(el => (
                                          <TableRow key={el.id}>
                                              <TableCell>
                                                  {el.id}
                                              </TableCell>
                                              <TableCell>
                                                  {el.fname}
                                              </TableCell>  
                                              <TableCell align="right">
                                                  <DeleteButton callback={this.onFileDelete} data={el} disabled={false} classes={classes}/>
                                              </TableCell>                                               
                                          </TableRow>                                    
    
                                      ))}                                          
                                      </TableBody>
                                  </Table>
                              </TableContainer> 
                              )}
         
                          </Grid>
                          <Grid item xs={12} md={12}>
                          {this.state.attachments.length === 0 && (
                              <div className="no-files"><span>No files attached</span></div>
                          )}
                          </Grid>
                          <Grid item xs={12} md={12} align="right">
                            <Fab color="primary" aria-label="add" onClick={this.onFileManagerOpen}>
                              <AddIcon />
                            </Fab>                              
                          </Grid>

                      </Grid>

                  </Grid>


              </Grid>
              {this.state.file_manager && (
                  <FileManagerDialog onCancel={this.onFileManagerCancel} onOK={this.onFileManagerOK} classes={classes} security={this.state.security}/>
              )}           
            <Typography gutterBottom>
            </Typography>
          </DialogContent>
          <DialogActions>
              {this.props.actions.length > 0 && this.props.actions.map(el => ( 
                  <Button onMouseUp={this.onSubmit} key={uuidv1()} color={el.color} className={el.className} variant={el.variant} >
                        {el.label}
                  </Button>
              ))}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}


export default withStyles(styles)(EmailEditorDialog);
