import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";


import Grid from '@material-ui/core/Grid';
import {Container} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';


import Autocomplete from '@material-ui/lab/Autocomplete';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {reloadData, closeCustomerPanel} from '../redux/actions';
import TextField from '@material-ui/core/TextField';


import Slide from '@material-ui/core/Slide';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {v1 as uuidv1} from "uuid";


import MuiDialogTitle from '@material-ui/core/DialogTitle';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import GetAppIcon from '@material-ui/icons/GetApp';
import WarningIcon from '@material-ui/icons/Warning';
import Button from '@material-ui/core/Button';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import moment from 'moment'
import Moment from 'react-moment';
import 'react-dates/lib/css/_datepicker.css';
import { Alert, AlertTitle } from '@material-ui/lab';


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    dialog:{
        backgroundColor: 'rgba(0,0,0,0.1)',
        '& .MuiAppBar-root':{
            backgroundColor: 'rgba(60, 141, 188, 1)',
        }
    },
    box:{
        height: '100%',
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: '5px',
        backgroundColor: '#fff',
        padding: '18px', 
        marginTop: '100px'       
    },    
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    section:{
       fontSize: '1.1em',
       fontWeight: 'bold',
    },  
    setting:{
        fontSize: '1em',
        fontWeight: 'normal', 
    },
    stdCell:{
        textAlign: 'center',
    },  
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    download:{
        fontSize: '0.8em',
        float:'right',
    },  
    reportDescription:{
        fontSize: '0.8em',
    },                  
    fabIcon:{
        '& .MuiSvgIcon-root':{
            fill: '#fff',
            color: '#fff',
            '&:hover':{
                color: '#ffe',
            }
        }
    },
    fabDelete:{
        backgroundColor: '#b80000',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#940000',
        }
    }, 
    fabReg:{
        backgroundColor: 'rgba(83, 120, 138, 1)',
        color: '#fff',
        '&:hover':{
            backgroundColor: 'rgba(83, 120, 138, 0.5)',
        }
    },         
    tableName:{
        width: '25%',
    },
    alignCenter:
    {
        textAlign: 'center'
    },
    buttonDanger:{
        color: '#d40000',
    },
    progress:{
        color: 'rgba(0,0,0,0.5)',
        fontSize: '18px',
        textAlign: 'center'
    },  
    progressIcon:{
        color: 'rgba(0,0,0,0.5)',

    }, 
    progressError:{
        color: 'rgba(199, 0, 0, 0.829)',

    },          
    filter:{
        paddingBottom: '10px'
    }
});

function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }




function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function Loading(props) {
    console.log('Loading', props);
    if (props.open) {
        return <div className="loading"><i className="fa fa-refresh fa-spin fa-5x fa-fw"></i><span className="sr-only">Loading...</span></div>
    }
    return ''
}


function mapDispatchToProps(dispatch) {
    return {
        reloadData: spec => dispatch(reloadData(spec)),
        closeCustomerPanel: spec => dispatch(closeCustomerPanel(spec)),

      
    };
  }





class ReportFinancialOverviewForSpecificClassesDialogModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            loading: false,
            security: props.security,
            open: true,
            focusedInput: null,
            close: props.onCancel,
            start_date: moment().subtract(7, 'days'),
            end_date: moment(),
            percentage: 1.4,
            nominal_fee: 0.20,
            data: [],
            raw_data: [],
            total_income: 0.00,
            total_transactions: 0,
            max_transactions: 0,
            transactions_used: 0.00,
            credit_card_estimate: 0.00,
            total_reservie_fees: 0.00,
            final_estimated_net: 0.00,
            events: [],
            selected_events:[],
            pause: false,
        };



        console.log('ReportFinancialOverviewForSpecificClassesDialogModule', this.state.data);

        this.onDateChange         = this.onDateChange.bind(this);
        this.onPercentageChange   = this.onPercentageChange.bind(this);
        this.onNominalChange      = this.onNominalChange.bind(this);
        this.onRefresh            = this.onRefresh.bind(this);
        this.onDownloadClick      = this.onDownloadClick.bind(this);
        this.onManageCustomerPanelClose = this.onManageCustomerPanelClose.bind(this);
        this.onEventFilterSelect  = this.onEventFilterSelect.bind(this);
        this.postProcess  = this.postProcess.bind(this);


    }


    postProcess()
    {
        var total_income        = 0.00;
        var total_reservie_fees = 0.00;
        var total_transactions  = 0.00;
        var _this = this;
        var data = [];
        if(this.state.selected_events.length === 0)
        {
            data = this.state.raw_data;
        }
        else
        {
            for(var j = 0; j < this.state.raw_data.length; j++)
            {
                for(var k = 0; k < this.state.selected_events.length; k++)
                {
                    if(this.state.raw_data[j].id === this.state.selected_events[k].id)
                    {
                        data.push(this.state.raw_data[j]);
                    }
                }
            }
        }

        this.setState({'data': data}, function(){
            for(var i = 0; i < this.state.data.length; i++)
            {
                total_income += parseFloat(this.state.data[i].net_float);
                total_reservie_fees += parseFloat(this.state.data[i].charge_float);
                total_transactions += parseFloat(this.state.data[i].transaction_count);
            }
    
            var credit_card_estimate = ((total_income * (this.state.percentage/100)) + (total_transactions * this.state.nominal_fee));
            var final_estimated_net = ((total_income - total_reservie_fees) - credit_card_estimate);
            _this.setState({'total_income': total_income, 'total_reservie_fees': total_reservie_fees, 'total_transactions': total_transactions, 'credit_card_estimate': credit_card_estimate, 'final_estimated_net': final_estimated_net });
            console.log('data', data);
        })

    }



    onEventFilterSelect( event, objects )
    {
        var _this = this;
        if(objects !== null)
        {

            this.setState({'selected_events': objects}, function(){
                _this.postProcess();

            });
        }
        else
        {
            this.setState({'selected_events': []}, function(){
                _this.postProcess();
                
            });
        }
    }

    onPercentageChange(event)
    {
        if(!isNaN(event.target.value))
        {
            this.setState({'percentage': Number(event.target.value).toFixed(2)});
        }
    }

    onNominalChange(event)
    {
        if(!isNaN(event.target.value))
        {
            this.setState({'nominal_fee': Number(event.target.value).toFixed(2)});
        }

    }

    onManageCustomerPanelClose()
    {
        this.state.close();
    }

    componentDidMount()
    {
        this.onRefresh();
    }

    onDownloadClick()
    {
        var a = moment();
        var rows = [
            ["Event ID", "Event name", "Event Date and Time", "Gross income", 'Gross discount', 'Gross adjustments', 'Net income', 'Income cash/card', 'Income pass', 'Gross', 'Discount applied', 'Adjustments', 'Customer paid', 'Reservie Charges'],
        ];

        for(var i = 0; i < this.state.data.length; i++)
        {
            var x = [
                this.state.data[i].id, 
                this.state.data[i].name, 
                moment(this.state.data[i].event_date).format("DD/MM/YYYY"), 
                this.state.data[i].gross_float, 
                this.state.data[i].discount_float, 
                this.state.data[i].adjustment_float, 
                this.state.data[i].net_float,
                this.state.data[i].card_cash_float,
                this.state.data[i].pass_float,
                this.state.data[i].charge_float
             ];
            rows.push(x);
        }
        rows.push([" ","","","","","","","","",""]);
        rows.push(["","","","","","","","","",""]);
        rows.push(["Percentage","Nominal fee","","","","","","","",""]);
        rows.push([parseFloat(this.state.percentage).toFixed(2),parseFloat(this.state.nominal_fee).toFixed(2),"","","","","","","",""]);
        rows.push(["","","","","","","","","",""]);
        rows.push(["Total net income", "Transactions", "Total reserVie charges", "Stripe charge estimate","","","","","",""]);
        rows.push([parseFloat(this.state.total_income).toFixed(2).toString(), parseFloat(this.state.total_transactions).toFixed(2), parseFloat(this.state.total_reservie_fees).toFixed(2), parseFloat(this.state.credit_card_estimate).toFixed(2) ,"","","","","",""]);

        
        
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");        
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "financial-earnings-" + a.format() + ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    onRefresh()
    {
        var _this = this;
        var chain = '&start='    + this.state.start_date.valueOf();
        chain = chain + '&end='  + this.state.end_date.valueOf();
        this.setState({'loading': true, 'selected_events': [], 'events': [], 'pause': true}, function(){
            try {
                fetch("/backenddata/get-financial-overview-for-specific-events-js", {
                  method: "POST",
                  mode: 'same-origin', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'include', // include, *same-origin, omit                            
                  body: _this.state.security.name + '=' + _this.state.security.token + chain,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-Requested-With": 'XMLHttpRequest',
                        "X_REQUESTED_WITH": 'xmlhttprequest'
                    }
                })
                .then(res => {
                    return res.json();
                })
                .then(response => {
                  if(response.status === 'OK')
                  {
                        _this.setState({'raw_data': response.data, 'events': response.events, 'pause': false}, function(){
                            this.postProcess();
                        });
                  }
                  else
                  {
                        _this.setState({'raw_data': [], 'data': [], 'pause': false});
                  }
            
                }).catch(function (err) {
             
                });           
        
            }
            catch(e)
            {
              console.error('api called', e);
      
            }   
        })      
    }




    onDateChange(obj)
    {
        var _this = this;

        if(obj.startDate !== null && obj.endDate !== null)
        {
            this.setState({'start_date': obj.startDate, 'end_date': obj.endDate}, function(){
                _this.onRefresh();
            });
        }
        else if(obj.startDate !== null)
        {
            this.setState({'start_date': obj.startDate}, function(){
                _this.onRefresh();
            });            
        }
        else if(obj.endDate !== null)
        {
            this.setState({'end_date': obj.endDate}, function(){
                _this.onRefresh();
            });            
        }

        
        
          
    }


    render(  )
    {
        var classes = this.props.classes;

        /* OK we need to kick off the loading of the data */


        console.log('render',this.props, this.state );




        return (
            <div className={classes.root}>

                <Dialog fullScreen open={this.state.open} onClose={this.onManageCustomerPanelClose} TransitionComponent={Transition} >
                    <div className={classes.dialog}>
                        <Container maxWidth="lg">
                                
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.onManageCustomerPanelClose} aria-label="close">
                                    <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Financial overview report
                                    </Typography>
                                </Toolbar>
                            </AppBar>                   
                            <div className={classes.box}>
                                <Grid container justify="flex-start" alignItems="flex-start" spacing={2} >
                                    <Grid item xs={12} md={12}>
                                        <h2 className={classes.section}>Configure report</h2>
                                        <p className={classes.reportDescription}>Configure the report below by choosing the date range.</p>
                                    </Grid>  
                                    <Grid item xs={12}>
                                        <Grid container justify="flex-start" alignItems="flex-start" className="reports"  spacing={2} >
                                            <Grid item xs={12} md={12}>
                                                <h2 className={classes.setting}>Step 1: Select the date range</h2>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="drpReportPicker">
                                                    <DateRangePicker 
                                                    isOutsideRange={day => (false)}
                                                    startDate={this.state.start_date} // momentPropTypes.momentObj or null,
                                                    startDateId="startDate" // PropTypes.string.isRequired,
                                                    endDate={this.state.end_date} // momentPropTypes.momentObj or null,
                                                    endDateId="endDate" // PropTypes.string.isRequired,
                                                    onDatesChange={this.onDateChange} // PropTypes.func.isRequired,
                                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                                    displayFormat="DD MMM YYYY"
    
                                                    />                                               

                                                </div>
                                                
                                                <div className="description">
                                                    Select the date range of the purchases. 
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <h2 className={classes.setting}>Step 2: Add filter if you need only specific events otherwise leave empty</h2>
                                            </Grid>                                            
                                            <Grid item  xs={8} className={classes.filter}>
                                                <Autocomplete
                                                    key={uuidv1()}
                                                    id="event-filter"
                                                    className={classes.formControl}
                                                    options={this.state.events}
                                                    getOptionLabel={event => event.name}
                                                    style={{ width: "100%" }}
                                                    autoHighlight
                                                    multiple={true}
                                                    disabled={this.state.pause}
                                                    value={this.state.selected_events}
                                                    onChange={this.onEventFilterSelect}
                                                    disableClearable={true}
                                                    filterSelectedOptions
                                                    getOptionSelected={(option, value)  => {
                                                        return (option.id === value.id);  
                                                    }}                                                
                                                    renderOption={event => (
                                                    <React.Fragment>
                                                        {event.name}
                                                    </React.Fragment>
                                                    )}                
                                                    renderInput={params => <TextField {...params} label="Specific events" variant="outlined" />}
                                                />                                                                                          
                                            </Grid>                                               
                                            <Grid item xs={12} md={12}>
                                                <h2 className={classes.setting}>Step 3: Enter the credit card processing fee for the estimate</h2>
                                            </Grid>                                            
                                            <Grid item xs={4}>
                                                <TextField
                                                    onChange={this.onPercentageChange}
                                                    margin="normal"
                                                    variant="outlined"
                                                    placeholder="1.4"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}  
                                                    inputProps={{step: 0.01, min:0, max: 100}}
                                                    type="number" 
                                                    value={this.state.percentage}                                
                                                    label="Percentage"
                                                    helperText="Enter the percentage fee for the transactions. For example, if the fee is 1.4% enter 1.4"
                                                /> 
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    onChange={this.onNominalChange}
                                                    margin="normal"
                                                    variant="outlined"
                                                    placeholder="1.4"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}  
                                                    inputProps={{step: 0.01, min:0, max: 1}}
                                                    type="number" 
                                                    value={this.state.nominal_fee}                                
                                                    label="Nominal fee"
                                                    helperText="Enter the nominal fee the credit card processor is charging. For example, if they are charging 20p, then enter 0.20"
                                                /> 
                                            </Grid>                                         
                                        </Grid>


                                        <Grid container justify="flex-start" alignItems="flex-start" className="reports" >
                                            <Grid item xs={6} md={6}>
                                                <h2 className={classes.section}>Report data</h2>
                                            </Grid>     
                                            <Grid item xs={6} md={6}>
                                                <Button color="primary"
                                                        startIcon={<GetAppIcon />}
                                                        className={classes.download}
                                                        onClick={this.onDownloadClick}

                                                >
                                                    Download CSV
                                                </Button>
                                            </Grid> 
                                            <Grid item xs={12}>
                                                    &nbsp;
                                            </Grid>                                       
                                        </Grid>

                                        <Grid container justify="flex-start" alignItems="flex-start" className="reports" spacing={4}>
                                            <Grid item xs={12} md={12}>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    ID
                                                                </TableCell>
                                                                <TableCell>
                                                                    Event Name
                                                                </TableCell>
                                                                <TableCell className={classes.stdCell}>
                                                                    Event Date
                                                                </TableCell>
                                                                <TableCell  className={classes.stdCell}>
                                                                    Total Gross
                                                                </TableCell>  
                                                                <TableCell  className={classes.stdCell}>
                                                                    Discounts/Adjustments
                                                                </TableCell>
                                                                <TableCell  className={classes.stdCell}>
                                                                    Total Net
                                                                </TableCell>  
                                                                <TableCell className={classes.stdCell}>
                                                                    Net by Card/Cash
                                                                </TableCell>
                                                                <TableCell  className={classes.stdCell}>
                                                                    Net by Pass
                                                                </TableCell>                                                                   
                                                                <TableCell  className={classes.stdCell}>
                                                                    Total Charge
                                                                </TableCell>                                                                  
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.data.length === 0 && (
                                                            <TableRow>
                                                                <TableCell key={-1} colSpan={11}>
                                                                    <div className={classes.progress}>
                                                                        <WarningIcon className={classes.progressIcon} style={{ height: 100, width: 100, textAlign: 'center' }}/>                                                                          
                                                                        <p>
                                                                            There are no records to display.
                                                                        </p>
                                                                    </div>                                                    
                                                                </TableCell>
                                                            </TableRow>
                                                            )}
                                                            {this.state.data.length > 0 && this.state.data.map(el => (
                                                                <React.Fragment key={uuidv1()}>

                                                                    <TableRow key={el.id}>
                                                                        <TableCell>
                                                                            {el.id}                                                  
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {el.name}                                                                                                                                                                          
                                                                        </TableCell>
                                                                        <TableCell className={classes.stdCell}>
                                                                            <Moment format="LL">{el.event_date}</Moment>                                                  
                                                                        </TableCell>                                                                       
                                                                        <TableCell  className={classes.stdCell}>
                                                                            {parseFloat(el.gross_float).toFixed(2)}
                                                                        </TableCell>  
                                                                        <TableCell  className={classes.stdCell}>
                                                                            {(parseFloat(el.discount_float) + parseFloat(el.adjustment_float)).toFixed(2)}
                                                                        </TableCell>
                                                                        <TableCell  className={classes.stdCell}>
                                                                            {parseFloat(el.net_float).toFixed(2)}
                                                                        </TableCell> 
                                                                        <TableCell className={classes.stdCell}>
                                                                            {parseFloat(el.card_cash_float).toFixed(2)}                                                                                                                                                                                                                                                                                                                                                                                                                              
                                                                        </TableCell>
                                                                        <TableCell  className={classes.stdCell}>
                                                                            {parseFloat(el.pass_float).toFixed(2)}                                                                                                                                                                                                                                                                                                                                                                                                                              
                                                                        </TableCell>                                                                          
                                                                        <TableCell  className={classes.stdCell}>
                                                                            {parseFloat(el.charge_float).toFixed(2)}
                                                                        </TableCell> 

                                                                    </TableRow>
                                                                </React.Fragment>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <h2 className={classes.section}>Summary</h2>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Alert severity="warning">
                                                    <AlertTitle>Note</AlertTitle>
                                                    This report will provide an estimage of the credit card processing fees based on the values provided above. The actual fees charge may vary based on the origin of the transaction - this information is not shared by the credit card process with reservie.   
                                                </Alert>                                                  
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className={classes.stdCell}>
                                                                    Total net income
                                                                </TableCell>
                                                                <TableCell className={classes.stdCell}>
                                                                    # Transactions
                                                                </TableCell>   
                                                                <TableCell className={classes.stdCell}>
                                                                    Total reserVie charges
                                                                </TableCell> 
                                                                <TableCell className={classes.stdCell}>
                                                                    Stripe charge estimate
                                                                </TableCell>     
                                                                <TableCell className={classes.stdCell}>
                                                                    Estimated final NET
                                                                </TableCell>                                                                                                                              
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell className={classes.stdCell}>                                                                   
                                                                    {parseFloat(this.state.total_income).toFixed(2)}
                                                                </TableCell>
                                                                <TableCell className={classes.stdCell}>
                                                                    {parseFloat(this.state.total_transactions).toFixed(2)}
                                                                </TableCell>
                                                                <TableCell className={classes.stdCell}>
                                                                    {parseFloat(this.state.total_reservie_fees).toFixed(2)}
                                                                </TableCell>  
                                                                <TableCell className={classes.stdCell}>
                                                                    {parseFloat(this.state.credit_card_estimate).toFixed(2)}
                                                                </TableCell>       
                                                                <TableCell className={classes.stdCell}>
                                                                    {parseFloat(this.state.final_estimated_net).toFixed(2)}
                                                                </TableCell>                                                                                                                                                                                          
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>

                        </Container>
                    </div>
                </Dialog>
            
            </div>            
        );
    }

};



const ReportFinancialOverviewForSpecificClassesDialog = connect(null, mapDispatchToProps)(ReportFinancialOverviewForSpecificClassesDialogModule);
export default withStyles(styles)(ReportFinancialOverviewForSpecificClassesDialog);
