import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './events-clone-stepper';
import TextField from '@material-ui/core/TextField';


import Divider from '@material-ui/core/Divider';

import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import Dialog from '../core/dialog';
import AddIcon from '@material-ui/icons/Add';


import DatesDialog from './dates-dialog';

import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from "@material-ui/pickers";

import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
  } from '@material-ui/pickers';
import moment from 'moment'
import 'moment-timezone';
import MenuItem from '@material-ui/core/MenuItem';
import SaveIcon from '@material-ui/icons/Save';

import  {
    EVENT_APP_CACHE_MODE_VIRTUAL,
    EVENT_APP_CACHE_STATE_CLONE_MANUAL, 
 } from '../redux/types';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";


    const styles = theme => ({
        root: {
            flexGrow: 1,
          },   
          date:{
            fontWeight: 'bold',
            marginTop: '35px',
          },    
          title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
          },
          formControl: {
            margin: '20px',
            minWidth: 120,
          },
          textInput:{
            marginTop: '20px'
          },
          switchLabel:{
              color: 'rgba(0,0,0,0.6)',
          },
          captionText:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',

          },
          sectionDescription:{
            color: 'rgba(0,0,0,0.6)',    
          },            
          captionSection:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',
            marginBottom: '10px',
            lineHeight: '1.5em',
          },
          schedTypeSelection:{
              marginLeft: '10px'
          },
          what:{
              width: '50%'
          },
          fabDelete:{
              backgroundColor: '#b80000',
              color: '#fff',
              marginTop: '20px',
              '&:hover':{
                  backgroundColor: '#940000',
              }
          },        
          fabIcon:{
              '& .MuiSvgIcon-root':{
                  fill: '#fff',
                  color: '#fff',
                  '&:hover':{
                      color: '#ffe',
                  }
              }
          }                     
});



class DateRow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            iteration: props.data.iteration,
            id: props.data.id,
            zones: [],
            update: props.update,
            delete: props.delete,
            setvalid: props.setValid,
            errors:{},
            delete_ready: false,
            disable_passes: true,
            disable_card: true,
            virtual: props.virtual,
            plugins: props.plugins,
            is_manual_virtual: false,
        }



        this.state['errors'] = {
            daypicker: {
                object: 'date',
                rule: '',
                ruletype: 'hasTime', /* function or regex */
                state: false, 
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "Select event date.", 
                error: 'Please select an event date'
            },
            timepicker: {
                object: 'time',
                rule: '',
                ruletype: 'hasTime', /* function or regex */
                state: false, 
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "You must select a time for your event.", 
                error: 'Please select a time'
            },  
            duration: {
                object: 'duration',
                rule: '',
                ruletype: 'regex', /* function or regex */
                state: false, 
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "Optional: Set duration. If not used set to 0", 
                error: 'Please set valid duration greater or equal to zero.'
            },  
            tz: {
                object: 'tz',
                rule: '',
                ruletype: 'tz', /* function or regex */
                state: false, 
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "Select one or more dates for your event.", 
                error: 'Please select a date'
            }                           
        };       

        if(this.state.virtual.mode === 'manual' && props.mode === EVENT_APP_CACHE_MODE_VIRTUAL)
        {
            this.state.is_manual_virtual = true;
            this.state['errors'].invite =  {
                object: 'invite',
                rule: '[0-9A-Za-z-\._+ ]+',
                ruletype: 'regex', /* function or regex */
                state: false, 
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "Add invite details", 
                error: 'Invalid invite'
            };
            this.state['errors'].invite_id = {
                object: 'invite_id',
                rule: '[0-9A-Za-z]*',
                ruletype: 'regex', /* function or regex */
                state: false, 
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "Add invite details", 
                error: 'Invalid invite'
            };
            this.state['errors'].invite_pass = {
                object: 'invite_pass',
                rule: '[0-9A-Za-z!\.-_+]*',
                ruletype: 'regex', /* function or regex */
                state: false, 
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "Add invite details", 
                error: 'Invalid invite'
            };



        }

        console.log('DateRow', this.state);
        this._handleDelete = this._handleDelete.bind(this);
        this.handleSetTime  = this.handleSetTime.bind(this);
        this.handleSetDate  = this.handleSetDate.bind(this);
        this.handleDurationChange  = this.handleDurationChange.bind(this);
        this.onDeleteComplete  = this.onDeleteComplete.bind(this);
        this.onCancel    = this.onCancel.bind(this);
        this.isValid    = this.isValid.bind(this);
        this.handleSetTZ   = this.handleSetTZ.bind(this);
        this.handleInviteChange = this.handleInviteChange.bind(this);
        this.handleInviteIdChange = this.handleInviteIdChange.bind(this);
        this.handleInvitePassChange = this.handleInvitePassChange.bind(this);
        this.isAllValid = this.isAllValid.bind(this);
        var timezones = moment.tz.names();

        var tzs = timezones.filter(timezone => { 
          var t = timezone.toLowerCase().substring(0, 3);
          switch(t)
          {
            case 'uni':
            case 'uct':
            case 'cst':
            case 'cet':
            case 'eet':
            case 'utc':
            case 'wet':
            case 'etc':
            case 'est':
            case 'gmt':
            case 'gb':
            case 'gb-':
            case 'gre':
            case 'mst':
            case 'met':
            case 'w-s':
            {
              //console.log('filtering', t);
              return false;
            }
            default:
            {
              return true;
            }
          }
        });

        for(var i in tzs)
        {
            var x = { value: tzs[i], gmt_name: " (GMT"+moment.tz(tzs[i]).format('Z')+") " + tzs[i], name: tzs[i] };
            this.state.zones.push(x);
        }       
    }

    componentDidMount()
    {
        console.log('vlaidating ', this.state.id);
       // this.isValid('invite', this.state.id, this.props.data.invite || '');
       // this.isValid('invite_pass', this.state.id, this.props.data.invite_pass || '');
       // this.isValid('invite_id', this.state.id, this.props.data.invite_id || '');
       this.isAllValid();
    }


    isAllValid()
    {
        var result = true;
        for(var i in this.state.errors)
        {
            var rule = i;
            var id = this.state.id;
            var data = this.props.data[this.state.errors[i].object];
            result = this.isValid(rule, id, data);
            console.log('validating', rule, id, data, result);
            if(result === false)
            {
                break;
            }
        }

        this.state.setvalid( this.state.id, result);

    }


    isValid( rule, id, value )
    {
        var result = false;
        //for(var i in this.state.errors)
        if(this.state.errors[rule])
        {
            var i = rule;
            var x = Object.assign({}, this.state.errors);

            console.log('testing ', i, this.state.errors[i].ruletype, value, rule);
            if(this.state.errors[i].ruletype === 'regex')
            {
                var regex = RegExp(this.state.errors[i].rule);
                if(regex.test(value) === false)
                {
                    console.log('failed test');
                    window.scrollTo(0, this.state.errors[i].ref.current.offsetTop);                
                    this.state.errors[i].state = true;
                    this.setState({'errors': this.state.errors, refresh: !this.state.refresh});
                    result = false
                }
                else
                {
                    console.log('Passes test');
                    this.state.errors[i].state = false;
                    this.setState({'errors': this.state.errors});
                    //this.setState({'errors': this.state.errors});
                    result = true;
                }
            }
            else
            {
                switch(this.state.errors[i].ruletype )
                {
                    case 'isEmpty':
                    {
                        if(this.state[value].length > 0)
                        {
                            this.state.errors[i].state = false;
                            this.setState({'errors': this.state.errors});
                            result = true;
        
                        }
                        else
                        {
                            window.scrollTo(0, this.state.errors[i].ref.current.offsetTop);                
                            this.state.errors[i].state = true;
                            this.setState({'errors': this.state.errors});
                            result = false;
        
                        }
                    }
                    break;
                    case 'tz':
                    {
                        var t = moment.tz.zone(value);

                        if(!!t)
                        {
                            this.state.errors[i].state = false;
                            this.setState({'errors': this.state.errors});
                            result = true;
        
                        }
                        else
                        {
                            window.scrollTo(0, this.state.errors[i].ref.current.offsetTop);                
                            this.state.errors[i].state = true;
                            this.setState({'errors': this.state.errors});
                            result = false;

        
                        }
                    }
                    break;
                    case 'hasTime':
                    {
                        var t = moment(this.state[value]);
                        if(t.isValid())
                        {
                            this.state.errors[i].state = false;
                            this.setState({'errors': this.state.errors});
                            result = true;
        
                        }
                        else
                        {
                            window.scrollTo(0, this.state.errors[i].ref.current.offsetTop);                
                            this.state.errors[i].state = true;
                            this.setState({'errors': this.state.errors});
                            result = false;
        
                        }
                    }
                    break;
                }

            }
            
        }


        return result;              
    }

    _handleDelete = () => {
        this.setState({'delete_ready': true});
    }

    onCancel(event)
    {
        this.setState({'delete_ready': false});        
    }   
 
    onDeleteComplete = (event) => {
        var x = {};
        x.iteration = this.state.iteration;
        x.id = this.state.id;
        x.name = this.state.name;
        x.price = this.state.price;
        x.credits = this.state.credits;
        x.stock = this.state.stock;
        x.invite_pass = this.state.invite_pass;
        x.invite_id = this.state.invite_id;
        x.invite = this.state.invite;
        x.deleted = true;

        this.state.delete(x);

        this.setState({'delete_ready': false});
    }


    handleInviteIdChange = (event) => {
        var _this  = this;
        var n      = event.target.value;
        if(this.isValid('invite_id', this.state.id, n))
        {
            this.state.update('invite_id', this.state.id, n, function(){
                _this.isAllValid();                
            });  
        }

    }    


    handleInvitePassChange = (event) => {
        var _this  = this;
        var n      = event.target.value;
        if(this.isValid('invite_pass', this.state.id, n))
        {
            this.state.update('invite_pass', this.state.id, n, function(){
                _this.isAllValid();                
            });  
        }


    } 


    handleInviteChange= (event) => {
        console.log("validating invite");
        var n      = event.target.value;
        var _this  = this;
        if(this.isValid('invite', this.state.id,  n))
        {
            this.state.update('invite', this.state.id, n, function(){
                _this.isAllValid();                
            }); 
        }

    }    

    handleSetTZ = (event) => {
        var _this  = this;
        var n      = event.target.value;
        if(this.isValid('tz', this.state.id,n))
        {
            this.state.update('tz', this.state.id, n, function(){
                _this.isAllValid();                
            });  
        }
 }

    handleSetTime = (event) => {
        var _this  = this;
        console.log('time', event);
        var z = moment(event);
        if(event === null)
        {
            z = moment();
        }
        if(this.isValid('timepicker', this.state.id,  z))
        {
            this.state.update('time', this.state.id, z.format('HH:mm:ss'), function(){
                _this.isAllValid();                
            });  
        }        
 
    }
    handleSetDate = (event) => {
        var _this  = this;   
        var z = moment(event);
        if(event === null)
        {
            z = moment();
        }        
        if(this.isValid('daypicker', this.state.id, z))
        {
            this.state.update('date', this.state.id, z.format('YYYY-MM-DD'), function(){
                _this.isAllValid();                
            });  
        }
    }

    handleDurationChange = (event) => {
        var _this  = this;        
        var z = Number(event.target.value).toFixed(0);
        if(z < 0)
        {
            z = 0;
        }

        if(this.isValid('duration', this.state.id, z))
        {
            this.state.update('duration', this.state.id, z, function(){
                _this.isAllValid();                
            });  
        }

    }    
    render() {
        var classes= this.props.classes;
        return (
            <div>

                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} md={1}>
                        <div className={classes.date}>Date #{Number(this.state.iteration + 1).toFixed(0)}.</div>
                    </Grid>
                    <Grid item md={4} xs={12} ref={this.state.errors['daypicker'].ref}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                key={uuidv1()}
                                label="Date"
                                autoOk
                                disablePast
                                value={moment(this.props.data.date)}
                                onChange={this.handleSetDate}
                                animateYearScrolling
                                margin="normal"
                                inputVariant="outlined"
                                fullWidth
                                clearable={false}
                                placeholder="Add date of the event."
                                InputLabelProps={{
                                    shrink: true,
                                }}     
                                error={this.state.errors.daypicker.state}                               
                                label="Date"
                                helperText={this.state.errors.daypicker.state? this.state.errors.daypicker.error: this.state.errors.daypicker.ok}

                            />                                                                   
                        </MuiPickersUtilsProvider>                                         
                    </Grid> 
                    <Grid item md={2} xs={12} ref={this.state.errors['timepicker'].ref}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        margin="normal"
                                        label="Time of event"
                                        inputVariant="outlined"
                                        ampm={false}
                                        value={moment(this.props.data.date + "T" + this.props.data.time)}
                                        onChange={this.handleSetTime}
                                        fullWidth
                                        clearable={false}
                                        error={this.state.errors.timepicker.state}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                        helperText={this.state.errors.timepicker.state?this.state.errors.timepicker.error:this.state.errors.timepicker.ok}                             
                                        />
                            </MuiPickersUtilsProvider>                                         
                    </Grid>
                    <Grid item md={2} xs={12} ref={this.state.errors['tz'].ref}>
                            <TextField
                                select
                                label="Timezone"
                                fullWidth
                                value={this.props.data.tz}
                                onChange={this.handleSetTZ}
                                helperText="Please select a timezone"
                                variant="outlined"
                                margin="normal"

                              >
                                {this.state.zones.map((m) => (
                                  <MenuItem key={m.value} value={m.value}>
                                    {m.name}
                                  </MenuItem>
                                ))}
                            </TextField>                                        
                    </Grid>                                               
                    <Grid item md={2} xs={12} ref={this.state.errors['duration'].ref}>
                        <TextField
                            onChange={this.handleDurationChange}
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            placeholder="1"
                            InputLabelProps={{
                                shrink: true,
                            }}  
                            step={1} 
                            type="number" 
                            value={Number(this.props.data.duration).toFixed(0) }                                
                            error={this.state.errors.duration.state}                               
                            label="Duration (minutes)"
                            helperText={this.state.errors.duration.state? this.state.errors.duration.error: this.state.errors.duration.ok}
                        />                                         
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <IconButton name="delete" className={classes.fabDelete} aria-label="Delete option" onClick={this._handleDelete}>
                            <DeleteForeverIcon className={classes.fabIcon}/>
                        </IconButton>            
                    </Grid> 
                    {this.state.is_manual_virtual === true &&(
                    <Grid item xs={12}>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                            <Grid item xs={12} md={1}>
                            </Grid>

                            <Grid item xs={12} md={6}  ref={this.state.errors['invite'].ref}>
                                <TextField
                                    onChange={this.handleInviteChange}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Not set"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}  
                                    type="text" 
                                    value={this.props.data.invite || ''}                                
                                    error={this.state.errors.invite.state}                               
                                    label="Invite details"
                                    helperText={this.state.errors.invite.state? this.state.errors.invite.error: this.state.errors.invite.ok}
                                />                                
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    onChange={this.handleInviteIdChange}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Not set"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}  
                                    type="text" 
                                    value={this.props.data.invite_id || ''}                                
                                    error={this.state.errors.invite_id.state}                               
                                    label="Invite ID"
                                    helperText={this.state.errors.invite_id.state? this.state.errors.invite_id.error: this.state.errors.invite_id.ok}
                                />                                  
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    onChange={this.handleInvitePassChange}
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Not set"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}  
                                    type="text" 
                                    value={this.props.data.invite_pass || ''}                                
                                    error={this.state.errors.invite_pass.state}                               
                                    label="Invite password"
                                    helperText={this.state.errors.invite_pass.state? this.state.errors.invite_pass.error: this.state.errors.invite_pass.ok}
                                />                                  
                            </Grid>
                            <Grid item xs={12} md={1}>
                            </Grid>
                        </Grid>

                    </Grid>
                    )} 

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
                <Dialog onCancel={this.onCancel} actions={[{action: this.onDeleteComplete, label:"OK" }]} danger={true} state={this.state.delete_ready} body="You are about to delete this option. Do you want to continue" title="Warning" />
            </div>

        )
    }
}



function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventsCloneManualModule extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            dates: props.cache.dates||[],
            add_dates: false,
            //time: this.props.cache.time || moment(),
            //duration: this.props.cache.duration || 0,
            error: false,
            refresh: false,

        }      


        obj = this.state;

        if(this.state.dates.length === 0)
        {
            this.state.add_dates = true;
        }


        console.log('EventsStep2AModule', this.state, props);

 


        this.state.security = props.security;
        this.myRef = React.createRef()  
        this.onOptionUpdate   = this.onOptionUpdate.bind(this); 
        this.onDateDelete   = this.onDateDelete.bind(this); 
        this.onDoPrevious   = this.onDoPrevious.bind(this);
        this.onDoNext   = this.onDoNext.bind(this);
        this.onOpenDateWidget = this.onOpenDateWidget.bind(this);
        this.setValid   = this.setValid.bind(this);
        this.onDatesCancel = this.onDatesCancel.bind(this);
        this.onDatesAdd = this.onDatesAdd.bind(this);

    }

    onDatesCancel()
    {
        this.setState({'add_dates': false});
    }


    setValid( id, state )
    {
        var x = JSON.parse(JSON.stringify(this.state.dates));

        for(var i = 0; i < x.length; i++)
        {
            console.log('processing update...', x[i].id, id);
            if((x[i].id === id))
            {
                console.log('setting state...', !state);
                x[i].invalid = !state;
                break;
            }
        }
        this.setState({'dates': x});
    }


    onDatesAdd( dates )
    {
        if(dates)
        {
            //var list = JSON.parse(JSON.stringify(this.state.dates));
            var list = this.state.dates.map(a => Object.assign({}, a));
            //var list = x.concat( dates );
            var i = 0, count = 0;
            for(i = 0; i < dates.length; i++)
            {
                var z = {};
                z.iteration = 0;
                z.id = dates[i].id;
                z.date = dates[i].date.format('YYYY-MM-DD');
                //console.log('Adding date and time', dates[i].date, dates[i].time, z.date)
                z.time = dates[i].time.format('HH:mm:00');
                z.tz = dates[i].tz;
                z.duration = dates[i].duration;
                z.deleted =  false;
                z.invalid = false;
                z.invite = z.invite_pass = z.invite_id = '';
                z.invalid = false;
                z.invalid = false;
                list.push(z);
            }

            //console.log('final date list to be sorted', list);

            list.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                var first = moment(a.date);
                var second = moment(b.date);

                return first.diff(second);
            });

            for(i = 0, count = 0; i < list.length; i++)
            {
                if(list[i].deleted === false)
                {
                    list[i].iteration = count++;
                }
            }
            console.log('list of dates', list);
            this.setState({'dates': list, 'add_dates': false });
        }

    }


    onDoPrevious(event)
    {
        var o = { 
            state: EVENT_APP_CACHE_STATE_CLONE_MANUAL
        };
        this.props.rewindStep(o);        
    }

    onOptionUpdate(what, id, v, callback)
    {
        var x = JSON.parse(JSON.stringify(this.state.dates));

        console.log('processing update - initial', obj, x, x.length);
        for(var i = 0; i < x.length; i++)
        {
            console.log('looping through update...',x[i].id, obj.id);
            if(x[i].id === id)
            {
                x[i][what] = v;
                break;
            }
        }


        this.setState({'dates': x}, function(){
     //       console.log('Saved', x);
            callback();
        });
    }  

    onDateDelete(obj)
    {
        var x = JSON.parse(JSON.stringify(this.state.dates));
        
        //console.log('delete update', obj, x, x.length);
        var it_length = 0; 
        for(var i = 0; i < x.length; i++)
        {
            //console.log('delete update...', x[i].id, obj.id);
            if((x[i].id === obj.id))
            {
                x[i].deleted = true;
                console.log('setting configuration', x, i);
            }
            if(x[i].deleted === false)
            {
                x[i].iteration = it_length;
                it_length += 1;
            }
        }
        this.setState({'dates': x});

    } 
 

    onOpenDateWidget(event)
    {
        this.setState({'add_dates': true});
    }

    isValid()
    {
        var count = 0;

        for(var i = 0; i < this.state.dates.length; i++)
        {
           // console.log('is valid...', this.state.dates[i]);
            if(this.state.dates[i].deleted === false)
            {
                count += 1;
                if(this.state.dates[i].invalid)
                {
                    return false;
                }   
            }
        } 
        if(count === 0)
        {
            return false;
        }

        return true;

    }

    
    onDoNext(event)
    {
        var x = Object.assign({}, this.state.errors);

        //if(regex.test(this.state.name))
        if(this.isValid())
        {
            var o = {
                  dates: this.state.dates,
                  state: EVENT_APP_CACHE_STATE_CLONE_MANUAL
                };
                this.setState({'error': false});
                this.props.setStep(o);
    
        }
        else
        {
            this.setState({'error': true});
        }

    }
  
    




  

    render()
    {
        var classes = this.props.classes;

        //console.log('render', this.state);

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Scheduling and date management - manual scheduling
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="center" alignItems="center" spacing={2} >


                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="center" spacing={2}>
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">

                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        
                                        <Grid item xs={12} md={12}>
                                            <StepperWidget key={uuidv1()} step={1}/>

                                        </Grid>
                                        
                                        
                                        <Grid item xs={12}>
                                            <div className="formSection">Configure the date(s) and time for the event.</div>                               
                                            <p className="sectionDescription">
                                                Add dates, times and durations to your event. Events must have at least one date and time.                         
                                            </p>                                        
                                        </Grid>

                                        {this.state.dates.filter(function(obj) {
                                            return !obj.deleted;
                                        }).map(el => (
                                            <Grid item xs={12}  key={el.id}>
                                                <DateRow data={el}
                                                            key={el.iteration} 
                                                            delete={this.onDateDelete} 
                                                            update={this.onOptionUpdate} 
                                                            setValid={this.setValid}
                                                            classes={classes} 
                                                            refresh={this.state.refresh}
                                                            virtual={this.props.virtual}
                                                            plugins={this.props.data.obj.plugins}
                                                            mode={this.props.mode}
                                                />
                                            </Grid>
                                        ))}
                                        {this.state.dates.length === 0 && (
                                            <Grid item xs={12}>
                                                <div className="error">
                                                    You need to add at least one date and time. Click the 'ADD DATES' button to get started.    
                                                </div>
                                           </Grid>                                            
                                        )}
                                        { this.state.error && (
                                            <Grid item xs={12}>
                                                <div className="error">
                                                    There is a problem with one of more of the dates. Please check and try again.    
                                                </div>
                                            </Grid>
                                        )}
                                        <Grid item  xs={12}>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                className={classes.button}
                                                startIcon={<AddIcon />}
                                                onClick={this.onOpenDateWidget}
                                                >
                                                add dates
                                            </Button>                                            
                                        </Grid>


                                        <Grid item xs={4} md={3}>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            size="large"
                                            className="rv-eng-next-button"
                                            startIcon={<NavigateBeforeIcon />}
                                            onClick={this.onDoPrevious}
                                            fullWidth={true}
                                        >
                                            Back
                                        </Button>                          
                                        </Grid>
                                        <Grid item xs={4} md={6}></Grid>
                                        <Grid item xs={4} md={3}>                      
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className="rv-eng-next-button"
                                            endIcon={<NavigateNextIcon />}
                                            onClick={this.onDoNext}
                                            fullWidth={true}
                                        >
                                            Next
                                        </Button>                        
                                        </Grid>                      
                                    </Grid>


                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>

                <DatesDialog onCancel={this.onDatesCancel} onOK={this.onDatesAdd}  state={this.state.add_dates}  />
            </div>            
        );
    }

};



const EventsCloneManual = connect(null, mapDispatchToProps)(EventsCloneManualModule);
export default withStyles(styles)(EventsCloneManual);
