import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'


import Autocomplete from '@material-ui/lab/Autocomplete';

import Loading from './loading';

import {v1 as uuidv1} from "uuid";


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonDisabled: {
    color: theme.palette.grey[900]
  },
  formSectionTitle:{
    marginTop: '15px',
    marginBottom: '15px',
    fontWeight: 'bold'
}    
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function TransferDialogWrapper( props ) {
  const [open, setOpen] = React.useState(props.state);
  const  [selected, setSelected] = React.useState({ name: '', eid: null, dates:[], pids: []});
  const  [pid, setPid] = React.useState({ id: null, name: null, price: 0, stock: 0, active: false, credits:null});
  const  [date, setDate] = React.useState({ id: null, eid: null, cid: null, date: null, time: null, duration: null, formatted_date: null});
  const  [loading, setLoading] = React.useState(props.loading);
  const  callback  = props.onCancel;

  const handleClose = () => {
    console.log('handleClose', props)
    callback(false);
    setOpen(false);
  };

  const handleTransfer = () => {

    var chain = '&from_eid='    + props.from.eid;
    chain    += '&from_did='    + props.from.did;
    chain    += '&from_stxid='  + props.from.stx_id;
    chain    += '&user='        + props.cust_id;
    chain    += '&to_eid='      + date.eid;
    chain    += '&to_did='      + date.id;
    chain    += '&to_po='       + pid.id;


    setLoading(true);

    var _this = this;
    console.log('Transfering customer');
    try {
        fetch("/api-actions-customers/ajax-transfer-from-event-to-event", {
          method: "POST",
          mode: 'same-origin', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'include', // include, *same-origin, omit                            
          body: props.security.name + '=' + props.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
          if(response.status === 'OK')
          {
            console.log('handleTransfer', props)
            callback(true);
            setOpen(false);
  
          }
          else
          {
            console.log('handleTransfer', props)
            callback(false);
            setOpen(false);
          }


        }).catch(function (err) {
          console.error('api called', err);
          setLoading(false);
          console.log('handleTransfer', props)
          callback(false);
          setOpen(false);          
          //              _this.setState({loading: true});
        });           

    }
    catch(e)
    {
      console.error('api called', e);
      setLoading(false);
      //_this.setState({loading: true});
      console.log('handleTransfer', props)
      callback(false);
      setOpen(false);
    }    

  };

  const handleEventSelect = (event, value) => {

    if(value !== null)
    {
      var o = {};
      o.dates = value.dates;
      o.eid = value.eid;
      o.name = value.name;
      o.pids = [];

      for(var i = 0; i < value.pids.length; i++)
      {
        if((typeof value.pids[i].deleted === 'undefined') || (value.pids[i].deleted === false))
        {
          o.pids.push(value.pids[i]);
        }
      }
      setSelected(o);
      if(o.pids.length > 0)
      {
        setPid( o.pids[0] );
      }      
    }
    else
    {
      setSelected({ name: '', eid: null, pids: []});

    }
    //props.onEventSelect(event, value);
  };
  const handleDateSelect = (event, value) => {

    console.log('date selected ', value);
    setDate(value === null ? { id: null, eid: null, cid: null, date: null, time: null, duration: null, formatted_date: null}: value);

  };
  

  const handleOptionSelect = (event, value) => {
    if(value !== null)
    {
      setPid( value );
    }
  }; 

  React.useEffect(() => {
    setOpen(props.state);
  }, [props.state])

  
  console.log('dialog date: ', date);

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} maxWidth = {'sm'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} danger={(props.danger?props.danger:false)}>
          Transfer Customer
        </DialogTitle>
        <DialogContent dividers>
          { loading === true && (<div style={{ minHeight: 150  }}><Loading /></div>) }
          { loading === false && (
            <div>
              <div className={props.classes.formSectionTitle}>Step 1: select the event they are moving to: </div>
              <Autocomplete
                id="company-events-list"
                options={props.events}
                getOptionLabel={option => option.name}
                style={{ width: "100%" }}
                autoHighlight
                disableClearable={true}
                onChange={handleEventSelect}
                renderOption={option => (
                  <React.Fragment>
                    {option.name}
                  </React.Fragment>
                )}                
                renderInput={params => <TextField {...params} label="Events" variant="outlined" />}
              />

            { selected.dates.length > 0 && (
              <div>
                <div className={props.classes.formSectionTitle}>Step 2: Select a date: </div>
                <Autocomplete
                  id="dates-list"
                  options={selected.dates}
                  getOptionLabel={option => option.formatted_date}
                  style={{ width: "100%" }}
                  autoHighlight
                  disableClearable={true}
                  onChange={handleDateSelect}
                  renderOption={option => (
                    <React.Fragment>
                      {option.formatted_date}
                    </React.Fragment>
                  )}                
                  renderInput={params => <TextField {...params} label="Dates" variant="outlined" />}
                />
              </div>
            )}


            { selected.pids.length > 0 && (
            <div>
              <div className={props.classes.formSectionTitle}>Step 3: select the payment option: </div>
              <Autocomplete
                id="options-list"
                options={selected.pids}
                getOptionLabel={option => option.name}
                style={{ width: "100%" }}
                autoHighlight
                onChange={handleOptionSelect}
                disableClearable={true}
                defaultValue={selected.pids[0]}
                renderOption={option => (
                  <React.Fragment>
                    {option.name}
                  </React.Fragment>
                )}                
                renderInput={params => <TextField {...params} label="Pricing options" variant="outlined" />}
              />
            </div>
            ) }

            </div>) }
          <Typography gutterBottom />
        </DialogContent>
        <DialogActions>
          { (pid.id !== null && date.id !== null) && (
          <Button onClick={handleTransfer} key={uuidv1()} color="primary" variant="outlined">
                Complete transfer
          </Button>
          ) }
          { (pid.id === null || date.id === null) && (
          <Button key={uuidv1()} disabled={true} color="inherit" variant="outlined" className={props.classes.buttonDisabled}>
                Complete transfer
          </Button>
          ) }          
        </DialogActions>
      </Dialog>
    </div>
  );
  
}

class TransferDialog extends Component{
    constructor( props )
    {
        super( props );
      // console.log('TopNav', props);


        this.state = {
            security: props.security,
            loading: false,
            cust_id: props.cust_id,
            eid: props.eid,
            did: props.did,
            stx_id: props.stx_id,
            callback: props.onCancel,
            events: [],
            pid: [],
            ready: false

        };

        for(var i = 0; i < props.events.length; i++)
        {

            var dates = [];

            for(var j = 0; j < props.events[i].dates.length; j++)
            {
              var z = Object.assign({}, props.events[i].dates[j]);
              z.formatted_date = moment(props.events[i].dates[j].date + ' ' + props.events[i].dates[j].time).format("D MMM YYYY HH:mm");
              dates.push(z);
            }
            console.log('Final date list', dates)
            this.state.events.push({ name: props.events[i].name, 
                            eid: props.events[i].eid, 
                            dates: dates, 
                            pids: props.events[i].object});
          }


        this.doneTransfer = this.doneTransfer.bind(this);
        this.doneCancel = this.doneCancel.bind(this);
        console.log('TransferDialog', this.state);
        
    }  



    doneTransfer(){

    }

    doneCancel( refresh ){
      this.state.callback(refresh);
    }

    render()
    {
        console.log('Loading customer data', this.state);
        console.log('The state is', this.props.state);
        
        var classes = this.props.classes;


        return (
            <div>
              <TransferDialogWrapper 
                classes={classes} 
                events={this.state.events} 
                from={{eid: this.state.eid, did: this.state.did, stx_id: this.state.stx_id}} 
                cust_id={this.state.cust_id} 
                loading={this.state.loading} 
                state={this.props.state} 
                pid={this.state.pid} 
                ready={this.state.ready} 
                onCancel={this.doneCancel} 
                security={this.state.security}
                />
            </div>
        );
    }
     
}

export default withStyles(styles)(TransferDialog);
