import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default function CustomerMergeDialog( props ) {

  const {open, customers, customer, handleClose, security}  = props;

  const [cust, setCust] = React.useState('');
  const [confirmed, setConfirmed] = React.useState(false);
  const [error, setError] = React.useState('')

  const onSave = (e) =>{
    e.preventDefault();

    var chain = '';
    chain += '&from=' + customer.id;
    chain += '&to=' + cust;

    try {
        fetch("/customers/do-ajax-customer-merge", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: security.name + '=' + security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                handleClose();
                window.location.reload();
            }
            else
            {
              console.error('Failed to merge this customer', response)
              setError('Failed to merge this customer')
            }
        }).catch(function (err) {
          console.error('Failed to merge this customer', err)
          setError('Failed to merge this customer')
        });           

    }
    catch(e)
    {
      console.error('Failed to merge this customer', e)
      setError('Failed to merge this customer')
    }

  }


  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={onSave}>
        <DialogTitle id="form-dialog-title">Merge Customer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you merge this customer with another, all records will combined.
          </DialogContentText>
            <Box sx={{mt:2}}>
              <FormControl required fullWidth={true}>
                  <InputLabel id="customer-select-label">Customer</InputLabel>
                  <Select
                    labelId="customer-select-label"
                    id="customer-select"
                    value={cust}
                    placeholder="Select a customer"
                    onChange={(e) => { setCust( e.target.value )}}
                  >
                    {
                      customers.map( (c) => (
                        <MenuItem value={c.id}>{`${c.firstname} ${c.lastname} (${c.email})`}</MenuItem>
                      ))
                    }
                  </Select>
              </FormControl>
            </Box>

            <Box sx={{mt:2}}>
              <FormControl required>
                  <FormControlLabel
                    className="small"
                    control={<Checkbox checked={confirmed} required onChange={(e) => {setConfirmed(e.target.checked)}} name="confirm" />}
                    label="Please select to confirm this merge. This cannot be undone!"
                  />
              </FormControl>
            </Box>
            <Box sx={{mt:2}}>
              {
                error && <Alert severity="error">{error}</Alert>
              }
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Merge now
          </Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
