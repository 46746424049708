import React, { Component } from 'react';
import { connect } from "react-redux";


import Grid from '@material-ui/core/Grid';


import { withStyles } from '@material-ui/core/styles';



import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField  from '@material-ui/core/TextField';


import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Loading from '../core/loading';


import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CirclePicker } from 'react-color';
import Box from '@material-ui/core/Box';


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    section:{
      paddingTop: '20px',
      paddingBottom: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem',
        marginTop: '0px'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    scheduleField:{
        marginTop: '15px',
        marginBottom: '15px',
    },
    alignCenter:
    {
        textAlign: 'center'
    },
    buttonGo:{
        backgroundColor:'rgba(145,215, 110, 0.8)',
        color: '#ffffff',
        '&:hover':{
            backgroundColor:'rgba(145,215, 110, 1)',
        },
        marginTop: '20px',
        marginBottom: '20px',
    },
    colorSelect:{
        marginTop:"10px",
        marginBottom:"10px",
    },
    widget:{
        marginTop: '10px',
        marginBottom: '10px',
        fontSize: "1rem",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.5',
        letterSpacing: '0.00938em'       
    }    



});






const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });




function mapDispatchToProps(dispatch) {
    return {
    };
  }


class ScheduleAddPanelManagerModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            security: props.security,
            options: props.categories||[],
            id: this.props.data.id || null,
            open: props.open,
            schedulename: this.props.data.name ||'',
            scheduleweeks: this.props.data.n || 1,
            show_spaces_available: this.props.data.show_spaces_available,
            show_future_block_date: this.props.data.show_future_block_date,
            color: this.props.data.color || '#eb6589',
            categories: this.props.data.categories || [],
            updating: false,
            error: null,
        };

      //  this.state.selected.push({id: 1, name:'wibble'});


        console.log('Panel ', this.state);

        this.onManagePassPanelClose = this.onManagePassPanelClose.bind(this);
        this.onUpdatePassName = this.onUpdatePassName.bind(this);
        this.onCategoriesOptionSelect   = this.onCategoriesOptionSelect.bind(this);
        this.onUpdateScheduleDisplayWeeks     = this.onUpdateScheduleDisplayWeeks.bind(this);
        this.onHandleScheduleUpdated     = this.onHandleScheduleUpdated.bind(this);
        this.onColorChange           = this.onColorChange.bind(this);
        this.onShowSpacesAvailableChangeState    = this.onShowSpacesAvailableChangeState.bind(this);
        this.onFutureBlockChange     = this.onFutureBlockChange.bind(this);
    }

    onFutureBlockChange(event)
    {
        var v = event.target.checked;
        if(v === true)
        {
            this.setState({show_future_block_date: true});
        }    
        else
        {
            this.setState({show_future_block_date: false});
        }         
    }

    onShowSpacesAvailableChangeState(event)
    {
        var v = event.target.checked;
        if(v === true)
        {
            this.setState({show_spaces_available: true});
        }    
        else
        {
            this.setState({show_spaces_available: false});
        }        
    }

    onColorChange(color, event)
    {
       this.setState({'color': color.hex});
    } 

    onHandleScheduleUpdated(event)
    {
        event.preventDefault();
        event.nativeEvent.stopImmediatePropagation();

        var _this = this;
        var id = this.state.id ||  null;
        var name = this.state.schedulename || "";
        var n = this.state.scheduleweeks || 1;
        var show_spaces_available = !!this.state.show_spaces_available;
        var show_future_block_date = !!this.state.show_future_block_date;
        var color = this.state.color ||  true;
        var cats = JSON.stringify(this.state.categories);
 
        var chain = '';
        chain = chain + "&v=" + 3;
        chain = chain + "&name=" + name;
        chain = chain +  "&id=" + id;
        chain = chain +  "&n=" + n;
        chain = chain +  "&show_spaces_available=" + show_spaces_available;
        chain = chain +  "&show_future_block_date=" + show_future_block_date;
        chain = chain +  "&color=" + color;
        chain = chain +  "&categories=" + cats;
 
 
        fetch("/schedules/ajax_schedule_edit", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({updating: false});
                _this.props.closeCustomerPanel(true);
            }
            else
            {

                this.setState({error: response.reason });
            }
        }).catch(function(err){
            console.error('Failed to update schedule', err);
        });  


        console.log(event);
        

    }


    onUpdatePassName(event)
    {
        var v = event.target.value || "";    
        this.setState({schedulename: v});

    }

  
    onCategoriesOptionSelect(event, value)
    {
        if(value !== null)
        {
            this.setState({'categories': value});
        }
        else
        {
            this.setState({'categories': []});
        }
    

    }

    onManagePassPanelClose(event)
    {
        this.props.closeCustomerPanel(false);
    }



    onUpdateScheduleDisplayWeeks(event)
    {
        var v = event.target.value || 1;    
        this.setState({scheduleweeks: Number(v).toFixed(0)});
    }
   
      
  
    render(  )
    {
        var classes = this.props.classes;
        var cont    = this.state.schedulename.length > 0 ? true : false

        /* OK we need to kick off the loading of the data */

        console.log('render',this.props, this.state );

        return (
            <div className={classes.root}>
 

                <Dialog fullScreen open={this.props.open} onClose={this.onManagePassPanelClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.onManagePassPanelClose} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Schedule editor
                        </Typography>
                    </Toolbar>
                    </AppBar> 

                    {this.state.updating && (
                        <div className="top-box fullscreen-panel">
                            <Loading /> 
                        </div>
                    )}
                    {!this.state.updating && (
                        <form id="myForm" onSubmit={this.onHandleScheduleUpdated} className="top-box fullscreen-panel">

                            <Grid container justify="flex-start" alignItems="stretch">  
                                <Grid item xs={12}>
                                <Typography variant="h6" className={classes.section}>Step 1: Basic details</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField name="schedulename" className={classes.scheduleField} value={this.state.schedulename} onChange={this.onUpdatePassName} id="schedule-name" label="Name" variant="outlined" fullWidth={true} autoFocus={true} helperText="Enter the name of the schedule. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'" inputProps={{required:true, pattern:"[A-Za-z-._(), 0-9]{1,250}"}} />
                                </Grid>  
          

                                <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.section}>Step 2: Configure the schedule</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField      name="scheduleweeks" 
                                                    className={classes.scheduleField} 
                                                    value={Number(this.state.scheduleweeks).toFixed(0)>0?Number(this.state.scheduleweeks).toFixed(0):1} 
                                                    step={1} 
                                                    onChange={this.onUpdateScheduleDisplayWeeks} 
                                                    id="schedule-weeks" label="# of weeks to be displayed." 
                                                    variant="outlined" 
                                                    fullWidth={true} 
                                                    helperText="Enter the number of weeks the schedule will generate and display. Allowed characters 1-9" type="number" inputProps={{required:true, pattern:"[1-9]+"}} /> 
                                </Grid>  
               
                                <Grid item xs={12} md={12}>
                                    
                                    <FormControlLabel
                                        control={<Switch checked={this.state.show_spaces_available} onChange={this.onShowSpacesAvailableChangeState} name="booknow" />}
                                        label="Display the number of spaces available"
                                    />                                    
                                    <FormHelperText>This displays to the customer the number of spaces available to book.</FormHelperText>
  
                                </Grid> 
                                <Grid item xs={12} md={12}>
                                    
                                    <FormControlLabel
                                        control={<Switch checked={this.state.show_future_block_date} onChange={this.onFutureBlockChange} name="limit" />}
                                        label="Display all dates associated to event blocks"
                                    />                                    
                                    <FormHelperText>Recommended - display all dates for event blocks. if disabled, on the first available date will be displayed. </FormHelperText>
                                    
                                </Grid> 

                                <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.widget}>Set button colour</Typography>

                                </Grid>

                                <Grid item xs={12}>

                                    <Box className={classes.colorSelect} m={1}>
                                            <CirclePicker 
                                                onChangeComplete={this.onColorChange} 
                                                color={this.state.color} 
                                                colors={["#eb6589", "#000000", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]} />
                                     </Box>                                
                                    <Typography variant="caption" className={classes.captionText}>Sets the colour of the book now buttons.</Typography>                               
                                </Grid>
                               

                                <Grid item xs={12}>
                                    <Typography variant="h6"  className={classes.section}>Step 3: Schedule categories (optional) </Typography>
                                </Grid>
                       
                                <Grid item xs={12} md={12}>
                                    <Autocomplete
                                        id="options-list"
                                        options={this.state.options}
                                        getOptionLabel={option => option.name}
                                        style={{ width: "100%" }}
                                        autoHighlight
                                        multiple={true}
                                        value={this.state.categories}
                                        onChange={this.onCategoriesOptionSelect}
                                        disableClearable={false}
                                        renderOption={option => (
                                        <React.Fragment>
                                            {option.name}
                                        </React.Fragment>
                                        )}                
                                        renderInput={params => <TextField {...params} label="Optional filter categories" variant="outlined" />}
                                    />

                                </Grid> 
 
              
                                <Grid item xs={12} md={12}>
                                    <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                                        <Button type="submit" className={classes.buttonGo} disabled={!cont}>Continue &gt;&gt;</Button>
                                    </Grid>  
                                </Grid> 
                            </Grid>  
   
                        </form>              
                    )}
                    
                </Dialog>




            </div>            
        );
    }

};



const ScheduleAddPanelV3Manager = connect(null, mapDispatchToProps)(ScheduleAddPanelManagerModule);
export default withStyles(styles)(ScheduleAddPanelV3Manager);
