import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'


import Autocomplete from '@material-ui/lab/Autocomplete';


import {v1 as uuidv1} from "uuid";
import Alert from '@material-ui/lab/Alert';


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonDisabled: {
    color: theme.palette.grey[900]
  },
  formSectionTitle:{
    marginTop: '15px',
    marginBottom: '15px',
    fontWeight: 'bold'
}    
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const selected_default = { name: '', eid: null, dates:[], pids: []};
const pid_default      = { id: null, name: null, price: 0, stock: 0, active: false, credits:0, available: 0};
const pass_default      =  null;

function PassRegistrationDialogWidget( props ) {
  const [open, setOpen] = React.useState(props.open);
  const  [selected, setSelected] = React.useState(selected_default);
  const  [pid, setPid] = React.useState(pid_default);
  const  [date, setDate] = React.useState([]);
  const  [loading, setLoading] = React.useState(props.loading);
  const  callback  = props.onCancel;
  const  [price, setPrice] = React.useState(0);
  const  [pass, setPass] = React.useState(pass_default);
  const  [error, setError] = React.useState('');

  const handleClose = () => {
    console.log('handleClose', props)
    callback(false);
  };

  const handleRegistration = () => {

    var chain = '';
    chain    += '&client_id=' + props.cust_id;
    chain    += '&eid='       + selected.eid;
    chain    += '&dates='     + encodeURI( JSON.stringify(date) );
    chain    += '&pid='       + pid.id;
    chain    += '&price='     + price;
    chain    += '&pass='     + pass.id;


    setLoading(true);

    var _this = this;
    console.log('Registering customer');
    try {
        fetch("/customers/do-ajax-register-event-credit-v2", {
          method: "POST",
          mode: 'same-origin', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'include', // include, *same-origin, omit                            
          body: props.security.name + '=' + props.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
          if(response.status === 'OK')
          {
            console.log('handleRegistration', props)
            callback(true);
            setOpen(false);
  
          }
          else
          {
            setError(response.message)
          }
        }).catch(function (err) {
          console.error('api called', err);
          setLoading(false);
          setError('Network error. Please retry.')
          //              _this.setState({loading: true});
        });           

    }
    catch(e)
    {
      console.error('api called', e);
      setLoading(false);
      setError('Network error. Please retry.')
    }    

  };

  const handleEventSelect = (event, value) => {

    if(value === null)
    {
      setSelected( selected_default );
      setPid( pid_default );
      setPrice( 0 );
    }
    else
    {
      setSelected( value );
      setPid( value.pids[0] );
      setPrice( date.length * value.pids[0].credits || 0);

    }
    //props.onEventSelect(event, value);
  };
  const handleDateSelect = (event, value) => {

    console.log('date selected ', value);
    if(value === null)
    {
      setDate([])
      setPrice(0)
    }
    else
    {
      if(!!selected)
      {
        setPrice( value.length * pid?.credits || 0);
      }
      setDate( value )
    }
  };

  const handlePassSelect = (event, value) => {
    console.log('pass selected ', value);
    if(value === null)
    {
      setPass(pass_default)
    }
    else
    {
      if(!!selected)
      {
        setPrice( date?.length * pid?.credits || 0);
      }      
      setPass( value )
    }    
  };


  const handleOptionSelect = (event, value) => {
    if(value !== null)
    {
      setPid( value );
      if(!!selected)
      {
        setPrice( date?.length * value?.credits || 0);
      }      
    }
  }; 




  const handlePriceSelect = ( event ) => {
      setPrice( parseInt(event.target.value) );
  }

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open])

  
  console.log('dialog date: ', date);

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} maxWidth = {'sm'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} danger={(props.danger?props.danger:false)}>
          Event Registation
        </DialogTitle>
        <DialogContent dividers>
          {(
            <div>
              <div className={props.classes.formSectionTitle}>Step 1: select the event to register them to: </div>
              <Autocomplete
                id="company-events-list"
                options={props.events}
                getOptionLabel={option => option.name}
                style={{ width: "100%" }}
                autoHighlight
                onChange={handleEventSelect}
                renderOption={option => (
                  <React.Fragment>
                    {option.name}
                  </React.Fragment>
                )}                
                renderInput={params => <TextField {...params} label="Events" variant="outlined" />}
              />

            { selected.dates.length > 0 && (
              <div>
                <div className={props.classes.formSectionTitle}>Step 2: Select a date: </div>
                <Autocomplete
                  multiple
                  id="dates-list"
                  options={selected.dates}
                  getOptionLabel={option => moment(option.datetime).format('LLL')}
                  style={{ width: "100%" }}
                  autoHighlight
                  onChange={handleDateSelect}
                  renderOption={option => (
                    <React.Fragment>
                      {moment(option.datetime).format('LLL')}
                    </React.Fragment>
                  )}                
                  renderInput={params => <TextField {...params} label="Dates" variant="outlined" />}
                />
              </div>
            )}

          { selected.pids.length > 0 && (
            <div>
              <div className={props.classes.formSectionTitle}>Step 3: select the pass: </div>
              <Autocomplete
                id="pass-list"
                options={props.passes}
                getOptionLabel={option => option.name + ' ( Expires: ' + moment(option.expires).format('LLL') + ' ). Credits available:' + option.available}
                style={{ width: "100%" }}
                autoHighlight
                onChange={handlePassSelect}
                disableClearable={true}
                defaultValue={pass}
                renderOption={option => (
                  <React.Fragment>
                    {option.name + ' ( Expires: ' + moment(option.expires).format('LLL') + ' ). Credits available:' + option.available}
                  </React.Fragment>
                )}                
                renderInput={params => <TextField {...params} label="Pass" variant="outlined" />}
              />
            </div>
            ) }


            { selected.pids.length > 0 && (
            <div>
              <div className={props.classes.formSectionTitle}>Step 3: select the payment option: </div>
              <Autocomplete
                id="options-list"
                options={selected.pids}
                getOptionLabel={option => option.name}
                style={{ width: "100%" }}
                autoHighlight
                onChange={handleOptionSelect}
                disableClearable={true}
                defaultValue={selected.pids[0]}
                renderOption={option => (
                  <React.Fragment>
                    {option.name}
                  </React.Fragment>
                )}                
                renderInput={params => <TextField {...params} label="Pricing options" variant="outlined" />}
              />
            </div>
            ) }
            { selected.pids.length > 0 && (
            <div>
              <div className={props.classes.formSectionTitle}>Step 3: Select the credits paid: </div>
              <TextField
                      onChange={handlePriceSelect}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      placeholder="1"
                      InputLabelProps={{
                          shrink: true,
                      }}  
                      inputProps={{ min: "1", step: "1" }}  
                      type="number" 
                      value={price}                                
                      error={false}                               
                      label="Price"
                      helperText={"Enter the credits used for this event"}
                    /> 
            </div>
            ) }
            {
              error !== '' &&  <Alert severity="error">{error}</Alert>
            }
            </div>) }
          <Typography gutterBottom />
        </DialogContent>
        <DialogActions>
          { (pid.id !== null && date.length > 0 && pass !== null && price <= pass.available) && (
          <Button onClick={handleRegistration} key={uuidv1()} color="primary" variant="outlined">
                Complete Registration
          </Button>
          ) }
          { (pid.id === null ||  date?.length === 0 || pass === null || price > pass.available) && (
          <Button key={uuidv1()} disabled={true} color="inherit" variant="outlined" className={props.classes.buttonDisabled}>
                Complete Registration
          </Button>
          ) }          
        </DialogActions>
      </Dialog>
    </div>
  );
  
}

class PassRegistrationDialog extends Component{
    constructor( props )
    {
        super( props );
      // console.log('TopNav', props);


        this.state = {
            security: props.security,
            loading: false,
            cust_id: props.cust_id,
            callback: props.onCancel,
            events: props.events,
            passes: props.passes,
            pid: [],
            ready: false

        };

        this.doneCancel = this.doneCancel.bind(this);        
    }  


    doneCancel( refresh ){
      this.state.callback(refresh);
    }

    render()
    {        
        var classes = this.props.classes;


        return (
            <div>
              <PassRegistrationDialogWidget 
                classes={classes} 
                events={this.state.events}
                cust_id={this.state.cust_id}   
                onCancel={this.doneCancel} 
                security={this.state.security}
                passes={this.state.passes}
                open={this.props.open}
                />
            </div>
        );
    }
     
}

export default withStyles(styles)(PassRegistrationDialog);
