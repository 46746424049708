import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './stepper';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


import  {
    EVENT_APP_CACHE_STATE_WIZARD_3,
    EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES,
    EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD,
} from '../redux/types';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";


import MuiDialogTitle from '@material-ui/core/DialogTitle';

    const styles = theme => ({
        root: {
            flexGrow: 1,
          },          
          title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
          },
          formControl: {
            margin: '20px',
            minWidth: 120,
          },
          textInput:{
            marginTop:"20px",
            marginBottom:"20px",
          },
          switchLabel:{
              color: 'rgba(0,0,0,0.6)',
          },
          captionText:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',

          },
          colorSelect:{
            marginTop:"10px",
            marginBottom:"10px",
          },
          sectionTitle:{
            color: 'rgba(0,0,0,0.8)',
            fontSize: '1.2em',
            fontWeight: 'normal',
            paddingTop: '10px',
            paddingBottom: '10px',
          },
          section:{
            color: 'rgba(0,0,0,0.7)',
            fontSize: '1.2em',
            fontWeight: 'bold',
          },
          sectionDescription:{
            color: 'rgba(0,0,0,0.6)',    
          },            
          captionSection:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',
            marginBottom: '10px',
            lineHeight: '1.5em',
          },
          schedTypeSelection:{
              marginLeft: '10px'
          }
});








function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventsStep3Module extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            mode: props.cache.mode || EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD,
            errors: {},
            passes: props.data.obj.passes || [],
            disable_pass: false,

        }

        if(this.state.passes.length ===  0)
        {
            this.state.mode = EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD;
            this.state.disable_pass = true;
        }
        obj = this.state;

        this.state['errors'] = {
            mode: {
                object: 'mode',
                rule: /^[1-3]{1}$/,
                state: false, 
                ok: "Please select a payment type", 
                error: 'Please select a payment type'
            }            
        };

        this.state.security = props.security;
        this.myRef = React.createRef()  
 
        this.onModeChange   = this.onModeChange.bind(this);
        this.onDoPrevious   = this.onDoPrevious.bind(this);
        this.onDoNext   = this.onDoNext.bind(this);

        
    }



 


    onDoPrevious(event)
    {

        if(this.isValid())
        {
            var o = {
                  store_previous: true,
                  mode: this.state.mode,
                  state: EVENT_APP_CACHE_STATE_WIZARD_3
                };
                this.props.rewindStep(o);  
    
        }
        else
        {
            var o = { 
                state: EVENT_APP_CACHE_STATE_WIZARD_3
            };
            this.props.rewindStep(o);  
        }
        
      
    }

    onModeChange(event)
    {
        console.log("mode change", event.target.value);
        this.setState({'mode': parseInt(event.target.value)});
    }  

    isValid()
    {
        for(var i in this.state.errors)
        {
            var regex = RegExp(this.state.errors[i].rule);
            var x = Object.assign({}, this.state.errors);


             if(regex.test(this.state[this.state.errors[i].object]) === false)
            {
                window.scrollTo(0, this.myRef.offsetTop);                
                this.state.errors[i].state = true;
                this.setState({'errors': this.state.errors});
                return false;
            }
            else
            {
                console.log('Passes test');
                this.state.errors[i].state = false;
                this.setState({'errors': this.state.errors});
                return true;

            }
            
        }
        return false;

    }

    
    onDoNext(event)
    {
        var x = Object.assign({}, this.state.errors);

        //if(regex.test(this.state.name))
        if(this.isValid())
        {
            var o = {
                  mode: this.state.mode,
                  state: EVENT_APP_CACHE_STATE_WIZARD_3
                };
            this.props.setStep(o);
    
        }
        else
        {
            this.state.errors.name.state = true;
            this.setState({'errors': this.state.errors});
        }

    }
  
    




  

    render()
    {
        var classes = this.props.classes;
        console.log('edit data', this.state.edit_data);
        var classes = this.props.classes;

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Event editor
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >


                <Grid item xs={12} md={12} > 
                    <Grid container justify="flex-start" alignItems="stretch">
                        
                                        
                        <Grid item xs={12} md={12}>
                            <div className="box">

                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className="rv-form-row">
                                    
                                    <Grid item xs={12} md={12}>
                                           <StepperWidget key={uuidv1()} step={2}/>

                                    </Grid>
                                    
                                    
                                    <Grid item xs={12}>
                                        <div className="formSection">Payment types</div>                               
                                        <p className="sectionDescription">
                                            How would you like to accept payments for this event? You can allow the customer to either use a credit/debit card at checkout, pass or both. Please select one of the options below: 
                                        </p>                                        
                                        
                                    
                                        <FormControl component="fieldset" className={classes.schedTypeSelection}>
                                            <RadioGroup aria-label="rv-option" name="mode" value={this.state.mode} onChange={this.onModeChange} error={this.state.errors.mode.state? this.state.errors.mode.error: this.state.errors.mode.ok} >
                                                <FormControlLabel className="rv-option" value={EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD} control={<Radio />} label="Credit or debit card only" />
                                                <FormControlLabel className="rv-option" disabled={this.state.disable_pass}  value={EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES} control={<Radio />} label="Pass credits only" />
                                                <FormControlLabel className="rv-option" disabled={this.state.disable_pass}  value={EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES|EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD} control={<Radio />} label="Credit card, debit card or pass credits" />
                                            </RadioGroup>
                                            <FormHelperText>{this.state.errors.mode.state? this.state.errors.mode.error: this.state.errors.mode.ok}</FormHelperText>

                                            
                                        </FormControl>                        
                                    </Grid>
                                    <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                size="large"
                                                className="rv-eng-next-button"
                                                startIcon={<NavigateBeforeIcon />}
                                                onClick={this.onDoPrevious}
                                                fullWidth={true}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4} md={6}></Grid>
                                        <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="rv-eng-next-button"
                                                endIcon={<NavigateNextIcon />}
                                                onClick={this.onDoNext}
                                                fullWidth={true}
                                            >
                                                Next
                                            </Button>  
                                                                
                                        </Grid>                      
                                </Grid>


                            </div>
                        </Grid>                                               
                    </Grid>

                </Grid>                                    

                </Grid>

                
            </div>            
        );
    }

};



const EventsStep3 = connect(null, mapDispatchToProps)(EventsStep3Module);
export default withStyles(styles)(EventsStep3);
