import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';


import Moment from 'react-moment';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

// use material theme
import 'react-times/css/material/default.css';

import GroupAddRoundedIcon from '@material-ui/icons/GroupAddRounded';
 

import 'react-day-picker/lib/style.css';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },           
  fabIcon:{
      '& .MuiSvgIcon-root':{
          fill: '#fff',
          color: '#fff',
          '&:hover':{
              color: '#ffe',
          }
      }
  }, 
  fabAction:{
      backgroundColor: '#FFA500',
      color: '#fff',
      '&:hover':{
          backgroundColor: '#ea9700',
      }
  },     
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  danger: {
    color: '#b80000',
    fontSize: '0.8em'
  },  
}))(MuiDialogContent);




function mapDispatchToProps(dispatch) {
  return {
  };
}  


class ActionButton extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          data: props.data,
          callback: props.callback
      }

      console.log('ActionButton', this.state);
      this._handleClick = this._handleClick.bind(this);
  }

  _handleClick = () => {
      var x = Object.assign({}, this.state.customer);
      console.log('Manage button initiated',x);
      this.state.callback(this.state.data);
  }

  render() {
      var classes= this.props.classes;
      return (
          <IconButton name="clone" className={classes.fabAction} aria-label="event register" onClick={this._handleClick}>
              <GroupAddRoundedIcon className={classes.fabIcon}/>
          </IconButton>            
      )
  }
}


class EventBasicDetailDialogModule extends Component {

    constructor( props )
    {
        super( props );
        console.log("EventBasicDetailDialogModule", props);

        this.state = {
            event: props.data,
            oncancel: props.onCancel,
            onOK: props.onOK,
        };  
        this.handleClose = this.handleClose.bind(this);
        this.handleView = this.handleView.bind(this);


    }



    handleView( data ){
      this.state.onOK(this.state.event.id, this.state.event.basic, data,  this.state.event.payment, this.state.event.mode);
    }
    handleClose(){
      this.state.oncancel();  
    }

    render(){

        var classes = this.props.classes;

        return (
          <div>
            <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.props.state} fullWidth={true} maxWidth = {'md'}>
              <DialogTitle id="customized-dialog-title" onClose={this.handleClose} danger={false}>
                Event Information
              </DialogTitle>
              <DialogContent dividers>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                      <Hidden smDown>
                        <Grid item md={1} ></Grid>
                      </Hidden>
                      <Grid item xs={12} >
                        
                        <TableContainer>
                          <Table className={classes.table} size="small" aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Date</TableCell>
                                  <TableCell align="center">Time</TableCell>
                                  <TableCell align="center">Sold</TableCell>
                                  <TableCell align="center">View Register</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.event.scheduling.configuration.dates.length > 0 && this.state.event.scheduling.configuration.dates.map((row) => (
                                  <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                      <Moment format="ll">{row.date}</Moment>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Moment format="HH:mm">{row.date + 'T'+ row.time}</Moment>                                        
                                    </TableCell>
                                    <TableCell align="center">
                                      {row.sold} of {row.stock}
                                      <div>  
                                          {row.dsold > 0 && (
                                              <Tooltip title="Indicates additional places that have been purchased for pricing options that are now disabled">
                                                  <i className={classes.danger}>{"+"}{row.dsold} extra</i>
                                              </Tooltip>
                                          )}
                                      </div>
                                    </TableCell>
                                    <TableCell align="center">
                                      <ActionButton callback={this.handleView} data={row} classes={classes}/>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                          </Table>
                          </TableContainer>


                        
                      </Grid>
                      <Grid item xs={12} >
                        <Divider />

                      </Grid>


                  </Grid> 
                        
      
      
                <Typography gutterBottom>
                </Typography>
              </DialogContent>
            </Dialog>
          </div>
        );
    }


}



const EventBasicDetailDialog = connect(null, mapDispatchToProps)(EventBasicDetailDialogModule);
export default withStyles(styles)(EventBasicDetailDialog);

