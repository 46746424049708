import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './events-clone-stepper';



import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from "@material-ui/pickers";


import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import SaveIcon from '@material-ui/icons/Save';


import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
  } from '@material-ui/pickers';
import moment from 'moment'


import  {
    EVENT_APP_CACHE_STATE_CLONE_WEEKLY,
    EVENT_APP_CACHE_BOOKING_MODE_TYPE_INDIVIDUAL
} from '../redux/types';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";


    const styles = theme => ({
        root: {
            flexGrow: 1,
          },          
          title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
          },
          formControl: {
            marginTop: '16px',
            marginBottom: '16px',
          },
          textInput:{
            marginTop: '20px'
          },
          switchLabel:{
              color: 'rgba(0,0,0,0.6)',
          },
          captionText:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',

          },
          sectionDescription:{
            color: 'rgba(0,0,0,0.6)',    
          },            
          captionSection:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',
            marginBottom: '10px',
            lineHeight: '1.5em',
          },
          schedTypeSelection:{
              marginLeft: '10px'
          },
          what:{
              width: '50%'
          },
          fabDelete:{
              backgroundColor: '#b80000',
              color: '#fff',
              '&:hover':{
                  backgroundColor: '#940000',
              }
          },        
          fabIcon:{
              '& .MuiSvgIcon-root':{
                  fill: '#fff',
                  color: '#fff',
                  '&:hover':{
                      color: '#ffe',
                  }
              }
          }            
});





function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventCloneAutomatedWeeklyModule extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            book_type: props.cache.book_type || EVENT_APP_CACHE_BOOKING_MODE_TYPE_INDIVIDUAL,
            day_of_week: props.cache.day_of_week || 0,
            day_of_week_obj: null,
            time: props.cache.time || moment(),
            next_date: props.cache.next_date || null,
            end_date: props.cache.end_date || null,
            tz: props.data.tz || moment.tz.guess(),
            duration: props.cache.duration || 0,
            active: props.cache.active || 1,
            zones: [],
            errors: {},
            refresh: false,
            disable_passes: true,
            disable_card: true,
        };

        this.state['errors'] = {
            dayofweek: {
                object: 'day_of_week_obj',
                subobject: 'value',
                rule: '[0-6]{1}',
                ruletype: 'regex', /* function or regex */
                allow_empty: false,
                state: false, 
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "Select the day of the week on which the event is to be scheduled. ", 
                error: 'Select a valid date.'
            },
            tz:{
                object: 'tz',
                subobject: null,
                rule: '',
                ruletype: 'tz', /* function or regex */
                state: false, 
                allow_empty: false,
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "Select a valid time zone ", 
                error: 'Select a valid time zone.'                
            },
            nextdaypicker: {
                object: 'next_date',
                subobject: null,
                allow_empty: true,
                rule: '',
                ruletype: 'hasTime', /* function or regex */
                state: false, 
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "Select a date for which your would like the automated scheduling to commence. If you leave this blank, this system will commence the schedule from the next available selected day.", 
                error: 'Select a valid date or leave blank.'
            },
            enddaypicker: {
                object: 'end_date',
                subobject: null,
                rule: '',
                ruletype: 'hasTime', /* function or regex */
                allow_empty: true,
                state: false, 
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "Select the last date for the event generation. The system will add no additional days after this date. If you would like the dates to be added without an end date, leave this blank.", 
                error: 'Select a valid date or leave blank.'
            },            
            timepicker: {
                object: 'time',
                subobject: null,
                rule: '',
                ruletype: 'hasTime', /* function or regex */
                allow_empty: false,
                state: false, 
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "You must select a time for your event.", 
                error: 'Please select a time'
            },  
            duration: {
                object: 'duration',
                subobject: null,
                rule: '',
                ruletype: 'regex', /* function or regex */
                allow_empty: false,
                state: false, 
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "Optional: Set duration. If not used set to 0", 
                error: 'Please set valid duration greater or equal to zero.'
            }, 
            active: {
                object: 'active',
                subobject: null,
                rule: '^([1-9]|10)$',
                ruletype: 'regex', /* function or regex */
                state: false, 
                allow_empty: false,
                okClass: '',
                errorClass: 'error',
                ref: React.createRef(),
                ok: "The number of dates available at any one time for booking by the customer.", 
                error: 'The value must be at least 1 and no more than 10.'
            }                                              
        };               
        
        
        this.week_days = [
            {value: 0, day: 'Sunday'},
            {value: 1, day: 'Monday'},
            {value: 2, day: 'Tuesday'},
            {value: 3, day: 'Wednesday'},
            {value: 4, day: 'Thursday'},
            {value: 5, day: 'Friday'},
            {value: 6, day: 'Saturday'},
        ];

        this.state.day_of_week_obj = this.week_days[this.state.day_of_week];
        


        obj = this.state;

        var timezones = moment.tz.names();

        var tzs = timezones.filter(timezone => { 
          var t = timezone.toLowerCase().substring(0, 3);
          switch(t)
          {
            case 'uni':
            case 'uct':
            case 'cst':
            case 'cet':
            case 'eet':
            case 'utc':
            case 'wet':
            case 'etc':
            case 'est':
            case 'gmt':
            case 'gb':
            case 'gb-':
            case 'gre':
            case 'mst':
            case 'met':
            case 'w-s':
            {
              console.log('filtering', t);
              return false;
            }
            default:
            {
              return true;
            }
          }
        });

        for(var i in tzs)
        {
            var x = { value: tzs[i], gmt_name: " (GMT"+moment.tz(tzs[i]).format('Z')+") " + tzs[i], name: tzs[i] };
            this.state.zones.push(x);
        }


        console.log('EventCloneAutomatedWeeklyModule', this.state, props);

 


        this.state.security     = props.security;
        this.onDoPrevious       = this.onDoPrevious.bind(this);
        this.onPurchaseChange   = this.onPurchaseChange.bind(this);
        this.onCancelChange     = this.onCancelChange.bind(this);
        this.onDayOfWeekOptionSelect     = this.onDayOfWeekOptionSelect.bind(this);
        this.onSetTime          = this.onSetTime.bind(this);
        this.onSetNextDate      = this.onSetNextDate.bind(this);
        this.onSetEndDate      = this.onSetEndDate.bind(this);
        this.onSetDuration      = this.onSetDuration.bind(this);
        this.onSetActive      = this.onSetActive.bind(this);
        this.onDoNext           = this.onDoNext.bind(this);
        this.onSetTZ            = this.onSetTZ.bind(this);
        this.isValid            = this.isValid.bind(this);

    }

    onPurchaseChange( event )
    {
        this.setState({'purchase': event.target.value});
    }
    onCancelChange( event )
    {
        this.setState({'cancel': event.target.value});
    }
    onDayOfWeekOptionSelect(event, value)
    {
        if(value !== null)
        {

            console.log('this.state.day_of_week_obj', value);
            this.setState({'day_of_week_obj': value});

        }
        else
        {
            this.setState({'day_of_week_obj': this.week_days[0]});
        }        
    }


    onSetTZ = (event) => {
        var z = event.target.value;
        console.log('setting timezone ', z);
        this.setState({'tz': z}); 
    } 

    onSetDuration = (event) => {
        var z = event.target.value;
        this.setState({'duration': z}); 
    }     
    onSetActive = (event) => {
        var z = event.target.value;
        if(z < 1)
        {
            z = 1;
        }
        if(z > 10)
        {
            z = 10
        }        
        this.setState({'active': z}); 
    }     

    onSetTime = (event) => {
        var z = moment(event);
        this.setState({'time': z}); 
    }    
    onSetNextDate = (event) => {
        var z = moment(event);
        this.setState({'next_date': z}); 
    } 
    onSetEndDate = (event) => {
        if(event === null)
        {
            this.setState({'end_date': null}); 
        }
        else
        {
            var z = moment(event);
            this.setState({'end_date': z});     
        }
    }  

    
    onDoPrevious(event)
    {
        var o = { 
            state: EVENT_APP_CACHE_STATE_CLONE_WEEKLY
        };
        this.props.rewindStep(o);        
    }

     

    isValid( )
    {
        
        for(var i in this.state.errors)
        {
            if(this.state.errors[i].ruletype === 'regex')
            {
                var regex = RegExp(this.state.errors[i].rule);
                var x = Object.assign({}, this.state.errors);
    
                if(this.state.errors[i].subobject !== null)
                {
                    console.log('valid state', this.state.errors[i].rule, this.state[this.state.errors[i].object][this.state.errors[i].subobject])
    
                    if(regex.test(this.state[this.state.errors[i].object][this.state.errors[i].subobject]) === false)
                   {
                       window.scrollTo(0, this.state.errors[i].ref.current.offsetTop);                
                       this.state.errors[i].state = true;
                       this.setState({'errors': this.state.errors});
                       return false;
                   }
                   else
                   {
                       console.log('Passes test');
                       this.state.errors[i].state = false;
                       this.setState({'errors': this.state.errors});
       
                   }
                }
                else
                {
                    console.log('valid state', this.state.errors[i].rule, this.state[this.state.errors[i].object])
    
                    if(regex.test(this.state[this.state.errors[i].object]) === false)
                   {
                       window.scrollTo(0, this.state.errors[i].ref.current.offsetTop);                
                       this.state.errors[i].state = true;
                       this.setState({'errors': this.state.errors});
                       return false;
                   }
                   else
                   {
                       console.log('Passes test');
                       this.state.errors[i].state = false;
                       this.setState({'errors': this.state.errors});
       
                   }
                } 
            }
            else
            {
                switch(this.state.errors[i].ruletype )
                {
                    case 'isEmpty':
                    {
                        if(this.state[this.state.errors[i].object].length > 0)
                        {
                            this.state.errors[i].state = false;
                            this.setState({'errors': this.state.errors});
        
                        }
                        else
                        {
                            window.scrollTo(0, this.state.errors[i].ref.current.offsetTop);                
                            this.state.errors[i].state = true;
                            this.setState({'errors': this.state.errors});
                            console.log('scrolling to', this.state.errors[i].ref.current.offsetTop, this.state.errors[i]);
                            return false;
        
                        }
                    }
                    break;
                    case 'tz':
                    {
                        var t = moment.tz.zone(this.state[this.state.errors[i].object]);
                        if(!!t)
                        {
                            this.state.errors[i].state = false;
                            this.setState({'errors': this.state.errors});
        
                        }
                        else
                        {
                            window.scrollTo(0, this.state.errors[i].ref.current.offsetTop);                
                            this.state.errors[i].state = true;
                            this.setState({'errors': this.state.errors});
                            console.log('scrolling to', this.state.errors[i].ref.current.offsetTop, this.state.errors[i]);
                            return false;
        
                        }
                    }
                    break;
                    case 'hasTime':
                    {
                        console.log('validating ', this.state.errors[i].object, this.state[this.state.errors[i].object]);
                        var t = moment(this.state[this.state.errors[i].object]);
                        if(t.isValid())
                        {
                            this.state.errors[i].state = false;
                            this.setState({'errors': this.state.errors});
                            console.log('time valid ', this.state.errors[i].object, this.state[this.state.errors[i].object]);
        
                        }
                        else
                        {
                            if(this.state.errors[i].allow_empty && this.state[this.state.errors[i].object] === null)
                            {
                                this.state.errors[i].state = false;
                                this.setState({'errors': this.state.errors});    
                            }
                            else
                            {
                                window.scrollTo(0, this.state.errors[i].ref.current.offsetTop);                
                                this.state.errors[i].state = true;
                                this.setState({'errors': this.state.errors});
                                console.log('scrolling to', this.state.errors[i].ref.current.offsetTop, this.state.errors[i]);
                                return false;
                            }

        
                        }
                    }
                    break;
                }

            }

   
        }
        return true;
    }        


 


    
    onDoNext(event)
    {
        var x = Object.assign({}, this.state.errors);

        if(this.isValid()){

            var o = {
                configuration: { book_type: this.state.book_type, 
                                 day_of_week: this.state.day_of_week_obj.value, 
                                 time: this.state.time, 
                                 next_date: this.state.next_date, 
                                 end_date: this.state.end_date,
                                 tz: this.state.tz,
                                 duration: this.state.duration,
                                 active: this.state.active
                                },
                state: EVENT_APP_CACHE_STATE_CLONE_WEEKLY
            };
            console.log("sending data", o);
            this.props.setStep(o);
        }
        else
        {
            console.log('validation error');
        }

    

    }
  
    




  

    render()
    {
        var classes = this.props.classes;

        console.log('render', this.state);

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Scheduling and date management: Weekly automated scheduling
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="center" alignItems="flex-start" spacing={2} >


                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">

                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                        
                                        <Grid item xs={12} md={12}>
                                            <StepperWidget key={uuidv1()} step={1}/>

                                        </Grid>
                                        
                                        <Grid item xs={12}>

                                        </Grid>
                                        
                                        <Grid item xs={12}>
                                            <div className="formSection">Step 1: Configure the date and time of the event.</div>                               
                                            <p className="sectionDescription">
                                               This section allows you to specify the day of week, the time and the duration of the event.    
                                            </p>                                        
                                        </Grid>

      
 
                                        <Grid item  xs={12} md={3} ref={this.state.errors['dayofweek'].ref}>
                                       
                                            <Autocomplete
                                                id="day-of-week-list"
                                                className={classes.formControl}
                                                options={this.week_days}
                                                getOptionLabel={week_day => week_day.day}
                                                style={{ width: "100%" }}
                                                autoHighlight
                                                multiple={false}
                                                value={this.state.day_of_week_obj}
                                                onChange={this.onDayOfWeekOptionSelect}
                                                disableClearable={true}
                                                renderOption={day_of_week => (
                                                <React.Fragment>
                                                    {day_of_week.day}
                                                </React.Fragment>
                                                )}                
                                                renderInput={params => 
                                                <TextField {...params} 
                                                label="Day of week" 
                                                variant="outlined"
                                                error={this.state.errors.dayofweek.state}
                                                helperText={this.state.errors.dayofweek.state? this.state.errors.dayofweek.error: this.state.errors.dayofweek.ok}
                                                />}
                                            /> 

                                        </Grid>

                                        <Grid item xs={12} md={3} ref={this.state.errors['timepicker'].ref} >
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    margin="normal"
                                                    label="Time of event"
                                                    inputVariant="outlined"
                                                    ampm={false}
                                                    value={this.state.time}
                                                    onChange={this.onSetTime}
                                                    fullWidth
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                    helperText="Select the time of the event"                             
                                                    />
                                            </MuiPickersUtilsProvider>    
                                        </Grid>  
                                        <Grid item md={3} xs={12} ref={this.state.errors['tz'].ref}>
                                            <TextField
                                                select
                                                label="Timezone"
                                                fullWidth
                                                value={this.state.tz}
                                                onChange={this.onSetTZ}
                                                helperText={this.state.errors.tz.state? this.state.errors.tz.error: this.state.errors.tz.ok}
                                                variant="outlined"
                                                margin="normal"
                                                error={this.state.errors['tz'].state}
                                            >
                                                {this.state.zones.map((m) => (
                                                <MenuItem key={m.value} value={m.value}>
                                                    {m.name}
                                                </MenuItem>
                                                ))}
                                            </TextField>                                        
                                        </Grid>                                           
                                        <Grid item xs={12} md={3} ref={this.state.errors['duration'].ref}>
                                            <TextField
                                                onChange={this.onSetDuration}
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                placeholder="1"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}  
                                                step={1} 
                                                type="number" 
                                                value={Number(this.state.duration).toFixed(0) }                                
                                                error={this.state.errors.duration.state}                               
                                                label="Duration (minutes)"
                                                helperText={this.state.errors.duration.state? this.state.errors.duration.error: this.state.errors.duration.ok}
                                            />                                         
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="formSection">Step 2: Configure when reservie should generate the dates.</div>                               
                                            <p className="sectionDescription">
                                               This section allows you to specify when the next date and last date for event generation should be.     
                                            </p>                                        
                                        </Grid>                                        

                                        <Grid item xs={12} md={3} ref={this.state.errors['nextdaypicker'].ref}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                disablePast
                                                value={this.state.next_date}
                                                onChange={this.onSetNextDate}
                                                animateYearScrolling
                                                margin="normal"
                                                inputVariant="outlined"
                                                fullWidth
                                                placeholder="Add date of the event."
                                                format="dd MMM yyyy"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}     
                                                error={this.state.errors.nextdaypicker.state}                               
                                                autoFocus={false}
                                                error={this.state.errors.nextdaypicker.state}
                                                label="Next date"
                                                helperText={this.state.errors.nextdaypicker.state? this.state.errors.nextdaypicker.error: this.state.errors.nextdaypicker.ok}

                                            />                                                                   
                                        </MuiPickersUtilsProvider> 
                                        </Grid>
                                         
                                        <Grid item xs={12} md={3}   ref={this.state.errors['enddaypicker'].ref}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                key={uuidv1()}
                                                autoOk
                                                disablePast
                                                value={this.state.end_date}
                                                onChange={this.onSetEndDate}
                                                animateYearScrolling
                                                margin="normal"
                                                inputVariant="outlined"
                                                fullWidth
                                                format="dd MMM yyyy"
                                                placeholder="Last date of the event."
                                                clearable
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}     
                                                error={this.state.errors.enddaypicker.state}                               
                                                label="Last date"
                                                helperText={this.state.errors.enddaypicker.state? this.state.errors.enddaypicker.error: this.state.errors.enddaypicker.ok}

                                            />                                                                   
                                        </MuiPickersUtilsProvider>  
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div className="formSection">Step 3: Configure the number of dates to be displayed.</div>                               
                                            <p className="sectionDescription">
                                               This section allows you to specify how many dates should be active at any one time and available for booking.      
                                            </p>                                        
                                        </Grid>                                          
                                        <Grid item xs={12} md={6} ref={this.state.errors['active'].ref}>
                                            <TextField
                                                onChange={this.onSetActive}
                                                margin="normal"
                                                variant="outlined"
                                                fullWidth
                                                placeholder="1"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}  
                                                inputProps={{
                                                    step: 1,
                                                    min: 1,
                                                    max: 10,
                                                }}
                                                type="number" 
                                                value={Number(this.state.active).toFixed(0) }                                
                                                error={this.state.errors.active.state}                               
                                                label="Dates displayed"
                                                helperText={this.state.errors.active.state? this.state.errors.active.error: this.state.errors.duration.ok}
                                            />                                         
                                        </Grid>
                                        <Hidden smDown>
                                            <Grid item md={6}>

                                            </Grid>
                                        </Hidden>
             
 
                                        <Grid item xs={4} md={3}>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            size="large"
                                            className="rv-eng-next-button"
                                            startIcon={<NavigateBeforeIcon />}
                                            onClick={this.onDoPrevious}
                                            fullWidth={true}
                                        >
                                            Back
                                        </Button>                          
                                        </Grid>
                                        <Grid item xs={4} md={6}></Grid>
                                        <Grid item xs={4} md={3}>                      
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className="rv-eng-save-button"
                                            onClick={this.onDoNext}
                                            fullWidth={true}
                                            startIcon={<SaveIcon />}
                                        >
                                        Save
                                        </Button>                         
                                        </Grid>                      
                                    </Grid>


                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>

                
            </div>            
        );
    }

};



const EventCloneAutomatedWeekly = connect(null, mapDispatchToProps)(EventCloneAutomatedWeeklyModule);
export default withStyles(styles)(EventCloneAutomatedWeekly);
