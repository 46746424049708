import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
const QuestionaireRadio = ({id, text, index, required, options, answer, onAnswer }) => {

    return ( 
        <React.Fragment>
            <div  className="customer_questionnaire_field">
                <FormControl component="fieldset">
                    <FormLabel component="legend">{index}. {text}</FormLabel>
                    <RadioGroup aria-label={`radio-${id}`} name={id} value={answer.answer} onChange={(e) => {onAnswer(id, e.target.value)}}>
                        {
                            options.map( (o, i) => (
                                <FormControlLabel  key={id + '-' + o.id} value={o.id} control={<Radio />} label={o.text} />
                            ))
                        }
                    </RadioGroup>
                </FormControl>                
            </div>
        </React.Fragment>
        

     );
}
 
export default QuestionaireRadio;