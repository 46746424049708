import React, { Component } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from "@material-ui/core/TableCell";
import DeleteIcon from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';

import TextField  from '@material-ui/core/TextField'
import './customers.css';

import {v1 as uuidv1} from "uuid";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import CustomerMergeDialog from './customer-merge-dialog';
import {reloadData, manageCustomer} from '../redux/actions';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    section:{
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },      
    avatar:{
        display: 'block',
        fontSize: '110px',
        margin: 'auto',
        paddingTop: '12px',
        paddingBottom: '12px',
    },

    editLink:{
        color: '#3c8dbc',
        marginTop: '20px',
        display: 'block',
        textAlign: 'right'
    },


});



const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});



const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);     

  function mapDispatchToProps(dispatch) {
    return {
        reloadData: spec => dispatch(reloadData(spec)),
        manageCustomer: spec => dispatch(manageCustomer(spec))

    };
}


class CustomerBasicPanelModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            security: props.security,
            loading: false,
            customer: props.customer,
            customers: props.customers,
            cust_add: false,
            customer_temp: props.customer,
            merge: false,
            error: '',
            customers: props.customers,
        };

        console.log('CustomerBasicPanel', this.state.customer);

        this.onBasicInfoEdit           = this.onBasicInfoEdit.bind(this);
        this.onUpdateBasicFirstname    = this.onUpdateBasicFirstname.bind(this);
        this.onUpdateBasicLastname     = this.onUpdateBasicLastname.bind(this);
        this.onUpdateBasicEmail        = this.onUpdateBasicEmail.bind(this);
        this.onUpdateBasicTel          = this.onUpdateBasicTel.bind(this);
        this.onhandleEditCustomerOK    = this.onhandleEditCustomerOK.bind(this);
        this.onhandleEditCustomerClose = this.onhandleEditCustomerClose.bind(this);
        this.onUpdateBasicPassword     = this.onUpdateBasicPassword.bind(this);
        this.onMergeStart              = this.onMergeStart.bind(this);
        this.onRefresh                 = props.onRefresh;

    }

    onMergeStart( )
    {
        this.setState({merge: true});
    }

    
    onhandleEditCustomerOK(event)
    {
        event.preventDefault();
        event.nativeEvent.stopImmediatePropagation();

        var firstname = this.state.customer_temp.firstname;
        var lastname = this.state.customer_temp.lastname;
        var email = this.state.customer_temp.email;
        var mobile = this.state.customer_temp.mobile;
        var id     = this.state.customer_temp.id;
        var password     = this.state.customer_temp.password || '';
        var chain = '';
        chain = chain + "&id=" + id;
        chain = chain + "&firstname=" + firstname;
        chain = chain +  "&lastname=" + lastname;
        chain = chain +  "&email=" + email;
        chain = chain +  "&telephone=" + mobile;
        chain = chain +  "&password=" + password;
        var _this = this;
        this.setState({'error': ''});

        try{
            fetch("/api-actions-customers/ajax-customers-edit", {
                method: "POST",
                mode: 'same-origin', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'include', // include, *same-origin, omit                            
                body: this.state.security.name + '=' + this.state.security.token + chain,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": 'XMLHttpRequest',
                    "X_REQUESTED_WITH": 'xmlhttprequest'
                }
            })
            .then(res => {
                return res.json();
            })
            .then(response => {
                //console.log(data);
                if(response.status === 'OK')
                {
                    var x = Object.assign({}, this.state.customer_temp);
                    x.firstname = firstname;
                    x.lastname  = lastname;
                    x.email     = email;
                    x.tel       = mobile;
                    _this.setState({ customer: x, error: '', cust_add: false});
                    _this.onRefresh();

                    const data = Object.assign({}, this.props.data);

                    data.customers = data?.customers?.map( c => {
                        if(c?.id === id)
                        {
                            c.firstname = firstname;                            
                            c.lastname = lastname;                            
                            c.email = email;                            
                            c.telephone = mobile;                            
                        }
                        return c;
                    })
                    const s = {
                        'settings': data
                    };
                    console.log("Hot update customers - ", s);
                    this.props.reloadData( s );
                    
                    const z = Object.assign({},this.state.customer);
                    z.firstname = firstname;
                    z.lastname  = lastname;
                    z.email     = email;
                    z.mobile    = mobile;

                    let message = {
                        customer: z
                    };
                    console.log("onManageCustomer: managing customer", this.state.customer, z, message);
                    this.props.manageCustomer(message);
                }
                else
                {
    
                    _this.setState({error: response.message});
                    _this.onRefresh();
                }
            }).catch(function (err) {
                console.error('Connection error', err);
                _this.setState({loading: false, error: 'Network error'} );
                _this.onRefresh();
            });             
        }
        catch(e)
        {
            _this.setState({loading: false, error: 'Netword error. Please try again'} );
        }
    
    }

    onhandleEditCustomerClose(event)
    {
        this.setState({cust_add: false});
    }

    onUpdateBasicFirstname(event)
    {
        var x = Object.assign({}, this.state.customer_temp);
        var firstname = event.target.value || "";    
        x.firstname = firstname;
        this.setState({customer_temp: x});
    }

    onUpdateBasicLastname(event)
    {
        var x = Object.assign({}, this.state.customer_temp);
        var lastname = event.target.value || "";    
        x.lastname = lastname;
        this.setState({customer_temp: x});
    }

    onUpdateBasicEmail(event)
    {
        var x = Object.assign({}, this.state.customer_temp);
        var f = event.target.value || "";    
        x.email = f;
        this.setState({customer_temp: x});
    }

    onUpdateBasicPassword(event)
    {
        var x = Object.assign({}, this.state.customer_temp);
        var f = event.target.value || "";    
        x.password = f;
        this.setState({customer_temp: x});
    }

    

    onUpdateBasicTel(event)
    {
        var x = Object.assign({}, this.state.customer_temp);
        var f = event.target.value || "";    
        x.mobile = f;
        this.setState({customer_temp: x});
    }

    onBasicInfoEdit(event)
    {
        this.setState({cust_add: true, customer_temp: this.state.customer});
    }



    render()
    {
        var props = this.props;
        console.log('Loading', props);
        var classes = props.classes;
        var e = [];

        return (
                <div>
                    <Grid container justify="flex-start" alignItems="stretch">

                        <Grid item xs={12} md={12}>
                            <Grid container spacing={3} justifyContent="flex-end">
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        onClick={this.onMergeStart}
                                        startIcon={<MergeTypeIcon />}
                                        color="error"
                                    >
                                        Merge
                                    </Button>                                    
                                </Grid>  
                                <Grid item >
                                    <Button
                                        variant="outlined"
                                        onClick={this.props.onArchive}
                                        startIcon={<DeleteIcon />}
                                        color="error"
                                    >
                                        Archive
                                    </Button>                                    
                                </Grid>                                
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        onClick={this.onBasicInfoEdit}
                                        startIcon={<EditIcon />}
                                    >
                                        Edit
                                    </Button>                                    
                                </Grid>
                            </Grid>                              
                        </Grid>

                        <Grid item xs={12}>
                            <TableContainer>                                
                                <Table className={classes.table} aria-label="Class table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className={classes.cellTitle}>
                                                <b>First name</b>
                                            </TableCell>    
                                            <TableCell>
                                               {this.state.customer.firstname}
                                            </TableCell>                                                                                                                                
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.cellTitle}>
                                                <b>Last name</b>
                                            </TableCell>  
                                            <TableCell>
                                                {this.state.customer.lastname}
                                            </TableCell>                                                                                                                                    
                                        </TableRow>                                        
                                        <TableRow>
                                            <TableCell className={classes.cellTitle}>
                                                <b>e-mail</b> 
                                            </TableCell> 
                                            <TableCell>
                                                {this.state.customer.email}
                                            </TableCell>                                                                                                                                  
                                        </TableRow> 
                                        <TableRow>
                                            <TableCell className={classes.cellTitle}>
                                                 <b>Contact number</b>
                                            </TableCell> 
                                            <TableCell>
                                                  {this.state.customer.mobile}
                                            </TableCell>                                                                                                                                    
                                        </TableRow>                                         
                                    </TableBody>
                                </Table>

                            </TableContainer>

                        </Grid>
                    </Grid>

                    <Dialog aria-labelledby="customized-dialog-title" open={this.state.cust_add} fullWidth={true} maxWidth = {'sm'}>
                    <form onSubmit={this.onhandleEditCustomerOK}>
                        <DialogTitle id="customized-dialog-title" onClose={this.onhandleEditCustomerClose}>
                        Edit Customer
                        </DialogTitle>
                        <DialogContent dividers>
                            <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                            {   this.state.error != '' &&
                                        <Grid item xs={12} md={12} > 
                                            <Alert severity="error">{this.state.error}</Alert>
                                         </Grid>
                                }
                                <Grid item xs={12} md={12} > 
                                    <TextField name="firstname" value={this.state.customer_temp.firstname} onChange={this.onUpdateBasicFirstname} id="first-name" label="First name" variant="outlined" fullWidth={true} autoFocus={true} helperText="Enter the customer's first name. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'" inputProps={{required:true, pattern:"[A-Za-z-._() ]{1,250}"}} />
                                </Grid>
                                <Grid item xs={12} md={12} > 
                                    <TextField name="lastname" value={this.state.customer_temp.lastname} onChange={this.onUpdateBasicLastname}  id="last-name" label="Last name" variant="outlined" fullWidth={true} helperText="Enter the customer's last or surname. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'" inputProps={{required:true, pattern:"[A-Za-z-._() ]{1,250}"}} />
                                </Grid>
                                <Grid item xs={12} md={12} > 
                                    <TextField name="email" value={this.state.customer_temp.email} onChange={this.onUpdateBasicEmail} id="email"  label="Email address" variant="outlined" type="email" fullWidth={true} helperText="Enter the customer's email address" inputProps={{required:true}} />
                                </Grid>  
                                <Grid item xs={12} md={12} > 
                                    <TextField name="telephone" value={this.state.customer_temp.mobile} onChange={this.onUpdateBasicTel} id="telephone" label="Mobile number" variant="outlined" type="tel" pattern="[0-9]{3}" fullWidth={true}  helperText="Enter the customer's mobile number. If you do not know this, please leave blank. Allowed characters '+' and 0-9." inputProps={{required:true, pattern:"^[+]{0,1}[0-9]{8,20}"}}/>
                                </Grid>  
                                <Grid item xs={12} md={12} > 
                                    <TextField name="password" value={this.state.customer_temp.password || ''} onChange={this.onUpdateBasicPassword} id="password" label="Password" variant="outlined" type="password" pattern="[0-9A-Za-z!-+,;?]{6,256}" fullWidth={true}  helperText="Optional - update the password. Must be at least 6 characters long and contact the following characters: a-z,A-Z, 0-9, !-+,;?" inputProps={{required:false, pattern:"^[0-9A-Za-z!-+,;?]{6,256}"}}/>
                                </Grid>                                                                                                
                            </Grid>

                            <Typography gutterBottom>
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button  key={uuidv1()} color="primary" type="submit">
                                OK
                            </Button>
                        </DialogActions>
                     </form>
                </Dialog>
                {
                    <CustomerMergeDialog open={!!this.state.merge} customers={this.state.customers} customer={this.state.customer} security={this.state.security} handleClose={(e)=> {this.setState({merge: false})}}/>
                }
                </div>
        );            
        
    
    }
}


const CustomerBasicPanel = connect(null, mapDispatchToProps)(CustomerBasicPanelModule);
export default withStyles(styles)(CustomerBasicPanel);

