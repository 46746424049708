import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {v1 as uuidv1} from "uuid";
import Alert from '@material-ui/lab/Alert';
import { FormControlLabel, Grid, Switch } from '@material-ui/core';
import moment from 'moment'


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonDisabled: {
    color: theme.palette.grey[900]
  },
  formSectionTitle:{
    marginTop: '15px',
    marginBottom: '15px',
    fontWeight: 'bold'
}    
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function addWeeks(numOfWeeks, date = new Date()) {
  date.setDate(date.getDate() + numOfWeeks * 7);

  return date;
}

function PassRegistrationDialogWidget( props ) {
  const [open, setOpen] = React.useState(props.open);
  const  [selected, setSelected] = React.useState(false);
  const  [pass, setPass] = React.useState(false);
  const  [expires, setExpires] = React.useState(false);
  const  [loading, setLoading] = React.useState(props.loading);
  const  [expireson, setExpiresOn] = React.useState(addWeeks(1).toISOString().split('.')[0]);
  const  callback  = props.onCancel;
  const  [price, setPrice] = React.useState(0);
  const  [error, setError] = React.useState('');

  const handleClose = () => {
    console.log('handleClose', props)
    callback(false);
    //setOpen(false);
  };


  const handleRegistration = () => {

    const d = new Date(expireson);
    const str = d.toISOString().split('T');

    console.log("date", str);


    var chain = '';
    chain    += '&client_id='     + props.cust_id;
    chain    += '&id='            + selected.id;
    chain    += '&paid='          + price;
    chain    += '&expires='       + expires;
    chain    += '&pass='           + pass?.id;
    chain    += '&expireson='     + str[0] + ' ' + str[1].split('.')[0];

    setLoading(true);

    var _this = this;
    console.log('Assigning video to customer');
    try {
        fetch("/customers/do-ajax-register-video-credit", {
          method: "POST",
          mode: 'same-origin', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'include', // include, *same-origin, omit                            
          body: props.security.name + '=' + props.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
          if(response.status === 'OK')
          {
            console.log('handleRegistration', props)
            callback(true);
            setOpen(false);
  
          }
          else
          {
            console.log('handleRegistration', props)
            setError(response.message)
          }
        }).catch(function (err) {
          console.error('api called', err);
          setLoading(false);
          setError('Network error. Please retry.')
          //              _this.setState({loading: true});
        });           

    }
    catch(e)
    {
      console.error('api called', e);
      setLoading(false);
      setError('Network error. Please retry.')
    }    

  };

  const handleEventSelect = (event, value) => {
      setSelected( value );
      setPrice(parseInt(value.credits));
  };

  
  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open])

  
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} maxWidth = {'sm'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} danger={(props.danger?props.danger:false)}>
          Video assignment
        </DialogTitle>
        <DialogContent dividers>
          {(
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className={props.classes.formSectionTitle}>Step 1: select the video to assign to customer: </div>
                    <Autocomplete
                      id="video-video-list"
                      options={props.videos}
                      getOptionLabel={option => option.name}
                      style={{ width: "100%" }}
                      autoHighlight
                      onChange={handleEventSelect}
                      renderOption={option => (
                        <React.Fragment>
                          {option.name}
                        </React.Fragment>
                      )}                
                      renderInput={params => <TextField {...params} label="Videos" variant="outlined" />}
                    />                  
                </Grid>
                <Grid item xs={12}>
                    <div className={props.classes.formSectionTitle}>Step 2: Select the pass </div>
                    <Autocomplete
                      id="video-pass-list"
                      options={props.passes?.filter((p) => parseInt(p.available) > 0)}
                      getOptionLabel={option => option.name + ' ( Expires: ' + moment(option.expires).format('LLL') + ' ). Credits available:' + option.available}
                      style={{ width: "100%" }}
                      autoHighlight
                      onChange={(e, v) => {
                        console.log("Selected", v);
                        setPass(v);
                      }}
                      disabled={!!!selected}
                      renderOption={option => (
                        <React.Fragment>
                          {option.name}
                        </React.Fragment>
                      )}                
                      renderInput={params => <TextField {...params} label="Pass" variant="outlined" />}
                    />                  
                </Grid>

                <Grid item xs={12}>
                <div className={props.classes.formSectionTitle}>Step 3: Select the credits to pay: </div>
                  <TextField
                      onChange={(e) => setPrice(parseInt(e.target.value))}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      placeholder="1"
                      disabled={!!!selected || !!!pass}
                      InputLabelProps={{
                          shrink: true,
                      }}  
                      inputProps={{ min: "0", step: "1", max: pass?.available || 0 }}  
                      type="number" 
                      value={price?.toFixed(0) || 0  }                              
                      error={false}                               
                      label="Credits"
                      helperText={"Enter the credits to pay for this video"}
                    /> 
                </Grid>
                <Grid item xs={12}>

                <div className={props.classes.formSectionTitle}>Step 3: Configure expiration </div>

                  <FormControlLabel
                    control={
                      <Switch
                        checked={expires}
                        onChange={(e) => setExpires( e.target.checked )}
                        name="Expires"
                        disabled={!!!selected || !!!pass}
                        color="primary"
                      />
                    }
                    label="Expires"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                      id="datetime-local"
                      label="Expires on"
                      type="datetime-local"
                      value={expireson}
                      disabled={!!!selected || !expires }
                      onChange={(e) => {}}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />                  
                </Grid>
              </Grid>



              


            {
              error !== '' &&  <Alert severity="error">{error}</Alert>
            }
            </div>) }
          <Typography gutterBottom />
        </DialogContent>
        <DialogActions>
          { (selected?.id) && (
          <Button onClick={handleRegistration} key={uuidv1()} color="primary" variant="outlined">
                Complete assignment
          </Button>
          ) }
          { (!selected.id) && (
          <Button key={uuidv1()} disabled={true} color="inherit" variant="outlined" className={props.classes.buttonDisabled}>
                Complete assignment
          </Button>
          ) }          
        </DialogActions>
      </Dialog>
    </div>
  );
  
}

class PassRegistrationDialog extends Component{
    constructor( props )
    {
        super( props );
      // console.log('TopNav', props);


        this.state = {
            security: props.security,
            loading: false,
            cust_id: props.cust_id,
            callback: props.onCancel,
            videos: props.videos,
            passes: props?.passes?.filter( p => (new Date(p.expires)).getTime() >= (new Date()).getTime() ),
            pid: [],
            ready: false

        };

        this.doneCancel = this.doneCancel.bind(this);        
    }  


    doneCancel( refresh ){
      this.state.callback(refresh);
    }

    render()
    {        
        var classes = this.props.classes;


        return (
            <div>
              <PassRegistrationDialogWidget 
                classes={classes} 
                videos={this.state.videos}
                passes={this.state.passes}
                cust_id={this.state.cust_id}   
                onCancel={this.doneCancel} 
                security={this.state.security}
                open={this.props.open}
                />
            </div>
        );
    }
     
}

export default withStyles(styles)(PassRegistrationDialog);
