import React, { Component } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';


import '../core/dashboard.css';


//import Link from '@material-ui/core/Link';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import IconButton from '@material-ui/core/IconButton';

import {setStep, reloadData, setCloneState} from '../redux/actions';

import 'react-dates/initialize';

import AssessmentIcon from '@material-ui/icons/Assessment';
import Tooltip from '@material-ui/core/Tooltip';



import ReportBooking1Dialog from './report-booking-1';
import ReportBooking2Dialog from './report-booking-2';
import ReportBooking3Dialog from './report-booking-3';
import ReportBooking4Dialog from './report-booking-4';
import ReportFinancialOverviewDialog from './report-financial-overview';
import ReportFinancialOverviewForSpecificClassesDialog from './report-financial-overview-for-specific-classes';
import ReportFinancialProportionDialog from './report-financial-proportion-overview';
import ReportPasses1Dialog from './report-passes-1';
import ReportAttendance1Dialog from './report-attendance-1';

import  {
    EVENT_APP_CACHE_STATE_DEFAULT,
    EVENT_MANAGER_VIEW_DATA_MODE_COURSE,
    EVENT_MANAGER_VIEW_DATA_MODE_WEEK,
} from '../redux/types';

import {v1 as uuidv1} from "uuid";


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,       
    },   
    date:{
        backgroundColor: 'rgba(0,0,0,0.1)',
        color: 'rgba(0,0,0,0.8)',
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
        fontWeight: '600',
        marginTop: '10px',
        marginBottom: '10px',
    },
    idCell:{
        width: '8.333%', //100% divided by 12
        textAlign:'left'
    },
    infoCell:{
        width: '83.333%', //100% divided by 12
        textAlign:'left'
    },    
    viewCell:{
        width: '8.333%', //100% divided by 12
        textAlign:'center'
    }, 
    reportSection:{
        paddingTop: '30px',
        marginBottom: '10px'
    },
    reportName:{
        fontSize: '1em',
    },
    reportDescription:{
        fontSize: '0.8em',
    },               
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    danger: {
        color: '#b80000',
        fontSize: '0.8em'
    },
    fabEdit:{
        backgroundColor: '#3c8dbc',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#346785',
        }
    },
    fabCode:{
        backgroundColor: '#9e9e9e',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#858585',
        }
    },
    fabReg:{
        backgroundColor: 'rgba(83, 120, 138, 1)',
        color: '#fff',
        '&:hover':{
            backgroundColor: 'rgba(83, 120, 138, 0.5)',
        }
    },            
    fabIcon:{
        '& .MuiSvgIcon-root':{
            fill: '#fff',
            color: '#fff',
            '&:hover':{
                color: '#ffe',
            }
        }
    }, 
    fabAction:{
        backgroundColor: '#FFA500',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#ea9700',
        }
    }, 

});





                                                                
class ActionButton extends React.Component {

    constructor(props) {
        super(props);
  
        this.state = {
            data: props.data,
            callback: props.callback
        }
  
        console.log('ActionButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }
  
    _handleClick = () => {
        this.state.callback(this.state.data);
    }
    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="clone" className={classes.fabAction} aria-label="event register" onClick={this._handleClick}>
                <AssessmentIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
  }



function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        reloadData: spec => dispatch(reloadData(spec)),
        setCloneState: spec => dispatch(setCloneState(spec))
    };
  }


  


class ReportsManagerModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            security: {},
            report: '',
        };


        console.log("ReportsManagerModule", this.state);
        

        this.state.security = props.security;
        this.report = this.report.bind(this);
        this.reportClose = this.reportClose.bind(this);

     //   alert(props.match.params);
        
       // console.log("Final TopNav",this.state);

    }

    reportClose()
    {
        this.setState({'report': ''});
    }

    report(action)
    {
        switch(action)
        {
            case 'bookings-report-#1':
            case 'financial-overview-#1':
            case 'bookings-report-#2':
            case 'bookings-report-#3':
            case 'bookings-report-#4':
            case 'financial-overview-#2':
            case 'financial-overview-#3':
            case 'pass-purchase-#1':
            case 'attendance-#1':
            {
                this.setState({'report': action});
            }
            break;
            
            default:
            {
                console.error('invalid action', action)
            }
        }
    }

    reportClose()
    {
        this.setState({'report': null});
    }


    componentDidMount()
    {


    }

    componentDidUpdate( props )
    {
       
    }
   
  

    render()
    {
        var classes = this.props.classes;
        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        {window.vocab.topbar}
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >

                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <Grid container justify="flex-start" alignItems="stretch">
                                        <Grid item xs={12} md={12}>
                                            <h2 className={classes.reportSection}>Booking reports</h2>                                
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>ID</TableCell>
                                                            <TableCell className={classes.infoCell}>Report</TableCell>
                                                            <TableCell className={classes.viewCell}>View</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>1.</TableCell>
                                                            <TableCell className={classes.infoCell}>
                                                                <div className={classes.reportName}>Customer event booking overview report</div>
                                                                <div className={classes.reportDescription}>Provides a list of customers that have booked events that occur between specific dates</div>
                                                            </TableCell>
                                                            <TableCell className={classes.viewCell}><ActionButton data='bookings-report-#1' callback={this.report} classes={classes} /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>2.</TableCell>
                                                            <TableCell className={classes.infoCell}>
                                                                <div className={classes.reportName}>Customers that have not booked after a specific date</div>
                                                                <div className={classes.reportDescription}>Provides a list of customers that have previously booked events, but have then not subsequently booked after a specific date.</div>
                                                            </TableCell>
                                                            <TableCell className={classes.viewCell}><ActionButton data='bookings-report-#2' callback={this.report} classes={classes} /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>3.</TableCell>
                                                            <TableCell className={classes.infoCell}>
                                                                <div className={classes.reportName}>Customer booking detail report</div>
                                                                <div className={classes.reportDescription}>Identifies all customers that have booked up to specific date. Details their total spend, classes and or passes booked.</div>
                                                            </TableCell>
                                                            <TableCell className={classes.viewCell}><ActionButton data='bookings-report-#3' callback={this.report} classes={classes} /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>4.</TableCell>
                                                            <TableCell className={classes.infoCell}>
                                                                <div className={classes.reportName}>Customer booking itemised report</div>
                                                                <div className={classes.reportDescription}>Identifies bookings by customers and associated data.</div>
                                                            </TableCell>
                                                            <TableCell className={classes.viewCell}><ActionButton data='bookings-report-#4' callback={this.report} classes={classes} /></TableCell>

                                                        </TableRow>

                                                    </TableBody>

                                                </Table>
                                            </TableContainer>

                                            
                                        </Grid>
                                        
                                    </Grid>
                                    <Grid container justify="flex-start" alignItems="stretch">
                                        <Grid item xs={12} md={12}>
                                            <h2 className={classes.reportSection}>Pass reports</h2>                                
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>ID</TableCell>
                                                            <TableCell className={classes.infoCell}>Report</TableCell>
                                                            <TableCell className={classes.viewCell}>View</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>1.</TableCell>
                                                            <TableCell className={classes.infoCell}>
                                                                <div className={classes.reportName}>Pass purchase report</div>
                                                                <div className={classes.reportDescription}>Provides detail of customers that have purchased a pass between specific dates</div>
                                                            </TableCell>
                                                            <TableCell className={classes.viewCell}><ActionButton data='pass-purchase-#1' callback={this.report} classes={classes} /></TableCell>
                                                        </TableRow>

                                                    </TableBody>

                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                    <Grid container justify="flex-start" alignItems="stretch">
                                        <Grid item xs={12} md={12}>
                                            <h2 className={classes.reportSection}>Attendance reports</h2>                                
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>ID</TableCell>
                                                            <TableCell className={classes.infoCell}>Report</TableCell>
                                                            <TableCell className={classes.viewCell}>View</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>1.</TableCell>
                                                            <TableCell className={classes.infoCell}>
                                                                <div className={classes.reportName}>Event attendance report</div>
                                                                <div className={classes.reportDescription}>Provides detail of attendance for events that fall with a specified date range.</div>
                                                            </TableCell>
                                                            <TableCell className={classes.viewCell}><ActionButton data='attendance-#1' callback={this.report} classes={classes} /></TableCell>
                                                        </TableRow>

                                                    </TableBody>

                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                    <Grid container justify="flex-start" alignItems="stretch">
                                        <Grid item xs={12} md={12}>
                                            <h2 className={classes.reportSection}>Financial reports</h2>                                
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>ID</TableCell>
                                                            <TableCell className={classes.infoCell}>Report</TableCell>
                                                            <TableCell className={classes.viewCell}>View</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>1.</TableCell>
                                                            <TableCell className={classes.infoCell}>
                                                                <div className={classes.reportName}>Financial overview between specific dates</div>
                                                                <div className={classes.reportDescription}>Provides detail of income received between specific dates</div>
                                                            </TableCell>
                                                            <TableCell className={classes.viewCell}><ActionButton data='financial-overview-#1' callback={this.report} classes={classes} /></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>2.</TableCell>
                                                            <TableCell className={classes.infoCell}>
                                                                <div className={classes.reportName}>Financial overview on a per class basis</div>
                                                                <div className={classes.reportDescription}>This report lists the classes, their bookings and provides an overview of earnings. Any bookings via pass will be converted to the cash value based on the amount that the client payed. </div>
                                                            </TableCell>
                                                            <TableCell className={classes.viewCell}><ActionButton data='financial-overview-#2' callback={this.report} classes={classes} /></TableCell>
                                                        </TableRow>

                                                        <TableRow>
                                                            <TableCell className={classes.idCell}>3.</TableCell>
                                                            <TableCell className={classes.infoCell}>
                                                                <div className={classes.reportName}>Percentile distribution of sales</div>
                                                                <div className={classes.reportDescription}>This report provides analysis of income and how sales are distributed between all events that fall within the specified date range. </div>
                                                            </TableCell>
                                                            <TableCell className={classes.viewCell}><ActionButton data='financial-overview-#3' callback={this.report} classes={classes} /></TableCell>
                                                        </TableRow>
                                                    </TableBody>

                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>                                                                            
                        </Grid>

                    </Grid>                                    

                </Grid>

                {this.state.report === 'bookings-report-#1' && (
                    <ReportBooking1Dialog security={this.state.security} onCancel={this.reportClose}/>
                )}
                {this.state.report === 'bookings-report-#2' && (
                    <ReportBooking2Dialog security={this.state.security} onCancel={this.reportClose}/>
                )}
                {this.state.report === 'bookings-report-#3' && (
                    <ReportBooking3Dialog security={this.state.security} onCancel={this.reportClose}/>
                )} 
                {this.state.report === 'bookings-report-#4' && (
                    <ReportBooking4Dialog security={this.state.security} onCancel={this.reportClose}/>
                )}                
                {this.state.report === 'financial-overview-#1' && (
                    <ReportFinancialOverviewDialog security={this.state.security} onCancel={this.reportClose}/>
                )}
                {this.state.report === 'financial-overview-#2' && (
                    <ReportFinancialOverviewForSpecificClassesDialog security={this.state.security} onCancel={this.reportClose}/>
                )}
                {this.state.report === 'financial-overview-#3' && (
                    <ReportFinancialProportionDialog security={this.state.security} onCancel={this.reportClose}/>
                )}                 
                {this.state.report === 'pass-purchase-#1' && (
                    <ReportPasses1Dialog security={this.state.security} onCancel={this.reportClose}/>
                )}   

                {this.state.report === 'attendance-#1' && (
                    <ReportAttendance1Dialog security={this.state.security} onCancel={this.reportClose}/>
                )}               


            </div>            
        );
    }

};



const ReportsManager = connect(null, mapDispatchToProps)(ReportsManagerModule);
export default withStyles(styles)(ReportsManager);



