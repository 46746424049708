import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './stepper';

import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';



import  {
    EVENT_APP_CACHE_STATE_WIZARD_4_DISCOUNTS
} from '../redux/types';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";


    const styles = theme => ({
        root: {
            flexGrow: 1,
          },          
          title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
          },
          formControl: {
            marginBottom: '20px',
            minWidth: 120,
          },
          textInput:{
            marginTop: '20px'
          },
          switchLabel:{
              color: 'rgba(0,0,0,0.6)',
          },
          captionText:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',

          },
          sectionDescription:{
            color: 'rgba(0,0,0,0.6)',    
          },            
          captionSection:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',
            marginBottom: '10px',
            lineHeight: '1.5em',
          },
          schedTypeSelection:{
              marginLeft: '10px'
          },
          what:{
              width: '50%'
          },
          fabDelete:{
              backgroundColor: '#b80000',
              color: '#fff',
              '&:hover':{
                  backgroundColor: '#940000',
              }
          },        
          fabIcon:{
              '& .MuiSvgIcon-root':{
                  fill: '#fff',
                  color: '#fff',
                  '&:hover':{
                      color: '#ffe',
                  }
              }
          }            
});





function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventsStep4DiscountsModule extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            discounts: props.data.obj.discounts || [],
            discount_selection: props.cache.discounts.vouchers || [] ,
            paymentrules: props.data.obj.payment_rules || [],
            paymentrule_selection: props.cache.discounts.payment_rules || [],
            refresh: false,
        }      


        obj = this.state;




        console.log('EventsStep4DiscountsModule', this.state, props);

 


        this.state.security     = props.security;
        this.onDoPrevious       = this.onDoPrevious.bind(this);
        this.onVoucherOptionSelect   = this.onVoucherOptionSelect.bind(this);
        this.onPaymentRuleOptionSelect   = this.onPaymentRuleOptionSelect.bind(this);
        this.onDoNext           = this.onDoNext.bind(this);

    }

    onVoucherOptionSelect(event, value)
    {
        if(value !== null)
        {

            console.log('Voucher options list', value);
            this.setState({'discount_selection': value});

        }
        else
        {
            this.setState({'discount_selection': []});
        }
    }


    onPaymentRuleOptionSelect(event, value)
    {
        if(value !== null)
        {

            console.log('Voucher options list', value);
            this.setState({'paymentrule_selection': value});

        }
        else
        {
            this.setState({'paymentrule_selection': []});
        }
    }    

    onDoPrevious(event)
    {
        var o = { 
            store_previous: true,
            options: { discounts: this.state.discount_selection, payment_rules: this.state.paymentrule_selection },
            state: EVENT_APP_CACHE_STATE_WIZARD_4_DISCOUNTS
        };
        this.props.rewindStep(o);        
    }

     


 


    
    onDoNext(event)
    {
        var x = Object.assign({}, this.state.errors);

        //if(regex.test(this.state.name))
    
        var o = {
                options: { discounts: this.state.discount_selection, payment_rules: this.state.paymentrule_selection },
                state: EVENT_APP_CACHE_STATE_WIZARD_4_DISCOUNTS
            };
        console.log("sending data", o);
        this.props.setStep(o);
    

    }
  
    




  

    render()
    {
        var classes = this.props.classes;

        console.log('render', this.state);

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Event editor
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="center" alignItems="center" spacing={2} >


                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="center" spacing={2}>
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">

                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        
                                        <Grid item xs={12} md={12}>
                                            <StepperWidget key={uuidv1()} step={2}/>

                                        </Grid>
                                        
                                        
                                        <Grid item xs={12}>
                                            <div className="formSection">Pricing adjustments</div>                               
                                            <p className="sectionDescription">
                                               This section allows you to configure the pricing adjusments that apply when the customer is booking a class direct with their credit or debit card. These settings have no effect for clients using a pass to book a class.    
                                            </p>                                        
                                        </Grid>

      
 
                                        <Grid item  xs={12}>
                                            <Autocomplete
                                                id="discounts-list"
                                                className={classes.formControl}
                                                options={this.state.discounts}
                                                getOptionLabel={discount => discount.name}
                                                style={{ width: "100%" }}
                                                autoHighlight
                                                multiple={true}
                                                value={this.state.discount_selection}
                                                onChange={this.onVoucherOptionSelect}
                                                disableClearable={false}
                                                filterSelectedOptions
                                                getOptionSelected={(option, value)  => {
                                                    return (option.id === value.id);  
                                                }}                                                
                                                renderOption={discount => (
                                                <React.Fragment>
                                                    {discount.name}
                                                </React.Fragment>
                                                )}                
                                                renderInput={params => <TextField {...params} label="Discount vouchers" variant="outlined" />}
                                            />

                                        </Grid>
                                        <Grid item  xs={12}>
                                            <Autocomplete
                                                id="paymentrules-list"
                                                className={classes.formControl}
                                                options={this.state.paymentrules}
                                                getOptionLabel={paymentrule => paymentrule.name}
                                                style={{ width: "100%" }}
                                                autoHighlight
                                                multiple={true}
                                                value={this.state.paymentrule_selection}
                                                onChange={this.onPaymentRuleOptionSelect}
                                                disableClearable={false}
                                                filterSelectedOptions
                                                getOptionSelected={(option, value)  => {
                                                    return (option.id === value.id);  
                                                }}                                                
                                                renderOption={paymentrule => (
                                                <React.Fragment>
                                                    {paymentrule.name}
                                                </React.Fragment>
                                                )}                
                                                renderInput={params => <TextField {...params} label="Payment rules" variant="outlined" />}
                                            />                                                                                     
                                        </Grid>

                                      
                                        <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                size="large"
                                                className="rv-eng-next-button"
                                                startIcon={<NavigateBeforeIcon />}
                                                onClick={this.onDoPrevious}
                                                fullWidth={true}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4} md={6}></Grid>
                                        <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="rv-eng-next-button"
                                                endIcon={<NavigateNextIcon />}
                                                onClick={this.onDoNext}
                                                fullWidth={true}
                                            >
                                                Next
                                            </Button>  
                                                                
                                        </Grid>  
                   
                                    </Grid>


                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>

                
            </div>            
        );
    }

};



const EventsStep4Discounts = connect(null, mapDispatchToProps)(EventsStep4DiscountsModule);
export default withStyles(styles)(EventsStep4Discounts);
