import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(0),
    },
  }));

const QuestionaireCheckbox = ({id, text, index, required, answer, onAnswer }) => {
    const classes = useStyles();


    return ( 
        <React.Fragment>
            <div className='customer_questionnaire_field'>

                <FormControl component="fieldset" className={classes.formControl} key={id}>
                    <FormControlLabel
                        control={<Checkbox value={id} checked={!!answer.answer} name={id} id={id} required={required}  autoFocus={index===1? true: false } onChange={(e) => {
                            console.log("Selected checkbox", id, answer?.answer)
                            onAnswer(id, !!!answer?.answer)
                        }} />}
                        label={text}
                    />
                </FormControl>
            </div>
        </React.Fragment>
        

     );
}
 
export default QuestionaireCheckbox;