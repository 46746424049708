import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField  from '@material-ui/core/TextField';
import {v1 as uuidv1} from "uuid";


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonDisabled: {
    color: theme.palette.grey[900]
  },
  passField:{
    marginTop: '20px',
  },
  passFieldCopy:{
    marginTop: '20px',
    '& .MuiOutlinedInput-notchedOutline':{
      borderColor: '#00c0ef',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':{
      borderColor: '#00c0ef',
    },
    '& .MuiFormLabel-root':{

      color:'#00c0ef',
    }
  },

  formSectionTitle:{
    marginTop: '15px',
    marginBottom: '15px',
    fontWeight: 'bold'
}    
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function CodeDialog( props ) {
  const [open, setOpen] = React.useState(props.open);
  const  [embedd] =  React.useState(props.data);
  const  callback  = props.callback;
  const  options   = props.options;
  const [value, setValue] = React.useState(props.options[0].type);
  const [widget, setWidget]     = React.useState(props.data.address);
  const [textClass, setTextClass]  = React.useState(props.classes.passField)
  const default_label              = "Copy and paste into website";
  const [label, setLabel]  = React.useState(default_label);

  const handleClose = () => {
    console.log('handleClose', props)
    callback(false);
    setOpen(false);
  };

  const handleChange = (event) => {
    doChange(event.target.value);
  } 


  const doChange = (v) => {
    setValue(v);
    switch(v)
    { 
      case 'address':
      {
        setWidget(embedd.address);
      }
      break;

      case 'hosted_address':
      {
        setWidget(embedd.base + '/hschedule/v3x/' + embedd.url);
      }
      break;

      case 'hosted_address_vy_x':
        setWidget(embedd.base + '/s/-/' + embedd.url);
      break;

      case 'obutton':
      {
        let w = '<a href="' + embedd.address + '" style="background:#d69017;background-image:-webkit-linear-gradient(top, #d69017, #faa74d);background-image:-moz-linear-gradient(top, #d69017, #faa74d);background-image:-ms-linear-gradient(top, #d69017, #faa74d);background-image:-o-linear-gradient(top, #d69017, #faa74d);background-image:linear-gradient(to bottom, #d69017, #faa74d);-webkit-border-radius:14;-moz-border-radius:14;border-radius:14px;font-family:Arial;color:#ffffff;font-size:14px;padding:10px 20px 10px 20px;text-decoration:none;">Buy now</a>';
        setWidget(w);
      }
      break;  
      case 'bbutton':
      {
        let w = '<a href="' + embedd.address + '" style="background:#3498db;background-image:-webkit-linear-gradient(top, #3498db, #2980b9);background-image:-moz-linear-gradient(top, #3498db, #2980b9);background-image:-ms-linear-gradient(top, #3498db, #2980b9);background-image:-o-linear-gradient(top, #3498db, #2980b9);background-image:linear-gradient(to bottom, #3498db, #2980b9);-webkit-border-radius:14;-moz-border-radius:14;border-radius:14px;font-family:Arial;color:#ffffff;font-size:14px;padding:10px 20px 10px 20px;text-decoration:none;">Buy now</a>';
        setWidget(w);
      }
      break;   
      
      case 'gbutton':
      {
        let w = '<a href="' + embedd.address + '" style="background:#a4b357;background-image:-webkit-linear-gradient(top, #a4b357, #75890c);background-image:-moz-linear-gradient(top, , #a4b357, #75890c);background-image:-ms-linear-gradient(top, #a4b357, #75890c);background-image:-o-linear-gradient(top, #a4b357, #75890c);background-image:linear-gradient(to bottom, #a4b357, #75890c);-webkit-border-radius:14;-moz-border-radius:14;border-radius:14px;font-family:Arial;color:#ffffff;font-size:14px;padding:10px 20px 10px 20px;text-decoration:none;">Buy now</a>';
        setWidget(w);
      }
      break; 
      
      case 'wbutton':
      {
        let w = '<a href="' + embedd.address + '" style="background:#fff;-webkit-border-radius:14;-moz-border-radius:14;border-radius:14px;font-family:Arial;color:#000000;font-size:14px;background:#ffffff;padding:10px 20px 10px 20px;border:solid #c2c2c2 1px;text-decoration:none">Buy now</a>';
        setWidget(w);
      }
      break;      
      case 'api_schedule_embed_v3':
      {
        //let w = '<a href="' + embedd.address + '" style="background:#fff;-webkit-border-radius:14;-moz-border-radius:14;border-radius:14px;font-family:Arial;color:#000000;font-size:14px;background:#ffffff;padding:10px 20px 10px 20px;border:solid #c2c2c2 1px;text-decoration:none">Buy now</a>';
        let w = '<link href="https://d2p08o3nl0hxfj.cloudfront.net/reservie-engine/ui/schedule/vx/widget.css" rel="stylesheet">';
        
        w += '<style>';
        w += '  .rv-sched-booknow{background-color:' + embedd.color + '}';

        w += '  .rv-sched-booknow:active,.rv-sched-booknow:hover{background-color:' + embedd.color + '}';

        w += '  .loading{display:flex;justify-content:center;align-items:center;font-size:2em;margin-top:100px}.rv-more-nav-button{padding-top:10px;padding-bottom:20px}';    
        w += '</style>';
        w += '<script src="https://d2p08o3nl0hxfj.cloudfront.net/reservie-engine/ui/schedule/vx/initialise.js"></script>';
        
        w += '<script>';
        w += '    /* Customise scripts here */';
        w += '    var rv_reservie_address="' + embedd.base + '";';      
        w += '    rv_sched_settings = { show_places: ' + embedd.show_spaces_available + ', show_future_block_date:' +  embedd.show_future_block_date + ' };';      
        w += '</script>';
        w += '<script src="https://cfapi.reservie.net/v1/schedule?id=' + embedd.url +'"></script>';
        w += '<div id="rv_root"><div class="loading">Loading please wait</div></div>';
        w += '<script src="https://d2p08o3nl0hxfj.cloudfront.net/reservie-engine/ui/schedule/vx/widget.js"></script>';
            
        
        setWidget(w);
      }
      break;  

      case 'api_schedule_embed_v4':
      {
        let w = '<link href="https://d2p08o3nl0hxfj.cloudfront.net/reservie-engine/ui/schedule/vy/widget.css" rel="stylesheet">';
        w += '<style>';
        w += '  .rvNS .btn.rv-sched-booknow{background-color:' + embedd.color + '}';

        w += '  .rvNS .btn.rv-sched-booknow:active,.rvNS .btn.rv-sched-booknow:hover{background-color:' + embedd.color + '; opacity:0.8;}';

        w += '  .loading{display:flex;justify-content:center;align-items:center;font-size:2em;margin-top:100px}.rv-more-nav-button{padding-top:10px;padding-bottom:20px}';    
        w += '</style>';
        w += '<script src="https://d2p08o3nl0hxfj.cloudfront.net/reservie-engine/ui/schedule/vy/initialise.js"></script>';
        
        w += '<script>';
        w += '    /* Customise scripts here */';
        w += '    var rv_reservie_address="' + embedd.base + '";';      
        w += '    rv_sched_settings = { show_places: ' + embedd.show_spaces_available + ', show_future_block_date:' +  embedd.show_future_block_date + ' };';      
        w += '</script>';
        w += '<script src="https://cfapi.reservie.net/v1/schedule?id=' + embedd.url +'"></script>';
        w += '<div id="rv_root"><div class="loading">Loading please wait</div></div>';
        w += '<script src="https://d2p08o3nl0hxfj.cloudfront.net/reservie-engine/ui/schedule/vy/widget.js"></script>';
            
        
        setWidget(w);
      }
      break;       

      case 'iframe':
      {
          let w = '<script src="https://my.reservie.net/libs/iframe-resizer/js/iframeResizer.min.js" type="text/javascript"></script>';
          w += '<iframe src="' + embedd.address + '/ewogICJub2xvZ28iOiB0cnVlCn0=" id="iFrameResizer0" scrolling="no" frameborder="0" style="width:100%;"></iframe>';
          w += '<script>iFrameResize({"log":true, "checkOrigin":false}, "#iFrameResizer0")</script>';
          setWidget(w);
      }
      break; 
      
      
    }
  }

  const handleFocus = (event) => {
    if(event.target && event.target.select)
    {
      setLabel('Copying...')
      event.target.select();
      document.execCommand('copy');
      event.target.focus();
      setTextClass( props.classes.passFieldCopy );

      setTimeout(function(){
        setLabel(default_label);
         setTextClass( props.classes.passField );
      }, 800)

    }
  }

  React.useEffect(() => {
    setOpen(props.state);
    doChange(value);
  }, [props.state])


  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true} fullWidth={true} maxWidth = {'sm'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} >
          Code
        </DialogTitle>
        <DialogContent dividers>
        <FormControl component="fieldset">
          <FormLabel component="legend">Select widget type</FormLabel>
          <RadioGroup aria-label="widget" name="widget" value={value} onChange={handleChange}>
            
          {options.map(el => (
            <FormControlLabel value={el.type} key={uuidv1()} control={<Radio />} label={el.name} />
          ))}               
          </RadioGroup>
          <TextField 
          name="passdata" 
          className={textClass} 
          value={widget} 
          multiline
          rows="4"
          rowsMax="4" 
          id="pass-widget" 
          label={label}
          variant="outlined" 
          fullWidth={true} 
          helperText="Click inside box to copy and then paste to your website. If you have selected a widget this will need to be pasted into your website as HTML" type="text"
          onFocus={handleFocus}  
          onClick={handleFocus}  
          />
           
        </FormControl>          

        </DialogContent>
      </Dialog>
    </div>
  );
  
}


export default withStyles(styles)(CodeDialog);
