import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import 'moment-timezone';
import DayPicker, { DateUtils } from 'react-day-picker';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';

import {v1 as uuidv1} from "uuid";
// use material theme
import 'react-times/css/material/default.css';
 

import 'react-day-picker/lib/style.css';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);



  function mapDispatchToProps(dispatch) {
    return {
    };
  }  

class DatesDialogModule extends Component {

    constructor( props )
    {
        super( props );

        this.state = {
            selectedDays: [],
            time: moment(),
            duration: 0,
            error: {},
            refresh: false,
            zones: [],
            tz: moment.tz.guess(),
            oncancel: props.onCancel,
            onOK: props.onOK,         
        };

        var timezones = moment.tz.names();

        var tzs = timezones.filter(timezone => { 
          var t = timezone.toLowerCase().substring(0, 3);
          switch(t)
          {
            case 'uni':
            case 'uct':
            case 'cst':
            case 'cet':
            case 'eet':
            case 'utc':
            case 'wet':
            //case 'etc':
            case 'est':
            case 'gmt':
            case 'gb':
            case 'gb-':
            case 'gre':
            case 'mst':
            case 'met':
            case 'w-s':
            {
              //console.log('filtering', t);
              return false;
            }
            default:
            {
              return true;
            }
          }
        });

        for(var i in tzs)
        {
            var x = { value: tzs[i], gmt_name: " (GMT"+moment.tz(tzs[i]).format('Z')+") " + tzs[i], name: tzs[i] };
            this.state.zones.push(x);
        }
        //this.state.tz = " (GMT"+moment.tz(moment.tz.guess()).format('Z')+") " + moment.tz.guess();

        this.state.errors = {
          daypicker: {
              object: 'selectedDays',
              rule: '',
              ruletype: 'isEmpty', /* function or regex */
              state: false, 
              okClass: '',
              errorClass: 'error',
              ok: "Please select one or more dates on which your event is scheduled.", 
              error: 'Required. At least one date must be selected'
          },
          timepicker: {
              object: 'time',
              rule: '',
              ruletype: 'hasTime', /* function or regex */
              state: false, 
              okClass: '',
              errorClass: 'error',
              ok: "Required: You must select a time for your event.", 
              error: 'Please select a time'
          },
          duration: {
            object: 'duration',
            rule: /^\d+$/,
            ruletype: 'regex', /* function or regex */
            state: false, 
            okClass: '',
            errorClass: 'error',
            ok: "You must select a duration for your event or set to 0", 
            error: 'You must select a duration for your event or set to 0'
        }        
        };   
        
   
        this.handleClose = this.handleClose.bind(this);
        this.handleClickDays = this.handleClickDays.bind(this);
        this.handleSetTime = this.handleSetTime.bind(this);
        this.handleSetDuration = this.handleSetDuration.bind(this);
        this.handleOK = this.handleOK.bind(this);
        this.handleSetTZ = this.handleSetTZ.bind(this);



    }



    handleOK(){
      if(this.isValid())
      {
          var objects = [];
          for(var i = 0; i < this.state.selectedDays.length; i++)
          {
            var uid = uuidv1() + '-' + i;
            uid = uid.replace(/-/g,"");
            
            var x = {
              id: uid,
              date: moment(this.state.selectedDays[i]),
              time: this.state.time,
              tz: this.state.tz,
              duration: this.state.duration,
              deleted: false,
              invalid: false,
            }
            console.log('Adding time ', this.state.time);
            objects.push(x);
          }
          if(objects.length > 0)
          {
            console.log('list of objects to be saved', objects)
            this.state.onOK(objects);
            this.setState({'open': false,  'selectedDays': []});

          }

      }
    }

    handleSetDuration(event)
    {
        this.setState({duration: event.target.value});
    }
    handleSetInvite(event)
    {
        this.setState({invite: event.target.value});
    }
    
    handleSetInviteID(event)
    {
        this.setState({invite_id: event.target.value});
    }

    handleSetInvitePass(event)
    {
        this.setState({invite_pass: event.target.value});
    }

    handleSetTime(date, value)
    {
        var t = moment(value, 'HH:mm');

        this.setState({time: t});

    }                         
    handleSetTZ(event)
    {
      console.log(event.target.value);
      this.setState({'tz': event.target.value});

    }  
    handleClose = () => {
      this.state.oncancel();
      this.setState({'open': false,  'selectedDays': []});
    }

    handleClickDays = (day, { selected }) =>  {
      const { selectedDays } = this.state;

      if (selected) {
        const selectedIndex = selectedDays.findIndex(selectedDay =>
          DateUtils.isSameDay(selectedDay, day)
        );
        selectedDays.splice(selectedIndex, 1);
      } else {
        selectedDays.push(day);
      }
      this.setState({ selectedDays });
    }  

    isValid()
    {
        for(var i in this.state.errors)
        {
            var regex = RegExp(this.state.errors[i].rule);
              var x = Object.assign({}, this.state.errors);


            if(x[i].ruletype === 'regex')
            {
                if(regex.test(this.state[x[i].object]) === false)
                {
                    x[i].state = true;
                    this.setState({'errors': x, refresh: !this.state.refresh});
                    return false;
                }
                else
                {
                    console.log('Passes test');
                    x[i].state = false;
                    this.setState({'errors': x});
                }
            }
            else
            {
                switch(this.state.errors[i].ruletype )
                {
                    case 'isEmpty':
                        if(this.state[x[i].object].length > 0)
                        {
                            x[i].state = false;
                            this.setState({'errors': x});
        
                        }
                        else
                        {
                            x[i].state = true;
                            this.setState({'errors': x});
                            return false;
        
                        }
                    break;

                    case 'hasTime':
                        var t = moment(this.state[x[i].object]);
                        if(t.isValid())
                        {
                            x[i].state = false;
                            this.setState({'errors': x});
        
                        }
                        else
                        {
                            x[i].state = true;
                            this.setState({'errors': x});
                            return false;
        
                        }
                    break;
                    default:
                      console.log('Unexpected check type', this.state.errors[i].ruletype)

                }

            }
            
        }

        return true;

    }


    render(){

        const today = new Date();
        return (
          <div>
            <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.props.state} fullWidth={true} maxWidth = {'md'}>
              <DialogTitle id="customized-dialog-title" onClose={this.handleClose} danger={true}>
                Date editor
              </DialogTitle>
              <DialogContent dividers>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                      <Hidden smDown>
                        <Grid item md={1} ></Grid>
                      </Hidden>
                      <Grid item xs={12} md={4} >
                          <div className="formLabel">Step 1: Select one or more dates</div>
                          <DayPicker 
                          onDayClick={this.handleClickDays}
                          selectedDays={this.state.selectedDays}
                          disabledDays={{ before: today }}
                          numberOfMonths={1} 
                          pagedNavigation 
                          fixedWeeks
                          className={this.state.errors.daypicker.state? this.state.errors.daypicker.errorClass: this.state.errors.daypicker.okClass}
                          />
                          <p className="captionText"><span className={this.state.errors.daypicker.state? this.state.errors.daypicker.errorClass: this.state.errors.daypicker.okClass}>{this.state.errors.daypicker.state? this.state.errors.daypicker.error: this.state.errors.daypicker.ok}</span></p>                             
          
                      </Grid> 
                      <Hidden smDown>
                      <Grid item md={1} ></Grid>
                      <Divider orientation="vertical" flexItem={true}/>
                      <Grid item md={1} ></Grid>
                      </Hidden>

                      <Grid item xs={12} md={4} >
                          <div className="formLabel">Step 2: Select a time and a duration.</div>

                          <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={2}>

                            <Grid item xs={12} md={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        key={uuidv1()}
                                        margin="normal"
                                        label="Time of event"
                                        inputVariant="outlined"
                                        ampm={false}
                                        value={this.state.time}
                                        onChange={this.handleSetTime}
                                        fullWidth
                                        error={this.state.errors.timepicker.state}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                        helperText={this.state.errors.daypicker.state?this.state.errors.daypicker.error:this.state.errors.daypicker.ok}                             
                                        />
                                </MuiPickersUtilsProvider>

                            </Grid>
                            <Grid item xs={12} md={12}>
                            <TextField
                                select
                                label="Timezone"
                                fullWidth
                                value={this.state.tz}
                                onChange={this.handleSetTZ}
                                helperText="Please select a timezone"
                                variant="outlined"
                              >
                                {this.state.zones.map((m) => (
                                  <MenuItem key={m.value} value={m.value}>
                                    {m.name}
                                  </MenuItem>
                                ))}
                              </TextField>                           
                            </Grid>                            
                            <Grid item xs={12} md={12}>                                
                                <TextField
                                    label="Event duration (minutes)"
                                    type="number"
                                    className="rv-eng-duration-field"
                                    value={this.state.duration}
                                    onChange={this.handleSetDuration}
                                    margin="none"
                                    variant="outlined"
                                    fullWidth
                                    error={this.state.errors.duration.state}
                                    placeholder="The duration in minutes"
                                    inputProps={{ min: 0, max: 28800 }} 
                                    helperText={this.state.errors.duration.state?this.state.errors.duration.error:this.state.errors.duration.ok}                             
                                    /> 

                            </Grid>                         
                          </Grid>
                      </Grid>

                  </Grid> 
                        
      
      
                <Typography gutterBottom>
                </Typography>
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleOK}>
                            OK
                  </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
    }


}



const DatesDialog = connect(null, mapDispatchToProps)(DatesDialogModule);
export default withStyles(styles)(DatesDialog);

