import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Hidden from '@material-ui/core/Hidden';

import IconButton from '@material-ui/core/IconButton';

import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {reloadData, manageCustomer} from '../redux/actions';
import RefreshIcon from '@material-ui/icons/Refresh';
import CoreDialog  from '../core/dialog'
import Loading from '../core/loading';

import QuestionaireEditorPanel  from './questionaire-editor';
import './../core/dashboard.css';
import './styles.scss';
import { red } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';
import {v1 as uuidv1} from "uuid";
import Alert from '@material-ui/lab/Alert';


import MuiDialogTitle from '@material-ui/core/DialogTitle';

    const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,
    },
    idCell:{
        width: '12%' //100% divided by 12
    }, 
    versionCell:{
        width: '12%' //100% divided by 12
    },       
    editCell:{
        width: '12%' //100% divided by 12
    }, 
    deleteCell:{
        width: '12%' //100% divided by 12
    },          
    nameCell:{
        width: '52%' //100% divided by 12 * 6
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    fabEdit:{
        backgroundColor: '#3c8dbc',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#346785',
        }
    },
    fabCode:{
        backgroundColor: '#9e9e9e',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#858585',
        }
    },
    fabDelete:{
        backgroundColor: red[500],
        color: '#fff',
        '&:hover':{
            backgroundColor: red[600],
        }
    },        
    fabIcon:{
        '& .MuiSvgIcon-root':{
            fill: '#fff',
            color: '#fff',
            '&:hover':{
                color: '#ffe',
            }
        }
    }


});


class EditButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="editbutton" className={classes.fabEdit} aria-label="Manage customer" onClick={this._handleClick}>
                <EditRoundedIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}


class DeleteButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="delete" className={classes.fabDelete} aria-label="Delete Schedule" onClick={this._handleClick}>
                <DeleteForeverIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}






function mapDispatchToProps(dispatch) {
    return {
        reloadData: spec => dispatch(reloadData(spec)),
        manageCustomer: spec => dispatch(manageCustomer(spec))
    };
  }


  


class QuestionairesManagerModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            questionaires: props.data.questionaires || [],
            security: {},
            refresh: false,
            questionaire_add: false,
            questionaire_edit: false,
            questionaire_delete: false,
            questionaire_focus: null,
            questionaire_code: 0,
            edit_data: {},
            loading: false,
            error: false,

            //customer_panel: false, //false
            //customer_id: null // null
        };

        this.state.security = props.security;


        this.setError                     = this.setError.bind(this);
        this.onAddQuestionaireDialogClose = this.onAddQuestionaireDialogClose.bind(this);
        this.onAddQuestionaire            = this.onAddQuestionaire.bind(this);
        this.onEditQuestionaire           = this.onEditQuestionaire.bind(this);
        this.onDelete                     = this.onDelete.bind(this);
        this.onCompleteDelete             = this.onCompleteDelete.bind(this);
        this.onCancelDelete               = this.onCancelDelete.bind(this);
        this.onRefresh                    = this.onRefresh.bind(this);
       // console.log("Final TopNav",this.state);
    }




    setError( e )
    {
        this.setState({error: e});
    }
    clearError(  )
    {
        this.setState({error: false});
    }

    onEditQuestionaire(data)
    {

        this.setState({questionaire_edit: true, edit_data: data});
     
    }

    onDelete(data)
    {
        this.setState({questionaire_delete: true, questionaire_focus: data});
    }

    onCancelDelete()
    {
        this.setState({questionaire_delete: false, questionaire_focus: {}});

    }


    onCompleteDelete()
    {

        var id = this.state.questionaire_focus.id ||  null;
        var _this = this;
        var chain = '';
        chain = chain +  "&id=" + id;
 
        fetch("/questionaires/ajax_questionaire_delete", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({questionaire_delete: false, questionaire_focus: {}});
                _this.onRefresh();
            }
            else
            {

                //this.setState({cust_add: false});
            }
        }).catch(function(err){
            _this.setState({questionaire_delete: false, questionaire_focus: {}});
        });      



    }





    handleMenuOpen( event )
    {
        this.setState({anchor_el: event.currentTarget})
    }

    onAddQuestionaire()
    {
        this.setState({questionaire_add: true})
    }




    onAddQuestionaireDialogClose( refresh )
    {
        this.setState({questionaire_add: false, questionaire_edit:false});
        if(refresh)
        {
            this.onRefresh()
        }
    }    
    



    onRefresh()
    {
        var _this = this;
        this.setState({'loading': true});
        fetch("/backenddata/ajax_fetch_questionaires", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + '&refresh=1',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(!response.hasOwnProperty("error"))
            {
              let o = {
                'settings': response.data
              };
              _this.props.reloadData( o );
              _this.setState({'loading': false});
            }
            else
            {
               /* handle error */
               _this.setError('Failed to load questionaires. Please refresh your page.');
               _this.setState({'loading': false});
            }
        }).catch(function(err){
            console.error('Failed to load the questionaires. Please refresh your page.');
            _this.setError('Network problems. Your session may have expired. Please refresh your page.');
            _this.setState({'loading': false});
        });     
    }



  

    render()
    {
        var classes = this.props.classes;
        return (
            <div className={"rvQuestionaires " + classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                        <Grid container justifyContent="flex-end" alignItems="flex-end">
                            <Grid item>
                                <Button  variant="outlined" className="button-green space-right" startIcon={<AddIcon />} onClick={this.onAddQuestionaire}>Add</Button>
                            </Grid>
                            <Grid item>
                                <Button  variant="outlined" color="primary" onClick={this.onRefresh} startIcon={<RefreshIcon />} >Refresh</Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>


                <Grid container direction="row" justifyContent="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >

                    <Grid item xs={12} md={12} > 
                        <Grid container justifyContent="flex-start" alignItems="stretch">
                    
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <Grid container justifyContent="flex-start" alignItems="stretch">
                                        <Grid item xs={12} md={12}>
                                            <h2>Questionnaires</h2>   
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Alert severity="info" className="mb-2">Please note: Questionnaires can only be used with version 4 schedules.</Alert>
                                            {
                                                this.state.error !== false &&
                                                <Alert severity="error" className="mb-2">Error: {this.state.error}.</Alert>
                                            }

                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <TableContainer>                                
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <Hidden smDown>
                                                                <TableCell className={classes.idCell}>
                                                                    ID
                                                                </TableCell> 
                                                            </Hidden>                                                         
                                                            <TableCell className={classes.nameCell}>
                                                                Name
                                                            </TableCell>
                                                            <Hidden smDown>
                                                                <TableCell className={classes.versionCell}>
                                                                    Version
                                                                </TableCell> 
                                                            </Hidden>                                                               
                                                            <TableCell align="center">
                                                                Edit
                                                            </TableCell>   
                                                            <TableCell align="center">
                                                                Delete
                                                            </TableCell>                                                                                                                                                                           
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {this.state.loading && (
                                                        <TableRow key={uuidv1()}>
                                                            <Hidden smDown>
                                                                <TableCell className={classes.nameCell} colSpan={5}>
                                                                    <Loading />
                                                                </TableCell> 
                                                            </Hidden>
                                                            <Hidden mdUp>
                                                                <TableCell className={classes.nameCell} colSpan={3}>
                                                                    <Loading />
                                                                </TableCell> 
                                                            </Hidden>
                                                        </TableRow>
                                                    )}
                                                    {this.state.loading === false && this.state.questionaires.map(el => (
                                                        <TableRow key={uuidv1()}>
                                                            <Hidden smDown>
                                                                <TableCell className={classes.idCell}>
                                                                    {el.displayid}
                                                                </TableCell>  
                                                            </Hidden>                                                                                     
                                                            <TableCell className={classes.nameCell}>
                                                                {el.name}
                                                            </TableCell>         
                                                            <Hidden smDown>
                                                                <TableCell className={classes.versionCell}>
                                                                    {el.v}
                                                                </TableCell>  
                                                            </Hidden>                                                                                                                  
                                                            <TableCell className={classes.editCell} align="center">
                                                                <EditButton classes={classes} data={el} callback={this.onEditQuestionaire} />
                                                            </TableCell>                                                                               
                                                            <TableCell className={classes.deleteCell} align="center">
                                                                <DeleteButton classes={classes} data={el} callback={this.onDelete} />
                                                            </TableCell> 
                                                        </TableRow>
                                                        ))}
                                                    </TableBody>

                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>

                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>

                {this.state.questionaire_add === true &&  (
                    <QuestionaireEditorPanel open={true} closeCustomerPanel={this.onAddQuestionaireDialogClose} security={this.state.security} data={this.state.edit_data} />
                )}

                {this.state.questionaire_edit === true &&  (
                    <QuestionaireEditorPanel open={true} closeCustomerPanel={this.onAddQuestionaireDialogClose} security={this.state.security} data={this.state.edit_data} />
                )}

                {this.state.questionaire_delete === true && (<CoreDialog title="Delete questionaire" body="You are about to delete this questionaire. Any existing records will be retained. Are you sure that you wish to do this?" state={true} danger={true} onCancel={this.onCancelDelete} actions={[{label:"Delete now", action:this.onCompleteDelete}]}  />)}

            </div>            
        );
    }

};



const SchedulesManager = connect(null, mapDispatchToProps)(QuestionairesManagerModule);
export default withStyles(styles)(SchedulesManager);
