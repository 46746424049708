import React, { Component, forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import MaterialTable, {MTableToolbar} from 'material-table';

import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import MoneyOffRoundedIcon from '@material-ui/icons/MoneyOffRounded';
import TableCell from '@material-ui/core/TableCell';
import Fab from '@material-ui/core/Fab';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CoreDialog from '../../core/dialog';
import TransferDialog from '../../core/transferdialog';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';

import '../customers.css';
import RegistrationWidgets from './video-registration-widget';


function Currency(v, c)
{
    //console.log('converting', v, c);
    switch( c )
    {
        case 'GBP':
            //console.log('Returning GBP', c, v, '£' + Number(v).toFixed(2).toString());
            return '£' + Number(v).toFixed(2).toString();
        case 'USD':
            return '$' + Number(v).toFixed(2).toString();
        case 'AUD':
            return 'AS$' + Number(v).toFixed(2).toString();
        case 'NZD':
            return 'NZ$' + Number(v).toFixed(2).toString();
        default:
        {
//            console.log('unknow', c, v);
            return  (<span>{c} {Number(v).toFixed(2)}</span>);
        }
    }
}

class DeleteButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            object: props.data,
            callback: props.callback,
            dodelete: false,
            dorefund: false,
            security: props.security,
            disabled: false
        }
        this.onInitiateCancel = this.onInitiateCancel.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.doClose = this.doClose.bind(this);

        this.state.disabled = this.state.object?.deleted;
    }

    onInitiateCancel = () => {
        this.setState({dodelete: true});
    }



    doCancel= () => {


        var chain = '';
        chain += '&id=' + encodeURI(this.state.object.id);
 
        var _this = this;
        var callback = this.state.callback;
        try {
            fetch("/api-actions-customers/ajax-void-video-transaction", {
                method: "POST",
                mode: 'same-origin', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'include', // include, *same-origin, omit                            
                body: this.state.security.name + '=' + this.state.security.token + chain,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": 'XMLHttpRequest',
                    "X_REQUESTED_WITH": 'xmlhttprequest'
                }
            })
            .then(res => {
                return res.json();
            })
            .then(response => {
                //console.log(data);
                if(response.status === 'OK')
                {
                    this.setState({dodelete: false, dorefund: false});
                    callback(this.state.id);
                }
                else
                {
                     this.setState({dodelete: false, dorefund: false});
                     callback(this.state.id);
                 }
            }).catch(function (err) {
             //_this.setState({dodelete: false, dorefund: true});
                 _this.setState({dodelete: false, dorefund: false});
                callback(_this.state.id);
            });           
    
        }
        catch(e)
        {
 //        _this.setState({dodelete: false, dorefund: true});
             _this.setState({loading: false, dorefund: false});
             callback(_this.state.id);
         }
 
 
     }



    doClose= () => {
        this.setState({dodelete: false, dorefund: false});

    }

    render() {
        var classes= this.props.classes;
        if(this.state.disabled === false)
        {
            return (
                <div>
                    <Fab className={classes.fabDanger}><DeleteRoundedIcon className={classes.fabIcon} onClick={this.onInitiateCancel}/></Fab>                        
                    <CoreDialog 
                        classes={classes} 
                        state={this.state.dodelete}  
                        danger={false}
                        onCancel={this.doClose}
                        actions={[{action:this.doCancel, label: "Yes, Cancel", className: classes.buttonDanger, variant: "text"}]}
                        body="Are you sure you want to cancel this video? Note this cannot be undone."
                        title="Cancel video"
                         />
                </div>
            );
        }
        else
        {
            return (
                <div>
                    <Fab className={classes.fabGrey}><DeleteRoundedIcon className={classes.fabIcon} /></Fab>                       
                </div>
            );            
        }


    }
}


class RefundButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            object: props.data,
            callback: props.callback,
            dodelete: false,
            dorefund: false,
            security: props.security,
        }
        this.onInitiateCancel = this.onInitiateCancel.bind(this);
        this.doRefund = this.doRefund.bind(this);
        this.doClose = this.doClose.bind(this);

    }

    onInitiateCancel = () => {
        this.setState({dodelete: true});
    }



    doRefund = () => {


        var chain = '';
        chain += '&id=' + encodeURI(this.state.object.id);
 
        var _this = this;
        var callback = this.state.callback;
        this.setState({loading: true});
        try {
            fetch("/api-actions-customers/ajax-refund-video", {
                method: "POST",
                mode: 'same-origin', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'include', // include, *same-origin, omit                            
                body: this.state.security.name + '=' + this.state.security.token + chain,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": 'XMLHttpRequest',
                    "X_REQUESTED_WITH": 'xmlhttprequest'
                }
            })
            .then(res => {
                return res.json();
            })
            .then(response => {
                //console.log(data);
                if(response.status === 'OK')
                {

                    this.setState({dorefund: false, loading: false});
                    callback(this.state.id);
                }
                else
                {
                     this.setState({dorefund: false, loading: false});
                     callback(this.state.id);
                 }
            }).catch(function (err) {
                 _this.setState({dorefund: false, loading: false});
                callback(_this.state.id);
            });           
    
        }
        catch(e)
        {
 //        _this.setState({dodelete: false, dorefund: true});
             _this.setState({dorefund: false, loading: false});
             callback(_this.state.id);
         }

    }


    doClose= () => {
        this.setState({dodelete: false, dorefund: false});

    }

    render() {
        var classes= this.props.classes;
        if(this.state.object.refunded === false)
        {
            return (
                <div>
                    <Fab className={classes.fabWarning}><MoneyOffRoundedIcon className={classes.fabIcon} onClick={this.onInitiateCancel}/></Fab>                        
                    <CoreDialog 
                        classes={classes} 
                        state={this.state.dodelete}  
                        danger={false}
                        onCancel={this.doClose}
                        actions={[{action:this.doRefund, label: "Yes, Refund", className: classes.buttonDanger, variant: "text"}]}
                        body="Are you sure you want to refund the client?"
                        title="Refund"
                         />

                </div>
            );
        }
        else
        {
            return (
                <div>
                    <Fab className={classes.fabGrey}><MoneyOffRoundedIcon className={classes.fabIcon} /></Fab>                       
                </div>
            );            
        }


    }
}



class VideoSalesTablePanel extends Component{


    
    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            security: props.security,
            loading: true,
            data: props.videotransactions||[],
            videos: props.videos||[],
            customer_id: props.customer.id||-1,
            passes: props?.passes.filter( p => p.status !== 'VOIDED') || [],
            cards: props.cards || [],
            rows_per_page: 5,
            page: 0,
            refresh: false,
            customer: props.customer,
            selected: [],
            confirmcancel: false,
            error: false,
        };

        this.handleChangePage           = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage    = this.handleChangeRowsPerPage.bind(this);
        this.state.loading              = false;
        this.getData                    = this.getData.bind(this);
        this.isSelected                 = this.isSelected.bind(this);
        this.setItemChecked             = this.setItemChecked.bind(this);
        this.cancelAll                  = this.cancelAll.bind(this);
        this.doCancelAll                = this.doCancelAll.bind(this);
        this.onRefresh                  = props.onRefresh;
      
    }

    setItemChecked(o, checked)
    {
        const a = [];
        //console.log("final", a);
        if(checked)
        {
            a.push(o);
        }
        this.setState({selected: a});
    }


    isSelected( id ){
       //  console.log("Is selected", !!selected);
         return !!this.state.selected.find( s => s.id === id);
    }

    handleChangeRowsPerPage(event)
    {
        this.setState({'rows_per_page': parseInt(event.target.value, 10)});

    }
    handleChangePage(event, newPage)
    {
        this.setState({'page': parseInt(newPage, 10)});

    }

    getData()
    {
        //console.log('initial object', this.state.data);
        
        return this.state.data.map( d => {
            let x = Object.assign({}, d);
            x.paid = Currency(d.paid, d.currency);
            x.purchased = moment(d.purchased).format("ll"); // "Sunday, February 14th 2010, 3:25:50 pm"
            if(x.expires === 'never')
            {
                x.expires = 'None'; // "Sunday, February 14th 2010, 3:25:50 pm"
            }
            else
            {
                x.expires = moment(d.expires).format("ll"); // "Sunday, February 14th 2010, 3:25:50 pm"
            }
            return x;
        }) || [];

    }

    cancelAll( e )
    {
        e.preventDefault();
        this.setState({confirmcancel: true});
    }

    async doCancelAll( e )
    {
        e.preventDefault();

        if(this.state.selected.length > 0)
        {
            console.log("Selected", this.state.selected);

            try
            {
                const result = await new Promise((resolve, reject) => {

            
                    try {

                        let chain = '';
                        chain += '&id=' + encodeURI(this.state.selected.id);
                        console.log("promise", this.state.selected);

                        fetch("/api-actions-customers/ajax-void-video-transaction", {
                            method: "POST",
                            mode: 'same-origin', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            credentials: 'include', // include, *same-origin, omit                            
                            body: this.state.security.name + '=' + this.state.security.token + chain,
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded",
                                "X-Requested-With": 'XMLHttpRequest',
                                "X_REQUESTED_WITH": 'xmlhttprequest'
                            }
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(response => {
                            //console.log(data);
                            resolve( response );

                        }).catch(function (err) {
                        //_this.setState({dodelete: false, dorefund: true});
                            reject(err);
                        });
                    }
                    catch(e)
                    {   
                        console.log("Failed to process network request", e);
                        reject(e);
                    }      
                })

                if(result.status === 'OK')
                {
                    this.onRefresh();
                    this.setState({'selected': [], 'confirmcancel': false});
                }
                else
                {
                    console.error('Failed to cancel one or more event dates', e);
                    this.setState({'error': 'Failed to cancel one or more event dates'});
                }
            }
            catch(e)
            {
                console.error("Failed to process request", e);
            }
        
        }

        
    }


    render()
    {
        console.log('Loading customer data', this.state);
        var classes = this.props.classes;
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
 
          };

        const l = this.getData().length;
        const c = [];
        let pagesize = 10;
        if(l <= 25)
        {
            pagesize = l;
            c.push(l);
        }
        if(l > 25)
        {
            pagesize = 25;
            c.push(25);
        }
        if(l >= 50)
        {
            pagesize = 50;
            c.push(50);
        }  
        if(l >= 100)
        {
            pagesize = 50;
            c.push(100);
        }  
        if(l >= 200)
        {
            pagesize = 100;
            c.push(200);
        }
        const filtered_pass = this.state.passes.filter((p, i) => {return p.status !== 'VOIDED'});
  
        return (
            <div>
                <Grid container justify="flex-start" alignItems="stretch">
                    <Grid item xs={12} md={12}>
                        <MaterialTable icons={tableIcons} title="" 
                                // other props
                            data={this.getData()}
                            columns={[
                                    { title: 'ID', field: 'id', maxWidth: "100px" },
                                    { title: 'Purchased', field: 'purchased' },
                                    { title: 'What', field: 'name' },
                                    { title: 'Expires', field: 'date' },
                                    { title: 'Price', field: 'paid',  maxWidth: "80px" },
                                    { title: 'State', field: 'state', sorting: false, maxWidth: "80px" },
                                    { title: 'Cancel', field: 'cancel', sorting: false,  maxWidth: "50px"  },
                                    { title: 'Refund', field: 'refund', sorting: false,  maxWidth: "50px"  },
                            ]}
                            options={{
                                search:this.props.fullwidth,
                                pageSize: pagesize,
                                pageSizeOptions: c,
                                padding: 'dense',
                                paging: true,
                                selection: false,
                            }}
                            components={{
                                Container: props => <div>{props.children}</div>,
                                    //Container: React.Fragment
                                    Toolbar: props => (
                                        <div style={{display: "flex", flexWrap: "wrap", justifyContent: "flex-end", alignItems:"center" }} > 
                                          {this.state.selected.length === 0 && <Button disabled>Cancel video</Button>}
                                          {this.state.selected.length > 0 && 
                                          <Button onClick={this.cancelAll}>Cancel video</Button> 
                                          }
                                        <RegistrationWidgets
                                        videos={this.state.videos}
                                        security={this.state.security}
                                        customer={this.state.customer}
                                        passes={filtered_pass}
                                        cards={this.state.cards} 
                                        refresh={this.onRefresh}/> 
                                          <MTableToolbar {...props} />
                                        </div>),    
                                Row: ({ data }) => {
                                    return (
                                        <React.Fragment>
                                            <TableRow key={data.id}>  
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={this.isSelected(data.id)}
                                                        disabled={data.deleted}
                                                        inputProps={{ 'aria-labelledby': data.id }}
                                                        onClick={(e) => this.setItemChecked( data, e.target.checked)}
                                                    /> {data.id}
                                                </TableCell>
                                                <TableCell>{data.purchased}</TableCell>
                                                <TableCell>{data.name}</TableCell>
                                                <TableCell>{data.expires}</TableCell>
                                                <TableCell key={'paid-' + data.id}>{data.paid}</TableCell>
                                                <TableCell>
                                                    {!data.deleted && 'Active'}
                                                    {data.deleted  && 'Cancelled'}
                                                </TableCell>
                                                <TableCell><DeleteButton data={data}  security={this.state.security} classes={classes} callback={this.onRefresh}/> </TableCell>
                                                <TableCell><RefundButton data={data}  security={this.state.security} classes={classes} callback={this.onRefresh}/> </TableCell>
                                            </TableRow>

                                        </React.Fragment>
                                    )
                                }
                            }}
                            
                        />
                    </Grid>
                </Grid>

                {
                    this.state.selected.length > 0 && this.state.confirmcancel && (<CoreDialog title={"Cancel all dates"} 
                        body={"Are you sure that you want to cancel this video purchase?. This cannot be undone!"} 
                        danger={true}
                        onCancel={()=>{this.setState({selected: [], confirmcancel: false })}}
                        actions={[{label:"Yes, Cancel", action:this.doCancelAll}]} state={true} />)
                }
            </div>
        );
    }
}


export default VideoSalesTablePanel;
