import  {
            EVENT_APP_CACHE_STATE_DEFAULT,
            EVENT_APP_CACHE_STATE_WIZARD_0,
            EVENT_APP_CACHE_MODE_NOT_SET,
        } from './types';

import 'moment-timezone';
        

export const CUSTOMER_APP_CACHE_TEMPLATE = { 
    customer:{
        id: null,
        name: null,
        email: null,
        mobile: null,
        loading: true,
        manage: false,
        statistics:{
            twelve_month_sales_data:{
                transactions: []
            },
            all_sales_data_detail:{
                events:[],
                passes:[]
            }
        }
    }            
}


export const PASSES_APP_CACHE_TEMPLATE = { 
    passes:{
        manage: false, 
    },
       
}

export const EVENTS_MANAGER_APP_CACHE_TEMPLATE = { 
    state:  EVENT_APP_CACHE_STATE_DEFAULT,
    previous: EVENT_APP_CACHE_STATE_WIZARD_0,
    refresh: false,
}



export const EVENTS_APP_CACHE_TEMPLATE = { 
    state:  EVENT_APP_CACHE_STATE_DEFAULT,
    previous: EVENT_APP_CACHE_STATE_WIZARD_0,
    mode: EVENT_APP_CACHE_MODE_NOT_SET,   
    basic: { 
        color: "#000000",
        description: "",
        name: "",
        waiting_list_active: true,
    },
    scheduling: {
        mode: "SCHEDULING_TYPE_MANUAL", 
        configuration: { book_type: "BOOKING_MODE_TYPE_INDIVIDUAL", dates:[]}
    },
    virtual:{
        mode: 'zoom',
        configuration: {
            type: 'zoom',
            object:{
                host_video_enabled: false,
                participant_video_enabled: false,
                waiting_room_enabled: true,
                join_before_host_enabled: false,
                mute_upon_entry_enabled: false,
                auto_recording: 'none',
                selected_account: null,
            }
        }
    },    
    payment: 
    {
        mode: 0x02, 
        configuration:
        {
            options: [],
        },
        passes: [],
        discounts:{
            vouchers: [],
            payment_rules: []
        }
    },
    actions:{
        purchase: 0,
        cancel: 0,
        available: 0,
        reminder: 0,
    },
    properties:{
        facilitators: [],
        categories: [],
        terms:[],
        location: null
    }
}


export const SUBSCRIPTIONS_MANAGER_APP_CACHE_TEMPLATE = { 

};

export const REPORT_MANAGER_APP_CACHE_TEMPLATE = { 

};


export const SCHEDULES_MANAGER_APP_CACHE_TEMPLATE = { 

};
export const MANDATES_MANAGER_APP_CACHE_TEMPLATE = {};

export const INVOICE_APP_CACHE_TEMPLATE = {};
export const QUESTIONAIRE_MANAGER_APP_CACHE_TEMPLATE = {};
export const VIDEO_MANAGER_APP_CACHE_TEMPLATE = {};
export const POLICY_APP_CACHE_TEMPLATE = { };
