import React, { Component } from 'react';
import { connect } from "react-redux";


import Grid from '@material-ui/core/Grid';


import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField  from '@material-ui/core/TextField';

import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import Loading from '../core/loading';

import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import { CirclePicker } from 'react-color';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import './schedules.scss';
import { Input, MenuItem, Select } from '@material-ui/core';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    section:{
      paddingTop: '15px',
      paddingBottom: '10px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem',
        marginTop: '0px'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    scheduleField:{
        marginTop: '15px',
        marginBottom: '15px',
    },
    alignCenter:
    {
        textAlign: 'center'
    },
    buttonGo:{
        marginTop: '20px',
        marginBottom: '20px',
    },
    colorSelect:{
        marginTop:"10px",
        marginBottom:"10px",
    },
    widget:{
        marginTop: '10px',
        marginBottom: '10px',
        fontSize: "1rem",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.5',
        letterSpacing: '0.00938em'       
    }    



});






const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });




function mapDispatchToProps(dispatch) {
    return {
    };
  }




class ScheduleAddPanelManagerModule extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            security: props.security,
            options: props.categories||[],
            qoptions: props.questionaires||[],
            id: this.props.data.id || null,
            open: props.open,
            schedulename: this.props.data.name ||'',
            scheduleweeks: this.props.data.n || 1,
            show_spaces_available: this.props.data.show_spaces_available,
            show_future_block_date: this.props.data.show_future_block_date,
            custom_view_events: this.props.data?.custom_view_events || true,
            custom_view_passes: this.props.data?.custom_view_passes || false,
            custom_view_subscriptions: this.props.data?.custom_view_subscriptions || false,
            custom_view_videos: this.props.data?.custom_view_videos || false,
            color: this.props.data.color || '#eb6589',
            categories: this.props.data.categories || [],
            questionaires: this.props.data.questionaires || false,
            clock: this.props.data.clock || '12hour',
            companypasses: props?.passes || [],
            companysubscriptions: props?.subscriptions || [],
            passes:  this.props.data?.passes || [],
            subscriptions:  this.props.data?.subscriptions || [],
            updating: false,
            error: null,
            event_book_multiples: this.props.data.hasOwnProperty('event_book_multiples') ? this.props.data.event_book_multiples : true,
            event_book_others: this.props.data.hasOwnProperty('event_book_others') ? this.props.data.event_book_others : true,

        };

        this.onManagePassPanelClose            = this.onManagePassPanelClose.bind(this);
        this.onUpdatePassName                  = this.onUpdatePassName.bind(this);
        this.onCategoriesOptionSelect          = this.onCategoriesOptionSelect.bind(this);
        this.onUpdateScheduleDisplayWeeks      = this.onUpdateScheduleDisplayWeeks.bind(this);
        this.onHandleScheduleUpdated           = this.onHandleScheduleUpdated.bind(this);
        this.onColorChange                     = this.onColorChange.bind(this);
        this.onShowSpacesAvailableChangeState  = this.onShowSpacesAvailableChangeState.bind(this);
        this.onFutureBlockChange               = this.onFutureBlockChange.bind(this);
        this.handleClockChange                 = this.handleClockChange.bind(this);
        this.onCustomerBookMultiplePlaceChange = this.onCustomerBookMultiplePlaceChange.bind(this);
        this.onCustomerBookOthersChange        = this.onCustomerBookOthersChange.bind(this);
        this.onQuestionaireOptionSelect          = this.onQuestionaireOptionSelect.bind(this);
        this.onCustomerViewEvents              = this.onCustomerViewEvents.bind(this);        
        this.onCustomerViewSubscriptions       = this.onCustomerViewSubscriptions.bind(this);        
        this.onCustomerViewPasses              = this.onCustomerViewPasses.bind(this);        
        this.onCustomerViewVideos              = this.onCustomerViewVideos.bind(this);   
        this.onPassSelection                   = this.onPassSelection.bind(this);     
        this.onSubscriptionSelection               = this.onSubscriptionSelection.bind(this);     

    }

    onFutureBlockChange(event)
    {
        var v = event.target.checked;
        if(v === true)
        {
            this.setState({show_future_block_date: true});
        }    
        else
        {
            this.setState({show_future_block_date: false});
        }         
    }

    onShowSpacesAvailableChangeState(event)
    {
        var v = event.target.checked;
        if(v === true)
        {
            this.setState({show_spaces_available: true});
        }    
        else
        {
            this.setState({show_spaces_available: false});
        }        
    }

    onColorChange(color, event)
    {
       this.setState({'color': color.hex});
    } 


    handleClockChange(event){
        this.setState({clock: event.target.value});
    }

    onHandleScheduleUpdated(event)
    {
        event.preventDefault();
        event.nativeEvent.stopImmediatePropagation();

        var _this = this;
        var id = this.state.id ||  null;
        var name = this.state.schedulename || "";
        var n = this.state.scheduleweeks || 1;
        var show_spaces_available = !!this.state.show_spaces_available;
        var show_future_block_date = !!this.state.show_future_block_date;
        var color = this.state.color ||  true;
        var cats = JSON.stringify(this.state.categories);
        var event_book_multiples = encodeURI( this.state.event_book_multiples );
        var event_book_others    = encodeURI( this.state.event_book_others );
        var questionaires = encodeURI(JSON.stringify(this.state.questionaires));

        var chain = '';
        chain = chain + "&v=" + 4;
        chain = chain + "&clock=" + this.state.clock;
        chain = chain + "&name=" + name;
        chain = chain +  "&id=" + id;
        chain = chain +  "&n=" + n;
        chain = chain +  "&show_spaces_available=" + show_spaces_available;
        chain = chain +  "&show_future_block_date=" + show_future_block_date;
        chain = chain +  "&color=" + color;
        chain = chain +  "&categories=" + cats;
        chain = chain +  "&event_book_multiples=" + event_book_multiples;
        chain = chain +  "&event_book_others=" + event_book_others;
        chain = chain +  "&questionaires=" + questionaires;
        chain = chain +  "&custom_view_events=" + this.state.custom_view_events;
        chain = chain +  "&custom_view_passes=" + this.state.custom_view_passes;
        chain = chain +  "&custom_view_subscriptions=" + this.state.custom_view_subscriptions;
        chain = chain +  "&custom_view_videos=" + this.state.custom_view_videos;
        chain = chain +  "&passes=" + JSON.stringify(this.state.passes || []);
        chain = chain +  "&subscriptions=" + JSON.stringify(this.state.subscriptions || []);

 
        fetch("/schedules/ajax_schedule_edit", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({updating: false});
                _this.props.closeCustomerPanel(true);
            }
            else
            {

                this.setState({error: response.reason });
            }
        }).catch(function(err){
            console.error('Failed to update schedule. Your session might have expired. Please refresh this page.',);
        });  


        console.log(event);
        

    }


    onUpdatePassName(event)
    {
        var v = event.target.value || "";    
        this.setState({schedulename: v});

    }

  
    onCategoriesOptionSelect(event, value)
    {
        if(value !== null)
        {
            this.setState({'categories': value});
        }
        else
        {
            this.setState({'categories': []});
        }
    

    }

    
    onQuestionaireOptionSelect(event, value)
    {
        if(value !== null)
        {

            this.setState({'questionaires': value, "event_book_multiples": false, "event_book_others": false});
        }
        else
        {
            this.setState({'questionaires': false});
        }
    

    }


    onManagePassPanelClose(event)
    {
        this.props.closeCustomerPanel(false);
    }



    onUpdateScheduleDisplayWeeks(event)
    {
        var v = event.target.value || 1;    
        this.setState({scheduleweeks: Number(v).toFixed(0)});
    }

    onPassSelection( event ) {
        this.setState({passes: event?.target?.value });
    }

    onSubscriptionSelection( event ) {
        this.setState({subscriptions: event?.target?.value });
    }


    onCustomerBookMultiplePlaceChange(e)
    {
        if(this.state.questionaires !== false)
        {
            this.setState({'event_book_multiples': false});
        }
        else
        {
            this.setState({'event_book_multiples': !!!this.state.event_book_multiples});
        }

    }

    onCustomerBookOthersChange(e)
    {
        if(this.state.questionaires !== false)
        {
            this.setState({'event_book_others': false});

        }
        else
        {
            this.setState({'event_book_others': !!!this.state.event_book_others});
        }

    }

    
   
    onCustomerViewEvents(e)
    {
        var v = e.target.checked;
        this.setState({'custom_view_events': v});
    }      
    onCustomerViewPasses(e)
    {
        var v = e.target.checked;
        this.setState({'custom_view_passes': v});
    } 

    onCustomerViewSubscriptions(e)
    {
        var v = e.target.checked;
        this.setState({'custom_view_subscriptions': v});
    } 

    onCustomerViewVideos(e)
    {
        var v = e.target.checked;
        this.setState({'custom_view_videos': v});
    } 
    
    render(  )
    {
        var classes = this.props.classes;
        var cont    = this.state.schedulename.length > 0 ? true : false

        /* OK we need to kick off the loading of the data */
        return (
            <div className={classes.root}>
 

                <Dialog fullScreen open={this.props.open} onClose={this.onManagePassPanelClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.onManagePassPanelClose} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Schedule editor
                        </Typography>
                    </Toolbar>
                    </AppBar> 

                    {this.state.updating && (
                        <div className="top-box fullscreen-panel">
                            <Loading /> 
                        </div>
                    )}
                    {!this.state.updating && (
                        <form id="myForm" onSubmit={this.onHandleScheduleUpdated} className="top-box fullscreen-panel rvNSSchedules">

                            <Grid container justify="flex-start" alignItems="stretch">  
                                <Grid item xs={12}>
                                <Typography variant="h6" className={classes.section}>Basic details</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField name="schedulename" className={classes.scheduleField} value={this.state.schedulename} onChange={this.onUpdatePassName} id="schedule-name" label="Name" variant="outlined" fullWidth={true} autoFocus={true} helperText="Enter the name of the schedule. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'. This is not displayed to customers." required inputProps={{pattern:"[A-Za-z-._(), 0-9]{1,250}"}} />
                                </Grid>  
          

                                <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.section}>Configure the schedule</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField      name="scheduleweeks" 
                                                    className={classes.scheduleField} 
                                                    value={Number(this.state.scheduleweeks).toFixed(0)>0?Number(this.state.scheduleweeks).toFixed(0):1} 
                                                    step={1} 
                                                    onChange={this.onUpdateScheduleDisplayWeeks} 
                                                    id="schedule-weeks" label="# of weeks to be displayed." 
                                                    variant="outlined" 
                                                    fullWidth={true} 
                                                    helperText="Enter the number of weeks the schedule will generate and display. Allowed characters 1-9" type="number" inputProps={{required:true, pattern:"[1-9]+"}} /> 
                                </Grid>  
               
                                <Grid item xs={12} md={12}>
                                    
                                    <FormControlLabel
                                        control={<Switch checked={this.state.show_spaces_available} onChange={this.onShowSpacesAvailableChangeState} name="booknow" />}
                                        label="Display the number of spaces available"
                                    />                                    
                                    <FormHelperText>This displays to the customer the number of spaces available to book.</FormHelperText>
  
                                </Grid> 
                                <Grid item xs={12} md={12}>
                                    
                                    <FormControlLabel
                                        control={<Switch checked={this.state.show_future_block_date} onChange={this.onFutureBlockChange} name="limit" />}
                                        label="Display all dates associated to event blocks"
                                    />                                    
                                    <FormHelperText>Recommended - display all dates for event blocks. if disabled, on the first available date will be displayed. </FormHelperText>
                                    
                                </Grid> 

                                <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.widget}>Set button colour</Typography>

                                </Grid>

                                <Grid item xs={12}>

                                    <Box className={classes.colorSelect} m={1}>
                                            <CirclePicker 
                                                onChangeComplete={this.onColorChange} 
                                                color={this.state.color} 
                                                colors={["#eb6589", "#000000", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]} />
                                     </Box>                                
                                    <Typography variant="caption" className={classes.captionText}>Sets the colour of the book now buttons.</Typography>                               
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.widget}>Clock display</Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <RadioGroup aria-label="clock" name="clock" value={this.state.clock} onChange={this.handleClockChange}>
                                            <FormControlLabel value="24hour" control={<Radio />} label="24 hour" />
                                            <FormControlLabel value="12hour" control={<Radio />} label="12 Hour" />
                                        </RadioGroup>
                                    </FormControl>                                    
                                </Grid>


                                <Grid item xs={12}>
                                    <Typography variant="h6"  className={classes.section}>Schedule categories (optional) </Typography>
                                </Grid>
                       
                                <Grid item xs={12} md={12}>
                                    <Autocomplete
                                        id="options-list"
                                        options={this.state.options}
                                        getOptionLabel={option => option?.name || ''}
                                        style={{ width: "100%" }}
                                        autoHighlight
                                        multiple={true}
                                        value={this.state.categories}
                                        onChange={this.onCategoriesOptionSelect}
                                        disableClearable={false}
                                        renderOption={option => (
                                        <React.Fragment>
                                            {option.name}
                                        </React.Fragment>
                                        )}                
                                        renderInput={params => <TextField {...params} label="Optional filter categories" variant="outlined" />}
                                    />

                                </Grid> 

                                <Grid item xs={12}>
                                    <Typography variant="h6"  className={classes.section}>Questionnaires (optional) </Typography>
                                </Grid>
                       
                                <Grid item xs={12} md={12}>
                                    <Autocomplete
                                        id="qoptions-list"
                                        options={this.state.qoptions}
                                        getOptionLabel={option => option?.name || ''}
                                        style={{ width: "100%" }}
                                        autoHighlight
                                        multiple={false}
                                        value={this.state.questionaires}
                                        onChange={this.onQuestionaireOptionSelect}
                                        disableClearable={false}
                                        renderOption={option => (
                                        <React.Fragment>
                                            {option.name}
                                        </React.Fragment>
                                        )}                
                                        renderInput={params => <TextField {...params} label="Optional - select a questionaire for client to complete" variant="outlined" />}
                                    />

                                </Grid>  

                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6" className={classes.section}>Display options</Typography>
                                    <p className="sectionIntro">
                                        This section allows you to configure what sections the customers see. 
                                    </p>

                                </Grid>                               
                                <Grid item xs={12} md={12}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={this.state.custom_view_events}
                                            onChange={this.onCustomerViewEvents}
                                            value="1"
                                            color="primary"
                                            disabled
                                        />
                                        }
                                        label={<span className="switchLabel">Events</span>}
                                    /> 
                                    <p className="caption">If enabled, the customer will be able to view and add events to their cart.</p>                                                                           

                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={this.state.custom_view_passes}
                                            onChange={this.onCustomerViewPasses}
                                            value="1"
                                            color="primary"
                                            disabled={this.state?.companypasses?.length === 0}
                                        />
                                        }
                                        label={<span className="switchLabel">Passes</span>}
                                    /> 
                                    <p className="caption">If enabled, the customer will be able to view and add passes directly to their cart.</p>                                                                           

                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        !!this.state?.custom_view_passes && !!(this.state?.companypasses?.length > 0) &&
                                        <Box>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="pass-selector-label"
                                                    id="pass-selector"
                                                    multiple
                                                    value={this.state.passes || []}
                                                    onChange={this.onPassSelection}
                                                    input={<Input />}
                                                    
                                                    >
                                                    {this.state.companypasses?.map((pass) => (
                                                        <MenuItem key={'pass-' + pass?.id} value={pass?.id}>
                                                        {pass?.name}
                                                        </MenuItem>
                                                    ))}
                                                    </Select>
                                            </FormControl>
                                            <p className="caption">If you select passes, only these will be shown in the schedule. If you would like all passes to be displayed, leave this field empty.</p>                                                                           

                                        </Box>
                                    }
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={this.state.custom_view_subscriptions}
                                            onChange={this.onCustomerViewSubscriptions}
                                            value="1"
                                            color="primary"
                                            disabled={this.state?.companysubscriptions?.length === 0}
                                        />
                                        }
                                        label={<span className="switchLabel">Subscriptions</span>}
                                    /> 
                                    <p className="caption">If enabled, the customer will be able to view and add subscriptions to their cart.</p>                                                                           

                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        !!this.state?.custom_view_subscriptions && !!(this.state?.companysubscriptions?.length > 0) &&
                                        <Box>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="subscription-selector-label"
                                                    id="subscription-selector"
                                                    multiple
                                                    value={this.state.subscriptions || []}
                                                    onChange={this.onSubscriptionSelection}
                                                    input={<Input />}
                                                    
                                                    >
                                                    {this.state.companysubscriptions?.map((subscription) => (
                                                        <MenuItem key={'subscription-' + subscription?.id} value={subscription?.id}>
                                                        {subscription?.name}
                                                        </MenuItem>
                                                    ))}
                                                    </Select>
                                            </FormControl>
                                            <p className="caption">If you select subscriptions, only these will be shown in the schedule. If you would like all subscriptions to be displayed, leave this field empty.</p>                                                                           
                                        </Box>
                                    }
                                </Grid>                                
                                <Grid item xs={12} md={12}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={this.state.custom_view_videos}
                                            onChange={this.onCustomerViewVideos}
                                            value="1"
                                            color="primary"
                                        />
                                        }
                                        label={<span className="switchLabel">Videos</span>}
                                    /> 
                                    <p className="caption">If enabled, the customer will be able to view and add videos to their cart.</p>                                                                           

                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <Typography variant="h6" className={classes.section}>Restrictions and limits</Typography>
                                    <p className="sectionIntro">
                                        This section allows you to configure event booking policies. This controls what customers can book and associated restrictions.
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={this.state.event_book_multiples}
                                            onChange={this.onCustomerBookMultiplePlaceChange}
                                            value="1"
                                            color="primary"
                                        />
                                        }
                                        label={<span className="switchLabel">Allow customers to book multiple places on an event date/time.</span>}
                                    /> 
                                    <p className="caption">If disabled, clients will only be able to book a single space on an event date/time.</p>                                                                           

                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormControlLabel
                                            control={
                                            <Switch
                                                checked={this.state.event_book_others && this.state.questionaires === false}
                                                onChange={this.onCustomerBookOthersChange}
                                                value="1"
                                                color="primary"
                                            />
                                            }
                                            label={<span className="switchLabel">Allow customers to book on behalf of others.</span>}
                                    /> 
                                    <p className="caption">If disabled, clients will only be able to book themselves on to an event date/time.</p>                                                                           
                                </Grid>              
                                <Grid item xs={12} md={12}>
                                    <div className="mt-4">
                                        <Button type="submit"  variant="contained" color="primary" className={styles.buttonGo} disabled={!cont}>Save</Button>
                                    </div>
                                </Grid> 
                            </Grid>  
   
                        </form>              
                    )}
                    
                </Dialog>




            </div>            
        );
    }

};



const ScheduleAddPanelManager = connect(null, mapDispatchToProps)(ScheduleAddPanelManagerModule);
export default withStyles(styles)(ScheduleAddPanelManager);
