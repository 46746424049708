import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'
import 'moment-timezone';
import DayPicker, { DateUtils } from 'react-day-picker';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {v1 as uuidv1} from "uuid";
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// use material theme
import 'react-times/css/material/default.css';
import 'react-day-picker/lib/style.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}



const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  progressOK:{
    backgroundColor: 'rgba(207, 0, 15, 1)',
    '& .MuiLinearProgress-barColorPrimary':{
      backgroundColor: '#00a65a',
    }    
  },
  progressError:{
    backgroundColor: 'rgba(207, 0, 15, 0.5)',
    '& .MuiLinearProgress-barColorPrimary':{
      backgroundColor: 'rgba(207, 0, 15, 1)',
    }
  },  
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}



class CheckBox extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          data: props.data,
          callback: props.callback
      }

      console.log('ActionButton', this.state);
      this._handleClick = this._handleClick.bind(this);
  }

  _handleClick = ( event ) => {
      var x = Object.assign({}, this.state.data);
      x.checked = event.target.checked;
      console.log('Manage button initiated',x);
      this.setState({'data': x}, function(){
        this.state.callback(this.state.data);
      });
  }

  render() {
      var classes= this.props.classes;
      return (
          <FormControlLabel
          control={<Checkbox color="primary" />}
          label={this.state.data.fname}
          labelPlacement="end"
          onChange={this._handleClick}
          checked={!!this.state.data.checked}
        />            
      )
  }
}

class FileManagerDialog extends Component {

    constructor( props )
    {
        super( props );

        this.state = {
            files: [],
            oncancel: props.onCancel,
            onOK: props.onOK,
            tabState: 'one',
            security: props.security || {'name': '', 'token': ''},
            file_status: [],
        };

/*
        this.state.files = [{cid: "3",
        fname: "CattleShedReshoot_lowres_21_of_36.jpg",
        fpath: "3/1568820114-0.jpg",
        fs: "1",
        fsize: "279514",
        id: "10",
        modified: "2019-09-18 15:21:54",
        type: "0"}];
*/     

        this.handleClose = this.handleClose.bind(this);
        this.handleOK = this.handleOK.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.onHandleDragEnter = this.onHandleDragEnter.bind(this);
        this.onHandleDragLeave = this.onHandleDragLeave.bind(this);
        this.onHandleDragOver = this.onHandleDragOver.bind(this);
        this.onHandleDrop = this.onHandleDrop.bind(this);
        this.filesSelected = this.filesSelected.bind(this);
        this.fileUpload     = this.fileUpload.bind(this);
        this.simulateProgress = this.simulateProgress.bind(this);
        this.fetchFiles     = this.fetchFiles.bind(this);
        this.onFileSelectionChange  = this.onFileSelectionChange.bind(this);
    }

    onFileSelectionChange( obj )
    {

      var x = JSON.parse( JSON.stringify(this.state.files) );

      for(var i = 0; i < x.length; i++)
      {
        console.log('comparing ', x[i].id, obj);
        if(x[i].id === obj.id)
        {
          x[i].checked = obj.checked;
          break;
        }
      }
      this.setState({'files': x});

    }


    fetchFiles()
    {
      var _this = this;
      var chain = '';

      fetch("/files/do_ajax_get_files", {
        method: "POST",
        mode: 'same-origin', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit                            
          body: this.state.security.name + '=' + this.state.security.token + chain,
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-Requested-With": 'XMLHttpRequest',
              "X_REQUESTED_WITH": 'xmlhttprequest'
          }
      })
      .then(res => {
          return res.json();
      })
      .then(response => {
          //console.log(data);
          if(response.status === 'OK')
          {
              for(var i = 0; i < response.files.length; i++)
              {
                response.files[i].checked = false;
              }
              _this.setState({'files': response.files});
          }
          else
          {
        //    _this.setState({'files': []});
              //this.setState({cust_add: false});
          }
      }).catch(function(err){
      //    _this.setState({'files': []});
          console.error('Failed to fetch files', err);
      });      

    }


    componentDidMount()
    {
      this.fetchFiles();
    }


    filesSelected(e)
    {
   //     alert(e.target.files);
        for(var i = 0; i < e.target.files.length; i++)
        {
          this.fileUpload( e.target.files[i]);
        }
    }

    simulateProgress( id, status, interval )
    {
      var _this = this;
      var x = this.state.file_status.slice();
      var i = 0;
      x.forEach(function(y, i){
        if(y.id === id)
        {
          const timer = setInterval(function(){
            x[i].progress += 10;
            x[i].status = status;
            _this.setState({'file_status': x});
            if(x[i].progress >= 100)
            {
              _this.fetchFiles();
              clearInterval(timer);
            }

            console.log(y.progress);
          }, interval);
        }

      });  
    }

    fileUpload(file)
    {
      var unique_id = uuidv1();
      var obj = {'id': unique_id, 'name': file.name, 'progress': 0, status: 'OK'};

      console.log(obj);
      var x = this.state.file_status.slice();

      x.push(obj);
      var _this = this;
      this.setState({'file_status': x}, function(){
          const formData = new FormData();
          formData.append(this.state.security.name,  this.state.security.token);
          formData.append('file', file);
          try{
            fetch('/files/do_ajax_file_upload', {
              method: "POST",
              mode: 'same-origin', // no-cors, *cors, same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'include', // include, *same-origin, omit                            
              body: formData,
              headers: {
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
              }        
            })
            .then(response => response.json())
            .then(data => {
              _this.simulateProgress(unique_id, 'OK', 500);
              console.log(data)
            })
            .catch(error => {
              console.error(error)
              _this.simulateProgress(unique_id, 'ERROR', 10);
            }) 
          }
          catch(e)
          {
            console.error(e)
            _this.simulateProgress(unique_id, 'ERROR', 10);
          }
 
      });    
    }

    onHandleDragEnter(e)
    {
      e.preventDefault();
      e.stopPropagation();
  
    }    
    onHandleDragLeave(e)
    {
      e.preventDefault();
      e.stopPropagation();
  
    }    

    onHandleDragOver(e)
    {
      e.preventDefault();
      e.stopPropagation();
  
    }    

    onHandleDrop(e)
    {
      e.preventDefault();
      e.stopPropagation();
 
      let files = [...e.dataTransfer.files];
  
      if (files && files.length > 0) {
        //alert(files);
        for(var i = 0; i < files.length; i++)
        {
          this.fileUpload( files[i] );        
//          this.fileUpload( e.target.files[i]);
        }
        e.dataTransfer.clearData();
      }
  
    }        

    onTabChange(event, newValue)
    {
      this.setState({'tabState': newValue});

    }


    handleOK(){
      var x = [];
      for(var i = 0; i < this.state.files.length; i++)
      {
        console.log('checking state', this.state.files[i]);
        if(this.state.files[i].checked === true)
        {
          x.push(this.state.files[i]);
        }
      }
      console.log('sending files', x);
      this.state.onOK(x);
    }

    handleClose = () => {
      this.state.oncancel();
    }



    render(){

        var classes = this.props.classes;
        const today = new Date();

        return (
          <div>
            <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={true} fullWidth={true} maxWidth = {'md'}>
              <DialogTitle id="customized-dialog-title" onClose={this.handleClose} danger={false}>
                File manager
              </DialogTitle>
              <DialogContent dividers>
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                      <Grid item xs={12}>
                        <Tabs
                          value={this.state.tabState}
                          onChange={this.onTabChange}
                          indicatorColor="primary"
                          textColor="primary"
                        >
                          <Tab label="Files" value='one' {...a11yProps('one')} />
                          <Tab label="Upload" value='two' {...a11yProps('two')} />
                        </Tabs>
                        <TabPanel value={this.state.tabState} index='one'>
                          <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                              {this.state.files.length === 0  && (
                                  <Grid item xs={12}>
                                    <div className="no-files"><span>No files</span></div>
                                  </Grid>
                              )}
                              {this.state.files.map(el =>(
                                <Grid item xs={12} key={el.id}>
                                    <CheckBox data={el} callback={this.onFileSelectionChange} />                                 
                                </Grid>
                              ))}
                          </Grid>
                        </TabPanel>
                        <TabPanel value={this.state.tabState} index='two'>
                           <div className="dragdroparea"
                                onDrop={e => this.onHandleDrop(e)}
                                onDragOver={e => this.onHandleDragOver(e)}
                                onDragEnter={e => this.onHandleDragEnter(e)}
                                onDragLeave={e => this.onHandleDragLeave(e)}
                           
                           >
                             <div>Drag and drop files here</div>
                             <div><CloudUploadIcon /></div>
                             <div>
                              
          
                                <input 
                                  accept="image/*" 
                                  className={classes.input} 
                                  id="raised-button-file" 
                                  multiple 
                                  type="file"
                                  style={{display: 'none'}} 
                                  onChange={this.filesSelected}
                                /> 
                                <label htmlFor="raised-button-file"> 
                                  <Button component="span" variant="contained" color="primary"> 
                                    Or click to open file browser
                                  </Button> 
                                </label>                                                  
                             </div>
                            </div>
                            <div>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                                    {this.state.file_status.map(el => (
                                      <Grid item xs={12}  key={el.id}>
                                          <Grid container direction="row" justify="flex-start"   alignItems="center" spacing={2} >
                                            <Grid item xs={3} >
                                                {el.name}
                                            </Grid>                                
                                            <Grid item xs={8} >
                                              {el.status === 'OK' && (
                                                <LinearProgress variant="determinate" value={el.progress} className={classes.progressOK} />
                                              )}
                                              {el.status !== 'OK' && (
                                                <LinearProgress variant="determinate" value={el.progress} className={classes.progressError} />
                                              )}                                              
                                            </Grid>
                                            <Grid item xs={1} >
                                              {el.status === 'OK' && (
                                                <CheckCircleOutlineIcon />
                                              )}
                                              {el.status !== 'OK' && (
                                                <ErrorIcon />
                                              )}                                              
                                              
                                            </Grid>
                                        </Grid>
                                      </Grid>
                                    ))}
                                </Grid>
                            </div>
                        </TabPanel>                        
                      </Grid>
                  </Grid> 
                        
      
      
                  <Typography gutterBottom>
                  </Typography>
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.handleOK}>
                            OK
                  </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
    }


}



export default withStyles(styles) (FileManagerDialog);

