import React, { Component, forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';

import MaterialTable from 'material-table';
import TableCell from '@material-ui/core/TableCell';

import TableRow from '@material-ui/core/TableRow';

import moment from 'moment';


import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


import './customers.css';


function Currency(v, c)
{
    //console.log('converting', v, c);
    switch( c )
    {
        case 'GBP':
            //console.log('Returning GBP', c, v, '£' + Number(v).toFixed(2).toString());
            return '£' + Number(v).toFixed(2).toString();
        case 'USD':
            return '$' + Number(v).toFixed(2).toString();
        case 'AUD':
            return 'AS$' + Number(v).toFixed(2).toString();
        case 'NZD':
            return 'NZ$' + Number(v).toFixed(2).toString();
        default:
        {
            console.log('unknow', c, v);
            return  (<span>{c} {Number(v).toFixed(2)}</span>);
        }
    }
}


class SubscriptionCustomerSalesTablePanel extends Component{


    
    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            security: props.security,
            loading: true,
            data: props.subscriptions||[],
            events: props.events||[],
            rows_per_page: 5,
            page: 0,
            refresh: false,
            customer: props.customer,
        };

        this.handleChangePage           = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage    = this.handleChangeRowsPerPage.bind(this);
        this.state.loading              = false;
        this.getData                     = this.getData.bind(this);
      
    }

    handleChangeRowsPerPage(event)
    {
        this.setState({'rows_per_page': parseInt(event.target.value, 10)});

    }
    handleChangePage(event, newPage)
    {
        this.setState({'page': parseInt(newPage, 10)});

    }

    getData()
    {
        //console.log('initial object', this.state.data);
        
        return this.state.data.map( d => {
            let x = Object.assign({}, d);
            x.paid = Currency(d.paid, d.currency);
            x.purchased = moment(d.purchased).format("ll"); // "Sunday, February 14th 2010, 3:25:50 pm"
            x.renews = moment(d.renews).format("ll"); // "Sunday, February 14th 2010, 3:25:50 pm"

            if(x.stx.length > 0)
            {
                x.stx = d.stx.map( s => {
                    //console.log('processing', s.id, s.paid, s.currency);
                    let o = Object.assign({}, s);
                    o.date = moment(s.date).format("ll");
                    o.paid = Currency(s.paid, s.currency);
                    return o;
                });
            }
            //console.log('Adjusted object', x);
            return x;
        }) || [];

    }



    render()
    {
        console.log('Loading customer data', this.state);
        var classes = this.props.classes;
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };

        const l = this.getData().length;
        const c = [];
        if(l <= 25)
        {
            c.push(l);
        }
        if(l > 25)
        {
            c.push(25);
        }
        if(l > 50)
        {
            c.push(50);
        }  
        if(l > 100)
        {
            c.push(100);
        }  
        if(l > 200)
        {
            c.push(200);
        }  
        return (
            <div>
                <Grid container justify="flex-start" alignItems="stretch">
                    <Grid item xs={12} md={12}>
                        <MaterialTable icons={tableIcons} title=""
                                // other props
                            data={this.getData()}
                            columns={[
                                    { title: 'ID', field: 'id', maxWidth: "50px" },
                                    { title: 'Next', field: 'renews' ,maxWidth: "50px" },
                                    { title: 'Plan', field: 'name' },
                                    { title: 'Status', field: 'status',  maxWidth: "50px" },
                                    { title: 'Invoice', field: 'invoice', sorting: false,  maxWidth: "50px"  },
                                    { title: 'Pass', field: 'pass', sorting: false },
                                    { title: 'Paid', field: 'paid', sorting: false, maxWidth: "50px"   },
                                    { title: 'Renewed', field: 'date', sorting: false, maxWidth: "80px"  },
                                    { title: 'Status', field: 'status', sorting: false, maxWidth: "50px"  },
                            ]}
                            options={{
                                selection: false,
                                pageSize: l,
                                pageSizeOptions: c,
                                padding: 'dense',
                                search: this.props.fullwidth
                            }}
                            components={{
                                Container: props => <div>{props.children}</div>,
                                Row: ({ data }) => {
                                    return (
                                        <React.Fragment>
                                            <TableRow key={data.id}>  
                                                <TableCell rowSpan={data.stx.length}>{data.id}</TableCell>
                                                <TableCell rowSpan={data.stx.length}>{data.renews}</TableCell>
                                                <TableCell rowSpan={data.stx.length}>{data.name}</TableCell>
                                                <TableCell rowSpan={data.stx.length}>{data.status}</TableCell>
                                                <TableCell>{data.stx[0].id}</TableCell>
                                                <TableCell>{data.stx[0].pass}</TableCell>
                                                <TableCell>{Currency(data.stx[0].fee, data.stx[0].currency)}</TableCell>
                                                <TableCell>{data.stx[0].date}</TableCell>
                                                <TableCell>{data.stx[0].status}</TableCell>

                                            </TableRow>
                                            {
                                                data.stx.filter((x, i) => i > 0 ).map(x => (
                                                    <TableRow key={x.id}>  
                                                        <TableCell>{x.id}</TableCell>
                                                        <TableCell>{x.pass}</TableCell>
                                                        <TableCell>{Currency(x.fee, x.currency)}</TableCell>
                                                        <TableCell>{x.date}</TableCell>
                                                        <TableCell>{x.status}</TableCell>
                                                    </TableRow>
                                                ))
                                            }

                                        </React.Fragment>
                                    )
                                }
                            }}
                            
                        />
                    </Grid>
                </Grid>


            </div>
        );
    }
}


export default SubscriptionCustomerSalesTablePanel;
