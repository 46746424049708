import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Loading from '../core/loading';

import TableRow from '@material-ui/core/TableRow';
import {v1 as uuidv1} from "uuid";
// use material theme


class DeleteButton extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          data: props.data,
          callback: props.callback
      }

      console.log('DeleteButton', this.state);
      this._handleClick = this._handleClick.bind(this);
  }

  _handleClick = () => {
      var x = Object.assign({}, this.state.customer);
      console.log('Manage button initiated',x);
      this.state.callback(this.state.data);
  }

  render() {
      var classes= this.props.classes;
      return (
          <IconButton name="delete" className={classes.fabDelete} aria-label="Delete event" onClick={this._handleClick}>
              <DeleteForeverIcon className={classes.fabIcon}/>
          </IconButton>            
      )
  }
}


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  fabDelete:{
    backgroundColor: '#b80000',
    color: '#fff',
    '&:hover':{
        backgroundColor: '#940000',
    }
  },             
  fabIcon:{
      '& .MuiSvgIcon-root':{
          fill: '#fff',
          color: '#fff',
          '&:hover':{
              color: '#ffe',
          }
      }
  },
  tableLeft:{
    width: '70%',
  },
  addButton: {
    textTransform: 'none'
  } 

});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  addButton: {
      textTransform: 'none'
  }

}))(MuiDialogActions);



  function mapDispatchToProps(dispatch) {
    return {
    };
  }  

class ZoomManagerDialogModule extends Component {

    constructor( props )
    {
        super( props );

        this.state = {

            accounts: props.accounts||[],
            oncancel: props.onCancel,
            ondelete: props.ondelete,
            onactivate: props.onactivate,
        };



        this.onDelete = this.onDelete.bind(this);
   
        this.handleClose = this.handleClose.bind(this);
        this.onActivate  = this.onActivate.bind(this);

    }


    onActivate()
    {
       this.state.onactivate(0);
    }


    onDelete( account ){
        this.state.ondelete( account );
    }
    
    
    handleClose = () => {
      this.state.oncancel();
    }




    render(){

        var classes = this.props.classes;
        const today = new Date();
        //console.log('re-render');
        return (
          <div>
            <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={true} fullWidth={true} maxWidth = {'md'}>
              <DialogTitle id="customized-dialog-title" onClose={this.handleClose} danger={true}>
                Associated Zoom account manager
              </DialogTitle>
              <DialogContent dividers>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Associated Email
                                        </TableCell>
                                        <TableCell  align="center">
                                           Revoke access
                                        </TableCell>                                        
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.props.loading === true && (
                                    <TableRow key={uuidv1()}>
                                        <TableCell align="center" colSpan={2}>
                                            <Loading />
                                        </TableCell>                                  
                                    </TableRow> 
                                  )}
                                  {this.props.loading === false && this.state.accounts.map(el => (
                                    <TableRow key={uuidv1()}>
                                        <TableCell className={classes.tableLeft} align="left">
                                            {el.foreign_email}
                                        </TableCell>
                                        <TableCell align="center">
                                            <DeleteButton data={el.id} callback={this.onDelete} classes={classes}/>
                                        </TableCell>                                        
                                    </TableRow> 
                                  ))}
                                 
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>                     
                </Grid> 
                <Typography gutterBottom>
                </Typography>
              </DialogContent>
              <DialogActions>
                  <Button onClick={this.onActivate} className={classes.addButton}>
                      Add new Zoom account
                  </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
    }


}



const ZoomManagerDialog = connect(null, mapDispatchToProps)(ZoomManagerDialogModule);
export default withStyles(styles)(ZoomManagerDialog);

