import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";


import Grid from '@material-ui/core/Grid';
import {Container} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';



import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {reloadData, closeCustomerPanel} from '../redux/actions';


import Slide from '@material-ui/core/Slide';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {v1 as uuidv1} from "uuid";


import MuiDialogTitle from '@material-ui/core/DialogTitle';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import GetAppIcon from '@material-ui/icons/GetApp';
import WarningIcon from '@material-ui/icons/Warning';
import Button from '@material-ui/core/Button';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import moment from 'moment'
import Moment from 'react-moment';
import 'react-dates/lib/css/_datepicker.css';


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    dialog:{
        backgroundColor: 'rgba(0,0,0,0.1)',
        '& .MuiAppBar-root':{
            backgroundColor: 'rgba(60, 141, 188, 1)',
        }
    },
    box:{
        height: '100%',
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: '5px',
        backgroundColor: '#fff',
        padding: '18px', 
        marginTop: '100px'       
    },    
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    section:{
       fontSize: '1.1em',
       fontWeight: 'bold',
    },  
    setting:{
        fontSize: '1em',
        fontWeight: 'normal', 
    },
    stdCell:{
        textAlign: 'center',
    },  
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    download:{
        fontSize: '0.8em',
        float:'right',
    },  
    reportDescription:{
        fontSize: '0.8em',
    },                  
    fabIcon:{
        '& .MuiSvgIcon-root':{
            fill: '#fff',
            color: '#fff',
            '&:hover':{
                color: '#ffe',
            }
        }
    },
    fabDelete:{
        backgroundColor: '#b80000',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#940000',
        }
    }, 
    fabReg:{
        backgroundColor: 'rgba(83, 120, 138, 1)',
        color: '#fff',
        '&:hover':{
            backgroundColor: 'rgba(83, 120, 138, 0.5)',
        }
    },         
    tableName:{
        width: '25%',
    },
    alignCenter:
    {
        textAlign: 'center'
    },
    buttonDanger:{
        color: '#d40000',
    },
    progress:{
        color: 'rgba(0,0,0,0.5)',
        fontSize: '18px',
        textAlign: 'center'
    },  
    progressIcon:{
        color: 'rgba(0,0,0,0.5)',

    }, 
    progressError:{
        color: 'rgba(199, 0, 0, 0.829)',

    },          
    filter:{
        paddingBottom: '10px'
    }
});

function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }




function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function Loading(props) {
    console.log('Loading', props);
    if (props.open) {
        return <div className="loading"><i className="fa fa-refresh fa-spin fa-5x fa-fw"></i><span className="sr-only">Loading...</span></div>
    }
    return ''
}


function mapDispatchToProps(dispatch) {
    return {
        reloadData: spec => dispatch(reloadData(spec)),
        closeCustomerPanel: spec => dispatch(closeCustomerPanel(spec)),

      
    };
  }





class ReportFinancialProportionDialogModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            loading: false,
            security: props.security,
            open: true,
            focusedInput: null,
            close: props.onCancel,
            start_date: moment().subtract(7, 'days'),
            end_date: moment(),
            data: [],

            
        };



        console.log('ReportFinancialProportionDialogModule', this.state.data);

        this.onDateChange         = this.onDateChange.bind(this);
        this.onRefresh            = this.onRefresh.bind(this);
        this.onDownloadClick      = this.onDownloadClick.bind(this);
        this.onManageCustomerPanelClose = this.onManageCustomerPanelClose.bind(this);

    }

    onManageCustomerPanelClose()
    {
        this.state.close();
    }

    componentDidMount()
    {
        this.onRefresh();
    }

    onDownloadClick()
    {
        var a = moment();
        var rows = [
            ["Purchase date", "First name", "Last name", "Email address", 'Telephone', 'Purchase type', 'Transaction ID', 'Payment mode', 'Currency', 'Gross', 'Discount applied', 'Adjustments', 'Customer paid', 'Reservie Charge'],
        ];

        for(var i = 0; i < this.state.data.length; i++)
        {
            const e = this.state.data[i].deleted ? 'deleted' : this.state.data[i].email;

            var x = [
                moment(this.state.data[i].purchase_date).format("DD/MM/YYYY"), 
                this.state.data[i].firstname, 
                this.state.data[i].lastname, 
                e, 
                this.state.data[i].mobile, 
                this.state.data[i].purchase_type, 
                this.state.data[i].id,
                this.state.data[i].payment_type,
                this.state.data[i].currency,
                this.state.data[i].gross_float,
                this.state.data[i].discount_float,
                this.state.data[i].adjustments_float,
                this.state.data[i].net_float,
                this.state.data[i].charge_float,
             ];
            rows.push(x);
        }
        
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");        
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "financial-report-" + a.format() + ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    onRefresh()
    {
        var _this = this;
        var chain = '&start='    + this.state.start_date.valueOf();
        chain = chain + '&end='  + this.state.end_date.valueOf();
        this.setState({'loading': true}, function(){
            try {
                fetch("/backenddata/get-financial-overview-js", {
                  method: "POST",
                  mode: 'same-origin', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'include', // include, *same-origin, omit                            
                  body: _this.state.security.name + '=' + _this.state.security.token + chain,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-Requested-With": 'XMLHttpRequest',
                        "X_REQUESTED_WITH": 'xmlhttprequest'
                    }
                })
                .then(res => {
                    return res.json();
                })
                .then(response => {
                  if(response.status === 'OK')
                  {
                        _this.setState({'data': response.data}, function(){
                            console.log('data', response.data);
                        });
                  }
                  else
                  {
                        _this.setState({'data': []});
                  }
            
                }).catch(function (err) {
             
                });           
        
            }
            catch(e)
            {
              console.error('api called', e);
      
            }   
        })      
    }




    onDateChange(obj)
    {
        var _this = this;

        if(obj.startDate !== null && obj.endDate !== null)
        {
            this.setState({'start_date': obj.startDate, 'end_date': obj.endDate}, function(){
                _this.onRefresh();
            });
        }
        else if(obj.startDate !== null)
        {
            this.setState({'start_date': obj.startDate}, function(){
                _this.onRefresh();
            });            
        }
        else if(obj.endDate !== null)
        {
            this.setState({'end_date': obj.endDate}, function(){
                _this.onRefresh();
            });            
        }

        
        
          
    }


    render(  )
    {
        var classes = this.props.classes;

        /* OK we need to kick off the loading of the data */


        console.log('render',this.props, this.state );




        return (
            <div className={classes.root}>

                <Dialog fullScreen open={this.state.open} onClose={this.onManageCustomerPanelClose} TransitionComponent={Transition} >
                    <div className={classes.dialog}>
                        <Container maxWidth="lg">
                                
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.onManageCustomerPanelClose} aria-label="close">
                                    <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Financial overview report
                                    </Typography>
                                </Toolbar>
                            </AppBar>                   
                            <div className={classes.box}>
                                <Grid container justify="flex-start" alignItems="flex-start" >
                                    <Grid item xs={12} md={12}>
                                        <h2 className={classes.section}>Configure report</h2>
                                        <p className={classes.reportDescription}>Configure the report below by choosing the date range</p>
                                    </Grid>  
                                    <Grid item xs={12}>
                                        <Grid container justify="flex-start" alignItems="flex-start" className="reports" >
                                            <Grid item xs={12} md={12}>
                                                <h2 className={classes.setting}>Step 1: Select the date range</h2>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="drpReportPicker">
                                                    <DateRangePicker 
                                                    isOutsideRange={day => (false)}
                                                    startDate={this.state.start_date} // momentPropTypes.momentObj or null,
                                                    startDateId="startDate" // PropTypes.string.isRequired,
                                                    endDate={this.state.end_date} // momentPropTypes.momentObj or null,
                                                    endDateId="endDate" // PropTypes.string.isRequired,
                                                    onDatesChange={this.onDateChange} // PropTypes.func.isRequired,
                                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                                    displayFormat="DD MMM YYYY"
    
                                                    />                                               

                                                </div>
                                                <div className="description">
                                                    Select the date range of the event(s). 
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid container justify="flex-start" alignItems="flex-start" className="reports" >
                                            <Grid item xs={6} md={6}>
                                                <h2 className={classes.section}>Report data</h2>
                                            </Grid>     
                                            <Grid item xs={6} md={6}>
                                                <Button color="primary"
                                                        startIcon={<GetAppIcon />}
                                                        className={classes.download}
                                                        onClick={this.onDownloadClick}

                                                >
                                                    Download CSV
                                                </Button>
                                            </Grid>                                        
                                        </Grid>
                                        <Grid container justify="flex-start" alignItems="flex-start" className="reports" >

                                            <Grid item xs={12} md={12}>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>
                                                                    Purchased
                                                                </TableCell>
                                                                <TableCell>
                                                                    Customer
                                                                </TableCell>
                                                                <TableCell>
                                                                    Type
                                                                </TableCell>
                                                                <TableCell>
                                                                    ID
                                                                </TableCell>
                                                                <TableCell className={classes.stdCell}>
                                                                    Payment
                                                                </TableCell>
                                                                <TableCell  className={classes.stdCell}>
                                                                    Currency
                                                                </TableCell>   
                                                                <TableCell  className={classes.stdCell}>
                                                                    Gross
                                                                </TableCell>  
                                                                <TableCell  className={classes.stdCell}>
                                                                    Discount
                                                                </TableCell>
                                                                <TableCell  className={classes.stdCell}>
                                                                    Net
                                                                </TableCell>  
                                                                <TableCell  className={classes.stdCell}>
                                                                    Charge
                                                                </TableCell>                                                                  

                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {this.state.data.length === 0 && (
                                                            <TableRow>
                                                                <TableCell key={-1} colSpan={10}>
                                                                    <div className={classes.progress}>
                                                                        <WarningIcon className={classes.progressIcon} style={{ height: 100, width: 100, textAlign: 'center' }}/>                                                                          
                                                                        <p>
                                                                            There are no records to display.
                                                                        </p>
                                                                    </div>                                                    
                                                                </TableCell>
                                                            </TableRow>
                                                            )}
                                                            {this.state.data.length > 0 && this.state.data.map(el => (
                                                                <React.Fragment key={uuidv1()}>

                                                                    <TableRow key={el.id}>
                                                                        <TableCell>
                                                                            <Moment format="LL">{el.purchase_date}</Moment>                                                  
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {el.firstname} {el.lastname}                                                                                                                                                                          
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {el.purchase_type}                                                                                                                                                                                                                                                                                                      
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {el.id}                                                                                                                                                                                                                                                                                                                                                                                                                              
                                                                        </TableCell>
                                                                        <TableCell  className={classes.stdCell}>
                                                                            {el.payment_type}                                                                                                                                                                                                                                                                                                                                                                                                                              
                                                                        </TableCell>
                                                                        <TableCell  className={classes.stdCell}>
                                                                            {el.currency}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
                                                                        </TableCell> 
                                                                        <TableCell  className={classes.stdCell}>
                                                                            {el.gross_float}
                                                                        </TableCell>  
                                                                        <TableCell  className={classes.stdCell}>
                                                                            {el.discount_float}
                                                                        </TableCell>
                                                                        <TableCell  className={classes.stdCell}>
                                                                            {el.net_float}
                                                                        </TableCell>  
                                                                        <TableCell  className={classes.stdCell}>
                                                                            {el.charge_float}
                                                                        </TableCell>                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                                                                    </TableRow>
                                                                </React.Fragment>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>

                        </Container>
                    </div>
                </Dialog>
            
            </div>            
        );
    }

};



const ReportFinancialProportionDialog = connect(null, mapDispatchToProps)(ReportFinancialProportionDialogModule);
export default withStyles(styles)(ReportFinancialProportionDialog);
