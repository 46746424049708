import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import moment from 'moment'
import parse from 'html-react-parser';
import Divider from '@material-ui/core/Divider';
import {
  INVOICE_PAYMENT_STATUS_UNPAID,
  INVOICE_PAYMENT_STATUS_PAID,
  INVOICE_PAYMENT_STATUS_PAYMENT_PROCESSING,
  INVOICE_PAYMENT_STATUS_PAYMENT_ACTION,
  INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CAPTURE,
  INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CONFIRM,
  INVOICE_PAYMENT_STATUS_PAYMENT_ERROR,
} from '../types';



import '../App.css';
const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,
    },
    invoiceblocks:{
      fontWeight: 'bold',
      fontSize: '0.9em',
      color: 'rgba(0,10,30,.75)'
    },
    invoiceDetail:{
      fontWeight: '400',
      color: 'rgba(0,0,0,0.6)',
      fontSize: '0.9em',
      marginBottom: '5px',
    }, 
    taxTitle:{
      marginTop: '40px',
      marginBottom: '40px',
      fontWeight: 'bold',
    }, 
    spacer50px:{
      height: '50px',
      display: 'block',
      width:'100%'
    },       
    formControl: {
      margin: theme.spacing(1),
      minWidth: 250,
    }, 
    error:{
      color: 'red',
    },
    empty:{
      paddingTop: '20px',
      paddingBottom: '20px',
      textAlign: 'center',
    }, 
    cell:{
      fontWeight: '600',
    },
    cellCenter:{
      fontWeight: '600',
      textAlign: 'center'
    }, 
    tableHeaderDescription:{
      width: '50%',
      fontWeight: 'bold',
    },
    tableSubtotal:{
      textAlign: 'right',
      fontWeight: 'bold',
      '> span':{
        fontSize: '1.2em'
      }
    },    
    textCenter:{
      textAlign: 'center'
    },
    textRight:{
      textAlign: 'right'
    },      
    tableHeaderLeft:{
      textAlign: 'left',
      fontWeight: 'bold',
    }, 
    tableHeaderCenter:{
      textAlign: 'center',
      fontWeight: 'bold',
    },                
    title: {
      flexGrow: 1,
      color: '#fff',
      fontSize: '1.3rem'
    },
    fabManage:{
      backgroundColor: 'rgba(83, 120, 138, 1)',
      color: '#fff',
      '&:hover':{
          backgroundColor: 'rgba(83, 120, 138, 0.5)',
      }
    },
    small:{
      fontSize: '0.8em',
    }      

});



class Invoice extends Component {

  
  constructor( props )
  {
    super( props );

    this.state = {
      invoice: props.data.invoice || {},
      company: props.data.company,
      error: false,
      error_str: '',
      security: {name: window.security_name, token: window.security_token},
      logo: props.data.logo || "",
    };

    this.interpretState  = this.interpretState.bind(this);
    
  }

  interpretState( s)
  {
      var classes = this.props.classes;

      switch(s)
      {
        case INVOICE_PAYMENT_STATUS_UNPAID:
        {
          return 'Unpaid';
        }
        case INVOICE_PAYMENT_STATUS_PAID:
        {
          return 'Paid';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_PROCESSING:
        {
          return 'Processing payment';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_ACTION:
        {
          return 'Action required';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CAPTURE:
        {
          return 'Capturing payment';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_PENDING_CONFIRM:
        {
          return 'Confirming  payment';
        }
        case INVOICE_PAYMENT_STATUS_PAYMENT_ERROR:
        {
          return parse("<div class=" + classes.error + ">Payment error</div>");
        }
        
        default:  
        {
          return 'unknown';
        }
        
      }

  }


  render() {
    var classes = this.props.classes;
    console.log('rendering', this.state);
    return (
      <div className={classes.root}>



        <div className="box">

          <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >

              <Grid item xs={9}>
                  <h2 className={classes.taxTitle}>
                      Invoice             
                  </h2>
              </Grid>
              <Grid item>
                  {this.state.logo.length > 0 && (
                      <img src={this.state.logo} alt="logo" style={{'maxHeight': '100px'}}/>
                  )}             
              </Grid>              
              <Grid item xs={12}>
                  <Divider />               
              </Grid>
              <Grid item xs={12} md={6}>
                  <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                        <Grid item xs={6}>
                            <div className={classes.invoiceblocks}>To</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceDetail}>{this.state.invoice.to}</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceblocks}>Invoice Number</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceDetail}>{this.state.invoice.invoice_id}</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceblocks}>Issue date</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceDetail}>{moment(this.state.invoice.created).format("LL")}</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceblocks}>Due date</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceDetail}>{moment(this.state.invoice.due).format("LL")}</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceblocks}>Status</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceDetail}>{this.interpretState(this.state.invoice.state)}</div>
                        </Grid>                        
                  </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                  <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                        <Grid item xs={6}>
                            <div className={classes.invoiceblocks}>From</div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className={classes.invoiceDetail}>
                              {this.state.company.name}
                            </div>
                            <div className={classes.invoiceDetail}>{this.state.company.address_1}</div>
                            <div className={classes.invoiceDetail}>{this.state.company.address_2}</div>
                            <div className={classes.invoiceDetail}>{this.state.company.town}</div>
                            <div className={classes.invoiceDetail}>{this.state.company.country}</div>
                            <div className={classes.invoiceDetail}>{this.state.company.zip}</div>

                        </Grid>
                  </Grid>
              </Grid>
              <Grid item xs={12}>
                  <Divider />               
              </Grid>
              <Grid item>
                  <div className={classes.spacer50px}></div>
              </Grid>
              <Grid item xs={12}>
                  <TableContainer>
                      <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeaderLeft}>
                                        ID
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderDescription}>
                                        Description
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCenter}>
                                        Quantity
                                    </TableCell>
                                    <TableCell className={classes.tableHeaderCenter}>
                                        Unit price
                                    </TableCell>                                    
                                    <TableCell className={classes.tableHeaderCenter}>
                                        Tax
                                    </TableCell>
                                    <TableCell className={classes.tableSubtotal}>
                                        Amount {this.state.invoice.currency}
                                    </TableCell>                                                                                                            
                                </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.invoice.items.length === 0 && (
                                  <TableRow>
                                      <TableCell colSpan={5}>
                                          <div className={classes.empty}>
                                              No invoice items
                                          </div>
                                      </TableCell>
                                  </TableRow>
                              )}
                              {this.state.invoice.items.length > 0 && this.state.invoice.items.map( el => ( 
                                <TableRow key={el.id}>
                                    <TableCell>
                                        {el.id}
                                    </TableCell>
                                    <TableCell>
                                        {el.description}
                                    </TableCell>    
                                    <TableCell className={classes.textCenter}>
                                        {parseInt(el.qty).toFixed(0)}
                                    </TableCell>  
                                    <TableCell  className={classes.textCenter}>
                                        {parseFloat(el.gross).toFixed(2)}
                                    </TableCell>  
                                    <TableCell className={classes.textCenter}>
                                        {parseFloat(this.state.invoice.tax_rate) > 0?parseFloat(this.state.invoice.tax_rate).toFixed(2)+'%':'N/A'}
                                    </TableCell>  
                                    <TableCell className={classes.textRight}>
                                        {parseFloat(el.gross).toFixed(2)}
                                    </TableCell>                                                                                                                                                                                
                                </TableRow>
                              ))}
                              {this.state.invoice.items.length > 0 && ( 
                                <React.Fragment>
                                  <TableRow>
                                      <TableCell colSpan={3}>
                                        &nbsp;   
                                      </TableCell>
                                      <TableCell className={classes.textRight} colSpan={2}>
                                          Subtotal
                                      </TableCell>
                                          
                                      <TableCell className={classes.textRight}>
                                          <span>{parseFloat(this.state.invoice.gross).toFixed(2)}</span>
                                      </TableCell>
                                  </TableRow>
                                  <TableRow>
                                      <TableCell colSpan={3}>
                                        &nbsp;   
                                      </TableCell>
                                      <TableCell className={classes.textRight} colSpan={2}>
                                          Tax
                                      </TableCell>
                                          
                                      <TableCell className={classes.textRight}>
                                          <span>{parseFloat(this.state.invoice.tax).toFixed(2)}</span>
                                      </TableCell>
                                  </TableRow>                                  
                                  <TableRow>
                                      <TableCell colSpan={3}>
                                        &nbsp;   
                                      </TableCell>
                                      <TableCell className={classes.tableSubtotal} colSpan={2}>
                                          Total {this.state.invoice.currency}
                                      </TableCell>
                                      <TableCell className={classes.tableSubtotal}>
                                          <span>{parseFloat(this.state.invoice.gross).toFixed(2)}</span>
                                      </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              )}                              

                            </TableBody>
                      </Table>
                  </TableContainer>           
              </Grid>

          </Grid>
        </div>

      </div> 

    );
  }
}

export default withStyles(styles)(Invoice);
