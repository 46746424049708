import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Questionaire from './Questionaire';
import CoreDialog from '../../core/dialog';

const useStyles = makeStyles(theme => ({
  list: {
    width: "100%",
  },
  wrapper:{
    padding: '20px 20px 20px 20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '330px'
    },
    [theme.breakpoints.up('md')]: {
        width: '500px',
      },
    [theme.breakpoints.up('lg')]: {
        width: '800px',
    },      

  },

  fullList: {
    width: 'auto',
  },
}));

export default function QuestionnaireEditDrawer( props ) {
  const {disabled, customer, refresh, questionnaire} = props;
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [qa, setQA] = React.useState("");
  const [error, setError] = React.useState('')

  const onSaveQuestionnaire =( e ) =>{
      e.preventDefault();

      let chain  = '&q=' + encodeURIComponent( JSON.stringify(qa) );
      chain     += '&customer=' + parseInt(customer.id);
  
  
      fetch("/customers/ajax-add-customer-questionnaire", {
        method: "POST",
        mode: 'same-origin', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit      
        body: window.setup.security_name + '=' + window.setup.security_value + '&' + chain ,
        headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "X-Requested-With": 'XMLHttpRequest',
              "X_REQUESTED_WITH": 'xmlhttprequest',
          }
      })
      .then(res => {
          return res.json();
      })
      .then(response => {
          //console.log(data);
          if(response.status === 'OK')
          {
  
            refresh();
            setState({
              top: false,
              left: false,
              bottom: false,
              right: false,
            })
          }
          else
          {
            console.error("Failed to store this questionnaire", response);
            setError(response.reason);
      
          }
      }).catch(function (err) {
        console.error("Failed to store this questionnaire", err);
        setError("Network error");
  
      });       
  }
  
  React.useEffect(() => {
    if(!!questionnaire.length)
    {
        const qu = props.questionnaire_templates.find( q => parseInt(q.id) === parseInt(questionnaire[0].quid));

        if(!!qu)
        {
          const x = {
              id: qu.id,
              name: qu.name,
              qas: qu.object.map( qas => {
                  const id = qas.id;
                  let z = Object.assign({}, qas);
                  const answers = questionnaire[0]?.qo || [];
                  let answer = answers.find( a => a.id === id );
                  z.answer = "";
                  if(!!answer)
                  {
                    z.answer = answer.answer;
                  }
                  return z;
              })
          }

          setQA(x);
        }

    }
  }, [questionnaire]);



  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <div className={classes.wrapper}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
            {
                qa !== "" && (
                    <React.Fragment>
                        <h3>Edit questionnaire</h3>
                        <form onSubmit={onSaveQuestionnaire}>
                            <Questionaire qas={qa} onChange={(v) => setQA(v)}/>
                            <Button type="submit" color='primary' variant="contained">Save</Button>
                        </form>

                    </React.Fragment>
                )
            }
            </Grid>
        </Grid>



      </div>
    </div>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
                variant="default"
                onClick={toggleDrawer(anchor, true)}
                disabled={disabled}
            >
                Edit Questionnaire
          </Button> 
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
      <CoreDialog title="Error" body={error}  state={error !== ''} danger={true} onCancel={()=>{setError('')}} actions={[{label:"Close", action:() => {setError('')}}]} />

    </div>
  );
}
