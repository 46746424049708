import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl  from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';

import Select  from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';


import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './stepper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";

import  {
    EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP
  } from '../redux/types';

import MuiDialogTitle from '@material-ui/core/DialogTitle';

    const styles = theme => ({
        root: {
            flexGrow: 1,
          },          
          title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
          },
          formControl: {
            minWidth: 120,
          },
          textInput:{
            marginTop:"20px",
            marginBottom:"20px",
          },
          switchLabel:{
              color: 'rgba(0,0,0,0.6)',
          },
          captionText:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',

          },
          colorSelect:{
            marginTop:"10px",
            marginBottom:"10px",
          },
          captionSection:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',
            marginBottom: '10px',
            lineHeight: '1.5em',
          }
});








function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventsStep2VirtualSetupModule extends Component{

    constructor( props )
    {
        super( props );

        console.log('cache', this.props.cache);
        console.log('data', this.props.data);
        this.state = {
            mode: props.cache.mode || 'zoom',
            disable: false,
            configuration: props.cache.configuration,
            plugins: props.data.obj.plugins,  
            refresh: false,
            is_edit: props.is_edit,
        };


        

        if(this.state.plugins.zoom === false)
        {
            this.state.mode = 'manual';
            this.state.disable = true;
        }
        else if(this.state.plugins.zoom.length > 0)
        {

            var selected = parseInt(this.state.configuration.selected_account);
            if(!this.state.configuration.selected_account || selected <= 0) /// if not set, use the default
            {
                this.state.configuration.selected_account = this.state.plugins.zoom[0].id;
                this.state.is_edit = false;
            }

        }

        if(this.state.mode === 'manual')
        {
            console.log('Setting disable due to manual');
            this.state.disable = true;
        }


        obj = this.state;

        this.state['errors'] = {
            name: {
                object: 'name',
                rule: /^[a-zA-Z0-9 \W]+$/,
                state: false, 
                ok: "This is the name of your event. It should summarise the event that you are selling. For example, 'Monday Yoga with Henry'", 
                error: 'This information is mandatory. Alpha numeric values only are allowed'
            },
            description: {
                object: obj.description,
                rule: /^[a-zA-Z0-9 \W]*$/,
                state: false, 
                ok: "This is the description of your event. Here you can add more detail about what you are selling", 
                error: 'This information is optional. Alpha numeric values only are allowed'
            }            
        };

        this.state.security = props.security;
        this.myRef = React.createRef()  
        this.handleMeetingChange   = this.handleMeetingChange.bind(this);
        this.onHostVideoEnabledChange = this.onHostVideoEnabledChange.bind(this);
        this.onParticipantVideoEnabledChange = this.onParticipantVideoEnabledChange.bind(this);
        this.onWaitRoomChangeEnabledChange   = this.onWaitRoomChangeEnabledChange.bind(this);
        this.onJoinBeforeHostEnabledChange   = this.onJoinBeforeHostEnabledChange.bind(this);
        this.handleRecordingChange           = this.handleRecordingChange.bind(this);
        this.onZoomAccountSelect             = this.onZoomAccountSelect.bind(this);
        this.onMuteUponEntryChange           = this.onMuteUponEntryChange.bind(this);
        
        this.onDoNext   = this.onDoNext.bind(this);
        this.onDoPrevious   = this.onDoPrevious.bind(this);

   
        
    }

    onZoomAccountSelect(event)
    {
        var x = Object.assign({}, this.state.configuration);
        
        x.selected_account = event.target.value;
        this.setState({'configuration': x});
    }

    onDoPrevious(event)
    {
        if(this.isValid())
        {
            var o = { store_previous: true,
                      virtual:
                      {
                        mode: this.state.mode,
                        configuration: this.state.configuration
                      },
                      state: EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP};
            this.props.rewindStep(o);  
    
        }
        else
        {
            var o = { 
                state: EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP
            };
            this.props.rewindStep(o);  
        }


      
    }

    handleMeetingChange(event)
    {
        var disable = false;
        var x = Object.assign({}, this.state.configuration);
        if(event.target.value === 'manual')
        {
            x.object.participant_video_enabled =  false;
            x.object.auto_recording =  'none';
            x.object.waiting_room_enabled =  false;
            x.object.join_before_host_enabled =  false;
            x.object.host_video_enabled = false;
            disable = true;

        }
        this.setState({'mode': event.target.value, 'configuration': x, 'refresh': !this.state.refresh, 'disable': disable});
    }


    onParticipantVideoEnabledChange(event)
    {
        var x = Object.assign({}, this.state.configuration);
        x.object.participant_video_enabled =  !x.object.participant_video_enabled;
        console.log("is ",  x)
        this.setState({'configuration': x});
    }

    handleRecordingChange(event)
    {
        var x = Object.assign({}, this.state.configuration);
        x.object.auto_recording =  event.target.value;
        this.setState({'configuration': x, 'refresh': !this.state.refresh});
    }

    onWaitRoomChangeEnabledChange(event)
    {
        var x = Object.assign({}, this.state.configuration);
        x.object.waiting_room_enabled =  !x.object.waiting_room_enabled;
        this.setState({'configuration': x, 'refresh': !this.state.refresh});

    }   
    
    onMuteUponEntryChange(event)
    {
        var x = Object.assign({}, this.state.configuration);
        x.object.mute_upon_entry_enabled =  !x.object.mute_upon_entry_enabled;
        this.setState({'configuration': x, 'refresh': !this.state.refresh});
    }
    

    onJoinBeforeHostEnabledChange(event)
    {
        var x = Object.assign({}, this.state.configuration);
        x.object.join_before_host_enabled =  !x.object.join_before_host_enabled;
        this.setState({'configuration': x, 'refresh': !this.state.refresh});
    }    

    onHostVideoEnabledChange( event )
    {
        var x = Object.assign({}, this.state.configuration);
        x.object.host_video_enabled =  !x.object.host_video_enabled ;
        this.setState({'configuration': x, 'refresh': !this.state.refresh});

    }
    

    isValid()
    {
        for(var i in this.state.errors)
        {
            var regex = RegExp(this.state.errors[i].rule);
            var x = Object.assign({}, this.state.errors);


             if(regex.test(this.state[this.state.errors[i].object]) === false)
            {
                window.scrollTo(0, this.myRef.offsetTop);                
                this.state.errors[i].state = true;
                this.setState({'errors': this.state.errors});
                return false;
            }
            else
            {
                console.log('Passes test');
                this.state.errors[i].state = false;
                this.setState({'errors': this.state.errors});
                return true;

            }
            
        }
        return false;

    }

    
    onDoNext(event)
    {
        var regex = RegExp(this.state.errors.name.rule);
       
        var x = Object.assign({}, this.state.errors);

        //if(regex.test(this.state.name))
        if(this.isValid())
        {
            var o = { virtual:
                      {
                        mode: this.state.mode,
                        configuration: this.state.configuration
                      },
                      state: EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP};
            this.props.setStep(o);
    
        }
        else
        {
            this.state.errors.name.state = true;
            this.setState({'errors': this.state.errors});
        }

    }
  
    




  

    render()
    {
        var classes = this.props.classes;
        console.log('edit data', this.state.edit_data);
        var color = this.state.color;
        var name = this.state.name;
        var description = this.state.description;
        var waiting = this.state.waiting_list_active;
        var classes = this.props.classes;
        var zoom_available = (this.state.plugins.zoom !== false) && (!this.state.disable);

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Scheduling
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >


                <Grid item xs={12} md={12} > 
                    <Grid container justify="flex-start" alignItems="stretch">
                        
                                        
                        <Grid item xs={12} md={12}>
                            <div className="box">
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    <Grid item xs={12} md={12}>
                                           <StepperWidget key={uuidv1()} step={1}/>

                                    </Grid>
                                    <Grid item xs={12} md={12} >
                                        <div className="formSection">Virtual Scheduling Setup</div>                               
                                        <p className="sectionDescription">
                                          This section enables you to configure your virtual event and to set how the corresponding meetings are setup with your audio or video streaming provider. If you select manual, you will be asked to enter the invite details. 
                                          If you select automated, reservie will automate the process of generating meetings. Automation is only available if you have activated Zoom in the integration system.                         
                                        </p>
                                    </Grid>

                                    <Grid item xs={12} md={12} >
                                        <form className="" ref={this.myRef} noValidate autoComplete="off">
                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup aria-label="widget" name="widget" value={this.state.mode} onChange={this.handleMeetingChange}>
                                                            <FormControlLabel value='zoom' key={uuidv1()} disabled={this.state.plugins.zoom === false?true: false} control={<Radio />} label='Zoom automation - allow reservie to create meeting invites' />
                                                            <FormControlLabel value='manual' key={uuidv1()} control={<Radio />} label='Manually manage invitations.' />
                                                        </RadioGroup>
                                                        
                                                    </FormControl>                                                       
                                                </Grid>
                                                
                                                <Grid item xs={12} md={12} >
                                                    <div className="formSection">Zoom configuration</div>                               
                                                    <p className="sectionDescription">
                                                        This setting allows you to configure the settings for your Zoom meeting.                       
                                                    </p>
                                                </Grid>  

                                                {zoom_available && (
                                                    <Grid item xs={12}>
                                                            <FormControl variant="outlined" fullWidth={false} className={classes.formControl}>
                                                                <InputLabel id="zoom-account-select">Select Zoom account</InputLabel>

                                                                <Select
                                                                id="zoom-account-select-outlined"
                                                                value={this.state.configuration.selected_account}
                                                                label="Select a Zoom account"
                                                                onChange={this.onZoomAccountSelect}
                                                                disabled={this.state.is_edit}
                                                                >
                                                                {this.state.plugins.zoom.map(el => (
                                                                    <MenuItem value={el.id} key={el.id}>{el.foreign_email}</MenuItem>
                                                                ))}                                            
                                                                </Select>    
                                                                <FormHelperText>This selects the Zoom account to be used.</FormHelperText>                                                 
                                                            </FormControl>                                                        
                                                    </Grid>  
                                                )}
                                           
                                                <Grid item xs={12} md={12} >
                                                    <FormControlLabel
                                                            control={
                                                            <Switch
                                                                checked={this.state.configuration.object.host_video_enabled && zoom_available}
                                                                onChange={this.onHostVideoEnabledChange}
                                                                value="1"
                                                                color="primary"
                                                                disabled={!zoom_available}
                                                            />
                                                            }
                                                            label={<span className={classes.switchLabel}>Display host video</span>}
                                                        /> 

                                                    <Typography variant="caption" className={classes.captionText}>This enables/disables the host video by default.</Typography>                               

                                                </Grid>
                                                <Grid item xs={12} md={12} >
                                                    <FormControlLabel
                                                            control={
                                                            <Switch
                                                            checked={this.state.configuration.object.participant_video_enabled && zoom_available}
                                                            onChange={this.onParticipantVideoEnabledChange}
                                                                value="1"
                                                                color="primary"
                                                                disabled={!zoom_available}
                                                            />
                                                            }
                                                            label={<span className={classes.switchLabel}>Display participant video</span>}
                                                        /> 

                                                    <Typography variant="caption" className={classes.captionText}>This enables/disables the participant video by default.</Typography>                               

                                                </Grid> 
                                                <Grid item xs={12} md={12} >
                                                    <FormControlLabel
                                                            control={
                                                            <Switch
                                                                checked={this.state.configuration.object.join_before_host_enabled && zoom_available}
                                                                onChange={this.onJoinBeforeHostEnabledChange}
                                                                value="1"
                                                                color="primary"
                                                                disabled={!zoom_available}
                                                            />
                                                            }
                                                            label={<span className={classes.switchLabel}>Allow join before host</span>}
                                                        /> 

                                                    <Typography variant="caption" className={classes.captionText}>Allows participants to join the meeting before the host.</Typography>                               

                                                </Grid>  
                                                <Grid item xs={12} md={12} >
                                                    <FormControlLabel
                                                            control={
                                                            <Switch
                                                                checked={this.state.configuration.object.waiting_room_enabled && zoom_available}
                                                                onChange={this.onWaitRoomChangeEnabledChange}
                                                                value="1"
                                                                color="primary"
                                                                disabled={!zoom_available}

                                                            />
                                                            }
                                                            label={<span className={classes.switchLabel}>Enable waiting room</span>}
                                                        /> 

                                                    <Typography variant="caption" className={classes.captionText}>Participants enter a waiting room.</Typography>                               

                                                </Grid>
                                                <Grid item xs={12} md={12} >
                                                    <FormControlLabel
                                                            control={
                                                            <Switch
                                                                checked={this.state.configuration.object.mute_upon_entry_enabled && zoom_available}
                                                                onChange={this.onMuteUponEntryChange}
                                                                value="1"
                                                                color="primary"
                                                                disabled={!zoom_available}

                                                            />
                                                            }
                                                            label={<span className={classes.switchLabel}>Mute audio upon entry</span>}
                                                        /> 

                                                    <Typography variant="caption" className={classes.captionText}>Participants muted upon entry.</Typography>                               

                                                </Grid>   




                                                <Grid item xs={12} md={12} >
                                                    <FormControl component="fieldset">
                                                        <RadioGroup aria-label="recording" name="recording" row value={this.state.configuration.object.auto_recording} onChange={this.handleRecordingChange}>
                                                            <FormControlLabel value='none' key={uuidv1()} disabled={!zoom_available} control={<Radio />} label='No video recording' />
                                                            <FormControlLabel value='local' key={uuidv1()} disabled={!zoom_available} control={<Radio />} label='Local video recording' />
                                                            <FormControlLabel value='cloud' key={uuidv1()} disabled={!zoom_available} control={<Radio />} label='Cloud video recording' />
                                                        </RadioGroup>
                                                        
                                                    </FormControl>  


                                                </Grid>  
                                                <Grid item xs={12} md={12} >
                                                    <Typography variant="caption" className={classes.captionText}>Video recording settings.</Typography>                               
                                                </Grid>  
                                            </Grid>                                                
                                        </form>

                                    </Grid>
                                    <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                size="large"
                                                className="rv-eng-next-button"
                                                startIcon={<NavigateBeforeIcon />}
                                                onClick={this.onDoPrevious}
                                                fullWidth={true}
                                            >
                                                Back
                                            </Button>
                                    </Grid>
                                    <Grid item xs={4} md={6}></Grid>
                                    <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="rv-eng-next-button"
                                                endIcon={<NavigateNextIcon />}
                                                onClick={this.onDoNext}
                                                fullWidth={true}
                                            >
                                                Next
                                            </Button>  
                                                                
                                    </Grid>  
                                </Grid>
                

                            </div>
                        </Grid>                                               
                    </Grid>

                </Grid>                                    

                </Grid>

                
            </div>            
        );
    }

};



const EventsStep2VirtualSetup = connect(null, mapDispatchToProps)(EventsStep2VirtualSetupModule);
export default withStyles(styles)(EventsStep2VirtualSetup);
