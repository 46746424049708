
import {    ACTION_TYPE_LOAD_DATA, 
            ACTION_TYPE_RELOAD_DATA,
            ACTION_TYPE_MANAGE_CUSTOMER,
            ACTION_TYPE_MANAGE_CUSTOMER_CLOSE_PANEL,
            ACTION_TYPE_SET_STATE,
            ACTION_TYPE_REWIND_STATE,
            ACTION_TYPE_SET_CLONE_STATE,
            
        }  from './types';


export function loadData(payload) {
    return { type: ACTION_TYPE_LOAD_DATA, payload };
}

export function reloadData(payload) {
    return { type: ACTION_TYPE_RELOAD_DATA, payload };
}

export function manageCustomer(payload) {
    return { type: ACTION_TYPE_MANAGE_CUSTOMER, payload };
}

export function closeCustomerPanel(payload){
    return { type: ACTION_TYPE_MANAGE_CUSTOMER_CLOSE_PANEL, payload };

}

export function setStep(payload){
    return { type: ACTION_TYPE_SET_STATE, payload };

}

export function rewindStep(payload){
    return { type: ACTION_TYPE_REWIND_STATE, payload };

}


export function setCloneState(payload){
    return { type: ACTION_TYPE_SET_CLONE_STATE, payload };

}