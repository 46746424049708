import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './stepper';

import Button from '@material-ui/core/Button';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';


import SaveIcon from '@material-ui/icons/Save';


import  {
    EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES
} from '../redux/types';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";


    const styles = theme => ({
        root: {
            flexGrow: 1,
          },          
          title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
          },
          formControl: {
            marginBottom: '20px',
            minWidth: 120,
          },
          textInput:{
            marginTop: '20px'
          },
          switchLabel:{
              color: 'rgba(0,0,0,0.6)',
          },
          captionText:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',

          },
          sectionDescription:{
            color: 'rgba(0,0,0,0.6)',    
          },            
          captionSection:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',
            marginBottom: '10px',
            lineHeight: '1.5em',
          },
          schedTypeSelection:{
              marginLeft: '10px'
          },
          what:{
              width: '50%'
          },
          fabDelete:{
              backgroundColor: '#b80000',
              color: '#fff',
              '&:hover':{
                  backgroundColor: '#940000',
              }
          },        
          fabIcon:{
              '& .MuiSvgIcon-root':{
                  fill: '#fff',
                  color: '#fff',
                  '&:hover':{
                      color: '#ffe',
                  }
              }
          }            
});





function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventsStep5PropertiesModule extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            faciliator_list: props.data.obj.facilitators || [],
            selected_facilitators: props.cache.facilitators || [],
            terms_list: props.data.obj.terms || [],
            selected_terms: props.cache.terms || null,
            categories_list: props.data.obj.categories || [],
            selected_categories: props.cache.categories || null,   
            locations_list: props.data.obj.locations || [],
            selected_location: props.cache.location || null,                       
            cancel: props.cache.cancel || 'default',
            available: props.cache.available || 'default',
            error: false,
            refresh: false,
            disable_passes: true,
            disable_card: true,
        }      


        obj = this.state;




        console.log('EventsStep5PropertiesModule', this.state, props);

 


        this.state.security     = props.security;
        this.onDoPrevious       = this.onDoPrevious.bind(this);
        this.onFacilitatorOptionSelect   = this.onFacilitatorOptionSelect.bind(this);
        this.onCategoriesOptionSelect    = this.onCategoriesOptionSelect.bind(this);
        this.onTermsOptionSelect         = this.onTermsOptionSelect.bind(this);
        this.onLocationOptionSelect         = this.onLocationOptionSelect.bind(this);
        this.onDoNext           = this.onDoNext.bind(this);

    }

    onFacilitatorOptionSelect(event, value)
    {
        if(value !== null)
        {

            console.log('selected_facilitators', value);
            this.setState({'selected_facilitators': value});

        }
        else
        {
            this.setState({'selected_facilitators': []});
        }
    }
    onCategoriesOptionSelect(event, value)
    {
        if(value !== null)
        {

            console.log('selected_categories', value);
            this.setState({'selected_categories': value});

        }
        else
        {
            this.setState({'selected_categories': []});
        }
    }

    onTermsOptionSelect(event, value)
    {
        if(value !== null)
        {

            console.log('selected_terms', value);
            this.setState({'selected_terms': value});

        }
        else
        {
            this.setState({'selected_terms': []});
        }
    }


    onLocationOptionSelect(event, value)
    {
        if(value !== null)
        {

            console.log('selected_location', value);
            this.setState({'selected_location': value});

        }
        else
        {
            this.setState({'selected_location': null});
        }
    }
 

    onDoPrevious(event)
    {
        var o = { 
            store_previous: true,
            configuration:{
                facilitators: this.state.selected_facilitators,
                categories: this.state.selected_categories,
                terms: this.state.selected_terms,
                location: this.state.selected_location
            },
            state: EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES
        };
        this.props.rewindStep(o);        
    }

     


 


    
    onDoNext(event)
    {
        var x = Object.assign({}, this.state.errors);

        //if(regex.test(this.state.name))
    
        var o = {
                configuration:{
                    facilitators: this.state.selected_facilitators,
                    categories: this.state.selected_categories,
                    terms: this.state.selected_terms,
                    location: this.state.selected_location
                },
                state: EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES
            };
        console.log("sending data", o);
        this.props.setStep(o);
    

    }
  
    




  

    render()
    {
        var classes = this.props.classes;

        console.log('render', this.state);

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Event editor
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="center" alignItems="center" spacing={2} >


                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="center" spacing={2}>
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">

                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        
                                        <Grid item xs={12} md={12}>
                                            <StepperWidget key={uuidv1()} step={3}/>

                                        </Grid>
                                        
                                        
                                        <Grid item xs={12}>
                                            <div className="formSection">Properties</div>                               
                                            <p className="sectionDescription">
                                               This section allows you to configure the properties of your event. If you are happy with the default, click next.    
                                            </p>                                        
                                        </Grid>

      
 
                                        <Grid item  xs={12}>
                                            <Autocomplete
                                                id="facilitator-list"
                                                className={classes.formControl}
                                                options={this.state.faciliator_list}
                                                getOptionLabel={facilitator => facilitator.firstname + ' ' + facilitator.lastname}
                                                style={{ width: "100%" }}
                                                autoHighlight
                                                multiple={true}
                                                value={this.state.selected_facilitators}
                                                onChange={this.onFacilitatorOptionSelect}
                                                getOptionSelected={(option, value)  => {
                                                    return (option.id === value.id);  
                                                }}                                                
                                                disableClearable={false}
                                                filterSelectedOptions
                                                renderOption={facilitator => (
                                                <React.Fragment>
                                                    {facilitator.firstname + ' ' + facilitator.lastname}
                                                </React.Fragment>
                                                )}                
                                                renderInput={params => <TextField {...params} label="Facilitators" variant="outlined" />}
                                            />
                                    
   

                                        </Grid>
                                        <Grid item  xs={12}>
                                            <Autocomplete
                                                id="terms-list"
                                                className={classes.formControl}
                                                options={this.state.terms_list}
                                                getOptionLabel={term => term.name}
                                                style={{ width: "100%" }}
                                                autoHighlight
                                                multiple={true}
                                                value={this.state.selected_terms}
                                                onChange={this.onTermsOptionSelect}
                                                disableClearable={false}
                                                filterSelectedOptions
                                                getOptionSelected={(option, value)  => {
                                                    return (option.id === value.id);  
                                                }}                                                
                                                renderOption={term => (
                                                <React.Fragment>
                                                    {term.name}
                                                </React.Fragment>
                                                )}                
                                                renderInput={params => <TextField {...params} label="Terms" variant="outlined" />}
                                            />
                                    
   

                                        </Grid>

                                        <Grid item  xs={12}>
                                            <Autocomplete
                                                id="categories-list"
                                                className={classes.formControl}
                                                options={this.state.categories_list}
                                                getOptionLabel={category => category.name}
                                                style={{ width: "100%" }}
                                                autoHighlight
                                                multiple={true}
                                                value={this.state.selected_categories}
                                                onChange={this.onCategoriesOptionSelect}
                                                getOptionSelected={(option, value)  => {
                                                    return (option.id === value.id);  
                                                }}                                                
                                                disableClearable={false}
                                                filterSelectedOptions={true}
                                                renderOption={category => (
                                                <React.Fragment>
                                                    {category.name}
                                                </React.Fragment>
                                                )}                
                                                renderInput={params => <TextField {...params} label="Categories" variant="outlined" />}
                                            />                                                                                          
                                        </Grid>                                        
                                        <Grid item  xs={12}>
                                            <Autocomplete
                                                id="locations-list"
                                                className={classes.formControl}
                                                options={this.state.locations_list}
                                                getOptionLabel={location => location.name}
                                                style={{ width: "100%" }}
                                                autoHighlight
                                                multiple={false}
                                                value={this.state.selected_location}
                                                onChange={this.onLocationOptionSelect}
                                                disableClearable={false}
                                                filterSelectedOptions
                                                getOptionSelected={(option, value)  => {
                                                    return (option.id === value.id);  
                                                }}                                                
                                                renderOption={location => (
                                                <React.Fragment>
                                                    {location.name}
                                                </React.Fragment>
                                                )}                
                                                renderInput={params => <TextField {...params} label="Locations" variant="outlined" />}
                                            />                                                                                          
                                        </Grid> 
                                        <Grid item xs={4} md={3}>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            size="large"
                                            className="rv-eng-next-button"
                                            startIcon={<NavigateBeforeIcon />}
                                            onClick={this.onDoPrevious}
                                            fullWidth={true}
                                        >
                                            Back
                                        </Button>                          
                                        </Grid>
                                        <Grid item xs={4} md={6}></Grid>
                                        <Grid item xs={4} md={3}>                      
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className="rv-eng-save-button"
                                            onClick={this.onDoNext}
                                            fullWidth={true}
                                            startIcon={<SaveIcon />}
                                            >
                                            Save
                                            </Button>                     
                                        </Grid>                      
                                    </Grid>


                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>

                
            </div>            
        );
    }

};



const EventsStep5Properties = connect(null, mapDispatchToProps)(EventsStep5PropertiesModule);
export default withStyles(styles)(EventsStep5Properties);
