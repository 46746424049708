import React, { Component } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './stepper';

import Button from '@material-ui/core/Button';

import Moment from 'react-moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';
import Backdrop from '@material-ui/core/Backdrop';



import  {
    EVENT_APP_CACHE_BOOKING_MODE_TYPE_INDIVIDUAL,
    EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL,
    EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_WEEKLY,
    EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_MONTHLY,
    EVENT_APP_CACHE_BOOKING_MODE_TYPE_BLOCK,
    EVENT_APP_CACHE_BOOKING_MODE_TYPE_FLEXIBLE,
    EVENT_APP_CACHE_STATE_WIZARD_SUMMARY,

    EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD,
    EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES
} from '../redux/types';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";
import { HorizontalBar } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import LinearProgress from '@material-ui/core/LinearProgress';


    const styles = theme => ({
        root: {
            flexGrow: 1,
          },          
          progress:{
            color: 'rgba(0,0,0,0.5)',
            fontSize: '25px',
            textAlign: 'center'
        },
        progressIcon:{
            color: 'rgba(0,0,0,0.5)',

        },
        progressDone:{
            color: 'rgb(145,215, 104)',

        },  
        progressError:{
            color: 'rgba(199, 0, 0, 0.829)',

        }, 
        title: {
            flexGrow: 1,
            color: '#fff',
            fontSize: '1.3rem'
        },
});


const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  }))(LinearProgress);


function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
var obj = null;
const PROCESSING_STATE_VALIDATE_BASIC = 'PROCESSING_STATE_VALIDATE_BASIC';
const PROCESSING_STATE_VALIDATE_SCHEDULING = 'PROCESSING_STATE_VALIDATE_SCHEDULING';
const PROCESSING_STATE_VALIDATE_PAYMENT = 'PROCESSING_STATE_VALIDATE_PAYMENT';
const PROCESSING_STATE_VALIDATE_ACTIONS = 'PROCESSING_STATE_VALIDATE_ACTIONS';
const PROCESSING_STATE_VALIDATE_PROPERTIES = 'PROCESSING_STATE_VALIDATE_PROPERTIES';
const PROCESSING_STATE_VALIDATE_VIRTUAL='PROCESSING_STATE_VALIDATE_VIRTUAL';
const PROCESSING_STATE_SUBMIT = 'PROCESSING_STATE_SUBMIT';

class EventsStepSummaryModule extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            mode: props.mode,
            configuration: props.cache,  
            loading: true, 
            done: false,                   
            error: false,
            refresh: false,
            backdrop: false,
            process_text: '',
            process_percentage: 0,
            error_msg: '',
            error: false,

        }  
        this.processing_state = PROCESSING_STATE_VALIDATE_BASIC;
        this.process_list     = [];
        
        


        obj = this.state;




        console.log('EventsStepSummaryModule', this.state, props);

 


        this.state.security     = props.security;
        this.onDoPrevious       = this.onDoPrevious.bind(this);
        this.onDoNext           = this.onDoNext.bind(this);
        this.processorSM        = this.processorSM.bind(this);

    }

 
 

    onDoPrevious(event)
    {
        var o = { 
            state: EVENT_APP_CACHE_STATE_WIZARD_SUMMARY
        };
        this.props.rewindStep(o);        
    }

     


 


    
    onDoNext(event)
    {
        var o = {
            state: EVENT_APP_CACHE_STATE_WIZARD_SUMMARY
        };
        console.log("sending data", o);
        this.props.setStep(o);
 
    }

    componentDidMount()
    {
        this.processorSM();    
    }
  
    


    processorSM()
    {
        var _this = this;
        switch(this.processing_state)
        {
            case PROCESSING_STATE_VALIDATE_BASIC:
            {    
                this.setState({'process_text': 'Validating basic information', 'process_percentage': 0, 'error': false}, function(){
                    var _this = this;

                    console.log('data', this.state);
            
                    var chain = 'data=' + encodeURIComponent(JSON.stringify(this.state.configuration.basic));
                    chain = chain + '&type=' + this.processing_state;
            
                    try{
                        fetch("/events/ajax-validate-event", {
                          method: "POST",
                          mode: 'same-origin', // no-cors, *cors, same-origin
                          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                          credentials: 'include', // include, *same-origin, omit                            
                          body: window.setup.security_name + '=' + window.setup.security_value + '&'+ chain,
                          headers: {
                              "Content-Type": "application/x-www-form-urlencoded",
                              "X-Requested-With": 'XMLHttpRequest',
                              "X_REQUESTED_WITH": 'xmlhttprequest'
                          }
                      })
                      .then(res => {
                          return res.json();
                      })
                      .then(response => {
                          //var x = Object.assign({}, window.setup);
                          //x.data = response.data;
                          //console.log(data);
                          if(!response.hasOwnProperty("error"))
                          {
                            _this.processing_state = PROCESSING_STATE_VALIDATE_SCHEDULING;
                            setTimeout(function(){
                                _this.processorSM();
                            }, 1);
                          }
                          else
                          {
                                /* handle error */
                                _this.setState({'error': true, 'loading': false, 'error_msg': response.message});
                                console.error('Failed to validate basic information', response.message);
                          }
                      }).catch(function(err){
                        _this.setState({'error': true, 'loading': false, 'error_msg': 'Network error: ' + err });
                        console.error('Failed to validate basic information', err);
                      });  
            
                    }
                    catch(e)
                    {
                        _this.setState({'error': true, 'error_msg': 'Network error: ' + e });
                        console.error('Failed to save the event', e);
                    }  
            
                });
            }
            break;

            case PROCESSING_STATE_VALIDATE_SCHEDULING:
            {
                this.setState({'process_text': 'Validating scheduling', 'process_percentage': 15, 'error': false}, function(){
                    var _this = this;

                    console.log('data', this.state);
            
                    var chain = 'data=' + encodeURIComponent(JSON.stringify(this.state.configuration.scheduling));
                    chain = chain + '&type=' + this.processing_state;
            
                    try{
                        fetch("/events/ajax-validate-event", {
                            method: "POST",
                            mode: 'same-origin', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            credentials: 'include', // include, *same-origin, omit                            
                          body: window.setup.security_name + '=' + window.setup.security_value + '&'+ chain,
                          headers: {
                              "Content-Type": "application/x-www-form-urlencoded",
                              "X-Requested-With": 'XMLHttpRequest',
                              "X_REQUESTED_WITH": 'xmlhttprequest'
                          }
                      })
                      .then(res => {
                          return res.json();
                      })
                      .then(response => {
                          //var x = Object.assign({}, window.setup);
                          //x.data = response.data;
                          //console.log(data);
                          if(!response.hasOwnProperty("error"))
                          {
                            _this.processing_state = PROCESSING_STATE_VALIDATE_PAYMENT;
                            setTimeout(function(){
                                _this.processorSM();
                            }, 1);
                          }
                          else
                          {
                                /* handle error */
                                _this.setState({'error': true, 'loading': false, 'error_msg': response.message});
                                console.error('Failed to validate scheduling information', response.message);
                          }
                      }).catch(function(err){
                        _this.setState({'error': true, 'loading': false, 'error_msg': 'Network error: ' + err });
                        console.error('Failed to validate scheduling information', err);
                      });  
            
                    }
                    catch(e)
                    {
                        _this.setState({'error': true, 'error_msg': 'Network error: ' + e });
                        console.error('Failed to save the event', e);
                    }  
            
                });
            }
            break;
            case PROCESSING_STATE_VALIDATE_PAYMENT:
            {
                this.setState({'process_text': 'Validating payments', 'process_percentage': 30, 'error': false}, function(){
                    var _this = this;

                    console.log('data', this.state);
            
                    var chain = 'data=' + encodeURIComponent(JSON.stringify(this.state.configuration.payment));
                    chain = chain + '&type=' + this.processing_state;
            
                    try{
                        fetch("/events/ajax-validate-event", {
                            method: "POST",
                            mode: 'same-origin', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            credentials: 'include', // include, *same-origin, omit                            
                          body: window.setup.security_name + '=' + window.setup.security_value + '&'+ chain,
                          headers: {
                              "Content-Type": "application/x-www-form-urlencoded",
                              "X-Requested-With": 'XMLHttpRequest',
                              "X_REQUESTED_WITH": 'xmlhttprequest'
                          }
                      })
                      .then(res => {
                          return res.json();
                      })
                      .then(response => {
                          //var x = Object.assign({}, window.setup);
                          //x.data = response.data;
                          //console.log(data);
                          if(!response.hasOwnProperty("error"))
                          {
                            _this.processing_state = PROCESSING_STATE_VALIDATE_ACTIONS;
                            setTimeout(function(){
                                _this.processorSM();
                            }, 1);
                          }
                          else
                          {
                                /* handle error */
                                _this.setState({'error': true, 'loading': false, 'error_msg': response.message});
                                console.error('Failed to validate payment information', response.message);
                          }
                      }).catch(function(err){
                        _this.setState({'error': true, 'loading': false, 'error_msg': 'Network error: ' + err });
                        console.error('Failed to validate payment information', err);
                      });  
            
                    }
                    catch(e)
                    {
                        _this.setState({'error': true, 'loading': false, 'error_msg': 'Network error: ' + e });
                        console.error('Failed to save the event', e);
                    }  
            
                });
            }
            break;
            case PROCESSING_STATE_VALIDATE_ACTIONS:
            {
                this.setState({'process_text': 'Validating notifications', 'process_percentage': 45, 'error': false}, function(){
                    var _this = this;

                    console.log('data', this.state);
            
                    var chain = 'data=' + encodeURIComponent(JSON.stringify(this.state.configuration.actions));
                    chain = chain + '&type=' + this.processing_state;
            
                    try{
                        fetch("/events/ajax-validate-event", {
                            method: "POST",
                            mode: 'same-origin', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            credentials: 'include', // include, *same-origin, omit                            
                          body: window.setup.security_name + '=' + window.setup.security_value + '&'+ chain,
                          headers: {
                              "Content-Type": "application/x-www-form-urlencoded",
                              "X-Requested-With": 'XMLHttpRequest',
                              "X_REQUESTED_WITH": 'xmlhttprequest'
                          }
                      })
                      .then(res => {
                          return res.json();
                      })
                      .then(response => {
                          //var x = Object.assign({}, window.setup);
                          //x.data = response.data;
                          //console.log(data);
                          if(!response.hasOwnProperty("error"))
                          {
                            _this.processing_state = PROCESSING_STATE_VALIDATE_PROPERTIES;
                            setTimeout(function(){
                                _this.processorSM();
                            }, 1);
                          }
                          else
                          {
                                /* handle error */
                                _this.setState({'error': true, 'loading': false, 'error_msg': response.message});
                                console.error('Failed to validate actions information', response.message);
                          }
                      }).catch(function(err){
                        _this.setState({'error': true, 'loading': false, 'error_msg': 'Network error: ' + err });
                        console.error('Failed to validate actions information', err);
                      });  
            
                    }
                    catch(e)
                    {
                        _this.setState({'error': true, 'loading': false, 'error_msg': 'Network error: ' + e });
                        console.error('Failed to save the event', e);
                    }  
            
                });
            }
            break;            
            case PROCESSING_STATE_VALIDATE_PROPERTIES:
            {
                this.setState({'process_text': 'Validating properties', 'process_percentage': 60, 'error': false}, function(){
                    var _this = this;

                    console.log('data', this.state);
            
                    var chain = 'data=' + encodeURIComponent(JSON.stringify(this.state.configuration.properties));
                    chain = chain + '&type=' + this.processing_state;
            
                    try{
                        fetch("/events/ajax-validate-event", {
                            method: "POST",
                            mode: 'same-origin', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            credentials: 'include', // include, *same-origin, omit                            
                          body: window.setup.security_name + '=' + window.setup.security_value + '&'+ chain,
                          headers: {
                              "Content-Type": "application/x-www-form-urlencoded",
                              "X-Requested-With": 'XMLHttpRequest',
                              "X_REQUESTED_WITH": 'xmlhttprequest'
                          }
                      })
                      .then(res => {
                          return res.json();
                      })
                      .then(response => {
                          //var x = Object.assign({}, window.setup);
                          //x.data = response.data;
                          //console.log(data);
                          if(!response.hasOwnProperty("error"))
                          {
                            _this.processing_state = PROCESSING_STATE_SUBMIT;
                            setTimeout(function(){
                                _this.processorSM();
                            }, 1);
                          }
                          else
                          {
                                /* handle error */
                                _this.setState({'error': true, 'loading': false, 'error_msg': response.message});
                                console.error('Failed to validate properties information', response.message);
                          }
                      }).catch(function(err){
                        _this.setState({'error': true, 'loading': false, 'error_msg': 'Network error: ' + err });
                        console.error('Failed to validate properties information', err);
                      });  
            
                    }
                    catch(e)
                    {
                        _this.setState({'error': true, 'loading': false, 'error_msg': 'Network error: ' + e });
                        console.error('Failed to save the event', e);
                    }  
            
                });
            }
            break; 

            case PROCESSING_STATE_VALIDATE_VIRTUAL:
            {
                    this.setState({'process_text': 'Validating 3rd party integration properties', 'process_percentage': 75, 'error': false}, function(){
                        var _this = this;
    
                        console.log('data', this.state);
                
                        var chain = 'data=' + encodeURIComponent(JSON.stringify(this.state.configuration.virtual));
                        chain = chain + '&type=' + this.processing_state;
                
                        try{
                            fetch("/events/ajax-validate-event", {
                                method: "POST",
                                mode: 'same-origin', // no-cors, *cors, same-origin
                                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                credentials: 'include', // include, *same-origin, omit                            
                                body: window.setup.security_name + '=' + window.setup.security_value + '&'+ chain,
                                headers: {
                                  "Content-Type": "application/x-www-form-urlencoded",
                                  "X-Requested-With": 'XMLHttpRequest',
                                  "X_REQUESTED_WITH": 'xmlhttprequest'
                              }
                          })
                          .then(res => {
                              return res.json();
                          })
                          .then(response => {
             
                              //console.log(data);
                              if(!response.hasOwnProperty("error"))
                              {
                                _this.processing_state = PROCESSING_STATE_SUBMIT;
                                setTimeout(function(){
                                    _this.processorSM();
                                }, 1);
                              }
                              else
                              {
                                    /* handle error */
                                    _this.setState({'error': true, 'loading': false, 'error_msg': response.message});
                                    console.error('Failed to validate properties information', response.message);
                              }
                          }).catch(function(err){
                            _this.setState({'error': true, 'loading': false, 'error_msg': 'Network error: ' + err });
                            console.error('Failed to validate properties information', err);
                          });  
                
                        }
                        catch(e)
                        {
                            _this.setState({'error': true, 'loading': false, 'error_msg': 'Network error: ' + e });
                            console.error('Failed to save the event', e);
                        }  
                
                    });
                }
                break; 

                case PROCESSING_STATE_SUBMIT:
                {
                    this.setState({'process_text': 'Publishing event', 'process_percentage': 90, 'error': false}, function(){
                        var _this = this;

                        var chain = 'basic=' + encodeURIComponent( JSON.stringify(this.state.configuration.basic) );
                        chain += '&scheduling=' + encodeURIComponent( JSON.stringify(this.state.configuration.scheduling) );
                        chain += '&payment=' +  encodeURIComponent( JSON.stringify(this.state.configuration.payment) );
                        chain += '&actions=' +  encodeURIComponent( JSON.stringify(this.state.configuration.actions) );            
                        chain += '&properties=' +  encodeURIComponent( JSON.stringify(this.state.configuration.properties) );
                        chain += '&virtual=' +  encodeURIComponent( JSON.stringify(this.state.configuration.virtual) );
                        chain += '&id=' + this.state.configuration.id;
                        chain +=  '&mode=' + this.state.mode;
                
                        try{
                            fetch("/events/ajax-publish-event", {
                            method: "POST",
                            mode: 'same-origin', // no-cors, *cors, same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            credentials: 'include', // include, *same-origin, omit                            
                            body: window.setup.security_name + '=' + window.setup.security_value + '&'+ chain,
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded",
                                "X-Requested-With": 'XMLHttpRequest',
                                "X_REQUESTED_WITH": 'xmlhttprequest'
                            }
                        })
                        .then(res => {
                            return res.json();
                        })
                        .then(response => {

                            //console.log(data);
                            if(!response.hasOwnProperty("error"))
                            {
              
                                _this.setState({'done': true, 'error':false, 'loading': false, 'process_percentage': 100}, function(){
                                    setTimeout(function(){
                                        _this.onDoNext();
                                    }, 2000);  
                                                                
                                });
                        
                            }
                            else
                            {
                                    /* handle error */
                                    _this.setState({'error': true, 'loading': false, 'process_text': response.message});
                                    console.error('Failed to submit information', response.reason);
                            }
                        }).catch(function(err){
                            _this.setState({'error': true, 'loading': false, 'error_msg': 'Network error: ' + err });
                            console.error('Failed to submit information', err);
                        });  
                
                        }
                        catch(e)
                        {
                            _this.setState({'error': true, 'loading': false, 'error_msg': 'Network error: ' + e });
                            console.error('Failed to save the event', e);
                        }  
                
                    });
                }
                break;


        }
    }


  

    render()
    {
        var classes = this.props.classes;

        console.log('render', this.state);
        console.log('mode', this.state.configuration.payment.mode & EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD);

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Event editor
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="center" alignItems="center" spacing={2} >


                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="center" spacing={2}>
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">

                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        
                                        <Grid item xs={12} md={12}>
                                            <StepperWidget key={uuidv1()} step={4}/>

                                        </Grid>
                                        
                                        {this.state.loading === true && (<Grid item xs={12}>
                                            <div className={classes.progress}>
                                                <CircularProgress className={classes.progressIcon} style={{ height: 100, width: 100, textAlign: 'center' }}/>                                                                          
                                                <p>
                                                    Standby whilst we setup the event
                                                </p>
                                            </div>
                                        </Grid>)}

                                        {this.state.done === true && (<Grid item xs={12}>
                                            <div className={classes.progress}>
                                                <CheckCircleOutlineRoundedIcon className={classes.progressDone} style={{ height: 100, width: 100, textAlign: 'center' }}/>                                                                          
                                                <p>
                                                    Done
                                                </p>
                                            </div>
                                        </Grid>)}
                                        {this.state.error === true && (<Grid item xs={12}>
                                            <div className={classes.progress}>
                                                <ErrorOutlineRoundedIcon className={classes.progressError} style={{ height: 100, width: 100, textAlign: 'center' }}/>                                                                          
                                                <p>
                                                    Error {this.state.error_msg}
                                                </p>
                                            </div>
                                        </Grid>)}   

                                        <Grid item xs={12}>
                                                <BorderLinearProgress variant="determinate" value={this.state.process_percentage} />
                                        </Grid>                                    
                                        <Grid item xs={12} align="center">
                                            {this.state.process_text}
                                        </Grid>                                    
                                                                                       
                                                           
                                        <Grid item xs={4} md={3}>                         
                                        </Grid>
                                        <Grid item xs={4} xs={6}></Grid>
                                        <Grid item xs={4} md={3}>  
                                        <Button
                                            variant="contained"
                                            color="default"
                                            size="large"
                                            className="rv-eng-next-button"
                                            startIcon={<NavigateNextIcon />}
                                            onClick={this.onDoNext}
                                            fullWidth={true}
                                            disabled={!this.state.error}
                                        >
                                            Close
                                        </Button>                                                                                  
                                        </Grid>                      
                                    </Grid>


                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>
                <Backdrop className={classes.backdrop} open={this.state.backdrop}>
                    <div>
                        <CircularProgress color="inherit" />
                    </div>
                </Backdrop>
                
            </div>            
        );
    }

};



const EventsStepSummary = connect(null, mapDispatchToProps)(EventsStepSummaryModule);
export default withStyles(styles)(EventsStepSummary);
