import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment'
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Loading from '../core/loading';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonDisabled: {
    color: theme.palette.grey[900]
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formSectionTitle:{
    marginTop: '15px',
    marginBottom: '15px',
    fontWeight: 'bold'
}    
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function MandateEditDialogWrapper( props ) {
  const security = props.security;
  const [open, setOpen] = React.useState(props.state);
  const  [loading, setLoading] = React.useState(false);
  const  [price, setPrice] = React.useState(props.gross);
  const  [date, setDate] = React.useState(props.date);
  const  [error, setError] = React.useState('');
  const  callback  = props.onCancel;
  const today = new Date();

  const handleClose = () => {
    callback(false);
    setOpen(false);
  };

  const onSaveAndUpdate = (e) =>{

    e.preventDefault();

    var chain = '';
    chain = chain + "&id=" + props.id;
    chain = chain +  "&date=" + date;
    chain = chain +  "&price=" + price;

    fetch("/subscriptions/ajax_mandate_edit", {
        method: "POST",
        mode: 'same-origin', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit                            
        body: security.name + '=' + security.token + chain,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "X-Requested-With": 'XMLHttpRequest',
            "X_REQUESTED_WITH": 'xmlhttprequest'
        }
    })
    .then(res => {
        return res.json();
    })
    .then(response => {
        //console.log(data);
        if(response.status === 'OK')
        {
          setOpen(false);
          callback(true);
        }
        else
        {
            setError(response.message);
        }
    }).catch(function(err){
        console.error('Failed to update mandate', err);
        setError('Unable to add plan due to network error. Refresh the page and try again.');
      }); 

  }

  const onUpdatePrice = (e) => {
    setPrice(e.target.value);
  }

  const onDateSet = (e) => {
    setDate(e.target.value);
  }
  

  React.useEffect(() => {
    setOpen(props.state);
  }, [props.state])


  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} maxWidth = {'sm'}>
        <form className={props.classes.container} onSubmit={onSaveAndUpdate}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} danger={(props.danger?props.danger:false)}>
          Edit mandate
        </DialogTitle>
        <DialogContent dividers>
          {
            loading === true && <Loading />
          }
          {
            loading === false && (
              <React.Fragment>
                    <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                        <Grid item xs={12} >
                          <TextField
                              id="datetime-local"
                              label="Next renewal"
                              type="datetime-local"
                              required
                              defaultValue={date}
                              className={props.classes.textField}
                              onChange={onDateSet}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{
                                // min: "2021-02-20T00:00",
                                min: today.toISOString().slice(0, 16)
                              }}
                          />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField name="standardprice" required step={0.01} type="number" value={price} onChange={onUpdatePrice} id="standard-price" label="Standard recurring price" variant="outlined" fullWidth={false} helperText="Enter the ongoing recurring cost. For example, if a pass costs £10.00 enter, 10.00. Allowed characters 0-9"  inputProps={{required:true, pattern:"[1-9]{1,10}[.]{1}[0-9]+", step:0.01}} /> 
                        </Grid>
                        <Grid item xs={12} >
                            <Alert severity="warning">This will only affect future invoices. <b>Pending invoices will NOT be adjusted.</b> Click OK below to continue.</Alert>
                        </Grid>
                        {
                          error && <Grid item xs={12} >
                                      <Alert severity="error">{error}</Alert>
                                  </Grid>
                        }
                        
                    </Grid>


              </React.Fragment>
            )
          }


        </DialogContent>
        <DialogActions>
          <Button onClick={onSaveAndUpdate} color="primary" variant="outlined" type="submit">
                OK
          </Button>        
        </DialogActions>
        </form>              
      </Dialog>
    </div>
  );
  
}

class MandateEditDialog extends Component{
    constructor( props )
    {
        super( props );
      // console.log('TopNav', props);


        this.state = {
            security: props.security,
            date: moment( props.mandate.next ).format('YYYY-MM-DDTHH:mm'),
            gross: props.mandate.gross,
            id: props.mandate.id,
            ready: false,

        };

        this.doneCancel = this.doneCancel.bind(this);        
    }  



    doneCancel( refresh ){
      this.props.onCancel(refresh);
    }

    render()
    {
        
        var classes = this.props.classes;


        return (
            <div>
              <MandateEditDialogWrapper 
                classes={classes} 
                ready={this.state.ready} 
                onCancel={this.doneCancel} 
                security={this.state.security}
                date={this.state.date}
                gross={this.state.gross}
                state={this.props.state}
                id={this.state.id}
                />
            </div>
        );
    }
     
}

export default withStyles(styles)(MandateEditDialog);
