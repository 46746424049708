import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';


import './../core/dashboard.css';


//import Link from '@material-ui/core/Link';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Hidden from '@material-ui/core/Hidden';

import IconButton from '@material-ui/core/IconButton';

import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CodeIcon from '@material-ui/icons/Code';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {reloadData, manageCustomer} from '../redux/actions';
import RefreshIcon from '@material-ui/icons/Refresh';
import CoreDialog  from '../core/dialog'
import CodeDialog  from '../core/code-dialog';
import Loading from '../core/loading';
import MuiMenuItem from "@material-ui/core/MenuItem";

import ScheduleAddPanelV3Manager  from './schedule-add-v3-panel';
import ScheduleAddPanelV4Manager  from './schedule-add-v4-panel';
import QueueIcon from '@material-ui/icons/Queue';


import './schedules.css';
import { red } from '@material-ui/core/colors';

import {v1 as uuidv1} from "uuid";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,
    },
    idCell:{
        width: '8.3333%' //100% divided by 12
    },
    editCell:{
        width: '8.3333%' //100% divided by 12
    }, 
    codeCell:{
        width: '8.3333%' //100% divided by 12
    },  
    deleteCell:{
        width: '8.3333%' //100% divided by 12
    },          
    nameCell:{
        width: '50%' //100% divided by 12 * 6
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    fabEdit:{
        backgroundColor: '#3c8dbc',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#346785',
        }
    },
    fabCode:{
        backgroundColor: '#9e9e9e',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#858585',
        }
    },
    fabDelete:{
        backgroundColor: red[500],
        color: '#fff',
        '&:hover':{
            backgroundColor: red[600],
        }
    },        
    fabIcon:{
        '& .MuiSvgIcon-root':{
            fill: '#fff',
            color: '#fff',
            '&:hover':{
                color: '#ffe',
            }
        }
    }


});


const MenuItem = withStyles({
    root: {
      justifyContent: "flex-start"
    }
  })(MuiMenuItem);

class EditButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('EditButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="editbutton" className={classes.fabEdit} aria-label="Manage customer" onClick={this._handleClick}>
                <EditRoundedIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}

class CodeButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('CodeButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="manage" className={classes.fabCode} aria-label="Manage customer" onClick={this._handleClick}>
                <CodeIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}

class DeleteButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('DeleteButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="delete" className={classes.fabDelete} aria-label="Delete Schedule" onClick={this._handleClick}>
                <DeleteForeverIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}






function mapDispatchToProps(dispatch) {
    return {
        reloadData: spec => dispatch(reloadData(spec)),
        manageCustomer: spec => dispatch(manageCustomer(spec))
    };
  }


  


class SchedulesManagerModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            schedules: props.data.schedules || [],
            categories: props.data.categories,
            questionaires: props.data.questionaires,
            passes: props.data?.passes || [] ,
            subscriptions: props.data?.subscriptions || [] ,
            security: {},
            refresh: false,
            schedule_add: false,
            schedule_version: 4,
            schedule_edit: false,
            schedule_delete: false,
            schedule_focus: null,
            schedule_code: 0,
            edit_data: {},
            loading: false,
            anchor_el: null

            //customer_panel: false, //false
            //customer_id: null // null
        };

        this.state.security = props.security;


        this.onAddScheduleDialogClose = this.onAddScheduleDialogClose.bind(this);
        this.onAddSchedule_v3       = this.onAddSchedule_v3.bind(this);
        this.onAddSchedule_v4       = this.onAddSchedule_v4.bind(this);
        this.onEditSchedule       = this.onEditSchedule.bind(this);
        this.onDeleteSchedule     = this.onDeleteSchedule.bind(this);
        this.onCompleteDelete     = this.onCompleteDelete.bind(this);
        this.onCancelDelete     = this.onCancelDelete.bind(this);
        this.onScheduleCodeClose    = this.onScheduleCodeClose.bind(this);
        this.onCodeSelect       = this.onCodeSelect.bind(this);
        this.onRefresh          = this.onRefresh.bind(this);
        this.handleMenuClose    = this.handleMenuClose.bind(this);
        this.handleMenuOpen     = this.handleMenuOpen.bind(this);
       // console.log("Final TopNav",this.state);
    }

    onEditSchedule(data)
    {
        if(data.v === "1")
        {
            window.location.href = '/schedules/edit_v1/' + data.id;
        }
        else if(data.v === "2")
        {
            window.location.href = '/schedules/edit_v2_1/' + data.id;
        }
        else if(data.v === "3")
        {
            this.setState({schedule_edit: true, edit_data: data, schedule_version: 3});
        }
        else
        {
            this.setState({schedule_edit: true, edit_data: data, schedule_version: 4});
        }
    }

    onDeleteSchedule(data)
    {
        this.setState({schedule_delete: true, schedule_focus: data});
    }

    onCancelDelete()
    {
        this.setState({schedule_delete: false, schedule_focus: {}});

    }


    onCompleteDelete()
    {

        var id = this.state.schedule_focus.id ||  null;
        var _this = this;
        var chain = '';
        chain = chain +  "&id=" + id;
 
        fetch("/schedules/ajax_schedule_delete", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({schedule_delete: false, schedule_focus: {}});
                _this.onRefresh();
            }
            else
            {

                //this.setState({cust_add: false});
            }
        }).catch(function(err){
            _this.setState({schedule_delete: false, schedule_focus: {}});
            console.error('Failed to delete the schedule. Please refresh your page.');
        });      



    }


    handleMenuClose(  )
    {
        this.setState({anchor_el: null});
    }


    handleMenuOpen( event )
    {
        this.setState({anchor_el: event.currentTarget})
    }

    onAddSchedule_v3()
    {
        this.setState({schedule_add: true, schedule_version: 3})
    }
    onAddSchedule_v4()
    {
        this.setState({schedule_add: true, schedule_version: 4})
    }




    onAddScheduleDialogClose( refresh )
    {
        this.setState({schedule_add: false, schedule_edit:false});
        if(refresh)
        {
            this.onRefresh()
        }
    }    
    
    onScheduleCodeClose()
    {
        this.setState({schedule_code: 0, schedule_focus: {}});
    }
    onCodeSelect(data)
    {
        this.setState({schedule_code: parseInt(data.v) , schedule_focus: data});

    }


    onRefresh()
    {
        this.setState({'loading': true});
        fetch("/backenddata/ajax_fetch_schedules", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + '&refresh=1',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(!response.hasOwnProperty("error"))
            {
              let o = {
                'settings': response.data
              };
              console.log("loading ajax", o);
              this.props.reloadData( o );
              this.setState({'loading': false});


            }
            else
            {
               /* handle error */
            }
        }).catch(function(err){
            console.error('Failed to load the schedule. Please refresh your page.');
        });     
    }



  

    render()
    {
        var classes = this.props.classes;
        return (
            <div className={classes.root}>


                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        {window.vocab.topbar}
                    </Typography>
                    <div className="space-right">
                        <Button  variant="outlined" className="button-green space-right"  startIcon={<QueueIcon />} onClick={this.onAddSchedule_v4}>Schedule</Button>
                    </div>



                    <Button  variant="outlined" color="primary" onClick={this.onRefresh}><RefreshIcon/>Refresh</Button>
                    </Toolbar>
                </AppBar>


                <Grid container direction="row" justifyContent="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >

                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <Grid container justify="flex-start" alignItems="stretch">
                                        <Grid item xs={12} md={12}>
                                            <h2>Active schedules</h2>   
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TableContainer>                                
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <Hidden smDown>
                                                                <TableCell className={classes.idCell}>
                                                                    ID
                                                                </TableCell> 
                                                            </Hidden>
                                                            <TableCell className={classes.nameCell}>
                                                                Name
                                                            </TableCell> 
                                                            <TableCell align="center">
                                                                Version
                                                            </TableCell> 
                                                            <TableCell align="center">
                                                                Edit
                                                            </TableCell> 
                                                            <TableCell align="center">
                                                                Code
                                                            </TableCell>   
                                                            <TableCell align="center">
                                                                Delete
                                                            </TableCell>                                                                                                                                                                           
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {this.state.loading && (
                                                        <TableRow key={uuidv1()}>
                                                            <Hidden smDown>
                                                                <TableCell className={classes.nameCell} colSpan={5}>
                                                                    <Loading />
                                                                </TableCell> 
                                                            </Hidden>
                                                            <Hidden mdUp>
                                                                <TableCell className={classes.nameCell} colSpan={4}>
                                                                    <Loading />
                                                                </TableCell> 
                                                            </Hidden>
                                                        </TableRow>
                                                    )}
                                                    {this.state.loading === false && this.state.schedules.map(el => (
                                                        <TableRow key={uuidv1()}>
                                                            <Hidden smDown>
                                                                <TableCell className={classes.idCell}>
                                                                    {el.id}
                                                                </TableCell>  
                                                            </Hidden>                        
                                                            <TableCell className={classes.nameCell}>
                                                                {el.name}
                                                            </TableCell> 
                                                            <TableCell align="center">
                                                                {el.v}
                                                            </TableCell>                                                             
                                                            <TableCell className={classes.editCell} align="center">
                                                                <EditButton classes={classes} data={el} callback={this.onEditSchedule} />
                                                            </TableCell>                                         
                                                            <TableCell className={classes.codeCell} align="center">
                                                                <CodeButton classes={classes} data={el} callback={this.onCodeSelect} />
                                                            </TableCell>                                       
                                                            <TableCell className={classes.deleteCell} align="center">
                                                                <DeleteButton classes={classes} data={el} callback={this.onDeleteSchedule} />
                                                            </TableCell> 
                                                        </TableRow>
                                                        ))}
                                                    </TableBody>

                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>

                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>



                {this.state.schedule_add === true && this.state.schedule_version === 3 && (
                    <ScheduleAddPanelV3Manager open={true} closeCustomerPanel={this.onAddScheduleDialogClose} security={this.state.security} categories={this.state.categories} data={{id: null, name: null, n: null , booknow_active: true, show_future_block_date: true, color: null , categories:[]}} />
                )}
                {this.state.schedule_add === true && this.state.schedule_version === 4 && (
                    <ScheduleAddPanelV4Manager open={true} closeCustomerPanel={this.onAddScheduleDialogClose} security={this.state.security} categories={this.state.categories} data={{id: null, name: null, n: null , booknow_active: true, show_future_block_date: true, event_book_multiples: true, event_book_others: true, color: null , categories:[]}} />
                )}                
                {this.state.schedule_edit === true && this.state.schedule_version === 3 && (
                    <ScheduleAddPanelV3Manager open={true} closeCustomerPanel={this.onAddScheduleDialogClose} security={this.state.security} categories={this.state.categories} data={this.state.edit_data} />
                )}
                {this.state.schedule_edit === true && this.state.schedule_version === 4 && (
                    <ScheduleAddPanelV4Manager 
                        open={true} 
                        closeCustomerPanel={this.onAddScheduleDialogClose} 
                        security={this.state.security} 
                        categories={this.state.categories} 
                        questionaires={this.state.questionaires} 
                        passes={this.state.passes}
                        subscriptions={this.state.subscriptions}
                        data={this.state.edit_data}
                    
                    />
                )}

                {this.state.schedule_delete === true && (<CoreDialog title="Delete Schedule" body="You are about to delete this schedule. Do you want to continue?" state={true} danger={true} onCancel={this.onCancelDelete} actions={[{label:"OK", action:this.onCompleteDelete}]}  />)}
                {this.state.schedule_code === 1 && (<CodeDialog callback={this.onScheduleCodeClose} data={this.state.schedule_focus} 
                                                        options={[{name:'Schedule page link', type:'address'}]}  /> )}

                {this.state.schedule_code === 2 && (<CodeDialog callback={this.onScheduleCodeClose} data={this.state.schedule_focus} 
                                                        options={[{name:'Schedule page link', type:'address'}, {name:'Embeddable schedule iframe', type:'iframe'} ]}  /> )}

                {this.state.schedule_code === 3 && (<CodeDialog callback={this.onScheduleCodeClose} data={this.state.schedule_focus} 
                                                        options={[
                                                            {name:'Embeddable API driven schedule', type:'api_schedule_embed_v3'},
                                                            {name:'Hosted page', type:'hosted_address'},
                                                            ]}  /> )}

                {this.state.schedule_code >= 4 && (<CodeDialog callback={this.onScheduleCodeClose} data={this.state.schedule_focus} 
                                                        options={[
                                                            {name:'Embeddable API driven schedule', type:'api_schedule_embed_v4'},
                                                            {name:'Hosted page', type:'hosted_address_vy_x'},
                                                            ]}  /> )}

            </div>            
        );
    }

};



const SchedulesManager = connect(null, mapDispatchToProps)(SchedulesManagerModule);
export default withStyles(styles)(SchedulesManager);
