import React, { Component } from 'react';
import { connect } from "react-redux";
import MandateDetailDrawer from './mandateDetailDrawer';

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';


import '../core/dashboard.css';


//import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import { red, orange, grey } from '@material-ui/core/colors';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {setStep, reloadData, setCloneState} from '../redux/actions';
import 'react-dates/initialize';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CoreDialog  from '../core/dialog'
import Alert from '@material-ui/lab/Alert';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import EditRoundedIcon from '@material-ui/icons/EditRounded';


import 'react-dates/lib/css/_datepicker.css';
import './subscriptions.css';
import MandateEditDialog from './MandateEditDialog';


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,       
    },          
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    nameCell:{
        width: '25%',
    },
    fabEdit:{
        backgroundColor: '#3c8dbc',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#346785',
        }
    },
    fabInfo:{
        backgroundColor: 'rgba(83, 120, 138, 1)',
        color: '#fff',
        '&:hover':{
            backgroundColor: 'rgba(83, 120, 138, 0.5)',
        }
    },    

    fabWarning:{
        backgroundColor: grey[500],
        color: '#fff',
        '&:hover':{
            backgroundColor: grey[600],
        }
    }, 
    fabInactive:{
        backgroundColor: grey[300],
        color: '#fff',
        '&:hover':{
            backgroundColor: grey[300],
        }
    },    
    fabGo:{
        backgroundColor: orange[500],
        color: '#fff',
        '&:hover':{
            backgroundColor: orange[600],
        }
    },      
    fabDelete:{
        backgroundColor: red[500],
        color: '#fff',
        '&:hover':{
            backgroundColor: red[600],
        },
        '&.Mui-disabled':{
          backgroundColor: 'rgba(0,0,0,0.1)',
        },
        '&.Mui-disabled:hover':{
          cursor: 'not-allowed',
        }
    },
    fabClone:{
        backgroundColor: '#FFA500',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#ea9700',
        }
    },             
    fabIcon:{
        '& .MuiSvgIcon-root':{
            fill: '#fff',
            color: '#fff',
            '&:hover':{
                color: '#ffe',
            }
        }
    }, 
    fabAction:{
        backgroundColor: '#FFA500',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#ea9700',
        }
    },
    alertSpace:{
        textAlign: 'center',
    } 

});

class InfoButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('InfoButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="editbutton" className={classes.fabInfo} aria-label="Manage customer" onClick={this._handleClick}>
                <ArrowForwardIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}

class ReactivateButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('CodeButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="Reactivate" className={classes.fabWarning} aria-label="Reactivate subscription" onClick={this._handleClick}>
                <PlayCircleOutlineIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}


class EditButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('Suspend', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Suspend button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="suspend" className={classes.fabEdit} aria-label="Pause subscription" onClick={this._handleClick}>
                <EditRoundedIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}


class SuspendButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('Suspend', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Suspend button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="suspend" className={classes.fabGo} aria-label="Pause subscription" onClick={this._handleClick}>
                <PauseCircleOutlineIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}

class InactiveButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        }

        console.log('InactiveButton', this.state);
    }


    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="Inactive" className={classes.fabInactive} aria-label="Inactive">
                <WarningIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}

class DeleteButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback,
            disabled: props.disable
        }

        console.log('DeleteButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="delete" className={classes.fabDelete} aria-label="Delete event" disabled={this.state.disabled} onClick={this._handleClick}>
                <DeleteForeverIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}



function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        reloadData: spec => dispatch(reloadData(spec)),
        setCloneState: spec => dispatch(setCloneState(spec))
    };
  }


  


class MandateManagerModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            mandates: props.data.mandates || [],
            focusedInput: null,
            security: {},
            loading: false,
            detail_draw_show: false,
            reload: props.refresh,
            mandate_focus: null,
            prompt_suspend: false,
            prompt_unsuspend: false,
            prompt_cancel: false,
            prompt_edit: false,
            error: '',
        };


        this.state.security = props.security;
        this.onMandateDetail = this.onMandateDetail.bind(this);
        this.onDrawerClose = this.onDrawerClose.bind(this);
        this.onSubscriptionSuspend = this.onSubscriptionSuspend.bind(this);
        this.onSubscriptionUnSuspend = this.onSubscriptionUnSuspend.bind(this);
        this.onDoSuspendCancel = this.onDoSuspendCancel.bind(this);
        this.onToggleSuspendNow = this.onToggleSuspendNow.bind(this);
        this.onMandateDelete = this.onMandateDelete.bind(this);
        this.onCancelSubscriptionPlanNow = this.onCancelSubscriptionPlanNow.bind(this);
        this.onSubscriptionEdit = this.onSubscriptionEdit.bind(this);
        this.onMandateEditClose = this.onMandateEditClose.bind(this);

    }



    

    onDrawerClose()
    {
        this.setState({'detail_draw_show': false});
    }

    onMandateDetail( o )
    {
        this.setState({'detail_draw_show': true, 'mandate_focus': o});
    }


    onSubscriptionSuspend(o)
    {
        this.setState({'prompt_suspend': true, 'mandate_focus': o});

    }


    onSubscriptionEdit(o)
    {
        this.setState({'prompt_edit': true, 'mandate_focus': o});
    }

    onSubscriptionUnSuspend(o)
    {
        this.setState({'prompt_unsuspend': true, 'mandate_focus': o});

    }

    onMandateDelete(o)
    {
        this.setState({'prompt_cancel': true, 'mandate_focus': o});

    }
   

    onDoSuspendCancel()
    {
        this.setState({'prompt_suspend': false, 'prompt_unsuspend': false, 'prompt_cancel': false, 'mandate_focus': null});
    }

    onMandateEditClose(reload)
    {
        this.setState({'prompt_edit': false});
        if(reload)
            window.location.reload();
    }


    onToggleSuspendNow()
    {
        var id = this.state.mandate_focus.id ||  null;
        var chain = '';
        chain = chain +  "&id=" + id;
        var _this = this;
        fetch("/subscriptions/ajax_toggle_mandate_pause_state", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({prompt_suspend: false, mandate_focus: null}, function()
                {
                    window.location.reload();
                });
            }
            else
            {
                _this.setState({prompt_suspend: false, prompt_unsuspend: false, error: response.error});
            }
        }).catch(function(err){
            _this.setState({prompt_suspend: false, prompt_unsuspend: false, error: 'Network error - unable to communicte with the server.'});
            console.error('Failed to delete the pass', err);
        });   
    }
  

    onCancelSubscriptionPlanNow()
    {
        var id = this.state.mandate_focus.id ||  null;
        var chain = '';
        chain = chain +  "&id=" + id;
        var _this = this;
        fetch("/subscriptions/do_ajax_cancel_subscription", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({prompt_cancel: false, mandate_focus: null}, function()
                {
                    window.location.reload();
                });
            }
            else
            {
                _this.setState({prompt_cancel: false, prompt_unsuspend: false, error: response.error});
            }
        }).catch(function(err){
            _this.setState({prompt_cancel: false, prompt_unsuspend: false, error: 'Network error - unable to communicte with the server.'});
            console.error('Failed to delete the mandate', err);
        });   
    }
  
    render()
    {
        var classes = this.props.classes;


        return (
            <div className={classes.root}>


                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        {window.vocab.topbar}
                    </Typography>
                    </Toolbar>
                </AppBar>


                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >

                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <Grid container justify="flex-start" alignItems="stretch">
                                        <Grid item xs={12} md={12} className={classes.alertSpace}>
                                            {this.state.error.length > 0 && (
                                                  <Alert severity="error">Opps something went wrong: {this.state.error}</Alert>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="left">
                                                                ID
                                                            </TableCell>
                                                            <TableCell align="left" className={classes.nameCell}>
                                                                Name
                                                            </TableCell> 
                                                            <TableCell align="left">
                                                                Customer
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                Type
                                                            </TableCell> 
                                                            <TableCell align="center">
                                                                Invoices
                                                            </TableCell>  
                                                            <TableCell align="center">
                                                                Status
                                                            </TableCell> 
                                                            <TableCell align="center">
                                                                Detail
                                                            </TableCell>  
                                                            <TableCell align="center">
                                                                Edit
                                                            </TableCell>  
                                                            <TableCell align="center">
                                                                Pause / Resume
                                                            </TableCell> 
                                                            <TableCell align="center">
                                                                Cancel
                                                            </TableCell>                                                                                                                    
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.mandates.length > 0 && this.state.mandates.map(el => (
                                                            <TableRow key={el.id}>
                                                                <TableCell align="left">
                                                                    {el.id}
                                                                </TableCell>
                                                                <TableCell align="left" className={classes.nameCell}>
                                                                    {el.name}
                                                                </TableCell> 
                                                                <TableCell align="left">
                                                                    {el.customer_firstname} {el.customer_lastname}
                                                                </TableCell> 
                                                                <TableCell align="center">
                                                                    {el.type}
                                                                </TableCell> 
                                                                <TableCell align="center">
                                                                    {el.invoices.length}
                                                                </TableCell>   
                                                                <TableCell align="center">
                                                                    {el.status}
                                                                </TableCell>                                                                                                                                 
                                                                <TableCell align="center">
                                                                    <InfoButton classes={classes} callback={this.onMandateDetail} data={el}/>
                                                                </TableCell> 

                                                                <TableCell align="center">
                                                                    {el.status === 'Active' && (
                                                                        <EditButton classes={classes}  data={el}  callback={this.onSubscriptionEdit}/>
                                                                    )}
                                                                    {el.status === 'Paused' && (
                                                                        <InactiveButton classes={classes} />
                                                                        )}
                                                                    {el.status !== 'Paused' && el.status !== 'Active' && (
                                                                        <InactiveButton classes={classes} />
                                                                    )}                                                                      
                                                                                                                                       
                                                                </TableCell>  
                                                                <TableCell align="center">
                                                                    {el.status === 'Active' && (
                                                                        <SuspendButton classes={classes}  data={el}  callback={this.onSubscriptionSuspend}/>
                                                                    )}
                                                                    {el.status === 'Paused' && (
                                                                        <ReactivateButton classes={classes}  data={el}  callback={this.onSubscriptionUnSuspend}/>
                                                                    )}
                                                                    {el.status !== 'Paused' && el.status !== 'Active' && (
                                                                        <InactiveButton classes={classes} />
                                                                    )}                                                                      
                                                                                                                                       
                                                                </TableCell>  
                                                                <TableCell align="center">
                                                                    <DeleteButton classes={classes} disable={el.status === 'Deleted' || el.status === 'Deactivated'} callback={this.onMandateDelete} data={el}/>
                                                                </TableCell>                                                                 
                                                            </TableRow>
                                                        ))}
                                                        {this.state.mandates.length === 0 && (
                                                            <TableRow>
                                                                <TableCell align="center" colSpan={8}>
                                                                    No mandates
                                                                </TableCell>
                                                            </TableRow>
                                                        )}                                                        

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>                                    

                </Grid>
                {this.state.detail_draw_show === true && (
                    <MandateDetailDrawer where={{'top': false, 'bottom': false, 'left': false, 'right': true}} mandate={this.state.mandate_focus} onClose={this.onDrawerClose}/>
                )}
                {this.state.prompt_suspend === true && (
                    <CoreDialog title="Pause Mandate" body="Are you sure that you want to pause this mandate?<br/><br/><b>Important:</b> Associated services will not be automatically renewed whilst this mandate is paused" state={true} danger={true} onCancel={this.onDoSuspendCancel} actions={[{label:"Yes, pause mandate", action:this.onToggleSuspendNow}]}  />
                )}  
                {this.state.prompt_unsuspend === true && (
                    <CoreDialog title="Resume Mandate" body="Are you sure that you want to resume this mandate?<br/><br/><b>Important:</b> Associated services will re-commence immeadiately" state={true} danger={true} onCancel={this.onDoSuspendCancel} actions={[{label:"Yes, resume mandate", action:this.onToggleSuspendNow}]}  />
                )}  
                {this.state.prompt_cancel === true && (
                    <CoreDialog title="Cancel Mandate" 
                    body='<br/><b><i>Warning this cannot be undone! Please consider using pause instead</i><br/><br/><br/></b>Are you sure that you wish to continue? If you delete this mandate, auto renewal of the associated services will stop.<br/><br/>' state={true} danger={true} onCancel={this.onDoSuspendCancel} actions={[{label:"Yes, cancel mandate", action:this.onCancelSubscriptionPlanNow}]}  />
                )}  

                {
                    this.state.prompt_edit === true && 
                    <MandateEditDialog state={true} danger={false} onCancel={this.onMandateEditClose} mandate={this.state.mandate_focus} security={this.state.security} />
                }

                
            </div>            
        );
    }

};



const MandateManager = connect(null, mapDispatchToProps)(MandateManagerModule);
export default withStyles(styles)(MandateManager);
