import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CustomerBasicPanel from './basic-panel';
import EventsCustomerSalesTablePanel from './event-salestable-panel';
import PassCustomerSalesTablePanel from './pass-salestable-panel';
import SubscriptionCustomerSalesTablePanel from './subscription-salestable-panel';
import QuestionaireCustomerTablePanel from './questionaire-table-panel';
import Paper from '@material-ui/core/Paper';

import Skeleton from '@material-ui/lab/Skeleton';
import VideoSalesTablePanel from './Videos/video-salestable-panel';


function Loading(){
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
         {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,

  },
  tabs: {
    paddingTop: "0px",
    textAlign:"left"
  },
  tabpanel:{
    width: '100%'
  }
}));


 


export default function CustomerTabs( props ) {
  const { customer, customers, data, security, clsses, events, availablepasses, transactions, loading, onRefresh, subscriptions, passes, questionaires, cards, onArchive, questionnaire_templates, videoenabled, videos, videotransactions} = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(parseInt(localStorage.getItem('rv-customer-panel') || 0 ));
  const [width, setWidth] = React.useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  React.useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isFullWidth = () => {
      if(width >= 600)
        return true;
      return false;
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('rv-customer-panel', newValue);
  };

  return (
    <div className={classes.root}>
      <Paper square>

      <Tabs
        indicatorColor="primary"
        textColor="primary"
        value={value}
        onChange={handleChange}
        aria-label="reservie customer actions"
        variant={isFullWidth() ? "fullWidth" : "scrollable"}
        scrollButtons="on"
        className={classes.tabs}
      >
        <Tab label="Bookings" {...a11yProps(0)} />
        <Tab label="Passes" {...a11yProps(1)} />
        <Tab label="Subscriptions" {...a11yProps(2)} />
        <Tab label="Questionnaires" {...a11yProps(3)} />
        <Tab label="Videos" disabled={!videoenabled} {...a11yProps(4)} />
        <Tab label="Details" {...a11yProps(5)} />
      </Tabs>
      </Paper>

      <TabPanel value={value} index={0}>
        {
            loading === false && <EventsCustomerSalesTablePanel classes={clsses}   customer={customer} transactions={transactions} events={events} passes={passes} security={security} onRefresh={onRefresh} fullwidth={isFullWidth()}/>
        }
        {
          loading === true &&  <Loading />
        }   
      </TabPanel>
      <TabPanel value={value} index={1}>
        {
            loading === false &&
            <PassCustomerSalesTablePanel classes={clsses}   customer={customer} transactions={passes} availablepasses={availablepasses} security={security} cards={cards} onRefresh={onRefresh}  fullwidth={isFullWidth()}/>
        }
        {
            loading === true &&  <Loading />  
        } 
      </TabPanel>
      <TabPanel value={value} index={2}>
        {
            loading === false &&                                                
            <SubscriptionCustomerSalesTablePanel classes={clsses}   customer={customer} subscriptions={subscriptions} security={security}  fullwidth={isFullWidth()}/>
        }
        {
            loading === true &&  <Loading />
        }                                                                                                

      </TabPanel>
      <TabPanel value={value} index={3}>
      {
          loading === false &&                                                  
          <QuestionaireCustomerTablePanel classes={clsses}  customer={customer} questionaires={questionaires}   fullwidth={isFullWidth()} questionnaire_templates={questionnaire_templates} refresh={onRefresh}/>
      }
      {
          loading === true &&  <Loading />
      } 
      </TabPanel>
      <TabPanel value={value} index={4}>
        {
            loading === false && <VideoSalesTablePanel classes={clsses}   customer={customer} transactions={transactions} videos={videos} videotransactions={videotransactions} passes={passes} security={security} onRefresh={onRefresh} fullwidth={isFullWidth()}/>
        }
        {
          loading === true &&  <Loading />
        }   
      </TabPanel>
      <TabPanel value={value} index={5} className={classes.tabpanel}>
          <CustomerBasicPanel  data={data} customer={customer} customers={customers} security={security}  onRefresh={onRefresh} classes={clsses} onArchive={onArchive} />
      </TabPanel>

    </div>
  );
}
