
import { 
        ACTION_TYPE_LOAD_DATA,
        ACTION_TYPE_RELOAD_DATA,
        ACTION_TYPE_MANAGE_CUSTOMER,
        ACTION_TYPE_MANAGE_CUSTOMER_CLOSE_PANEL,
        ACTION_TYPE_SET_STATE,
        ACTION_TYPE_REWIND_STATE,
        ACTION_TYPE_SET_CLONE_STATE,

        EVENT_APP_CACHE_MODE_PHYSICAL,
        EVENT_APP_CACHE_MODE_VIRTUAL,
        EVENT_APP_CACHE_STATE_DEFAULT,
        EVENT_APP_CACHE_STATE_WIZARD_0,
        EVENT_APP_CACHE_STATE_WIZARD_1,
        EVENT_APP_CACHE_STATE_WIZARD_2,
        EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP,
        EVENT_APP_CACHE_STATE_WIZARD_2_A,
        EVENT_APP_CACHE_STATE_WIZARD_2_B,
        EVENT_APP_CACHE_STATE_WIZARD_2_C,
        EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE,
        EVENT_APP_CACHE_STATE_WIZARD_2_MANUALLY_ADJUST_DATES,
        EVENT_APP_CACHE_STATE_WIZARD_3,
        EVENT_APP_CACHE_STATE_WIZARD_4,
        EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT,
        EVENT_APP_CACHE_STATE_WIZARD_4_DISCOUNTS,
        EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS,
        EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES,
        EVENT_APP_CACHE_STATE_WIZARD_SUMMARY,

        EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL,
        EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_WEEKLY,
        EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_MONTHLY,

        EVENT_APP_CACHE_STATE_CLONE_SELECT_MODE,
        EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE,
        EVENT_APP_CACHE_STATE_CLONE_SETUP_VIRTUAL,
        EVENT_APP_CACHE_STATE_CLONE_MANUAL,
        EVENT_APP_CACHE_STATE_CLONE_WEEKLY,
        EVENT_APP_CACHE_STATE_CLONE_MONTHLY,

        EVENT_APP_CACHE_STATE_CLONE_SAVE,
        
        EVENT_APP_CACHE_MODE_NOT_SET,

    
        EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES,
        EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD,


    }  from './types';

import {CUSTOMER_APP_CACHE_TEMPLATE, EVENTS_APP_CACHE_TEMPLATE} from './templates';    

import {
    VIEW_TYPE_NONE,
} from './types';   
//import Moment from 'react-moment';
//import 'moment-timezone';

const initialState = {
    settings: { view: VIEW_TYPE_NONE },
    data: {},
    security: { name: '', token:''},
    app_cache: {},
    refresh_state: false
};



function rootReducer(state = initialState, action) {
    console.log('reducer', action);
    if(action.type === ACTION_TYPE_LOAD_DATA)
    {
        let x = JSON.parse(JSON.stringify(state));
        console.log('action', action);
        x.settings.view = action.payload.settings.view;
        x.data = action.payload.settings.data;
        x.security.name = action.payload.settings.security_name;
        x.security.token = action.payload.settings.security_value;
        x.app_cache      = action.payload.app_cache;
        x.app_cache.refresh = false;
        console.log('Initial load state', x);
        return x;
    }
    else if(action.type === ACTION_TYPE_RELOAD_DATA)
    {
        //let x = JSON.parse(JSON.stringify(state));
        let x = Object.assign({}, state);        
        console.log('action', action);
        x.data = action.payload.settings;
        x.app_cache.refresh = false;
        console.log('state', x);
        return x;
    }
    else if(action.type === ACTION_TYPE_MANAGE_CUSTOMER)
    {
        let x = JSON.parse(JSON.stringify(state));

        
        if(x.app_cache.customer.manage === true && action.payload.lock)
        {
            return state;
        }
        
        let customer = Object.assign({}, action.payload.customer);
        x.app_cache = Object.assign({}, CUSTOMER_APP_CACHE_TEMPLATE);
        x.app_cache.customer.id = customer.id;
        x.app_cache.customer.name = customer.name;
        x.app_cache.customer.firstname = customer.firstname;
        x.app_cache.customer.lastname = customer.lastname;
        x.app_cache.customer.email = customer.email;
        x.app_cache.customer.mobile = customer.mobile;
        x.app_cache.customer.manage = true;
        x.app_cache.customer.loading = false;
        x.refresh_state = !state.refresh_state;
        //console.log('state', x);
        return x;
    }
    else if(action.type === ACTION_TYPE_MANAGE_CUSTOMER_CLOSE_PANEL)
    {
        let x = JSON.parse(JSON.stringify(state));
        console.log('action', action);
        x.app_cache = CUSTOMER_APP_CACHE_TEMPLATE;
        x.app_cache.customer.manage = false;
        x.refresh_state = !state.refresh_state;
        x.data.view = false;
        console.log('state', x);
        return x;
    }  
    else if(action.type === ACTION_TYPE_SET_STATE)
    {
        console.log('action types', action.type);
        switch(action.payload.state)
        {


            case EVENT_APP_CACHE_STATE_DEFAULT:
            {
                //let x = JSON.parse(JSON.stringify(state));
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache          =  Object.assign({}, EVENTS_APP_CACHE_TEMPLATE);
                x.app_cache.previous = EVENT_APP_CACHE_STATE_DEFAULT;
                x.app_cache.state     = EVENT_APP_CACHE_STATE_WIZARD_0;
                x.app_cache.edit      = false;
                if(action.payload.scheduling.create !== true)
                {
                    x.app_cache.edit = true;
                    x.app_cache.id = action.payload.scheduling.data.id;
                    x.app_cache.basic = action.payload.scheduling.data.basic;
                    x.app_cache.scheduling = action.payload.scheduling.data.scheduling;
                    x.app_cache.payment = action.payload.scheduling.data.payment;
                    x.app_cache.actions = action.payload.scheduling.data.actions;
                    x.app_cache.properties = action.payload.scheduling.data.properties;
                    x.app_cache.virtual = action.payload.scheduling.data.virtual;
                    x.app_cache.mode = action.payload.scheduling.data.mode;
                    if(x.app_cache.mode === EVENT_APP_CACHE_MODE_NOT_SET)
                    {
                        x.app_cache.state     = EVENT_APP_CACHE_STATE_WIZARD_0;

                    }
                    else
                    {
                        x.app_cache.state     = EVENT_APP_CACHE_STATE_WIZARD_1;

                    }
                    console.log(x);

                }
                x.refresh_state = !state.refresh_state;
                console.log('EVENT_APP_CACHE_STATE_DEFAULT', x);
                return x;
            }

            case EVENT_APP_CACHE_STATE_WIZARD_0:
            {
//                let x = Object.assign({}, state);
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);
                //x.app_cache =  Object.assign({}, EVENTS_APP_CACHE_TEMPLATE);                ;
                x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_0;
        
                if(action.payload.mode === EVENT_APP_CACHE_MODE_PHYSICAL)
                {
                    x.app_cache.mode = EVENT_APP_CACHE_MODE_PHYSICAL;
                    x.app_cache.virtual.mode = 'none';
                    x.app_cache.virtual.configuration.type = 'none';
                    x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_1;
                }
                else if(action.payload.mode === EVENT_APP_CACHE_MODE_VIRTUAL)
                {
                    x.app_cache.mode = EVENT_APP_CACHE_MODE_VIRTUAL;
                    x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_1;
                    x.app_cache.virtual.mode = 'zoom';
                    x.app_cache.virtual.configuration.type = 'zoom';
                }
                x.refresh_state = !state.refresh_state;
                return x;
            }
            break;
            case EVENT_APP_CACHE_STATE_WIZARD_1:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);
                x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_1;

                if(state.app_cache.mode === EVENT_APP_CACHE_MODE_PHYSICAL)
                {
                    x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2;
                }
                else if(state.app_cache.mode === EVENT_APP_CACHE_MODE_VIRTUAL)
                {
                   // x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2;
                   x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP;                  
                }
                x.app_cache.basic = action.payload.basic;
                x.refresh_state = !state.refresh_state;
                console.log('Setting state', x);
                return x;
            }
            break; 
  
          
            
            case EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2;
                x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP;
                x.app_cache.virtual = action.payload.virtual;
      
                x.refresh_state = !state.refresh_state;
                console.log('Setting state', x);
                return x;   
            }

            case EVENT_APP_CACHE_STATE_WIZARD_2:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);

    
                switch(action.payload.scheduling.mode)
                {
                    case EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL:
                    {
                        
                        x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2_A;
                        x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_2;
                        x.app_cache.scheduling.mode = action.payload.scheduling.mode;
                        x.refresh_state = !state.refresh_state;
                        console.log('Setting state', x);
                        return x;                        
                    }
                    case EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_WEEKLY:
                    {
                        if(x.app_cache.scheduling.configuration.dates.length > 0)
                        {
                            x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2_MANUALLY_ADJUST_DATES;
                        }
                        else
                        {
                            x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2_B;
                        }


                        x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_2;
                        x.app_cache.scheduling.mode = action.payload.scheduling.mode;
                        x.refresh_state = !state.refresh_state;
                        console.log('Setting state', x);
                        return x;                         
                        
                    }
                    break;
                    case EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_MONTHLY:
                    {
                        if(x.app_cache.scheduling.configuration.dates.length > 0)
                        {
                            x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2_MANUALLY_ADJUST_DATES;
                        }
                        else
                        {
                            x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2_C;
                        }                        
                        x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_2;
                        x.app_cache.scheduling.mode = action.payload.scheduling.mode;
                        x.refresh_state = !state.refresh_state;
                        console.log('Setting state', x);
                        return x;                         
                        
                    }
                    break;
                }
         
            }
            break;
            
            case EVENT_APP_CACHE_STATE_WIZARD_2_MANUALLY_ADJUST_DATES:
            {
                let x = JSON.parse(JSON.stringify(state));
                switch(x.app_cache.scheduling.mode)
                {
                    case EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_WEEKLY:
                    {
                        x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2_B;
                        x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_2;
                        x.app_cache.scheduling.configuration.dates = action.payload.dates;
                        x.refresh_state = !state.refresh_state;
                        console.log('Setting state', x);
                        return x;                         
                        
                    }
                    break;
                    case EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_MONTHLY:
                    {
                        
                        x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2_C;
                        x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_2;
                        x.app_cache.scheduling.configuration.dates = action.payload.dates;
                        x.refresh_state = !state.refresh_state;
                        console.log('Setting state', x);
                        return x;                         
                        
                    }
                    break;

                }

                
            }
            break;



            case EVENT_APP_CACHE_STATE_WIZARD_2_A:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);

                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE;
                x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_2_A;
                x.app_cache.scheduling.configuration.dates = action.payload.dates;
                x.refresh_state = !state.refresh_state;
                console.log('Setting state', x);
                return x;                         

            }
            case EVENT_APP_CACHE_STATE_WIZARD_2_B:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);

                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE;
                x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_2_B;
                var book_type = x.app_cache.scheduling.configuration.book_type;
                var dates = x.app_cache.scheduling.configuration.dates;
                x.app_cache.scheduling.configuration = action.payload.configuration;
                x.app_cache.scheduling.configuration.dates = dates;
                x.app_cache.scheduling.configuration.book_type = book_type;
                x.refresh_state = !state.refresh_state;
                console.log('Setting state', x);
                return x;                         

            } 
            
            case EVENT_APP_CACHE_STATE_WIZARD_2_C:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);

                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE;
                x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_2_C;
                var book_type = x.app_cache.scheduling.configuration.book_type;
                var dates = x.app_cache.scheduling.configuration.dates;
                x.app_cache.scheduling.configuration = action.payload.configuration;
                x.app_cache.scheduling.configuration.dates = dates;
                x.app_cache.scheduling.configuration.book_type = book_type;                
                x.refresh_state = !state.refresh_state;
                console.log('Setting state', x);
                return x;                         

            } 
            
            case EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);

                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_3;
                x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE;
                x.app_cache.scheduling.configuration.book_type = action.payload.book_type;
                x.refresh_state = !state.refresh_state;
                console.log('Setting state', x);
                return x;                         

            }
            case EVENT_APP_CACHE_STATE_WIZARD_3:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);
        
                switch(action.payload.mode)
                {
                    case EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES:
                    case EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD | EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES:
                    case EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD:
                    {
                      
                        x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_3;
                        x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_4;
                        x.app_cache.payment.mode = action.payload.mode;
                        x.refresh_state = !state.refresh_state;
                        console.log('Setting state', x);
                        return x;                        
                    }
  
                }

            }
            break;              
            case EVENT_APP_CACHE_STATE_WIZARD_4:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);
                x.app_cache.previous                      = EVENT_APP_CACHE_STATE_WIZARD_4;
                x.app_cache.payment.configuration.options = action.payload.options;
                x.app_cache.payment.configuration.max     = action.payload.max;
                //x.app_cache.payload.configuration.overridemax = action.payload.overridemax;
                x.app_cache.payment.configuration.overridemax     = action.payload.overridemax;

                switch(state.app_cache.payment.mode)
                {
                    case EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES:
                    {
                        x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT;
                        x.refresh_state = !state.refresh_state;
                        console.log('Setting state', x);
                        return x;                          
                    }

                    case EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD | EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES:
                    {
                      
                        x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_4_DISCOUNTS;
                        x.refresh_state = !state.refresh_state;
                        console.log('Setting state', x);
                        return x;  
                      
                    }

                    case EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD:
                    {
                        x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_4_DISCOUNTS;
                        x.refresh_state = !state.refresh_state;
                        console.log('Setting state', x);
                        return x;  
                    }

                    default:
                    {
                        console.error('unsupport payment mode', action.payload.mode);
                    }

  
                }                
                      

            }
            break; 

            case EVENT_APP_CACHE_STATE_WIZARD_4_DISCOUNTS:
            {
                    let x = JSON.parse(JSON.stringify(state));
                    console.log('action', action);
                    x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_4;
                    x.app_cache.payment.discounts.vouchers = action.payload.options.discounts;
                    x.app_cache.payment.discounts.payment_rules = action.payload.options.payment_rules;
    
                    switch(state.app_cache.payment.mode)
                    {
                        case EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD | EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES:
                        {
                          
                            x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT;
                            x.refresh_state = !state.refresh_state;
                            console.log('Setting state', x);
                            return x;  
                          
                        }
    
                        case EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD:
                        {
                            x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS;
                            x.refresh_state = !state.refresh_state;
                            console.log('Setting state', x);
                            return x;  
                        }
    
      
                    }                
                          
    
                }
                break; 

            case EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);
                x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT;
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS;
                x.app_cache.payment.passes = action.payload.passes;
                x.refresh_state = !state.refresh_state;
                return x;                        

            }
            
            case EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS', action);
                x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS;
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES;
                x.app_cache.actions.purchase = action.payload.configuration.purchase;
                x.app_cache.actions.cancel = action.payload.configuration.cancel;
                x.app_cache.actions.available = action.payload.configuration.available;
                x.app_cache.actions.reminder = action.payload.configuration.reminder;
                x.refresh_state = !state.refresh_state;
                return x;                    
            }

            case EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES', action);
                x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES;
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_SUMMARY;
                x.app_cache.properties.facilitators = action.payload.configuration.facilitators;
                x.app_cache.properties.terms = action.payload.configuration.terms;
                x.app_cache.properties.categories = action.payload.configuration.categories;
                x.app_cache.properties.location = action.payload.configuration.location;
                x.refresh_state = !state.refresh_state;
                return x;                    
            } 

            case EVENT_APP_CACHE_STATE_WIZARD_SUMMARY:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_DEFAULT;
                x.app_cache.refresh = true;
                x.refresh_state = !state.refresh_state;
                return x;
            }
         
            case EVENT_APP_CACHE_STATE_CLONE_SELECT_MODE:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);
                //x.app_cache =  Object.assign({}, EVENTS_APP_CACHE_TEMPLATE);                ;
                x.app_cache.previous = EVENT_APP_CACHE_STATE_WIZARD_0;
        
                if(action.payload.mode === EVENT_APP_CACHE_MODE_PHYSICAL)
                {
                    x.app_cache.mode = EVENT_APP_CACHE_MODE_PHYSICAL;
                    x.app_cache.state = EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE;
                    x.app_cache.virtual.mode = 'none';
                    x.app_cache.virtual.configuration.type = 'none';
                }
                else if(action.payload.mode === EVENT_APP_CACHE_MODE_VIRTUAL)
                {
                    x.app_cache.mode = EVENT_APP_CACHE_MODE_VIRTUAL;
                    x.app_cache.state = EVENT_APP_CACHE_STATE_CLONE_SETUP_VIRTUAL;
                    x.app_cache.virtual.mode = 'zoom';
                    x.app_cache.virtual.configuration.type = 'zoom';    
                }
                x.refresh_state = !state.refresh_state;
                return x;
            }
            break;
            
            case EVENT_APP_CACHE_STATE_CLONE_SETUP_VIRTUAL:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);
                //x.app_cache =  Object.assign({}, EVENTS_APP_CACHE_TEMPLATE);                ;
                x.app_cache.previous = EVENT_APP_CACHE_STATE_CLONE_SETUP_VIRTUAL;
                x.app_cache.virtual = action.payload.virtual;
                x.app_cache.state = EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE;
                x.refresh_state = !state.refresh_state;
                return x;
                
            }

            case EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);
        
                switch(action.payload.scheduling.mode)
                {
                    case EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL:
                    {
                      
                        x.app_cache.state = EVENT_APP_CACHE_STATE_CLONE_MANUAL;
                        x.app_cache.previous = EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE;
                        x.app_cache.scheduling.mode = action.payload.scheduling.mode;
                        x.app_cache.scheduling.configuration.dates = [];
                        x.refresh_state = !state.refresh_state;
                        console.log('Setting state', x);
                        return x;                        
                    }
                    case EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_WEEKLY:
                    {
                        x.app_cache.state = EVENT_APP_CACHE_STATE_CLONE_WEEKLY;
                        x.app_cache.previous = EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE;
                        x.app_cache.scheduling.mode = action.payload.scheduling.mode;
                        x.refresh_state = !state.refresh_state;
                        console.log('Setting state', x);
                        return x;                         
                        
                    }
                    break;
                    case EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_MONTHLY:
                    {
                      
                        x.app_cache.state = EVENT_APP_CACHE_STATE_CLONE_MONTHLY;
                        x.app_cache.previous = EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE;
                        x.app_cache.scheduling.mode = action.payload.scheduling.mode;
                        x.refresh_state = !state.refresh_state;
                        console.log('Setting state', x);
                        return x;                         
                        
                    }
                    break;
                }

            }
            break; 


            case EVENT_APP_CACHE_STATE_CLONE_MANUAL:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);

                x.app_cache.state = EVENT_APP_CACHE_STATE_CLONE_SAVE;
                x.app_cache.previous = EVENT_APP_CACHE_STATE_CLONE_MANUAL;
                x.app_cache.scheduling.configuration.dates = action.payload.dates;
                x.refresh_state = !state.refresh_state;
                console.log('Setting state', x);
                return x; 
            }
            break;


            case EVENT_APP_CACHE_STATE_CLONE_SAVE:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_DEFAULT;
                x.app_cache.refresh = true;
                x.refresh_state = !state.refresh_state;
                return x;
            }

            case  EVENT_APP_CACHE_STATE_CLONE_WEEKLY:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);

                x.app_cache.state = EVENT_APP_CACHE_STATE_CLONE_SAVE;
                x.app_cache.previous = EVENT_APP_CACHE_STATE_CLONE_WEEKLY;
                x.app_cache.scheduling.configuration = action.payload.configuration;
                x.refresh_state = !state.refresh_state;
                console.log('Setting state', x);
                return x;                 
            }
            case  EVENT_APP_CACHE_STATE_CLONE_MONTHLY:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);

                x.app_cache.state = EVENT_APP_CACHE_STATE_CLONE_SAVE;
                x.app_cache.previous = EVENT_APP_CACHE_STATE_CLONE_MONTHLY;
                x.app_cache.scheduling.configuration = action.payload.configuration;
                x.refresh_state = !state.refresh_state;
                console.log('Setting state', x);
                return x;                 
            }



            
                       
        }


    }
    else if(action.type === ACTION_TYPE_REWIND_STATE)
    {
        switch(state.app_cache.state)
        {     
            case EVENT_APP_CACHE_STATE_WIZARD_0:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_DEFAULT;
                x.refresh_state = !state.refresh_state;
                return x;
                
            }
            break;

            case EVENT_APP_CACHE_STATE_WIZARD_1:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_DEFAULT;
                x.refresh_state = !state.refresh_state;


                if(action.payload.store_previous)
                {
                    x.app_cache.basic = action.payload.data;
                }
                return x;
            }
            case EVENT_APP_CACHE_STATE_WIZARD_2:
            {
                let x = JSON.parse(JSON.stringify(state));
                if(action.payload.store_previous)
                {
                    switch(action.payload.mode)
                    {
                        case EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_MONTHLY:
                        case EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_WEEKLY:
                        case EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL:
                        {
                                                    
                            x.app_cache.scheduling.mode = action.payload.mode;
                            console.log('Setting state', x);
                        }
    
                    }
                }

                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_1;
                x.refresh_state = !state.refresh_state;
                console.log('setting state', x);
                return x;                

            }

            case EVENT_APP_CACHE_STATE_WIZARD_2_MANUALLY_ADJUST_DATES:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2;
                x.refresh_state = !state.refresh_state;
                console.log('setting state', x);
                return x;                 
            }
            break;

            case EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_1;
                if(action.payload.store_previous)
                {
                    x.app_cache.virtual = action.payload.virtual;
                }                

                x.refresh_state = !state.refresh_state;
                console.log('setting state', x);
                return x;                
            }
            case EVENT_APP_CACHE_STATE_WIZARD_2_A:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action ', action);

                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2;
                x.refresh_state = !state.refresh_state;

                if(action.payload.store_previous)
                {
                    x.app_cache.scheduling.configuration.dates = action.payload.dates;
                }                
                console.log('setting state', x);
                return x;                
            }
            case EVENT_APP_CACHE_STATE_WIZARD_2_B:
            {
                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2;
                if(action.payload.store_previous)
                {
                    var book_type = x.app_cache.scheduling.configuration.book_type;
                    var dates = x.app_cache.scheduling.configuration.dates;
                    x.app_cache.scheduling.configuration = action.payload.configuration;
                    x.app_cache.scheduling.configuration.dates     = dates;
                    x.app_cache.scheduling.configuration.book_type = book_type;
                }
                x.refresh_state = !state.refresh_state;
                console.log('Setting state', x);
                return x;  

            }
            case EVENT_APP_CACHE_STATE_WIZARD_2_C:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2;
                if(action.payload.store_previous)
                {
                    var book_type = x.app_cache.scheduling.configuration.book_type;
                    var dates = x.app_cache.scheduling.configuration.dates;
                    x.app_cache.scheduling.configuration = action.payload.configuration;
                    x.app_cache.scheduling.configuration.dates     = dates;
                    x.app_cache.scheduling.configuration.book_type = book_type;
                }                
                x.refresh_state = !state.refresh_state;
                console.log('setting state', x);
                return x;                
            }
            case EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2;
                if(action.payload.store_previous)
                {
                    x.app_cache.scheduling.configuration.book_type = action.payload.book_type;
                }
                x.refresh_state = !state.refresh_state;
                console.log('setting state', x);
                return x;                
            }            
            case EVENT_APP_CACHE_STATE_WIZARD_3:
            {

                let x = JSON.parse(JSON.stringify(state));
                console.log('action', action);
                if(action.payload.store_previous)
                {
                    switch(action.payload.mode)
                    {
                        case EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES:
                        case EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD | EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES:
                        case EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD:
                        {
                          
                            x.app_cache.payment.mode = action.payload.mode;
                        }
      
                    } 
                }
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE;
                x.refresh_state = !state.refresh_state;
                console.log('setting state', x);
                return x;                
            }
            case EVENT_APP_CACHE_STATE_WIZARD_4:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_3;
                if(action.payload.store_previous)
                {
                    x.app_cache.payment.configuration.options = action.payload.options;
                }

                x.refresh_state = !state.refresh_state;
                console.log('setting state', x);
                return x;                
            }    

            case EVENT_APP_CACHE_STATE_WIZARD_4_DISCOUNTS:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_4;
                if(action.payload.store_previous)
                {
                    x.app_cache.payment.discounts.vouchers = action.payload.options.discounts;
                    x.app_cache.payment.discounts.payment_rules = action.payload.options.payment_rules;
                }

                x.refresh_state = !state.refresh_state;
                console.log('setting state', x);
                return x;   

            }

            case EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_4;
                if(action.payload.store_previous)
                {
                    x.app_cache.payment.passes = action.payload.passes;
                }                
                x.refresh_state = !state.refresh_state;
                console.log('setting state', x);
                return x;                
            } 

            case EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_4;
                if(action.payload.store_previous)
                {
                    x.app_cache.actions.purchase = action.payload.configuration.purchase;
                    x.app_cache.actions.cancel = action.payload.configuration.cancel;
                    x.app_cache.actions.available = action.payload.configuration.available;
                    x.app_cache.actions.reminder = action.payload.configuration.reminder;    
                }                
                x.refresh_state = !state.refresh_state;
                console.log('setting state', x);
                return x;                
            } 

            case EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS;
                if(action.payload.store_previous)
                {
                    x.app_cache.properties.facilitators = action.payload.configuration.facilitators;
                    x.app_cache.properties.terms = action.payload.configuration.terms;
                    x.app_cache.properties.categories = action.payload.configuration.categories;
                    x.app_cache.properties.location = action.payload.configuration.location;    
                }
                x.refresh_state = !state.refresh_state;
                console.log('setting state', x);
                return x;                      
            }
            case EVENT_APP_CACHE_STATE_WIZARD_SUMMARY:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES;
                x.refresh_state = !state.refresh_state;
                console.log('setting state', x);
                return x;                      
            }  
            
            case EVENT_APP_CACHE_STATE_CLONE_SETUP_VIRTUAL:
            case EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_CLONE_SELECT_MODE;
                x.refresh_state = !state.refresh_state;
                return x;                
            }
            
            case EVENT_APP_CACHE_STATE_CLONE_SELECT_MODE:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_DEFAULT;
                x.refresh_state = !state.refresh_state;
                return x;                
            }
            


            
            
            case EVENT_APP_CACHE_STATE_CLONE_SAVE:
            case EVENT_APP_CACHE_STATE_CLONE_MANUAL:
            case  EVENT_APP_CACHE_STATE_CLONE_WEEKLY:
            case  EVENT_APP_CACHE_STATE_CLONE_MONTHLY:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache.state = EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE;
                x.refresh_state = !state.refresh_state;
                return x;                 
            }

            
            
        }
        
    }
    else if(action.type === ACTION_TYPE_SET_CLONE_STATE)
    {
        switch(action.payload.state)
        {


            case EVENT_APP_CACHE_STATE_DEFAULT:
            {
                let x = JSON.parse(JSON.stringify(state));
                x.app_cache          =  Object.assign({}, EVENTS_APP_CACHE_TEMPLATE);
                x.app_cache.previous = EVENT_APP_CACHE_STATE_DEFAULT;
                x.app_cache.state     = EVENT_APP_CACHE_STATE_CLONE_SELECT_MODE;
                if(action.payload.scheduling.create !== true)
                {
                    x.app_cache.id = action.payload.scheduling.data.id;
                    x.app_cache.basic = action.payload.scheduling.data.basic;
                    x.app_cache.scheduling = action.payload.scheduling.data.scheduling;
                    x.app_cache.payment = action.payload.scheduling.data.payment;
                    x.app_cache.actions = action.payload.scheduling.data.actions;
                    x.app_cache.properties = action.payload.scheduling.data.properties;
                }
                x.refresh_state = !state.refresh_state;
                console.log('Clone', x);
                return x;
            }
        }        
    }

    return state;
}


export default rootReducer;
