import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";


import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {reloadData, closeCustomerPanel} from '../redux/actions';
import Slide from '@material-ui/core/Slide';
import CoreDialog from '../core/dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import QRCode from "react-qr-code";
import Hidden from '@material-ui/core/Hidden';

import {Avatar} from  '@material-ui/core';
import Box from '@material-ui/core/Box';
import CustomerTabs from './customers-tabs';

import { v5 as uuidv5 } from 'uuid';

import './customers.css';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    section:{
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    box:{
        backgroundColor: '#fff  ',
        padding: '18px', 
        marginTop: '68px',       
    },
    dialog:{
        backgroundColor: 'rgba(0,0,0,0.1)',
        '& .MuiAppBar-root':{
            backgroundColor: 'rgba(60, 141, 188, 1)',
        }
    },      
    avatar:{
        display: 'block',
        fontSize: '100px',
        lineHeight: '220px',
        textAlign: 'center',
        width: 220,
        height: 220
    },

    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    editLink:{
        color: '#3c8dbc',
        marginTop: '20px',
        display: 'block',
        textAlign: 'right'
    },
    detailCellName:{
        width: '40%'
    },    
    fabDanger:{
        backgroundColor: '#d40000',
        color: '#fff',
        width: '46px',
        height: '46px',
        '&:hover':{
            backgroundColor: '#AA0000',
        }
    },
    fabWarning:{
        backgroundColor: '#FF7900',
        color: '#fff',
        width: '46px',
        height: '46px',
        '&:hover':{
            backgroundColor: '#FF7900',
            opacity: '0.8'
        }
    },
    fabGo:{
        backgroundColor: '#91d768',
        color: '#fff',
        width: '46px',
        height: '46px',
        '&:hover':{
            backgroundColor: '#91ff68',
        }
    },  
    fabGrey:{
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: '#fff',
        width: '46px',
        height: '46px',
        '&:hover':{
            backgroundColor: 'rgba(0,0,0,0.5)',
            cursor: 'not-allowed',
        }
    },        
    fabIcon:{
        '& .MuiSvgIcon-root':{
            fill: '#fff',
            color: '#fff',
            '&:hover':{
                color: '#ffe',
            }
        }
    },
    archiveButton:{
        marginLeft: '5px',
        marginRight: '5px',
        '& .MuiButton-outlined':{
            backgroundColor: '#d40000'
        }
    },    
    innerTable:{
        border: '1px solid rgba(0,0,0,0.01)',
        borderRadius: '5px',
        '& .MuiTable-root':{
            '& .MuiTableHead-root':{
                backgroundColor:"rgba(0,0,0,0.01)"
            }
        }
    },
    alignCenter:
    {
        textAlign: 'center'
    },
    buttonDanger:{
        color: '#d40000',
    },
    widgetButton:{
        marginLeft: '5px',
        marginRight: '5px',
    },
    cellTitle:{
        width: '12%',
    },
    fabStar:{
        color: '#FFA500',
    }, 
    fabDisabled:{
        "&:hover, &.Mui-focusVisible":{
            backgroundColor: '#fff',
            '&:hover':{
                backgroundColor: '#fff',
            }
        }
    },



});



const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });




function mapDispatchToProps(dispatch) {
    return {
        reloadData: spec => dispatch(reloadData(spec)),
        closeCustomerPanel: spec => dispatch(closeCustomerPanel(spec)),

      
    };
}






class CustomersPanelManagerModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);

        this.state = {
            error: '',
            cid: props.data?.cid,
            security: props.security,
            customer: props.customer,
            videoenabled: props.data.videoenabled,
            videos: props.data?.videos,
            videotransactions: props.data?.videotransactions,
            customers: props.data.customers,
            statistics: props.data?.statistics,
            transactions: props.data?.transactions,
            passes: props.data?.passes || [],
            subscriptions: props.data?.subscriptions,
            questionaires: props.data?.questionaires,
            events: props.data?.events,
            questionnaire_templates: props.data?.questionnaire_templates,
            availablepasses: props.data?.availablepasses || [],
            cards: props.data?.cards || [],
            cust_add: false,
            loading: false,
            add_button_state: false,
            archive_start: false,
            tab: localStorage.getItem('rv-panel-cust-tab')|| 0
            
        };


        this.onManageCustomerPanelClose = this.onManageCustomerPanelClose.bind(this);
        this.onAddCustomer   = this.onAddCustomer.bind(this);
        this.doRefresh       = this.doRefresh.bind(this);
        this.onArchive       = this.onArchive.bind(this);
        this.onArchiveStart  = this.onArchiveStart.bind(this);
        this.onArchiveCancel = this.onArchiveCancel.bind(this);
        this.onSetTab = this.onSetTab.bind(this);
             
        
        // console.log("Final TopNav",this.state);
    }


    onSetTab(v)
    {
        localStorage.setItem('rv-panel-cust-tab', v);
        this.setState({'tab': v})
        
    }

    doRefresh()
    {
        var chain = '';
        chain = '&id=' + this.props?.customer?.id;
        this.setState({loading: true, error:''});
        var _this = this;
        try {
            fetch("/backenddata/ajax-fetch-customer-data", {
                method: "POST",
                mode: 'same-origin', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'include', // include, *same-origin, omit                            
                body: this.state.security.name + '=' + this.state.security.token + chain,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": 'XMLHttpRequest',
                    "X_REQUESTED_WITH": 'xmlhttprequest'
                }
            })
            .then(res => {
                return res.json();
            })
            .then(response => {
                //console.log(data);
                if(response.status === 'OK')
                {
                    this.setState({
                                    transactions: response.data.transactions || [],  
                                    statistics: response.data.statistics || [], 
                                    questionaires: response.data.questionaires || [], 
                                    subscriptions: response.data.subscriptions || [], 
                                    videotransactions: response.data.videotransactions || [],
                                    passes: response.data.passes || [],
                                    cards: response.data.cards || [], 
                                    loading: false
                                });
                }
                else
                {
                    this.setState({loading: false, error: 'Failed to fetch records'});
                }
            }).catch(function (err) {
                _this.setState({loading: false, error: 'Network error. Please reload this page and try again'});
            });           
    
        }
        catch(e)
        {
            _this.setState({loading: false});
        }


    }


    componentDidMount()
    {
        this.doRefresh();
    } 

    onArchiveStart( )
    {
        this.setState({archive_start: true})
    }

    onArchiveCancel( )
    {
        this.setState({archive_start: false})
    }

    onArchive()
    {

        var chain = '';
        chain = '&id=' + this.props?.customer?.id;
        this.setState({loading: true, error:'', archive_state: false});
        var _this = this;
        try {
            fetch("/customers/do-ajax-customer-archive", {
                method: "POST",
                mode: 'same-origin', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'include', // include, *same-origin, omit                            
                body: this.state.security.name + '=' + this.state.security.token + chain,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": 'XMLHttpRequest',
                    "X_REQUESTED_WITH": 'xmlhttprequest'
                }
            })
            .then(res => {
                return res.json();
            })
            .then(response => {
                //console.log(data);
                if(response.status === 'OK')
                {
                    this.setState({loading: false});
                    this.props.closeCustomerPanel({});
                    window.location.reload();
                }
                else
                {
                    this.setState({loading: false, error: response.message});
                }
            }).catch(function (err) {
                _this.setState({loading: false});
            });           
    
        }
        catch(e)
        {
            _this.setState({loading: false, error: 'Network error - please try again.'});
            this.props.closeCustomerPanel({});
        }

    }


    onManageCustomerPanelClose(event)
    {
        this.props.closeCustomerPanel({});
    }

    onAddCustomer()
    {
        this.setState({add_button_state:!this.state.add_button_state});
    }


    render(  )
    {
        var classes = this.props.classes;

        /* OK we need to kick off the loading of the data */


        console.log('render',this.props, this.state );



        var name = this.props?.customer?.name;
        const filtered_pass = this.state.passes.filter((p, i) => {return p.status !== 'VOIDED'});

        return (
            <div className={classes.root}>

                <Dialog fullScreen open={this.props.open} onClose={this.onManageCustomerPanelClose} TransitionComponent={Transition}>
                    
                    <div className={classes.dialog}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.onManageCustomerPanelClose} aria-label="close">
                                    <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                    {name} 
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <div className={classes.box}>
                                <Box>
                                    <Grid container spacing={5} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                        <Hidden smDown>
                                            <Grid item  md={3}>
                                                <Avatar alt="avatar" src={this.props?.customer?.avatar || ''} className={classes.avatar}>
                                                    {this.props?.customer?.firstname.charAt(0).toUpperCase()+this.props?.customer?.lastname.charAt(0).toUpperCase()}
                                                </Avatar>
                                            </Grid>
                                        </Hidden>

                                        <Grid item xs={12} md={9}>
                                            <Grid container spacing={3} alignContent="flex-end" mt={4}>
                                                <Grid item xs={4} className='customer-section'>Customer Unique Identifier</Grid>
                                                <Grid item xs={8}>{this.state.cid.toString().padStart(6, '0') + '-' + this.props?.customer?.id.toString().padStart(6, '0')}</Grid>
                                                <Grid item xs={4} className='customer-section'>First name</Grid>
                                                <Grid item xs={8}>{this.props?.customer?.firstname.charAt(0).toUpperCase() + this.props?.customer?.firstname.slice(1)}</Grid>
                                                <Grid item xs={4} className='customer-section'>Last name</Grid>
                                                <Grid item xs={8}>{this.props?.customer?.lastname}</Grid>
                                                <Grid item xs={4} className='customer-section'>e-mail</Grid>
                                                <Grid item xs={8}><a href={`mailto:${this.props?.customer?.email}`}>{this.props?.customer?.email}</a></Grid>
                                                <Grid item xs={4} className='customer-section'>Contact number</Grid>
                                                <Grid item xs={8}><a href={`tel:${this.props?.customer?.mobile}`}>{this.props?.customer?.mobile}</a></Grid>
                                                <Grid item xs={4}  className='customer-section'>Customer's QR Code</Grid>
                                                <Grid item xs={8}><QRCode size={100} value={JSON.stringify({"cid": this.state.cid,"customer": this.props?.customer?.id})}></QRCode></Grid>
                                            </Grid>
                                        </Grid>  
                                        <Grid item xs={12}>
                                            <CustomerTabs loading={this.state.loading} data={this.props.data} clsses={this.props.classes} customer={this.props?.customer} videoenabled={this.state.videoenabled} customers={this.props?.data?.customers} security={this.state.security} transactions={this.state.transactions} events={this.state.events}  onRefresh={this.doRefresh} passes={this.state.passes} availablepasses={this.state.availablepasses} subscriptions={this.state.subscriptions}  questionaires={this.state.questionaires} cards={this.state.cards} onArchive={this.onArchive} questionnaire_templates={this.state.questionnaire_templates} videos={this.state.videos} videotransactions={this.state.videotransactions}/>                                
                                        </Grid>                                
                                    </Grid>
                                </Box>
   
                            </div>
               


                    </div>
                    {
                        this.state.archive_start === true && (
                            <CoreDialog title="Archive customer" body="Warning! You are about to archive this customer. Do you want to continue?" state={true} danger={true} onCancel={this.onArchiveCancel} actions={[{label:"Yes, archive", action:this.onArchive}]}  />
                        )
                    }
                    {
                        this.state.error !== '' &&
                        <CoreDialog title="We have detected a problem" body={this.state.error}  state={true} danger={true} onCancel={()=>{this.setState({error:''})}} actions={[{label:"OK", action:() => {this.setState({error: ''})}}]} />
                    }
                </Dialog>
            </div>            
        );
    }

};



const CustomersPanelManager = connect(null, mapDispatchToProps)(CustomersPanelManagerModule);
export default withStyles(styles)(CustomersPanelManager);
