import React, { Component } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';


import '../core/dashboard.css';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';



import IconButton from '@material-ui/core/IconButton';
import GroupIcon from '@material-ui/icons/Group';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import {setStep, rewindStep} from '../redux/actions';

import CloseIcon from '@material-ui/icons/Close';


import  {
    EVENT_APP_CACHE_MODE_PHYSICAL,
    EVENT_APP_CACHE_MODE_VIRTUAL,
    EVENT_APP_CACHE_STATE_CLONE_SELECT_MODE
  } from '../redux/types';


import './events-manager.css';

const styles = theme => ({
        root: {
            flexGrow: 1,
        },          
        title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
        },
          formControl: {
            margin: '20px',
            minWidth: 120,
        },  
        icon:{
              fontSize: '4em',
              textAlign:'center',
        },
        widgetContainer:{
            textAlign:'center',
    
        },
        sectionDescription:{
            color: 'rgba(0,0,0,0.6)',
            textAlign:'center',
    
        },
        eventDescription:{
            fontWeight: 'normal', 
            color: 'rgba(0,0,0,0.5)',
            fontSize: '0.8em',
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },         


});








function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  


class EventsSelectModeModule extends Component{

    constructor( props )
    {
        super( props );

        this.state = {

        };

        this.state.security = props.security;


        this.setStep             = this.setStep.bind(this);
        this.onPhysicalClick     = this.onPhysicalClick.bind(this);
        this.onVirtualClick      = this.onVirtualClick.bind(this);
        this.onClose             = this.onClose.bind(this);

        
    }

    setStep(data)
    {
        var o = { mode: data, state: EVENT_APP_CACHE_STATE_CLONE_SELECT_MODE};
        this.props.setStep(o);
    }

    onClose()
    {  
        var o = { state: EVENT_APP_CACHE_STATE_CLONE_SELECT_MODE};
        this.props.rewindStep(o);

    }    


    onPhysicalClick()
    {
        this.setStep( EVENT_APP_CACHE_MODE_PHYSICAL );
    }
    onVirtualClick()
    {
        this.setStep( EVENT_APP_CACHE_MODE_VIRTUAL );
    }


    onAddPassDialogClose( refresh )
    {
        this.setState({pass_add: false, pass_edit:false});
        if(refresh)
        {
            this.onRefresh()
        }
    }    
    




  

    render()
    {
        var classes = this.props.classes;
        console.log('edit data', this.state.edit_data);


        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Event Clone Editor
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={this.onClose}>
                        <CloseIcon />
                    </IconButton>                    
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >

                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                <div className="formTitle centerText">Select an event type</div>                             
                                    <p className={classes.sectionDescription}>
                                        Select an event type that best represents the type of event that you are organising.
                                    </p>
                                    
                                    <Grid container direction="row" justify="space-evenly" alignItems="center">
                                        <Grid item xs={12} md={6} className={classes.widgetContainer}>
                                            <div className="formSection">Standard event</div>                       
                                            <IconButton aria-label="Physical event" onClick={this.onPhysicalClick}>
                                                <GroupIcon fontSize="large" className={classes.icon}/>
                                            </IconButton> 
                                            <div className={classes.eventDescription}>This type of event is best chosen if you are physically meeting your clients face to face</div>                       

                                        </Grid>
                                        <Grid item xs={12} md={6} className={classes.widgetContainer}>
                                            <div className="formSection">Virtual event</div>                       
                                            <IconButton aria-label="Physical event" onClick={this.onVirtualClick}>
                                                <VideoCallIcon fontSize="large" className={classes.icon} />
                                            </IconButton>                        
                                            <div className={classes.eventDescription}>This type of event is best chosen if you are virtually meeting your clients</div>                       
                                        </Grid>                                    
                                    </Grid>
                                </div>
                            </Grid>                                                                        
                        </Grid>

                    </Grid>  

                </Grid>

                
            </div>            
        );
    }

};



const EventsSelectMode = connect(null, mapDispatchToProps)(EventsSelectModeModule);
export default withStyles(styles)(EventsSelectMode);
