import React from 'react';
import parse from 'html-react-parser';
import Box from '@material-ui/core/Box';

const QuestionaireTextBlock = ({id, text, index, required, answer, onAnswer }) => {
    const jsonUnescape = (str) =>{
        const x =   str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\").replace(/\\n\\r/g, "<br/>").replace(/\\r\\n/g, "<br/>").replace(/\\r/g, "<br/>").replace(/\\n/g, "<br/>").replace(/\n/g, "<br/>").replace(/\r/g, "<br/>");
        return x;
    }
    return ( 
        <React.Fragment>
            <div>
                <Box pt={2} pb={2} >
                    {parse(jsonUnescape(text))}
                </Box>                
            </div>
        </React.Fragment>
        

     );
}
export default QuestionaireTextBlock;