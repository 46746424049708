import React from 'react';
import parse from 'html-react-parser';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(0),
    },
  }));



const QuestionaireTextBlockConfirm = ({id, text, confirm, index, required, answer, onAnswer }) => {
    const classes = useStyles();
    const jsonUnescape = (str) =>{
        const x =   str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\").replace(/\\n\\r/g, "<br/>").replace(/\\r\\n/g, "<br/>").replace(/\\r/g, "<br/>").replace(/\\n/g, "<br/>").replace(/\n/g, "<br/>").replace(/\r/g, "<br/>");
        return x;
    }
    return ( 
        <React.Fragment>
            <div>
                <div className="form-group" pt={4}>
                    {parse(jsonUnescape(text))}
                </div>
                <FormControl component="fieldset" className={classes.formControl} key={id}>
                    <FormControlLabel
                        control={<Checkbox checked={!!answer.answer} name={id} id={id} required={required} value={id}  autoFocus={id===1? true: false } onClick={(e) => {onAnswer(id, !answer.answer)}}/>}
                        label={parse(jsonUnescape(confirm))}
                    />
                </FormControl>                                               
            </div>
        </React.Fragment>
        

     );
}
export default QuestionaireTextBlockConfirm;