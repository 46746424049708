import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';


import '../core/dashboard.css';


//import Link from '@material-ui/core/Link';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Hidden from '@material-ui/core/Hidden';

import IconButton from '@material-ui/core/IconButton';

import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CodeIcon from '@material-ui/icons/Code';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {reloadData, manageCustomer} from '../redux/actions';
import RefreshIcon from '@material-ui/icons/Refresh';
import CoreDialog  from '../core/dialog'
import CodeDialog  from '../core/code-dialog';
import Loading from '../core/loading';
import { red } from '@material-ui/core/colors';

import PassesAddPanelManager  from './passes-add-panel';

import './passes.css';

import {v1 as uuidv1} from "uuid";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,
    },
    idCell:{
        width: '8.3333%' //100% divided by 12
    },
    editCell:{
        width: '8.3333%' //100% divided by 12
    }, 
    codeCell:{
        width: '8.3333%' //100% divided by 12
    },  
    deleteCell:{
        width: '8.3333%' //100% divided by 12
    },          
    nameCell:{
        width: '50%' //100% divided by 12 * 6
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    fabEdit:{
        backgroundColor: '#3c8dbc',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#346785',
        }
    },
    fabCode:{
        backgroundColor: '#9e9e9e',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#858585',
        }
    },
    fabDelete:{

        backgroundColor: red[500],
        color: '#fff',
        '&:hover':{
            backgroundColor: red[600],
        }
    },        
    fabIcon:{
        '& .MuiSvgIcon-root':{
            fill: '#fff',
            color: '#fff',
            '&:hover':{
                color: '#ffe',
            }
        }
    }


});

class EditButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('EditButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="editbutton" className={classes.fabEdit} aria-label="Manage customer" onClick={this._handleClick}>
                <EditRoundedIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}

class CodeButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="manage" className={classes.fabCode} aria-label="Manage customer" onClick={this._handleClick}>
                <CodeIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}

class DeleteButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('DeleteButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="delete" className={classes.fabDelete} aria-label="Delete Pass" onClick={this._handleClick}>
                <DeleteForeverIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}






function mapDispatchToProps(dispatch) {
    return {
        reloadData: spec => dispatch(reloadData(spec)),
        manageCustomer: spec => dispatch(manageCustomer(spec))
    };
  }


  


class PassesManagerModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            passes: props.data.passes,
            vouchers: props.data.vouchers,
            security: {},
            refresh: false,
            pass_add: false,
            pass_edit: false,
            pass_delete: false,
            pass_focus: null,
            pass_code: false,
            edit_data: {},
            loading: false,

            //customer_panel: false, //false
            //customer_id: null // null
        };

        this.state.security = props.security;


        this.onAddPassDialogClose = this.onAddPassDialogClose.bind(this);
        this.onAddPass       = this.onAddPass.bind(this);
        this.onEditPass       = this.onEditPass.bind(this);
        this.onDeletePass     = this.onDeletePass.bind(this);
        this.onCompleteDelete     = this.onCompleteDelete.bind(this);
        this.onCancelDelete     = this.onCancelDelete.bind(this);
        this.onPassCodeClose    = this.onPassCodeClose.bind(this);
        this.onCodeSelect       = this.onCodeSelect.bind(this);
        this.onRefresh          = this.onRefresh.bind(this);
        
       // console.log("Final TopNav",this.state);
    }

    onEditPass(data)
    {
        this.setState({pass_edit: true, edit_data: data});
    }

    onDeletePass(data)
    {
        console.log('deleting', data);
        this.setState({pass_delete: true, pass_focus: data});
    }

    onCancelDelete()
    {
        this.setState({pass_delete: false, pass_focus: {}});

    }


    onCompleteDelete()
    {

        var id = this.state.pass_focus.id ||  null;
        var _this = this;
        var chain = '';
        chain = chain +  "&id=" + id;
 
        fetch("/packages/ajax_passes_delete", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({pass_delete: false, pass_focus: {}});
                _this.onRefresh();
            }
            else
            {

                //this.setState({cust_add: false});
            }
        }).catch(function(err){
            _this.setState({pass_delete: false, pass_focus: {}});
            console.error('Failed to delete the pass', err);
        });      



    }


    onAddPass(event)
    {
        this.setState({pass_add: true})
    }





    onAddPassDialogClose( refresh )
    {
        this.setState({pass_add: false, pass_edit:false});
        if(refresh)
        {
            this.onRefresh()
        }
    }    
    
    onPassCodeClose()
    {
        this.setState({pass_code: false, pass_focus: {}});
    }
    onCodeSelect(data)
    {
        console.log('onCodeSelect', data);
        this.setState({pass_code: true, pass_focus: data});

    }


    onRefresh()
    {
        var _this = this;
        this.setState({'loading': true});
        fetch("/backenddata/ajax_fetch_passes", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + '&refresh=1',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(!response.hasOwnProperty("error"))
            {
              let o = {
                'settings': response.data
              };
              console.log("loading ajax", o);
              this.props.reloadData( o );
              this.setState({'loading': false});


            }
            else
            {
               /* handle error */
            }
        }).catch(function(err){
            console.error('Failed to load the pass', err);
        });     
    }



  

    render()
    {
        var classes = this.props.classes;
        console.log('edit data', this.state.edit_data);


        return (
            <div className={classes.root}>


                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        {window.vocab.topbar}
                    </Typography>
                    <Button  variant="outlined" className="button-green space-right" onClick={this.onAddPass}>Add Pass</Button>
                    <Button  variant="outlined" color="primary" onClick={this.onRefresh}><RefreshIcon/>Refresh</Button>
                    </Toolbar>
                </AppBar>


                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >

                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <Grid container justify="flex-start" alignItems="stretch">
                                        <Grid item xs={12} md={12}>
                                            <h2>Active passes</h2>   
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TableContainer>                                
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <Hidden smDown>
                                                                <TableCell className={classes.idCell}>
                                                                    ID
                                                                </TableCell> 
                                                            </Hidden>
                                                            <TableCell className={classes.nameCell}>
                                                                Name
                                                            </TableCell> 
                                                            <TableCell>
                                                                Edit
                                                            </TableCell> 
                                                            <TableCell>
                                                                Code
                                                            </TableCell>   
                                                            <TableCell>
                                                                Delete
                                                            </TableCell>                                                                                                                                                                           
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {this.state.loading && (
                                                        <TableRow key={uuidv1()}>
                                                            <Hidden smDown>
                                                                <TableCell className={classes.nameCell} colSpan={5}>
                                                                    <Loading />
                                                                </TableCell> 
                                                            </Hidden>
                                                            <Hidden mdUp>
                                                                <TableCell className={classes.nameCell} colSpan={4}>
                                                                    <Loading />
                                                                </TableCell> 
                                                            </Hidden>
                                                        </TableRow>
                                                    )}
                                                    {this.state.loading === false && this.state.passes.map(el => (
                                                        <TableRow key={uuidv1()}>
                                                            <Hidden smDown>
                                                                <TableCell className={classes.idCell}>
                                                                    {el.id}
                                                                </TableCell>  
                                                            </Hidden>                        
                                                            <TableCell className={classes.nameCell}>
                                                                {el.name}
                                                            </TableCell> 
                                                            <TableCell className={classes.editCell}>
                                                                <EditButton classes={classes} data={el} callback={this.onEditPass} />

                                                            </TableCell>                                         
                                                            <TableCell className={classes.codeCell}>
                                                                <CodeButton classes={classes} data={el} callback={this.onCodeSelect} />
                                                            </TableCell>                                       
                                                            <TableCell className={classes.deleteCell}>
                                                                <DeleteButton classes={classes} data={el} callback={this.onDeletePass} />
                                                            </TableCell> 
                                                        </TableRow>
                                                        ))}
                                                    </TableBody>

                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>

                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>



                {this.state.pass_add === true && (
                    <PassesAddPanelManager open={true} closeCustomerPanel={this.onAddPassDialogClose} security={this.state.security} vouchers={this.state.vouchers} data={{id: null, name: null, deleted: 0 , expires: 0, price: 0, credits: 0 , description:null, vouchers:[]}} />
                )}
                {this.state.pass_edit === true && (
                    <PassesAddPanelManager open={true} closeCustomerPanel={this.onAddPassDialogClose} security={this.state.security} vouchers={this.state.vouchers} data={this.state.edit_data} />
                )}
                {this.state.pass_delete === true && (<CoreDialog title="Delete Pass" body="You are about to delete this pass. Do you want to continue?" state={true} danger={true} onCancel={this.onCancelDelete} actions={[{label:"OK", action:this.onCompleteDelete}]}  />)}
                {this.state.pass_code === true && (<CodeDialog callback={this.onPassCodeClose} data={this.state.pass_focus} 
                                                        options={[{name:'Pass purchase link', type:'address'}, 
                                                                  {name: 'Orange button widget', type:'obutton'}, 
                                                                  {name: 'Green button widget', type:'gbutton'},
                                                                  {name: 'Blue button widget', type:'bbutton'},
                                                                  {name: 'White button widget', type:'wbutton'},
                                                                ]}  /> )}
            </div>            
        );
    }

};



const PassesManager = connect(null, mapDispatchToProps)(PassesManagerModule);
export default withStyles(styles)(PassesManager);
