import React from 'react';
import QuestionaireTextBox from './QuestionaireTextBox';
import QuestionaireMultiTextBox from './QuestionaireMultiTextBox';
import QuestionaireRadio from './QuestionaireRadio';
import QuestionaireCheckbox from './QuestionaireCheckbox';
import QuestionaireTextBlock from './QuestionaireTextBlock';
import QuestionaireTextBlockConfirm from './QuestionaireTextBlockConfirm';
import QuestionaireMultiCheckbox from './QuestionaireMultiCheckbox';
import Grid from '@material-ui/core/Grid';

const Questionaire = ({qas, onChange}) => {

    const onAnswer = ( id, a ) => {

        let nqa = Object.assign({}, qas) // not sure why this is necessary - but system is not detecting object change if we do not clone
        const search = nqa.qas.findIndex( q => q.id === id);
        if(search >= 0)
        {
            if(nqa.qas[search].type === 'multi-checkbox')
            {

                if(!!Array.isArray(nqa.qas[search].answer))
                {
                    let s = nqa.qas[search].answer.find( (answer) => {
                        let r = !!(a === answer.answer)
                        console.log("comparing", a, answer.answer, r);
                        return r;
                    });
                    console.log("Search", s);
                    if(!!s)
                    {
                        // OK it is there - lets remove it 
                       let list = nqa.qas[search].answer.filter( (answer) => {
                            let r = (a === answer.answer);
                            return !!!r;
                        });
                        nqa.qas[search].answer = list;
                    }
                    else
                    {
                        nqa.qas[search].answer.push({id: id, answer: a})   
                    }
                }
                else
                {
                    nqa.qas[search].answer = [];
                    nqa.qas[search].answer.push({id: id, answer: a})   
                }

            }
            else
            {
                nqa.qas[search].answer = {id: id, answer: a};
            }
            onChange(nqa);
            console.log('Updated QA', nqa);
        }
    };

    const jsonUnescape = (str) =>{
        return  str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\");
    }



    return ( 
        <React.Fragment>
            {
                <Grid container spacing={4}  direction="row" justifyContent="flex-start"  alignItems="flex-start" >
                   {
                    (!!qas && qas?.qas?.length > 0) && (
                        <Grid item xs={12}>
                            {
                                    qas.qas.map( (q, index) =>
                                    {

                                        switch(q.type)
                                        {
                                            case 'input':
                                                return <QuestionaireTextBox key={q.id} index={index + 1} id={q.id} answer={q?.answer || ''} text={jsonUnescape(q.text)} required={q.required} onAnswer={onAnswer} />
                                            case 'text':
                                                return <QuestionaireMultiTextBox key={q.id} index={index + 1} id={q.id} answer={q?.answer || ''} text={jsonUnescape(q.text)} required={q.required} onAnswer={onAnswer} />
                                            case 'radio':
                                                return <QuestionaireRadio key={q.id} index={index + 1} id={q.id} text={jsonUnescape(q.text)} answer={q?.answer || ''}  options={q.options} required={q.required} onAnswer={onAnswer} />
                                            case 'multi-checkbox':
                                                return <QuestionaireMultiCheckbox key={q.id} index={index + 1} id={q.id} text={jsonUnescape(q.text)} answer={q?.answer || ''}  options={q.options} required={q.required} onAnswer={onAnswer} />

                                            case 'checkbox':
                                                return <QuestionaireCheckbox key={q.id} index={index + 1} id={q.id} text={jsonUnescape(q.text)} answer={q?.answer || ''} required={true} onAnswer={onAnswer} />
                                            case 'text-block':
                                                return <QuestionaireTextBlock key={q.id} index={index + 1} id={q.id} text={jsonUnescape(q.text)}/>
                                            case 'text-block-confirm':
                                                return <QuestionaireTextBlockConfirm key={q.id} index={index + 1} id={q.id} text={jsonUnescape(q.text)} confirm={q.options[0].text}  answer={q?.answer || ''} required={true} onAnswer={onAnswer} />
                
                                            default: 
                                            return <div></div>;

                                        }
                                    }
                                )
                            }
                        </Grid>
                    )
                   }

                </Grid>

            }
        </React.Fragment>
        

     );
}
 
export default Questionaire;