import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField  from '@material-ui/core/TextField';
import './engine.scss';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


import RemoveIcon from '@material-ui/icons/Remove';
import SubjectIcon from '@material-ui/icons/Subject';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Hidden from '@material-ui/core/Hidden';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import Divider from '@material-ui/core/Divider';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import { v4 as uuidv4 } from "uuid";
import Switch from '@material-ui/core/Switch';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';

function AddWidget({onSelect}) {
  return (
    <PopupState variant="popover" popupId="add-widget-menu">
      {(popupState) => (
        <React.Fragment>
          <Button variant="contained" startIcon={<AddCircleOutlineIcon />} color="primary"  {...bindTrigger(popupState)}>
            New section
          </Button>
          <Menu {...bindMenu(popupState)}>          
            <MenuItem value={'input'} onClick={(e) => {onSelect('input'); popupState.close(); }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <RemoveIcon />
                    <div>&nbsp;&nbsp;Single text box</div>
                </div>
            </MenuItem>
            <MenuItem value={'text'} onClick={(e) => {onSelect('text'); popupState.close();}}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SubjectIcon />
                    <div>&nbsp;&nbsp;Multi-line text box</div>
                </div>
            </MenuItem>
            <MenuItem value={'text-block'} onClick={(e) => {onSelect('text-block'); popupState.close();}}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ViewHeadlineIcon />
                    <div>&nbsp;&nbsp;Text block</div>
                </div>
            </MenuItem>  
            <MenuItem value={'text-block-confirm'} onClick={(e) => {onSelect('text-block-confirm'); popupState.close();}}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SubjectIcon />
                    <div>&nbsp;&nbsp;Text block with read confirmation.</div>
                </div>
            </MenuItem> 

            <MenuItem value={'radio'} onClick={(e) => {onSelect('radio');popupState.close();}}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <RadioButtonCheckedIcon />
                    <div>&nbsp;&nbsp;Multiple choice</div>
                </div>
            </MenuItem>

            <MenuItem value={'check'} onClick={(e) => {onSelect('checkbox'); popupState.close()}}>                                            
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CheckBoxIcon />
                    <div>&nbsp;&nbsp;Checkbox</div>
                </div>
            </MenuItem>   

            <MenuItem value={'check'} onClick={(e) => {onSelect('multi-checkbox'); popupState.close()}}>                                            
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <LibraryAddCheckIcon />
                    <div>&nbsp;&nbsp;Multiple Checkbox options</div>
                </div>
            </MenuItem>                       
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
}



const Engine = ({questionaire, onUpdate}) => {

    const [questions, setQuestions] = React.useState(questionaire);


    const onSetQuestions = ( q ) => {
        setQuestions(q);
        onUpdate(q);
    }

    const onChangeQuestion = ( id, value ) =>{
        let q = questions.map( (qu, i) => {
            if(qu.id === id)
            {
                qu.text = value;
            }
            return qu;
        });
        onSetQuestions(q);
    }


    const onQuestionUp = ( qid ) => {


        let qu = questions.filter( i => {return true});
        let index = qu.findIndex((q) =>{
            return q.id === qid;
        });
        if(index > 0)
        {
            let f = qu.splice(index, 1)[0];
            qu.splice(index-1, 0, f);                   
        }
        onSetQuestions(qu);    
    }    


    const onQuestionDown = ( qid ) => {

        let qu = questions.filter( i => {return true});
        let index = qu.findIndex((q) =>{
            return q.id === qid;
        });
        if(index >= 0)
        {
            let f = qu.splice(index, 1)[0];
            qu.splice(index+1, 0, f);                   
        }
        onSetQuestions(qu);    
    } 


    const onQuestionDelete = ( qid ) => {

        let q = questions.filter( (qu, i) => {
            if(qu.id === qid)
            {
                return false;
            }
            return true;
        });
        onSetQuestions(q);
    } 


    const onChangeQuestionOption = ( qid, oid, value) => {
        let q = questions.map( (qu, i) => {
            if(qu.id === qid)
            {
               qu.options = qu.options.map( (o, index) =>{
                    if(o.id === oid)
                    {
                        o.text = value;
                    }
                    return o;
               });
            }
            return qu;
        });
        onSetQuestions(q);        
    }

   

    const onDeleteQuestionOption = ( qid, oid) => {
        let q = questions.map( (qu, i) => {
            if(qu.id === qid)
            {
               qu.options = qu.options.filter( (o, index) =>{
                    if(o.id === oid)
                    {
                        return false
                    }
                    return true;
               });
            }
            return qu;
        });
        onSetQuestions(q);        
    }


    const onAddQuestionOption = ( qid ) => {
        let q = questions.map( (qu, i) => {
            if(qu.id === qid)
            {
               qu.options.push({
                id: uuidv4(),
                text: ''
              });
            }
            return qu;
        });
        onSetQuestions(q);        
    }

    const onSetRequired = ( qid, oid ) => {
        let q = questions.map( (qu, i) => {
            if(qu.id === qid)
            {
                qu.required = !qu.required;
            }
            return qu;
        });
        onSetQuestions(q);           
    }

    const onOptionUp = ( qid, oid ) => {
        let q = questions.map( (qu, i) => {
            if(qu.id === qid)
            {
               let index = qu.options.findIndex( (o) =>{
                    if(o.id === oid)
                    {
                        return true
                    }
                    return false;
               });

               if(index > 0)
               {
                 let f = qu.options.splice(index, 1)[0];
                 qu.options.splice(index-1, 0, f);                   
               }
            }
            return qu;
        });
        onSetQuestions(q);    
    }

    const onSetImportant = ( qid, oid ) => {
        let q = questions.map( (qu, i) => {
            if(qu.id === qid)
            {
               let index = qu.options.findIndex( (o) =>{
                    if(o.id === oid)
                    {
                        return true
                    }
                    return false;
               });

               if(index >= 0)
               {
                    qu.options[index].important = !!!qu.options[index].important;
               }
            }
            return qu;
        });
        onSetQuestions(q);    

    }

    const onOptionDown = ( qid, oid ) => {
        let q = questions.map( (qu, i) => {
            if(qu.id === qid)
            {
               let index = qu.options.findIndex( (o) =>{
                    if(o.id === oid)
                    {
                        return true
                    }
                    return false;
               });

               if(index >= 0)
               {
                    let f = qu.options.splice(index, 1)[0];
                    qu.options.splice(index+1, 0, f);
               }
            }
            return qu;
        });
        onSetQuestions(q); 
    }    


    const onChangeType= ( id, value ) =>{
        let q = questions.map( (qu, i) => {
            if(qu.id === id)
            {
                qu.type = value;

                if(qu.type === 'text-block'  || qu.type === 'text-block-confirm')
                {
                    qu.special = true;
                }
                else
                {
                    qu.special = false;
                }
            }
            return qu;
        });
        onSetQuestions(q);
    }

    const onAddItem = ( type ) =>{
        switch(type)
        {
            case 'input':
            {
                console.log('uuid', uuidv4());
                const o = {
                    id: uuidv4(),
                    state: true,
                    type: 'input',
                    required: true,
                    special: false,
                    text: '',
                    options: [],
                };

                let q = questions.filter( (i) => {return true});
                q.push( o );
                onSetQuestions(q);

            }
            break;
            case 'text':
            {
                console.log('uuid', uuidv4());
                const o = {
                    id: uuidv4(),
                    state: true,
                    type: 'text',
                    special: false,
                    required: true,
                    text: '',
                    options: [],
                };

                let q = questions.filter( (i) => {return true});
                q.push( o );
                onSetQuestions(q);

            }
            break;   
            
            case 'text-block':
            {
                console.log('uuid', uuidv4());
                const o = {
                    id: uuidv4(),
                    state: true,
                    type: 'text-block',
                    special: true,
                    required: true,
                    text: '',
                    options: [],
                };

                let q = questions.filter( (i) => {return true});
                q.push( o );
                onSetQuestions(q);

            }
            break; 
            case 'text-block-confirm':
            {
                console.log('uuid', uuidv4());
                const o = {
                    id: uuidv4(),
                    state: true,
                    type: 'text-block-confirm',
                    required: true,
                    special: true,
                    text: '',
                    options: [{
                        id: uuidv4(),
                        text: 'I have read and agree to this statement.',
                    }],
                };

                let q = questions.filter( (i) => {return true});
                q.push( o );
                onSetQuestions(q);

            }
            break; 
            case 'radio':
            {
                console.log('uuid', uuidv4());
                const o = {
                    id: uuidv4(),
                    state: true,
                    type: 'radio',
                    required: true,
                    special: false,
                    text: '',
                    options: [{
                        id: uuidv4(),
                        text: '',
                        important: false
                      },
                      {
                        id: uuidv4(),
                        text: '',
                        important: false
                      }],
                };

                let q = questions.filter( (i) => {return true});
                q.push( o );
                onSetQuestions(q);

            }
            break;   


            case 'multi-checkbox':
            {
                console.log('uuid', uuidv4());
                const o = {
                    id: uuidv4(),
                    state: true,
                    type: 'multi-checkbox',
                    required: true,
                    special: false,
                    text: '',
                    options: [{
                        id: uuidv4(),
                        text: '',
                        important: false
                      },
                      {
                        id: uuidv4(),
                        text: '',
                        important: false
                      }],
                };

                let q = questions.filter( (i) => {return true});
                q.push( o );
                onSetQuestions(q);

            }
            break;   

                          
            case 'checkbox':
            {
                console.log('uuid', uuidv4());
                const o = {
                    id: uuidv4(),
                    state: true,
                    type: 'checkbox',
                    required: true,
                    text: '',
                    options: []
                };

                let q = questions.filter( (i) => {return true});
                q.push( o );
                onSetQuestions(q);

            }
            break; 

            default:
                console.error('unexpected type', type);
        }

    }

    const jsonUnescape = (str) =>{
        //return str.replace(/\\\\/g, "\\");
        return  str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\").replace(/\\n/g, "\n").replace(/\\r/g, "\r").replace(/\\t/g, "\t");

    }



    return ( 
            <Grid container justifyContent="flex-start" alignItems="stretch" className="rvQEng">  

                {
                    questions.map( (q, i) => (
                        <Grid item xs={12} className="WidgetBox mb-1" key={'wb-' + q.id}>
                            <Grid container justifyContent="flex-start" alignItems="flex-start" className="WidgetContainer" spacing={1}>  
                                <Grid item xs={1} md={1} className="colItem">S{i+1}</Grid>
                                {
                                    q.special !== true &&
                                    <Grid item xs={11} md={5} className="mb-xs-2">
                                        <TextField name={q.id} value={jsonUnescape(q.text)} onChange={(e) => {e.preventDefault(); onChangeQuestion(q.id, e.target.value)}} id={q.id} variant="outlined" placeholder="Enter your question" className="textWidget" fullWidth={true} helperText="Enter the question that the client is to answer. Max length 2048 characters. Allowed characters A-Z, a-z, 0-9, ':', ';', '*', '/' ,  '\' ,'-', '_', ' ', '?', '!', (' and ')'" required inputProps={{pattern:"[A-Za-z-._(), 0-9?!:;*/.']{1,2048}"}} />
                                    </Grid>
                                }
                                {
                                    q.special === true && (q.type === 'text-block'  || q.type === 'text-block-confirm') &&
                                    <Grid item xs={11} md={5} className="mb-xs-2">
                                        <TextField name={q.id} value={jsonUnescape(q.text)} rows={2} multiline maxRows={4} onChange={(e) => {e.preventDefault(); onChangeQuestion(q.id, e.target.value)}} id={q.id} variant="outlined" placeholder="Enter your text" className="textWidget" fullWidth={true} helperText="Enter the text that the client must read. Max length 2048 characters. Allowed characters A-Z, a-z, 0-9, ':', ';', '*', '/' , '-', '_', ' ', '?', '!', '.', (' and ')'" required inputProps={{pattern:"[A-Za-z-._(), 0-9?!:;*/']{1,2048}"}} />
                                        {
                                            q.special === true && (q.type === 'text-block-confirm') &&
                                            <div className="mt-2">
                                                <TextField   name={q.options[0].id} value={jsonUnescape(q.options[0].text)} onChange={(e) => {e.preventDefault(); onChangeQuestionOption(q.id, q.options[0].id, e.target.value)}} id={q.options[0].id}  variant="outlined" placeholder="Enter your checkbox confirmation text" className="textWidget" fullWidth={true} helperText="Enter the text that will be added to the checkbox. An example might be 'I have read and agree to this statement'. Max length 2048 characters. Allowed characters A-Z, a-z, 0-9, ':', ';', '*', '/' , '-', '_', ' ', '?', '!', '.', (' and ')'" required inputProps={{pattern:"[A-Za-z-._(), 0-9?!:;*./']{1,2048}"}} />
                                            </div>
                                        }
                                    
                                    </Grid>
                                }
        

                                <Hidden mdUp>
                                    <Grid item xs={1} className="mb-xs-2 mb-sm-2"></Grid>
                                </Hidden>
                                {
                                    <Grid item xs={11} md={3} className="mb-xs-2">
                                        <FormControl variant="outlined" className="typeWidget">
                                            <InputLabel htmlFor="type">Type</InputLabel>
                                            <Select
                                            value={q.type}
                                            disabled={q.special === true}
                                            label="Type"
                                            onChange={(e) => {onChangeType(q.id, e.target.value)}}
                                            inputProps={{
                                                name: 'type',
                                                id: 'type',
                                            }}
                                            >
                                            <MenuItem value={'input'}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <RemoveIcon />
                                                    <div>&nbsp;&nbsp;Single text box</div>
                                                </div>
                                            </MenuItem>
                                            <MenuItem value={'text'}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <SubjectIcon />
                                                    <div>&nbsp;&nbsp;Multi-line text box</div>
                                                </div>
                                            </MenuItem>
                                            <MenuItem value={'text-block'}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <ViewHeadlineIcon />
                                                    <div>&nbsp;&nbsp;Text block</div>
                                                </div>
                                            </MenuItem> 
                                            <MenuItem value={'text-block-confirm'}>                                            
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <CheckBoxIcon />
                                                    <div>&nbsp;&nbsp;Text block with read confirmation</div>
                                                </div>
                                            </MenuItem>                                                                               
                                            <MenuItem value={'radio'}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <RadioButtonCheckedIcon />
                                                    <div>&nbsp;&nbsp;Multiple choice</div>
                                                </div>
                                            </MenuItem>
                                            
                                            <MenuItem value={'checkbox'}>                                            
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <CheckBoxIcon />
                                                    <div>&nbsp;&nbsp;Checkbox</div>
                                                </div>
                                            </MenuItem>
                                            <MenuItem value={'multi-checkbox'}>                                            
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <LibraryAddCheckIcon />
                                                    <div>&nbsp;&nbsp;Multiple Checkbox options</div>
                                                </div>
                                            </MenuItem>


                                            </Select>
                                        </FormControl>                                    
                                    </Grid>
                                }

                                <Grid item xs={12} md={3} className="mb-xs-2">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={!!q.required || q.type === 'checkbox'}
                                            onChange={(e)=> {onSetRequired(q.id)}}
                                            color="primary"
                                            name={"required-" + q.id}
                                            disabled={q.type === 'checkbox' || q.type === 'text-block' || q.type === 'text-block-confirm'}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="Required"
                                    />                                                                            
                                    <IconButton aria-label="up" disabled={i===0} onClick={(e) => {onQuestionUp(q.id)}}>
                                        <ExpandLessIcon />
                                    </IconButton>                                    
                                    <IconButton aria-label="down" disabled={i===questions.length - 1} onClick={(e) => {onQuestionDown(q.id)}}>
                                        <ExpandMoreIcon />
                                    </IconButton>   
                                    <IconButton aria-label="delete" disabled={questions.length <= 1} color="primary" onClick={(e) => {onQuestionDelete(q.id)}}>
                                        <DeleteIcon />
                                    </IconButton>                                       
                                </Grid>


                                {
                                    (q.type === 'radio' || q.type === 'multi-checkbox') && 
                                    <React.Fragment>

                                        <Grid item xs={12} md={12} className="mb-2 mt-2" >
                                            <Divider variant="middle"  />
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            {

                                                q.options.map( (o, index) => (
                                                    <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={1} key={o.id}> 
                                                        <Grid item xs={1} className="colItem"><b>Option: {index + 1}</b></Grid>
                                                        <Grid item xs={7} md={5}>
                                                            <TextField name={o.id} value={jsonUnescape(o.text)} onChange={(e) => {e.preventDefault(); onChangeQuestionOption(q.id, o.id, e.target.value)}} id={o.id} variant="outlined" placeholder="Enter the option text" className="textWidget" fullWidth={true}  helperText="This is one of the multple choice options that the customer is allowed to select. Allowed characters A-Z, a-z, 0-9, '-', '_', '/', ' ', '?', '!', (' and ')'" required inputProps={{pattern:"[A-Za-z-._(), 0-9?!/']{1,250}"}} />
                                                        </Grid>
                                                        <Grid item xs={4} md={6} >
                                                            <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={!!o.important}
                                                                    onChange={(e)=> {onSetImportant(q.id, o.id)}}
                                                                    color="primary"
                                                                    name={"important-" + o.id}
                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                />}
                                                                label="Important"
                                                            />   
                                                            <IconButton aria-label="up" disabled={index===0} onClick={(e) => {onOptionUp(q.id, o.id)}}>
                                                                <ExpandLessIcon />
                                                            </IconButton>                                    
                                                            <IconButton aria-label="down" disabled={index === (q.options.length - 1)} onClick={(e) => {onOptionDown(q.id, o.id)}}>
                                                                <ExpandMoreIcon />
                                                            </IconButton>   
                                                            <IconButton aria-label="delete" color="primary" onClick={(e)=>{onDeleteQuestionOption(q.id, o.id)}}>
                                                                <DeleteIcon />
                                                            </IconButton>                                       
                                                        </Grid>                                              
                                                    </Grid>
                                                ))
                                            }
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Grid container justifyContent="flex-start" alignItems="flex-end" spacing={1}> 
                                                <Grid item xs={3} md={3}>
                                                    <Button variant="contained" size="small" color="primary" startIcon={<AddCircleOutlineIcon />}  onClick={(e)=>{onAddQuestionOption(q.id)}}>
                                                        Add option
                                                    </Button>    
                                                </Grid>                                               
                                            </Grid>                                               
                                        </Grid>  
                                    </React.Fragment>
                                }
                            </Grid>
                        </Grid>  
                    ))
                }


                <Grid item xs={12} className="WidgetBox mt-4" key={'wb-add'}>
                    <Grid container justifyContent="center" alignItems="center" className="WidgetContainer" spacing={1}>  
                        <Grid item>
                            <AddWidget onSelect={onAddItem} />
                        </Grid>
                    </Grid>
                </Grid>

                     
            </Grid>

     );
}
 
export default Engine;