import React, { Component } from 'react';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import '../core/dashboard.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {setStep, reloadData, setCloneState} from '../redux/actions';
import 'react-dates/initialize';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import 'react-dates/lib/css/_datepicker.css';
import './setup.scss';


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,       
    },          
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    alertSpace:{
        textAlign: 'center',
    } 

});



function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        reloadData: spec => dispatch(reloadData(spec)),
        setCloneState: spec => dispatch(setCloneState(spec))
    };
  }



class PolicyManagerModule extends Component{

    constructor( props )
    {
        super( props );
        this.state = {
            event_pass_cancellations: props.data.pass || false,
            event_card_cancellations: props.data.card || false,
            event_pass_cancellation_time: props.data.pass_time || 0,
            event_card_cancellation_time: props.data.card_time || 0,
            focusedInput: null,
            security: props.security,
            loading: false,
            error: '',
            ok: false,

        };


        this.onPassCancelChange = this.onPassCancelChange.bind(this);
        this.onCardCancelChange = this.onCardCancelChange.bind(this);
        this.onPassTimeChange = this.onPassTimeChange.bind(this);
        this.onCardTimeChange = this.onCardTimeChange.bind(this);
        this.onSave           = this.onSave.bind(this);
    }




    onPassTimeChange(e)
    {
        this.setState({'event_pass_cancellation_time': parseInt(e.target.value)});
    }

    onPassCancelChange( o )
    {
        this.setState({'event_pass_cancellations': !!!this.state.event_pass_cancellations});
    }

    onCardCancelChange( o )
    {
        this.setState({'event_card_cancellations': !!!this.state.event_card_cancellations});
    }

    onCardTimeChange(e)
    {
        this.setState({'event_card_cancellation_time': parseInt(e.target.value)});

    }



    onSave(e)
    {
        e.preventDefault();
        var pass_cancellation    = encodeURI( this.state.event_pass_cancellations );
        var card_cancellation    = encodeURI( this.state.event_card_cancellations );

        var pass_time = encodeURI( this.state.event_pass_cancellation_time );
        var card_time = encodeURI( this.state.event_card_cancellation_time );
        var chain = '';
        chain = chain +  "&pass_cancel_state=" + pass_cancellation;
        chain = chain +  "&card_cancel_state=" + card_cancellation;
        chain = chain +  "&pass_cancel_time=" + pass_time;
        chain = chain +  "&card_cancel_time=" + card_time;
        var _this = this;

        fetch("/company/ajax-set-policies", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({prompt_cancel: false, subscription_focus: null, ok: true}, function()
                {
                    setTimeout(() => {
                        _this.setState({ok: false});
                    }, 20000);
                });
            }
            else
            {
                _this.setState({prompt_cancel: false, prompt_unsuspend: false, error: response.error});
            }
        }).catch(function(err){

            _this.setState({prompt_cancel: false, prompt_unsuspend: false, error: 'Network error - unable to communicte with the server.'});
            console.error('Failed to delete the mandate', err);


        });   

    }
  
    render()
    {
        var classes = this.props.classes;


        return (
            <div className={"rvNSPolicy " + classes.root}>


                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        {window.vocab.topbar}
                    </Typography>
                    </Toolbar>
                </AppBar>

                <form onSubmit={this.onSave}>
                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >

                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <Grid container justify="flex-start" alignItems="stretch" direction={'row'}  spacing={24}>
                                        <Grid item xs={12} md={12}>
                                            <h3 className="sectionTitleL1">Event Cancellation Policy configurations</h3>
                                            <p className="sectionIntro">
                                                This section allows you to configure the cancellation policies. This control what customers can and cannot do in terms of cancellations within their account administration portal.
                                            </p>

                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <h4 className="sectionTitleL2">Bookings made via a pass</h4>
                                            <p>This configuration affects bookings of events where the original payment was made using pass credit(s)</p>
                                            <h5 className="formfield">Are cancellations allowed or disallowed for events when payment was made via a pass?</h5>
                                            <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.event_pass_cancellations}
                                                    onChange={this.onPassCancelChange}
                                                    value="1"
                                                    color="primary"
                                                />
                                                }
                                                label={<span className="switchLabel">Allow customers to cancel event bookings if purchased the event via a pass credit</span>}
                                            /> 
                                            <p className="caption">If enabled, clients will be able to make cancellations to bookings if they originally made the booking via a pass.</p>                               
                                            <h5 className="formfield">If allowed, then how many hours before class can a cancellation made?</h5>
                                            <TextField
                                                id="outlined-number"
                                                label="hours"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={this.state.event_pass_cancellations === true ? this.state.event_pass_cancellation_time:0}
                                                onChange={this.onPassTimeChange}
                                                disabled={!!!this.state.event_pass_cancellations}
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                                            />
                                            <p className="caption">If cancellations are allowed, this defines cut-off time before the class commences for cancellations. For example, if you allow cancellations up to 6 hours before class, when the customer has paid via a pass, then you should enter 6 in this field.</p>                               


                                        </Grid>
                                        <Grid item xs={12} md={12} >
                                            <h4 className="sectionTitleL2">Bookings made via a credit or debit card</h4>
                                            <p  className="sectionIntro">This configuration affects bookings of events where the original payment was made directly via a credit or debit card.</p>
                                            <Alert severity="warning">If you allow cancellations for credit/debit payments, the original fees to reservie and to stripe are not refunded. We recommend that you disable this function!</Alert>
                                            <h5 className="formfield">Are cancellations allowed or disallowed for events when payment was made directly via a credit or debit card?</h5>

                                            <FormControlLabel
                                                control={
                                                <Switch
                                                    checked={this.state.event_card_cancellations}
                                                    onChange={this.onCardCancelChange}
                                                    value="1"
                                                    color="primary"
                                                />
                                                }
                                                label={<span className="switchLabel">Allow customers to cancel event bookings if purchased the event directly via credit or debit card payment</span>}
                                            /> 
                                            <p className="caption">If enabled, clients will be able to make cancellations to bookings if they originally made the booking directly via a credit/debit card payment.</p>                               
                                            <h5 className="formfield">If allowed, then how many hours before class can a cancellation be made?</h5>
                                            <TextField
                                                id="outlined-number"
                                                label="hours"
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={this.state.event_card_cancellations === true ? this.state.event_card_cancellation_time:0}
                                                onChange={this.onCardTimeChange}
                                                disabled={!!!this.state.event_card_cancellations}
                                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                                            />
                                            <p className="caption">If cancellations are allowed, this defines cut-off time before the class commences for cancellations. For example, if you allow cancellations up to 6 hours before class, when the customer has paid directly via a credit or debit card, then you should enter 6 in this field.</p>                               

                                        </Grid> 
                                        <Grid item xs={12} md={12} className={classes.alertSpace}>
                                            <div className="area">
                                            {this.state.error.length > 0 && this.state.ok === false && (
                                                  <Alert severity="error">Opps something went wrong: {this.state.error}</Alert>
                                            )}
                                            {
                                                this.state.ok && <Alert severity="success">Policies have been updated</Alert>
                                            } 
                                            </div>
                                        </Grid>                                          
                                        <Grid item xs={12} md={12}>
                                            <Button type="submit" variant="contained" className="submit" color="primary" >Save</Button>
                                        </Grid>                                     
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>                                    

                </Grid>
  
                </form>


                
            </div>            
        );
    }

};



const PolicyManager = connect(null, mapDispatchToProps)(PolicyManagerModule);
export default withStyles(styles)(PolicyManager);
