import React from 'react';
import { TextField } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';

const QuestionaireTextBox = ({id, text, index, required, answer, onAnswer }) => {

    return ( 
        <React.Fragment>
            <div className='customer_questionnaire_field'>
                <InputLabel>{`${index} ${text}`}</InputLabel>
                <FormControl fullWidth>
                    <TextField  placeholder="" id={id} name={id} value={answer.answer} required={required} autoFocus={id===1? true: false } autoComplete={id + Date.now().toString()} onChange={(e) => {
                        console.log("Updated text", e.target.value);
                        onAnswer(id, e.target.value);
                    }} />
                </FormControl>                
            </div>
        </React.Fragment>
     );
}
export default QuestionaireTextBox;