import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './stepper';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


import Divider from '@material-ui/core/Divider';

import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import Dialog from '../core/dialog';
import AddIcon from '@material-ui/icons/Add';

import  {
    EVENT_APP_CACHE_STATE_WIZARD_4,
    EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES,
    EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD,
} from '../redux/types';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";


    const styles = theme => ({
        root: {
            flexGrow: 1,
          },          
          title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
          },
          formControl: {
            margin: '20px',
            minWidth: 120,
          },
          textInput:{
            marginTop: '20px'
          },
          switchLabel:{
              color: 'rgba(0,0,0,0.6)',
          },
          captionText:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',

          },
          sectionDescription:{
            color: 'rgba(0,0,0,0.6)',    
          },            
          captionSection:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',
            marginBottom: '10px',
            lineHeight: '1.5em',
          },
          schedTypeSelection:{
              marginLeft: '10px'
          },
          what:{
              width: '50%'
          },
          fabDelete:{
              backgroundColor: '#b80000',
              color: '#fff',
              '&:hover':{
                  backgroundColor: '#940000',
              }
          },        
          fabIcon:{
              '& .MuiSvgIcon-root':{
                  fill: '#fff',
                  color: '#fff',
                  '&:hover':{
                      color: '#ffe',
                  }
              }
          }            
});



class OptionRow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            iteration: props.data.iteration,
            id: props.data.id,
            update: props.update,
            delete: props.delete,
            setvalid: props.setValid,
            errors:{},
            delete_ready: false,
            mode: props.mode,
            disable_passes: true,
            disable_card: true,
        }

        this.state['errors'] = {
            price: {
                object: 'price',
                rule: /^([0]+\.{0,1}[0]{0,2})|([1-9]+[0-9]*\.{0,1}\d{0,6})$/,
                state: false, 
                ok: "Enter Price per class", 
                error: '0 or greater than 1.',
                ref: React.createRef(),                       
            },          
            name: {
                object: 'name',
                rule: /^[a-zA-Z0-9\.-_, \W]+$/,
                state: false, 
                ok: "What type of ticket is being sold?", 
                error: 'Allowed characters: A-Z, a-z, 0 - 9, .,-_',                 
                ref: React.createRef(),                       
            }, 
            credits: {
                object: 'credits',
                rule: /^\d+$/,
                state: false, 
                ok: "Cost in pass credits", 
                error: 'Enter pass credits',                 
                ref: React.createRef(),                       
            }, 
            stock: {
                object: 'stock',
                rule: /^\d+$/,
                state: false, 
                ok: "Space available", 
                error: 'Enter spaces',                 
                ref: React.createRef(),                       
            }  
        };        

        console.log('DeleteButton', this.state);
        this._handleDelete = this._handleDelete.bind(this);
        this.onNameChange  = this.onNameChange.bind(this);
        this.onPriceChange  = this.onPriceChange.bind(this);
        this.onCreditsChange  = this.onCreditsChange.bind(this);
        this.onStockChange  = this.onStockChange.bind(this);
        this.onDeleteComplete  = this.onDeleteComplete.bind(this);
        this.onCancel    = this.onCancel.bind(this);
        this.isValid    = this.isValid.bind(this);
        this.isAllValid    = this.isAllValid.bind(this);
        if(this.state.mode & EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES)
        {
            this.state.disable_passes = false;
        }
        if(this.state.mode & EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD)
        {
            this.state.disable_card = false;
        }       
    }
    componentDidMount( )
    {
        this.isAllValid();
    }

    isAllValid()
    {
        var errors = Object.assign({}, this.state.errors);
        var obj = {};
        

        var is_valid = false;
        var regex = RegExp(this.state.errors['name'].rule);
        is_valid = regex.test(this.props.data.name);

        console.log('Checking name: ', this.props.data.name, "rule ", this.state.errors['name'].rule);
        if(!is_valid)
        {
            console.log('name validation error', this.state.name);
            this.state.errors['name'].state = true;
            obj.invalid = true;
            this.setState({'errors': errors});
            this.state.setvalid(obj);
            return;

        }

        regex = RegExp(this.state.errors['stock'].rule);
        is_valid = regex.test(this.props.data.stock);        

        if(!is_valid)
        {
            console.error('stock validation log', this.props.data.stock);
            errors['stock'].state = true;
            obj.invalid = true;
            this.setState({'errors': errors});
            this.state.setvalid(obj);
            return;            
        }

        if(this.props.data.mode & EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES)
        {

            regex = RegExp(this.state.errors['credits'].rule);
            is_valid = regex.test(this.props.data.credits);
            if(!is_valid)
            {
                console.log('credits validation error', this.props.data.credits);
                errors['credits'].state = true;
                obj.invalid = true;
                this.setState({'errors': errors});
                this.state.setvalid(obj);
                return;                
            }

        }
        if(this.props.data.mode & EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD)
        {

            regex = RegExp(this.state.errors['price'].rule);
            is_valid = regex.test(Number(this.props.data.price).toFixed(2));
            if(!is_valid)
            {
                errors['price'].state = true;
                obj.invalid = true;
                this.setState({'errors': errors});
                this.state.setvalid(obj);
                return;                
            }            
      
        } 
  
        obj.invalid = false;
        obj.id = this.state.id;
        console.log('sending state', obj);
        this.state.setvalid(obj);

    }

    isValid( rule, value )
    {
        var errors = Object.assign({}, this.state.errors);
        var regex = RegExp(this.state.errors[rule].rule);
        console.log('regex', this.state.errors[rule].rule, value);
        if(regex.test(value) === false)
        {
            window.scrollTo(0, this.state.errors[rule].ref.current.offsetTop);                
            errors[rule].state = true;
            this.setState({'errors': errors});
            return false;
        }
        else
        {
            console.log('Passes test');
            errors[rule].state = false;
            this.setState({'errors': errors});
            return true;
        }                
    }

    _handleDelete = () => {
        this.setState({'delete_ready': true});
    }

    onCancel(event)
    {
        this.setState({'delete_ready': false});        
    }   
 
    onDeleteComplete = (event) => {
        var x = {};
        x.iteration = this.state.iteration;
        x.id = this.state.id;
        x.name = this.state.name;
        x.price = this.state.price;
        x.credits = this.state.credits;
        x.stock = this.state.stock;

        this.state.delete(x);

        this.setState({'delete_ready': false});
    }

    onNameChange = (event) => {
        var _this = this;
        var z = event.target.value;
        if(this.isValid('name', z))
        {
            this.state.update('name', this.state.id, z, function(){
                _this.isAllValid()  ;          
            }); 
        }
        else
        {
            this.state.update('name', this.state.id, z, function(){}); 
        }
       // this.isAllValid();
    }
    onPriceChange = (event) => {
        
        var _this = this;
        var x = {};
     //   var z = Number(event.target.value).toFixed(2);

        var z = event.target.value;


        if(!isNaN(z) && z !== '')
        {
            if(z < 0.8)
            {
                z = 0.00;
            }
        }
        if(z < 0)
        {
            z = -1 * z;
        }
    //    if(z < 0)
    //    {
    //        z = 0.00;
    //    }        
        //this.setState({'price': z}, this.isAllValid); // we need to keep a local copy as we do not want the parent to re-render since focus of form is lost       
        if(this.isValid('price', z))
        {
            //var z = Number(event.target.value).toFixed(2);
            if(z < 0)
            {
                z = -1 * z;
            }
            this.state.update('price', this.state.id, z, function(){
                _this.isAllValid()  ;          
            }); 
        }
        else
        {
            this.state.update('price', this.state.id, z, function(){
            });
        }
    }
    onCreditsChange = (event) => {
        var _this = this;
        //this.setState({'credits': z}, this.isAllValid); // we need to keep a local copy as we do not want the parent to re-render since focus of form is lost       
        if(this.isValid('credits', event.target.value))
        {
            this.state.update('credits', this.state.id, event.target.value, function(){
                _this.isAllValid()  ;          
            }); 

        }
        else
        {
            this.state.update('credits', this.state.id, event.target.value, function(){

            }); 
        }
    }
    onStockChange = (event) => {
        var _this = this;
        //var z = Number(event.target.value).toFixed(0);
        //if(z < 0)
        //{
        //    z = 0;
        //}

        let x = parseInt(event.target.value);
        if(x < 0)
        {
            x = 0;
        }

        //this.setState({'stock': z}, this.isAllValid); // we need to keep a local copy as we do not want the parent to re-render since focus of form is lost       
        if(this.isValid('stock', x))
        {
            this.state.update('stock', this.state.id, x, function(){
                _this.isAllValid()  ;          
            }); 
        }
        else
        {
            this.state.update('stock', this.state.id, x, function(){
            }); 
        }

}    
    render() {
        var classes= this.props.classes;

        return (
            <div>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={1}>
                        <b>Option #{Number(this.state.iteration + 1).toFixed(0)}.</b>
                    </Grid>
                    <Grid item md={4} xs={12} ref={this.state.errors['name'].ref}>
                        <TextField
                            className={classes.textInput}
                            value={this.props.data.name}
                            onChange={this.onNameChange}
                            margin="none"
                            variant="outlined"
                            required
                            fullWidth
                            placeholder="Add details of what is being sold e.g 1 place."
                            InputLabelProps={{
                                shrink: true,
                            }}     
                            error={this.state.errors.name.state}                               
                            autoFocus={true}
                            label="What is being sold"
                            helperText={this.state.errors.name.state? this.state.errors.name.error: this.state.errors.name.ok}
                            />                                        
                    </Grid> 
                        <Grid item md={2} xs={12} ref={this.state.errors['price'].ref}>
                            <TextField
                                className={classes.textInput}
                                onChange={this.onPriceChange}
                                margin="none"
                                variant="outlined"
                                fullWidth
                                placeholder="1.00"
                                type="number" 
                                disabled={this.state.disable_card}
                                value={this.state.disable_card?0:this.props.data.price}
                                InputLabelProps={{
                                    shrink: true,
                                }}  
                                required
                                inputProps={{pattern:"[0-9]{1,10}[.]{0,1}[0-9]{0,6}", step:0.01}}                        
                                error={this.state.errors.price.state}                               
                                label="Price"
                                helperText={this.state.errors.price.state? this.state.errors.price.error: this.state.errors.price.ok}
                                />                                         
                        </Grid>
                        <Grid item md={2} xs={12} ref={this.state.errors['credits'].ref}>
                            <TextField
                                className={classes.textInput}
                                onChange={this.onCreditsChange}
                                margin="none"
                                variant="outlined"
                                fullWidth
                                placeholder="1"
                                InputLabelProps={{
                                    shrink: true,
                                }}  
                                step={1} 
                                type="number" 
                                required
                                disabled={this.state.disable_passes}
                                value={this.props.data.credits}                           
                                error={this.state.errors.credits.state}                               
                                label="Credit value"
                                helperText={this.state.errors.credits.state? this.state.errors.credits.error: this.state.errors.credits.ok}
                            />                                         
                        </Grid>                                                
                        <Grid item md={2} xs={12} ref={this.state.errors['stock'].ref}>
                            <TextField
                                className={classes.textInput}
                                onChange={this.onStockChange}
                                required
                                margin="none"
                                variant="outlined"
                                fullWidth
                                placeholder="1"
                                InputLabelProps={{
                                    shrink: true,
                                }}  
                                step={1} 
                                type="number" 
                                value={this.props.data.stock}                                
                                error={this.state.errors.stock.state}                               
                                label="Spaces available"
                                helperText={this.state.errors.stock.state? this.state.errors.stock.error: this.state.errors.stock.ok}
                            />                                         
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <IconButton name="delete" className={classes.fabDelete} aria-label="Delete option" onClick={this._handleDelete}>
                                <DeleteForeverIcon className={classes.fabIcon}/>
                            </IconButton>            
                        </Grid>  
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                </Grid>                
                <Dialog onCancel={this.onCancel} actions={[{action: this.onDeleteComplete, label:"OK" }]} danger={true} state={this.state.delete_ready} body="You are about to delete this option. Do you want to continue" title="Warning" />
            </div>

        )
    }
}

const OverrideTotal = ({max, required, limit, classes, onUpdateMax, onUpdateState }) => {

    return ( 
        <React.Fragment>
            <Grid item xs={12} md={5}>&nbsp;</Grid>
            <Grid item xs={12} md={4}>
                <FormControlLabel
                        control={
                        <Switch
                            checked={required}
                            onChange={onUpdateState}
                            value="1"
                            color="primary"
                        />
                        }
                        label={<span className={classes.switchLabel}>Override maximum spaces</span>}
                    /> 
                    <br/>
                <Typography variant="caption" className={classes.captionText}>You may override the upper limit of spaces.</Typography>                               
                                                                
            </Grid>
            <Grid item xs={2} md={2}>
                <TextField
                    className={classes.textInput}
                    onChange={onUpdateMax}
                    required={!required}
                    disabled={!required}
                    margin="none"
                    variant="outlined"
                    fullWidth
                    placeholder={1}
                    InputLabelProps={{
                        shrink: true,
                    }}  
                    step={1} 
                    type="number" 
                    value={required ? max : 0} 
                    error={!!required && max !== limit}                                                              
                    label={"Maximum spaces"}
                    helperText="This should be less than or equal to the sum of the individual option spaces."
                />                 
            </Grid>
            <Grid item xs={12} md={1}>&nbsp;</Grid>
        </React.Fragment>
        

     );
}


function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventsStep4Module extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            options: [],
            max: props.cache.max || 0,
            overridemax: !!props.cache.overridemax,
            maxmismatch: false,
            error: false,
            refresh: false,
            mode: props.mode,
            disable_passes: true,
            disable_card: true,
        }
        this.queue = [];  
        
        for(var i = 0; i < props.cache.options.length; i++)
        {
            if(props.cache.options[i].id === null)
            {
                continue;
            }

            var st = false;
            console.log('EventsStep4Module checking', props.cache.options[i]);

            if(typeof props.cache.options[i].new !== 'undefined')
            {
                console.log('is New', props.cache.options[i].new);
                st = props.cache.options[i].new;
            }

            this.state.options.push({iteration: i, id: props.cache.options[i].id, name:props.cache.options[i].name, price: props.cache.options[i].price, stock: props.cache.options[i].stock, credits: props.cache.options[i].credits, deleted: props.cache.options[i].deleted, invalid: false, new: st });
        }
    

        if(this.state.options.length === 0)
        {
            var id = uuidv1().replace(/-/g,"");

            this.state.options.push({iteration: 0, id: id, name:'', price:0.00, stock:0, credits:0, deleted: false, invalid: true, new:  true });
        }
        obj = this.state;

        let v = this.state.max;
        if(v <= 0)
        {
            v =  1;
        }
        const x = this.state.options.reduce((v, o) =>{
            const s = parseInt(o.stock);
            return s + v;
        }, 0);

        if(v > x)
        {
            v = x;
        }

        this.state.max = v;

        console.log('EventsStep4Module', this.state.options);

 


        this.state.security = props.security;
        this.myRef = React.createRef()  
        this.onOptionUpdate   = this.onOptionUpdate.bind(this); 
        this.onOptionDelete   = this.onOptionDelete.bind(this); 
        this.onDoPrevious   = this.onDoPrevious.bind(this);
        this.onDoNext   = this.onDoNext.bind(this);
        this.onAddOption = this.onAddOption.bind(this);
        this.setValid   = this.setValid.bind(this);
        this.onOverrideUpdateMax = this.onOverrideUpdateMax.bind(this);
        this.onOverrideUpdateState = this.onOverrideUpdateState.bind(this);
        this.calculateMax = this.calculateMax.bind(this);
        this._critical  = false;

    }


    calculateMax( m )
    {
        let v = m;

        if(!!!this.state.overridemax)
        {
            return 0;
        }

        const options = this.state.options.filter(function(obj) {
            return !obj.deleted && !obj.disabled;
        })


        const x = options.reduce((v, o) =>{
            const s = parseInt(o.stock);
            return s + v;
        }, 0);

        if(v > x)
        {
            v = x;
        }
        else if(v < 0)
        {
            v = x;
        }

        return v;
    }



    onOverrideUpdateMax( e )
    {       
        let v = parseInt(e.target.value);
        this.setState({max:  this.calculateMax(v)});
    }

    onOverrideUpdateState( e )
    {
        let max = 0;
        if(!!!this.state.overridemax)
        {
            max = this.calculateMax(-1);
        }
        this.setState({overridemax: !!!this.state.overridemax, max: max});
    }


    setValid( obj )
    {
        if(this._critical)
        {
            var _this = this;
            setTimeout(function(){
                _this.setValid( obj );
            }, 100)
            return;
        }
        this._critical = true;
        var x = JSON.parse(JSON.stringify(this.state.options));
        for(var i = 0; i < x.length; i++)
        {
            console.log('processing update...', x[i].id, obj.id,  (x[i].id === obj.id? true : false) );
            if((x[i].id === obj.id))
            {
                console.log('setting state...', obj.invalid);
                x[i].invalid = obj.invalid;
                break;
            }
        }
        
        this.setState({options: x, max: this.calculateMax(this.state.max)});
        this._critical = false;

    }




    onDoPrevious(event)
    {
        if(this.isValid())
        {
            var x =  JSON.parse ( JSON.stringify( this.state.options ));

            for(var i = 0; i < x.length; i++)
            {
                x[i].price = parseFloat(x[i].price);
            }

            var o = {
                  store_previous: true,
                  options: x,
                  state: EVENT_APP_CACHE_STATE_WIZARD_4
                };
                console.log("sending data", o);
                this.setState({'error': false});
                this.props.rewindStep(o);      
    
        }
        else
        {
            var o = { 
                state: EVENT_APP_CACHE_STATE_WIZARD_4
            };
            this.props.rewindStep(o);      
        }
        
  
    }

    onOptionUpdate(what, id, v, f)
    {
        var x = JSON.parse(JSON.stringify(this.state.options));

        //console.log('processing update', obj, x, x.length);

        for(var i = 0; i < x.length; i++)
        {
           // console.log('processing update...', x[i].id, obj.id, (x[i].id === obj.id)? true: false);
            if((x[i].id === id))
            {
                x[i][what] = v;
                break;
            }
        }
        this.setState({'options': x}, function(){
            f();
        });
    }  

    onOptionDelete(obj)
    {
        var x = JSON.parse(JSON.stringify(this.state.options));

        console.log('delete update', obj, x, x.length);
        var it_length = 0; 

        for(var i = x.length - 1; i >= 0 ; i--)
        {
            console.log('delete update...', x[i].id, obj.id);
            if((x[i].id === obj.id))
            {
                if(x[i].new)
                {
                    x.splice(i, 1);
                }
                else
                {
                    x[i].deleted = true;
                    console.log('setting configuration', x, i);
                }
            }
        }
        for(var i = 0; i < x.length ; i++)
        {
            if(x[i].deleted === false)
            {
                x[i].iteration = it_length;
                it_length += 1;
            }  
        }
        let overridemax = this.state.overridemax;
        let max = this.state.max;

        if(x.length <= 1)
        {
            overridemax = false;
            max = 0;
        }

        this.setState({options: x, overridemax: overridemax, max: max});

    } 
    onAddOption(event)
    {
        var x = JSON.parse(JSON.stringify(this.state.options));
        var it_length = 0;
        for(var i = 0; i < x.length; i++)
        {
            console.log('delete update...', x[i].id, obj.id);
            if(x[i].deleted === false)
            {
                x[i].iteration = it_length;
                it_length += 1;
            }
        }   
        var id = uuidv1().replace(/-/g,"");

        x.push({iteration: it_length, id: id, name:'', price:0.00, stock:0, credits:0, deleted: false, invalid: true, new: true });
        this.setState({options: x});

    } 


    isValid()
    {
        var count = 0;

        for(var i = 0; i < this.state.options.length; i++)
        {
            console.log('is valid...', this.state.options[i]);
            if(this.state.options[i].deleted === false)
            {
                count += 1;
                if(this.state.options[i].invalid)
                {
                    return false;
                }   
            }
        } 
        if(count === 0)
        {
            return false;
        }

        return true;

    }

    
    
    onDoNext(event)
    {
        event.preventDefault();

        //if(regex.test(this.state.name))
        if(this.isValid())
        {
            var x =  JSON.parse ( JSON.stringify( this.state.options ));

            for(var i = 0; i < x.length; i++)
            {
                x[i].price = parseFloat(x[i].price || 0);
            }

            var o = {
                  options: x,
                  max: this.state.max,
                  overridemax: this.state.overridemax,
                  state: EVENT_APP_CACHE_STATE_WIZARD_4
                };
                console.log("sending data", o);
                this.setState({'error': false});
                this.props.setStep(o);
    
        }
        else
        {
            this.setState({'error': true});
        }

    }
  
    




  

    render()
    {
        var classes = this.props.classes;
        const options = this.state.options.filter(function(obj) {
            return !obj.deleted && !obj.disabled;
        })

        console.log('render', this.state);

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Event editor
                    </Typography>
                    </Toolbar>
                </AppBar>
                <form onSubmit={this.onDoNext}>
                <Grid container direction="row" justify="flex-start" alignContent="center" alignItems="center" spacing={2} >


                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="center" spacing={2}>
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">

                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        
                                        <Grid item xs={12} md={12}>
                                            <StepperWidget key={uuidv1()} step={2}/>

                                        </Grid>
                                        
                                        
                                        <Grid item xs={12}>
                                            <div className="formSection">Pricing options</div>                               
                                            <p className="sectionDescription">
                                                The pricing option defines what is being purchased, the number of places that you have available and the price. For standard events there is normally only one pricing option. However, where necessary you can add multiple pricing options.  
                                            </p>                                        
                                        </Grid>

                                        {options.map(el => (
                                            <Grid item key={el.id} xs={12}>
                                                <OptionRow data={el} 
                                                            mode={this.state.mode} 
                                                            key={el.iteration} 
                                                            delete={this.onOptionDelete} 
                                                            update={this.onOptionUpdate} 
                                                            setValid={this.setValid}
                                                            classes={classes} />
                                            </Grid>
                                        ))}
                                        { this.state.error && (
                                            <Grid item xs={12}>
                                                <div className="error">
                                                    There is a problem with one of more of the options. Please check and try again.    
                                                </div>
                                            </Grid>
                                        )}
                                        {
                                            options.length > 1 && (
                                                <OverrideTotal classes={classes} max={this.state.max || this.calculateMax(-1)} limit={this.calculateMax(-1)} required={this.state.overridemax} onUpdateMax={this.onOverrideUpdateMax} onUpdateState={this.onOverrideUpdateState} />
                                            )
                                        }
 
                                        <Grid item  xs={12}>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            className={classes.button}
                                            startIcon={<AddIcon />}
                                            onClick={this.onAddOption}
                                            >
                                            add option
                                        </Button>                                            
                                        </Grid>


                                        <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                size="large"
                                                className="rv-eng-next-button"
                                                startIcon={<NavigateBeforeIcon />}
                                                onClick={this.onDoPrevious}
                                                fullWidth={true}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4} md={6}></Grid>
                                        <Grid item xs={4} md={3}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="rv-eng-next-button"
                                                endIcon={<NavigateNextIcon />}
                                                fullWidth={true}
                                            >
                                                Next
                                            </Button>  
                                                                
                                        </Grid>                     
                                    </Grid>


                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>
                </form>
                
            </div>            
        );
    }

};



const EventsStep4 = connect(null, mapDispatchToProps)(EventsStep4Module);
export default withStyles(styles)(EventsStep4);
