import React, { Component } from 'react';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField  from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loading from '../core/loading';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl  from '@material-ui/core/FormControl';




const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    section:{
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    avatarBox:{
        width: "100%",

        
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem',
        marginTop: '0px'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    passField:{
        marginTop: '15px',
        marginBottom: '15px',
    },
    alignCenter:
    {
        textAlign: 'center'
    },
    passSectionSpace:
    {
        marginTop: '10px'
    },    
    buttonGo:{
        backgroundColor:'rgba(145,215, 110, 0.8)',
        color: '#ffffff',
        '&:hover':{
            backgroundColor:'rgba(145,215, 110, 1)',
        },
        marginTop: '20px',
        marginBottom: '20px',
    }



});






const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });




function mapDispatchToProps(dispatch) {
    return {
    };
  }


class PassesAddPanelManagerModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            security: props.security,
            options: props.vouchers||[],
            selected: this.props.data.vouchers||[],
            id: this.props.data.id || null,
            open: props.open,
            passname: this.props.data.name ||'',
            passdescription: this.props.data.description || '',
            passcredits: this.props.data.credits || 0,
            passexpires: this.props.data.expires || 0,
            passprice: this.props.data.price || '0.00',
            period: this.props.data.period || '0',
            limited: (this.props.data.l == 2) ? true : false,
            l_state: this.props.data.l || 1,
            subscriptionlimited: (parseInt(this.props.data.subscriptionlimited) === 1) || false,
            passpurchaselimited:  (parseInt(this.props.data.passpurchaselimited) === 1) || false,
            maxlimit: 0,
            maxclientpurchaselimit: Number(parseInt(this.props.data.maxclientpurchaselimit) > 0 ? parseInt(this.props?.data?.maxclientpurchaselimit) : 1).toFixed(0),
            updating: false,
            error: null,
        };

      //  this.state.selected.push({id: 1, name:'wibble'});

        if(this.props.data.limit_value)
        {
            this.state.maxlimit = Number(this.props.data.limit_value).toFixed(0);
        }
        else
        {
            this.state.maxlimit = 0;
        }

        this.onManagePassPanelClose  = this.onManagePassPanelClose.bind(this);
        this.onUpdatePassName        = this.onUpdatePassName.bind(this);
        this.onUpdatePassDescription = this.onUpdatePassDescription.bind(this);
        this.onVoucherOptionSelect   = this.onVoucherOptionSelect.bind(this);
        this.onUpdatePassExpires     = this.onUpdatePassExpires.bind(this);
        this.onUpdatePassCredits     = this.onUpdatePassCredits.bind(this);
        this.onHandlePassUpdated     = this.onHandlePassUpdated.bind(this);
        this.onUpdatePassPrice       = this.onUpdatePassPrice.bind(this);
        this.onLimitChange           = this.onLimitChange.bind(this);
        this.onUpdatePassLimit       = this.onUpdatePassLimit.bind(this);
        this.onChangePeriodType      = this.onChangePeriodType.bind(this);
        this.onSubscriptionLimitChange = this.onSubscriptionLimitChange.bind(this);
        this.onPassPurchaseLimitChange = this.onPassPurchaseLimitChange.bind(this);
        this.onUpdatePassPurchaseLimit = this.onUpdatePassPurchaseLimit.bind(this);


        // console.log("Final TopNav",this.state);
    }

    onHandlePassUpdated(event)
    {
        event.preventDefault();
        event.nativeEvent.stopImmediatePropagation();

        var _this = this;

        var name = this.state.passname || "";
        var description = this.state.passdescription || "";
        var credits = this.state.passcredits || "";
        var expires = this.state.passexpires ||  "";
        var price = this.state.passprice ||  "";
        var id = this.state.id ||  null;
        var limit   = this.state.l_state;
        var period  = this.state.period;
        var limit_value   = this.state.maxlimit;
        var subscriptionlimited   = this.state.subscriptionlimited;

        var vid = JSON.stringify(this.state.selected);

        var chain = '';
        chain = chain + "&name=" + name;
        chain = chain +  "&description=" + description;
        chain = chain +  "&expires=" + expires;
        chain = chain +  "&period=" + period;
        chain = chain +  "&credits=" + credits;
        chain = chain +  "&price=" + Number(price).toFixed(2);
        chain = chain +  "&id=" + id;
        chain = chain +  "&voucher=" + vid;
        chain = chain +  "&limit_value=" + limit_value;
        chain = chain +  "&limit=" + limit;
        chain = chain +  "&subscriptionlimited=" + subscriptionlimited;
        chain = chain + "&passpurchaselimited=" + this.state.passpurchaselimited; 
        chain = chain + "&maxclientpurchaselimit=" + this.state.maxclientpurchaselimit; 
        
 
        fetch("/packages/ajax-passes-edit", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({updating: false});
                _this.props.closeCustomerPanel(true);
            }
            else
            {

                _this.setState({error: response.reason });
            }
        }).catch(function(err){
            console.error('Failed to update pass', err);
        });  
    
    }


    onUpdatePassName(event)
    {
        var v = event.target.value || "";    
        this.setState({passname: v});

    }
    onUpdatePassDescription(event)
    {
        var v = event.target.value || "";    
        this.setState({passdescription: v});

    }
  
    onVoucherOptionSelect(event, value)
    {
        if(value !== null)
        {
            this.setState({'selected': value});
        }
        else
        {
            this.setState({'selected': []});
        }
    

    }

    onManagePassPanelClose(event)
    {
        this.props.closeCustomerPanel(false);
    }

    onUpdatePassPrice(event)
    {
        var v = event.target.value || "";    
        this.setState({passprice: v});

    }

    
    onUpdatePassExpires(event)
    {
        var v = event.target.value || "";    
        this.setState({passexpires: v });
    }

    onChangePeriodType(event)
    {
        var v = event.target.value;   
        this.setState({period: v});
    }

    onUpdatePassCredits(event)
    {
        var v = event.target.value || "";    
        this.setState({passcredits: Number(v).toFixed(0)});
    }
    onLimitChange(event)
    {
        var v = event.target.checked;
        if(v === true)
        {
            this.setState({limited: true, l_state: 2});
        }    
        else
        {
            this.setState({limited: false, l_state: 1, maxlimit: 0});
        }
    }    


    onSubscriptionLimitChange( event )
    {
        this.setState({subscriptionlimited: event.target.checked});        
    }

    onPassPurchaseLimitChange( event )
    {
        this.setState({passpurchaselimited: event.target.checked});        
    }


    onUpdatePassLimit(event)
    {
        var v = event.target.value || 0;    
        this.setState({maxlimit: v});
    }   
    
    onUpdatePassPurchaseLimit(event)
    {
        var v = parseInt(event.target.value) || 1;  
        
        if(v < 1)
        {
            v = 1;
        }
        
        this.setState({maxclientpurchaselimit: v});
    } 
    
  
    render(  )
    {
        var classes = this.props.classes;

        /* OK we need to kick off the loading of the data */
        return (
            <div className={classes.root}>
 

                <Dialog fullScreen open={this.props.open} onClose={this.onManagePassPanelClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.onManagePassPanelClose} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Pass editor
                        </Typography>
                    </Toolbar>
                    </AppBar> 

                    {this.state.updating && (
                        <div className="top-box fullscreen-panel">
                            <Loading /> 
                        </div>
                    )}
                    {!this.state.updating && (
                        <form id="myForm" onSubmit={this.onHandlePassUpdated} className="top-box fullscreen-panel">

                            <Grid container justify="flex-start" alignItems="stretch">  
                                <Grid item xs={12}>
                                <Typography variant="h6">Step 1: Basic details</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField name="passname" className={classes.passField} value={this.state.passname} onChange={this.onUpdatePassName} id="pass-name" label="Name"  fullWidth={true} autoFocus={true} helperText="Enter the name of the pass. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'" inputProps={{required:true, pattern:"[A-Za-z-._(), 0-9]{1,250}"}} />
                                </Grid>  
                                <Grid item xs={12} md={12}>
                                    <TextField name="passdescription"  className={classes.passField}  multiline rows="4" value={this.state.passdescription} onChange={this.onUpdatePassDescription} id="pass-description" label="Description"  fullWidth={true} helperText="Enter the description of the pass. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'" inputProps={{required:false, pattern:"[A-Za-z-._() \\r\\n]+"}} />
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h6">Step 2: Pass configuration</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField name="passcredits" className={classes.passField} value={Number(this.state.passcredits).toFixed(0)} step={1} onChange={this.onUpdatePassCredits} id="pass-credits" label="# of pass credits to be allocated"  helperText="Enter the number of pass credit that will be allocated to the client upon purchase. Allowed characters 0-9" type="number" inputProps={{required:true, pattern:"[1-9]{1}[0-9]+"}} /> 
                                </Grid>  
                                <Grid item xs={12} md={12}>
                                    <TextField name="passexpires" className={classes.passField} value={Number(this.state.passexpires).toFixed(0)} step={1} onChange={this.onUpdatePassExpires} id="pass-expires" label="# of days or months the pass will be valid for after purchase"  helperText="Enter the number of days or months which the pass will be valid for after purchase. Allowed characters 0-9. Remember to select days or months below." type="number" inputProps={{required:true, pattern:"[1-9]{1}[0-9]+"}} />
                                </Grid> 

                                <Grid item xs={12}>
                                    <FormControl component="fieldset" className={classes.radioSelect} required>
                                        <RadioGroup aria-label="Pass expiration period" name="subcription-type" value={this.state.period} onChange={this.onChangePeriodType}>
                                            <FormControlLabel key="pass" value='0' control={<Radio />} label="Days" />
                                            <FormControlLabel key="eventgroup" value='1' control={<Radio />} label="Months" />
                                            <FormHelperText id="subcription-type-helper-text">Select the pass expiration period</FormHelperText>
                                        </RadioGroup>
                                    </FormControl>                                     
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h6">Step 3: Pass price and discounts </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField name="passprice" className={classes.passField} step={0.01} type="number" value={this.state.passprice} onChange={this.onUpdatePassPrice} id="pass-price" label="Price of pass"  helperText="Enter the price of the pass. For example, if a pass costs £10.00 enter, 10.00. Allowed characters 0-9" type="number" inputProps={{required:true, pattern:"[1-9]{1,10}[.]{1}[0-9]+", step:0.01}} /> 
                                </Grid>  
                                <Grid item xs={12} md={12}>
                                <Autocomplete
                                    id="options-list"
                                    options={this.state.options}
                                    getOptionLabel={option => option.name}
                                    style={{ maxWidth: "100%" }}
                                    autoHighlight
                                    multiple={true}
                                    value={this.state.selected}
                                    onChange={this.onVoucherOptionSelect}
                                    disableClearable={false}
                                    renderOption={option => (
                                    <React.Fragment>
                                        {option.name}
                                    </React.Fragment>
                                    )}                
                                    renderInput={params => <TextField {...params} label="Discount vouchers"  />}
                                />

                                </Grid> 


                                <Grid item xs={12} className={classes.passSectionSpace  }>
                                    <Typography variant="h6">Step 4: Limits</Typography>
                                </Grid>
                                

                                <Grid item xs={12} md={12}>
                                    
                                    <FormControlLabel
                                        control={<Switch checked={this.state.subscriptionlimited} onChange={this.onSubscriptionLimitChange} name="subscriptionlimited" />}
                                        label="Can only be purchased as part of subscription"
                                    />                                    
                                </Grid>  

                                <Grid item xs={12} md={12}>
                                    
                                    <FormControlLabel
                                        control={<Switch checked={this.state.passpurchaselimited} onChange={this.onPassPurchaseLimitChange} name="passpurchaselimited" />}
                                        label="Restrict the number of passes of this type a client can purchase"
                                    />                                    
                                </Grid>  
                                <Grid item xs={12} md={12}>
                                    {
                                        this.state.passpurchaselimited === false && (<TextField name="limit" className={classes.passField} disabled value={0} step={1} onChange={this.onUpdatePassPurchaseLimit} id="pass-limit" label="# passes of this type that can be purchased by a client" helperText="The number of passes of this type that a client is allowed to purchase. Allowed characters 1-9" type="number" inputProps={{required:true, pattern:"[1-9]{1}[0-9]+"}} />)
                                    }
                                    {
                                        this.state.passpurchaselimited === true && (<TextField name="limit" className={classes.passField} value={this.state.maxclientpurchaselimit} step={1} onChange={this.onUpdatePassPurchaseLimit} id="pass-limit" label="# passes of this type that can be purchased by a client"  helperText="The number of passes of this type that a client is allowed to purchase. Allowed characters 1-9" type="number" inputProps={{required:true, pattern:"[1-9]{1}[0-9]+", min: 1}} />)
                                    }                                    

                                </Grid> 

                                <Grid item xs={12} md={12}>
                                    
                                    <FormControlLabel
                                        control={<Switch checked={this.state.limited} onChange={this.onLimitChange} name="limit" />}
                                        label="Active Pass limit active"
                                    />                                    
                                    
                                </Grid>  
                                <Grid item xs={12} md={12}>
                                    {
                                        this.state.limited === false && (<TextField name="limit" className={classes.passField} disabled value={Number(this.state.maxlimit).toFixed(0)} step={1} onChange={this.onUpdatePassLimit} id="pass-purchase-limit" label="# passes of this type that can be active at any one time"  helperText="Enter the maximum number of passes of this tyoe that can be active. Allowed characters 0-9"  type="number" inputProps={{required:true, pattern:"[1-9]{1}[0-9]+"}} />)
                                    }
                                    {
                                        this.state.limited === true && (<TextField name="limit" className={classes.passField} value={Number(this.state.maxlimit).toFixed(0)} step={1} onChange={this.onUpdatePassLimit} id="pass-purchase-limit" label="# passes of this type that can be active at any one time"  helperText="Enter the maximum number of passes of this tyoe that can be active. Allowed characters 0-9" type="number" inputProps={{required:true, pattern:"[1-9]{1}[0-9]+", min: 0}} />)
                                    }                                    

                                </Grid> 
                                
                                <Grid item xs={12} md={12}>
                                    &nbsp;
                                </Grid>  
                                <Grid item xs={12} md={12}>
                                    <Button type="submit" variant="contained" className="submit" color="primary" >Save</Button>
                                </Grid> 
                                <Grid item xs={12} md={12}>
                                    &nbsp;
                                </Grid>                                  
                            </Grid>  
   
                        </form>              
                    )}
                    
                </Dialog>




            </div>            
        );
    }

};



const PassesAddPanelManager = connect(null, mapDispatchToProps)(PassesAddPanelManagerModule);
export default withStyles(styles)(PassesAddPanelManager);
