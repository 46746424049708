import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";


import Grid from '@material-ui/core/Grid';
import {Container} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';



import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {reloadData, closeCustomerPanel} from '../redux/actions';


import Slide from '@material-ui/core/Slide';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {v1 as uuidv1} from "uuid";


import MuiDialogTitle from '@material-ui/core/DialogTitle';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Box from '@material-ui/core/Box';
import GetAppIcon from '@material-ui/icons/GetApp';
import WarningIcon from '@material-ui/icons/Warning';


import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';


import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import moment from 'moment'
import Moment from 'react-moment';
import 'react-dates/lib/css/_datepicker.css';


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    dialog:{
        backgroundColor: 'rgba(0,0,0,0.1)',
        '& .MuiAppBar-root':{
            backgroundColor: 'rgba(60, 141, 188, 1)',
        }
    },
    box:{
        height: '100%',
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: '5px',
        backgroundColor: '#fff',
        padding: '18px', 
        marginTop: '100px'       
    },    
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    section:{
       fontSize: '1.1em',
       fontWeight: 'bold',
    },  
    setting:{
        fontSize: '1em',
        fontWeight: 'normal', 
    },
    leftCell:{
        textAlign: 'left',
    }, 
    stdCell:{
        textAlign: 'center',
    },  
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    download:{
        fontSize: '0.8em',
        float:'right',
    },  
    reportDescription:{
        fontSize: '0.8em',
    },  
    reportSection:{
        paddingBottom: '30px',
        marginBottom: '30px',
    },                
    fabIcon:{
        '& .MuiSvgIcon-root':{
            fill: '#fff',
            color: '#fff',
            '&:hover':{
                color: '#ffe',
            }
        }
    },
    fabDelete:{
        backgroundColor: '#b80000',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#940000',
        }
    }, 
    fabReg:{
        backgroundColor: 'rgba(83, 120, 138, 1)',
        color: '#fff',
        '&:hover':{
            backgroundColor: 'rgba(83, 120, 138, 0.5)',
        }
    },         
    tableName:{
        width: '25%',
    },
    alignCenter:
    {
        textAlign: 'center'
    },
    buttonDanger:{
        color: '#d40000',
    },
    progress:{
        color: 'rgba(0,0,0,0.5)',
        fontSize: '18px',
        textAlign: 'center'
    },  
    progressIcon:{
        color: 'rgba(0,0,0,0.5)',

    }, 
    progressError:{
        color: 'rgba(199, 0, 0, 0.829)',

    },          
    filter:{
        paddingBottom: '10px'
    }
});

function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }




function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function Loading(props) {
    console.log('Loading', props);
    if (props.open) {
        return <div className="loading"><i className="fa fa-refresh fa-spin fa-5x fa-fw"></i><span className="sr-only">Loading...</span></div>
    }
    return ''
}


function mapDispatchToProps(dispatch) {
    return {
        reloadData: spec => dispatch(reloadData(spec)),
        closeCustomerPanel: spec => dispatch(closeCustomerPanel(spec)),

      
    };
  }





class ReportBooking3DialogModule extends Component{

    constructor( props )
    {
        super( props );
        this.state = {
            loading: false,
            security: props.security,
            open: true,
            focusedInput: null,
            close: props.onCancel,
            start_date: moment().subtract(1, 'months'),
            end_date: moment(),
            filter_mode: 'all',
            data: [],
            raw_data:[],
            events: [],
            selected_customers:[],
            
        };



        console.log('ReportBooking3DialogModule', this.state.data);

        this.onDateChange         = this.onDateChange.bind(this);
        this.onRefresh            = this.onRefresh.bind(this);
        this.onEventFilterSelect  = this.onEventFilterSelect.bind(this);
        this.postProcess          = this.postProcess.bind(this);
        this.onDownloadClick      = this.onDownloadClick.bind(this);
        this.onManageCustomerPanelClose = this.onManageCustomerPanelClose.bind(this);

    }

    onManageCustomerPanelClose()
    {
        this.state.close();
    }

    componentDidMount()
    {
        this.onRefresh();
    }


    postProcess()
    {
        var data = [];
        if(this.state.raw_data.length > 0)
        {
            for(var n in this.state.raw_data)
            {
                var customer = this.state.raw_data[n];
                
                if(this.state.selected_customers.length > 0)
                {
                    for(var j = 0; j < this.state.selected_customers.length; j++)
                    {
                        if(this.state.selected_customers[j].id === customer.id)
                        {
                            data.push(customer);
                        }
                    }
                }
                else
                {
                    data.push(customer);
                }
            }
        }
        this.setState({'data': data});
    }

    onDownloadClick()
    {
        var a = moment();
        var rows = [
        ];

        for(var i = 0; i < this.state.data.length; i++)
        {
            //var z = moment(this.state.data[i].date);
            var x = [

                'Customer ID', 
                this.state.data[i].id
            ];

            rows.push(x);

            x = [

                'Firstname', 
                this.state.data[i].customer_firstname.replace(/,/g, ' ')
            ];
            rows.push(x);

            x = [

                'Lastname', 
                this.state.data[i].customer_lastname.replace(/,/g, ' ')
            ];
            rows.push(x);

            x = [

                'Email', 
                this.state.data[i].deleted ? 'deleted' : this.state.data[i].customer_email.replace(/,/g, ' ')
            ];
            rows.push(x);            

            x = [

                'Booked before date range', 
                this.state.data[i].booked_before === "1" ? 'YES' : 'NO'
            ];
            rows.push(x);              


            x = [

                'Booked after date range', 
                this.state.data[i].booked_after === "1" ? 'YES' : 'NO'
            ];
            rows.push(x); 


            x = []; // add space
            rows.push(x);

            x = ['Transaction ID', 'Transaction Date', 'Event name', 'Event ID', 'Dates booked', 'Currency', 'Gross', 'Discount', 'Adjustments', 'Net'];
            rows.push(x);
            for(var j = 0; j < this.state.data[i].bookings.length; j++)
            {
                var pd = moment(this.state.data[i].bookings[j].purchase_date)
                x = [
                    this.state.data[i].bookings[j].transaction_id,
                    pd.format("DD/MM/YYYY").replace(/,/g, ' '),
                    this.state.data[i].bookings[j].event_name.replace(/,/g, ' '),
                    this.state.data[i].bookings[j].event_id,
                    this.state.data[i].bookings[j].transaction_dates,
                    this.state.data[i].bookings[j].currency,
                    parseFloat(this.state.data[i].bookings[j].gross_float).toFixed(2),
                    parseFloat(this.state.data[i].bookings[j].discounts_float).toFixed(2),
                    parseFloat(this.state.data[i].bookings[j].adjustments_float).toFixed(2),
                    parseFloat(this.state.data[i].bookings[j].net_float).toFixed(2),
                ];
                rows.push(x);
            }
            x = []; // add space
            rows.push(x);
            rows.push(x);
        }
        
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");        
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "customer-booking-detail-report-" + a.format() + ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    onRefresh()
    {
        var _this = this;
        var chain = '&start='    + this.state.start_date.valueOf();
        chain = chain + '&end='  + this.state.end_date.valueOf();
        this.setState({'loading': true}, function(){
            try {
                fetch("/backenddata/get-customer-booking-detail-view-js", {
                  method: "POST",
                  mode: 'same-origin', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'include', // include, *same-origin, omit                            
                  body: _this.state.security.name + '=' + _this.state.security.token + chain,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-Requested-With": 'XMLHttpRequest',
                        "X_REQUESTED_WITH": 'xmlhttprequest'
                    }
                })
                .then(res => {
                    return res.json();
                })
                .then(response => {
                  if(response.status === 'OK')
                  {
                        _this.setState({'raw_data': response.data}, function(){
                            _this.postProcess();
                        });
                  }
                  else
                  {
                        _this.setState({'data': [], 'raw_data': []});
                  }
            
                }).catch(function (err) {
             
                });           
        
            }
            catch(e)
            {
              console.error('api called', e);
      
            }   
        })      
    }


    onEventFilterSelect( event, objects )
    {
        var _this = this;
        if(objects !== null)
        {

            this.setState({'selected_customers': objects}, function(){
                _this.postProcess();

            });
        }
        else
        {
            this.setState({'selected_customers': []}, function(){
                _this.postProcess();
                
            });
        }
    }



    onDateChange(obj)
    {
        var _this = this;

        if(obj.startDate !== null && obj.endDate !== null)
        {
            this.setState({'start_date': obj.startDate, 'end_date': obj.endDate}, function(){
                _this.onRefresh();
            });
        }
        else if(obj.startDate !== null)
        {
            this.setState({'start_date': obj.startDate}, function(){
                _this.onRefresh();
            });            
        }
        else if(obj.endDate !== null)
        {
            this.setState({'end_date': obj.endDate}, function(){
                _this.onRefresh();
            });            
        }

        
        
          
    }


    render(  )
    {
        var classes = this.props.classes;

        /* OK we need to kick off the loading of the data */


        console.log('render',this.props, this.state );




        return (
            <div className={classes.root}>

                <Dialog fullScreen open={this.state.open} onClose={this.onManageCustomerPanelClose} TransitionComponent={Transition} >
                    <div className={classes.dialog}>
                        <Container maxWidth="lg">
                                
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.onManageCustomerPanelClose} aria-label="close">
                                    <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        Report
                                    </Typography>
                                </Toolbar>
                            </AppBar>                   
                            <div className={classes.box}>
                                <Grid container justify="flex-start" alignItems="flex-start" >
                                    <Grid item xs={12} md={12}>
                                        <h2 className={classes.section}>Configure report</h2>
                                        <p className={classes.reportDescription}>Configure the report below choosing the scheduled date range(s) and if applicable events that you are interested in.</p>
                                    </Grid>  
                                    <Grid item xs={12}>
                                        <Grid container justify="flex-start" alignItems="flex-start" className="reports" >
                                            <Grid item xs={12} md={12}>
                                                <h2 className={classes.setting}>Step 1: Select the date range</h2>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="drpReportPicker">
                                                    <DateRangePicker 
                                                    isOutsideRange={day => (false)}
                                                    startDate={this.state.start_date} // momentPropTypes.momentObj or null,
                                                    startDateId="startDate" // PropTypes.string.isRequired,
                                                    endDate={this.state.end_date} // momentPropTypes.momentObj or null,
                                                    endDateId="endDate" // PropTypes.string.isRequired,
                                                    onDatesChange={this.onDateChange} // PropTypes.func.isRequired,
                                                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                                    displayFormat="DD MMM YYYY"
    
                                                    />                                               

                                                </div>
                                                <div className="description">
                                                    Select the date range of the bookings. Customers that have booked within this range will be listed. 
                                                </div>
                                            </Grid>
                                        </Grid>

                                        <Grid container justify="flex-start" alignItems="flex-start" className="reports" >
                                            <Grid item xs={12} md={12}>
                                                <h2 className={classes.setting}>Step 2: Set filters and attributes</h2>
                                            </Grid>  
                                            <Grid item  md={4} xs={12} className={classes.filter}>
                                                <Autocomplete
                                                    key={uuidv1()}
                                                    id="customer-filter"
                                                    className={classes.formControl}
                                                    options={this.state.raw_data}
                                                    getOptionLabel={(customer) => {
                                                        return (customer.customer_firstname + ' ' + customer.customer_lastname + ' (' + customer.deleted? 'deleted' : customer.customer_email + ')' );
                                                    }}
                                                    style={{ width: "100%" }}
                                                    autoHighlight
                                                    multiple={true}
                                                    value={this.state.selected_customers}
                                                    onChange={this.onEventFilterSelect}
                                                    disableClearable={true}
                                                    filterSelectedOptions
                                                    getOptionSelected={(option, value)  => {
                                                        return (option.id === value.id);  
                                                    }}                                                
                                                    renderOption={customer => (
                                                    <React.Fragment>
                                                        {customer.customer_firstname + ' ' + customer.customer_lastname + ' (' + customer.deleted? 'deleted' : customer.customer_email + ')'}
                                                    </React.Fragment>
                                                    )}                
                                                    renderInput={params => <TextField {...params} label="Specific customers" variant="outlined" />}
                                                />                                                                                          
                                            </Grid> 
                                            <Grid item  xs={12}  md={8}>
                                            </Grid>                                             
                                        </Grid>

                
                                        <Grid container justify="flex-start" alignItems="flex-start" className="reports" >
                                            <Grid item xs={6} md={6}>
                                                <h2 className={classes.section}>Report data</h2>
                                            </Grid>     
                                            <Grid item xs={6} md={6}>
                                                <Button color="primary"
                                                        startIcon={<GetAppIcon />}
                                                        className={classes.download}
                                                        onClick={this.onDownloadClick}

                                                >
                                                    Download CSV
                                                </Button>
                                            </Grid>                                        
                                        </Grid>
                                        <Grid container justify="flex-start" alignItems="flex-start" className="reports" >

                                            <Grid item xs={12} md={12}>

                                                {this.state.data.length === 0 && (
                                                    <Paper variant="outlined" elevation={0} className={classes.reportSection}>
                                                        <TableContainer>
                                                            <Table>
                                                                <TableBody> 
                                                                    <TableRow>
                                                                        <TableCell key={-1}>
                                                                            <div className={classes.progress}>
                                                                                <WarningIcon className={classes.progressIcon} style={{ height: 100, width: 100, textAlign: 'center' }}/>                                                                          
                                                                                <p>
                                                                                    There are no records to display.
                                                                                </p>
                                                                            </div>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>  
                                                    </Paper>               
                                                )}
                                                {this.state.data.length > 0 && this.state.data.map(el => (
                                                    <React.Fragment key={uuidv1()}>
                                                        <Paper variant="outlined" elevation={0} className={classes.reportSection}>
                                                            <TableContainer>
                                                                <Table>
                                                                    <TableBody> 
                                                                        <TableRow>
                                                                            <TableCell>
                                                                                <Grid container justify="flex-start" alignItems="flex-start" >
                                                                                    <Grid item xs={4} md={2}><b>ID:</b></Grid>
                                                                                    <Grid item xs={8} md={10}>{el.id}</Grid>
                                                                                    <Grid item xs={4} md={2}><b>Name:</b></Grid>
                                                                                    <Grid item xs={8} md={10}>{el.customer_firstname} {el.customer_lastname}</Grid>
                                                                                    <Grid item xs={4} md={2}><b>Event date(s) booked:</b></Grid>
                                                                                    <Grid item xs={8} md={10}>{el.date_count}</Grid>
                                                                                    <Grid item xs={4} md={2}><b>Booked before date range:</b></Grid>
                                                                                    <Grid item xs={8} md={10}>{el.booked_before === "1" ? 'YES' : "NO"}</Grid>
                                                                                    <Grid item xs={4} md={2}><b>Booked after date range:</b></Grid>
                                                                                    <Grid item xs={8} md={10}>{el.booked_after === "1" ? 'YES' : "NO"}</Grid>
                                                                                </Grid>                                                                                  
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        <TableRow key={'detail-' + el.id}>
                                                                            <TableContainer>
                                                                                <Table>
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell>
                                                                                                Transaction ID
                                                                                            </TableCell>  
                                                                                            <TableCell className={classes.leftCell}>
                                                                                                Transaction Date
                                                                                            </TableCell>                                                                                                                                                            
                                                                                            <TableCell className={classes.leftCell}>
                                                                                                Event 
                                                                                            </TableCell>  
                                                                                            <TableCell className={classes.leftCell}>
                                                                                                Event ID
                                                                                            </TableCell>  
                                                                                            <TableCell className={classes.stdCell}>
                                                                                                Date booked
                                                                                            </TableCell>                                                                                              
                                                                                            <TableCell className={classes.stdCell}>
                                                                                                Gross 
                                                                                            </TableCell> 
                                                                                            <TableCell className={classes.stdCell}>
                                                                                                Discounts 
                                                                                            </TableCell>                                                                                             
                                                                                            <TableCell className={classes.stdCell}>
                                                                                                Net 
                                                                                            </TableCell>  
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {el.bookings.length === 0 && (
                                                                                            <TableRow>
                                                                                                <TableCell colSpan={8}>
                                                                                                    <p className={classes.alignCenter}>No Bookings</p>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        )}
                                                                                        {el.bookings.length > 0 && el.bookings.map(booking => (
                                                                                            <TableRow key={booking.id}>
                                                                                                <TableCell>
                                                                                                    {booking.transaction_id}
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.leftCell}>
                                                                                                    <Moment format="ll">{booking.purchase_date}</Moment>
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.leftCell}>
                                                                                                    {booking.event_name}
                                                                                                </TableCell>
                                                                                                <TableCell className={classes.leftCell}>
                                                                                                    {booking.event_id}
                                                                                                </TableCell>    
                                                                                                <TableCell className={classes.stdCell}>
                                                                                                    {booking.transaction_dates}
                                                                                                </TableCell>                                                                                                                                                                                                
                                                                                                <TableCell className={classes.stdCell}>
                                                                                                    {booking.currency}{parseFloat(booking.gross_float).toFixed(2)}
                                                                                                </TableCell>     
                                                                                                <TableCell className={classes.stdCell}>
                                                                                                    {booking.currency}{parseFloat(booking.discounts_all_float).toFixed(2)}
                                                                                                </TableCell>     
                                                                                                <TableCell className={classes.stdCell}>
                                                                                                    {booking.currency}{parseFloat(booking.net_float).toFixed(2)}
                                                                                                </TableCell>  
                                                                                            </TableRow>
                                                                                        ))}
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </TableContainer>

                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>   
                                                        </Paper>  
                                                    </React.Fragment>
                                                ))}
 
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>

                        </Container>
                    </div>
                </Dialog>
            
            </div>            
        );
    }

};



const ReportBooking3Dialog = connect(null, mapDispatchToProps)(ReportBooking3DialogModule);
export default withStyles(styles)(ReportBooking3Dialog);
