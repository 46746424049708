import React, { Component } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './stepper';
import Button from '@material-ui/core/Button';



import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


import  {
    EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE, 
    EVENT_APP_CACHE_BOOKING_MODE_TYPE_INDIVIDUAL,
    EVENT_APP_CACHE_BOOKING_MODE_TYPE_BLOCK,
    EVENT_APP_CACHE_BOOKING_MODE_TYPE_FLEXIBLE
} from '../redux/types';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";


    const styles = theme => ({
        root: {
            flexGrow: 1,
          },          
          title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
          },
          formControl: {
            margin: '20px',
            minWidth: 120,
          },
          textInput:{
            marginTop: '20px'
          },
          switchLabel:{
              color: 'rgba(0,0,0,0.6)',
          },
          captionText:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',

          },
          sectionDescription:{
            color: 'rgba(0,0,0,0.6)',    
          },            
          captionSection:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',
            marginBottom: '10px',
            lineHeight: '1.5em',
          },
          schedTypeSelection:{
              marginLeft: '10px'
          },
          what:{
              width: '50%'
          },
          fabDelete:{
              backgroundColor: '#b80000',
              color: '#fff',
              marginTop: '20px',
              '&:hover':{
                  backgroundColor: '#940000',
              }
          },        
          fabIcon:{
              '& .MuiSvgIcon-root':{
                  fill: '#fff',
                  color: '#fff',
                  '&:hover':{
                      color: '#ffe',
                  }
              }
          }                     
});


function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventsStep2ABookTypeModule extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            book_type: this.props.cache.book_type || EVENT_APP_CACHE_BOOKING_MODE_TYPE_INDIVIDUAL,
            error: false,
            refresh: false,
        }      


        obj = this.state;


        this.state.security = props.security;
        this.onDoPrevious   = this.onDoPrevious.bind(this);
        this.onDoNext   = this.onDoNext.bind(this);
        this.onSchedTypeChange = this.onSchedTypeChange.bind(this);


    }

 

    onSchedTypeChange(event)
    {
        this.setState({'book_type': event.target.value});

    }


    onDoPrevious(event)
    {

        if(this.isValid())
        {
            var o = {
                  store_previous: true,
                  book_type: this.state.book_type,
                  state: EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE,
                };
                this.setState({'error': false});
                this.props.rewindStep(o);
    
        } 
        else
        {
            var o = { 
                state: EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE
            };
            this.props.rewindStep(o);
        }       
        
    }




 



    isValid()
    {
        if( (this.state.book_type ===  EVENT_APP_CACHE_BOOKING_MODE_TYPE_INDIVIDUAL) || 
            (this.state.book_type ===  EVENT_APP_CACHE_BOOKING_MODE_TYPE_BLOCK) || 
            (this.state.book_type ===  EVENT_APP_CACHE_BOOKING_MODE_TYPE_FLEXIBLE))
            {
                return true;
            }
            else
            {
                return false;
            }

    }

    
    onDoNext(event)
    {
        var x = Object.assign({}, this.state.errors);

        //if(regex.test(this.state.name))
        if(this.isValid())
        {
            var o = {
                  book_type: this.state.book_type,
                  state: EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE,
                };
                this.setState({'error': false});
                this.props.setStep(o);
    
        }
        else
        {
            this.setState({'error': true});
        }

    }
  
    




  

    render()
    {
        var classes = this.props.classes;

        console.log('render', this.state);

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Event editor
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="center" alignItems="center" spacing={2} >


                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="center" spacing={2}>
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">

                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        
                                        <Grid item xs={12} md={12}>
                                            <StepperWidget key={uuidv1()} step={1}/>

                                        </Grid>
                                        
                                    

                                        <Grid item xs={12} md={12}>
                                            <div className="formSection">Tell us how the dates should be booked?</div>   
                                            <p className="sectionInfoDescription">
                                                Tell reservie how the dates should be booked by the customer.                         
                                            </p>   
                                            <FormControl component="fieldset" className={classes.schedTypeSelection}>
                                                <RadioGroup aria-label="rv-option" name="booking" value={this.state.book_type} onChange={this.onSchedTypeChange}  >
                                                    <FormControlLabel className="rv-option" value={EVENT_APP_CACHE_BOOKING_MODE_TYPE_INDIVIDUAL} control={<Radio />} label="Individually" />
                                                    <FormHelperText><b>Individual</b> - customers can book each date individually. This is best used for drop-in style events where you wish to offer maximum flexibility</FormHelperText>
                                                    <FormControlLabel className="rv-option"  value={EVENT_APP_CACHE_BOOKING_MODE_TYPE_BLOCK} control={<Radio />} label="Blocks" />
                                                    <FormHelperText><b>Blocks</b> - customers must book all available dates together. This is best used for course style events, where all dates should be booked together </FormHelperText>
                                                    <FormControlLabel className="rv-option"  value={EVENT_APP_CACHE_BOOKING_MODE_TYPE_FLEXIBLE} control={<Radio />} label="Selectable" />
                                                    <FormHelperText><b>Selectable</b> - customers may book classes either individually or as a block. However, reservie will encourage clients to book as a block. </FormHelperText>
                                                </RadioGroup>

                                                
                                            </FormControl> 
                                        </Grid>
 
                                        <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                size="large"
                                                className="rv-eng-next-button"
                                                startIcon={<NavigateBeforeIcon />}
                                                onClick={this.onDoPrevious}
                                                fullWidth={true}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4} md={6}></Grid>
                                        <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="rv-eng-next-button"
                                                endIcon={<NavigateNextIcon />}
                                                onClick={this.onDoNext}
                                                fullWidth={true}
                                            >
                                                Next
                                            </Button>  
                                                                
                                        </Grid>  
                    
                                    </Grid>


                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>

            </div>            
        );
    }

};



const EventsStep2ABookType = connect(null, mapDispatchToProps)(EventsStep2ABookTypeModule);
export default withStyles(styles)(EventsStep2ABookType);
