import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './stepper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';

import  {
    EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT,
} from '../redux/types';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";


    const styles = theme => ({
        root: {
            flexGrow: 1,
          },          
          title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
          },
          formControl: {
            marginBottom: '20px',
            minWidth: 120,
          },
          textInput:{
            marginTop: '20px'
          },
          switchLabel:{
              color: 'rgba(0,0,0,0.6)',
          },
          captionText:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',

          },
          sectionDescription:{
            color: 'rgba(0,0,0,0.6)',    
          },            
          captionSection:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',
            marginBottom: '10px',
            lineHeight: '1.5em',
          },
          schedTypeSelection:{
              marginLeft: '10px'
          },
          what:{
              width: '50%'
          },
          fabDelete:{
              backgroundColor: '#b80000',
              color: '#fff',
              '&:hover':{
                  backgroundColor: '#940000',
              }
          },        
          fabIcon:{
              '& .MuiSvgIcon-root':{
                  fill: '#fff',
                  color: '#fff',
                  '&:hover':{
                      color: '#ffe',
                  }
              }
          }            
});





function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventsStep4PassSelectModule extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            passes: props.data.obj.passes || [],
            pass_selection: props.cache.passes || [] ,
            error: false,
            refresh: false,
        }      


        console.log('EventsStep4PassSelectModule', this.state, props);
        this.onSelectPass    = this.onSelectPass.bind(this); 
        this.isValid         = this.isValid.bind(this);
        this.onDoPrevious    = this.onDoPrevious.bind(this);
        this.onDoNext        = this.onDoNext.bind(this);

    }






    onDoPrevious(event)
    {
        if(this.isValid())
        {
            var o = {
                  store_previous: true,
                  passes: this.state.pass_selection,
                  state: EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT
                };
                this.setState({'error': false});
                this.props.rewindStep(o);      
    
        }
        else
        {
            var o = { 
                state: EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT
            };
            this.props.rewindStep(o);      
        }
  
    }


    onSelectPass(event, value)
    {
        if(value !== null)
        {
            console.log('Pass list', value);
            this.setState({'pass_selection': value});
        }
        else
        {
            this.setState({'pass_selection': []});
        }

    } 


    isValid()
    {

        if(this.state.pass_selection.length > 0)
        {
            return true;
        }

        return false;


    }

    
    onDoNext(event)
    {
        var x = Object.assign({}, this.state.errors);

        //if(regex.test(this.state.name))
        if(this.isValid())
        {
            var o = {
                  passes: this.state.pass_selection,
                  state: EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT
                };
                this.setState({'error': false});
                this.props.setStep(o);
    
        }
        else
        {
            this.setState({'error': true});
        }

    }
  
    




  

    render()
    {
        var classes = this.props.classes;

        console.log('render', this.state);

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Event editor
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="center" alignItems="center" spacing={2} >


                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="center" spacing={2}>
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">

                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        
                                        <Grid item xs={12} md={12}>
                                            <StepperWidget key={uuidv1()} step={2}/>

                                        </Grid>
                                        
                                        
                                        <Grid item xs={12}>
                                            <div className="formSection">Passes</div>                               
                                            <p className="sectionDescription">
                                                You have specified that you would like to the customer to use previously purchased pass credits as payment to book an event. Select one or more passes from the options below.   
                                            </p>                                        
                                        </Grid>

                                        <Grid item xs={12}>

                                            <Autocomplete
                                                id="passes"
                                                className={classes.formControl}
                                                options={this.state.passes}
                                                getOptionLabel={pass => pass.name}
                                                style={{ width: "100%" }}
                                                autoHighlight
                                                multiple={true}
                                                value={this.state.pass_selection}
                                                onChange={this.onSelectPass}
                                                disableClearable={false}
                                                filterSelectedOptions
                                                getOptionSelected={(option, value)  => {
                                                    return (option.id === value.id);  
                                                }}                                                
                                                renderOption={pass => (
                                                <React.Fragment>
                                                    {pass.name}
                                                </React.Fragment>
                                                )}                
                                                renderInput={params => <TextField {...params} label="Select one or more passes" variant="outlined" />}
                                            />                                                                                                                     
                                        </Grid>

                                        <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                size="large"
                                                className="rv-eng-next-button"
                                                startIcon={<NavigateBeforeIcon />}
                                                onClick={this.onDoPrevious}
                                                fullWidth={true}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4} md={6}></Grid>
                                        <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="rv-eng-next-button"
                                                endIcon={<NavigateNextIcon />}
                                                onClick={this.onDoNext}
                                                fullWidth={true}
                                            >
                                                Next
                                            </Button>  
                                                                
                                        </Grid>                      
                                    </Grid>


                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>

                
            </div>            
        );
    }

};



const EventsStep4PassSelect = connect(null, mapDispatchToProps)(EventsStep4PassSelectModule);
export default withStyles(styles)(EventsStep4PassSelect);
