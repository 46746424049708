import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";


import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';


import { withStyles } from '@material-ui/core/styles';

import { Box, Divider, Tabs } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';


//import Link from '@material-ui/core/Link';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import CloseIcon from '@material-ui/icons/Close';
import {reloadData, manageCustomer} from '../redux/actions';
import MaterialTable from "material-table";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import TextField  from '@material-ui/core/TextField'
import Alert from '@material-ui/lab/Alert';
import Tab from '@material-ui/core/Tab';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DescriptionIcon from '@material-ui/icons/Description';
import '../core/dashboard.css';
import './customers.css';
import './customers.scss';

import {v1 as uuidv1} from "uuid";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CoreDialog from '../core/dialog';
import { Skeleton } from '@material-ui/lab';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        flexGrow: 1,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    fab:{
        backgroundColor: '#3c8dbc',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#346785',
        }
    },
    fabIcon:{
        '& .MuiSvgIcon-root':{
            fill: '#fff',
            color: '#fff',
            '&:hover':{
                color: '#ffe',
            }
        }
    },
    fabWarning:{
        backgroundColor: '#FF7900',
        color: '#fff',
        width: '46px',
        height: '46px',
        '&:hover':{
            backgroundColor: '#FF7900',
            opacity: '0.8'
        }
    },
    fabDanger:{
        backgroundColor: '#d40000',
        color: '#fff',
        width: '46px',
        height: '46px',
        '&:hover':{
            backgroundColor: '#AA0000',
        }
    },

});


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  class DeleteButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            customer: props.customer,
            callback: props.callback
        }

        console.log('Delete customer', this.state.customer);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Delete button initiated',x);
        this.state.callback(this.state.customer);
    }

    render() {
        var classes= this.props.classes;
        return (
            <Fab name="delete" className={classes.fabDanger} aria-label="Delete customer" onClick={this._handleClick}><DeleteForeverIcon className={classes.fabIcon}/></Fab>            
        )
    }
}


  class RestoreButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            customer: props.customer,
            callback: props.callback
        }

        console.log('Restore custoemr', this.state.customer);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Restore button initiated',x);
        this.state.callback(this.state.customer);
    }

    render() {
        var classes= this.props.classes;
        return (
            <Fab name="restore" className={classes.fabWarning} aria-label="Restore customer" onClick={this._handleClick}><SettingsBackupRestoreIcon className={classes.fabIcon}/></Fab>            
        )
    }
}



class ManageButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            customer: props.customer,
            callback: props.callback
        }

        console.log('ManageButton', this.state.customer);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.customer);
    }

    render() {
        var classes= this.props.classes;
        return (
            <Fab name="manage" className={classes.fab} aria-label="Manage customer" onClick={this._handleClick}><EditRoundedIcon className={classes.fabIcon}/></Fab>            
        )
    }
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});



function mapDispatchToProps(dispatch) {
    return {
        reloadData: spec => dispatch(reloadData(spec)),
        manageCustomer: spec => dispatch(manageCustomer(spec))
    };
  }


class CustomersManagerModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            customers: [],
            acustomers: [],
            cust_add: false,
            cust_restore: false,
            cust_delete: false,
            security: {},
            refresh: false,
            error: '',
            view: (!!props?.data?.view) ?props.data?.view : false,
            selected: false,
            tab: parseInt(window.localStorage.getItem('rvCustomerTab')) || 0,
            focus: false,
            loading: false,
        };

        window.localStorage.setItem('rvCustomerTab', this.state.tab);


        this.state.security = props.security;

        for(var a = 0; a < props.data.customers.length; a++)
        {
            this.state.customers.push(
                this.createCustomers(
                    props.data.customers[a].id,
                    props.data.customers[a].firstname,
                    props.data.customers[a].lastname,
                    props.data.customers[a].email,
                    props.data.customers[a].telephone,
                    )
            );
        }

        for(var a = 0; a < props.data.acustomers.length; a++)
        {
            this.state.acustomers.push(
                this.createCustomers(
                    props.data.acustomers[a].id,
                    props.data.acustomers[a].firstname,
                    props.data.acustomers[a].lastname,
                    props.data.acustomers[a].email,
                    props.data.acustomers[a].telephone,
                    )
            );
        }


        this.onManageCustomer = this.onManageCustomer.bind(this);
        this.handleAddCustomerClose = this.handleAddCustomerClose.bind(this);
        this.handleAddCustomerOK = this.handleAddCustomerOK.bind(this);
        this.onAddCustomer       = this.onAddCustomer.bind(this);
        this.onRestoreCustomer       = this.onRestoreCustomer.bind(this);
        this.onRestoreCancel     = this.onRestoreCancel.bind(this);
        this.onRestoreComplete   = this.onRestoreComplete.bind(this);
        this.onDeleteCustomer       = this.onDeleteCustomer.bind(this);
        this.onDeleteCancel      = this.onDeleteCancel.bind(this);
        this.onDeleteComplete    = this.onDeleteComplete.bind(this);
        this.onManageCustomerPanelClose = this.onManageCustomerPanelClose.bind(this);
        this.onExport = this.onExport.bind(this);
       // console.log("Final TopNav",this.state);
       this.onTabChange       = this.onTabChange.bind(this);
    }



    componentDidMount()
    {
        if(this.state.view !== false)
        {
            const x = parseInt(this.state.view);
            const object = this.state.customers.find(i => parseInt(i.id) === x);
            if(!!object)
            {
                if(this.state.selected === false)
                {
                    this.setState({selected: true}, function(){
                        this.onManageCustomer( object, true );
                    })
                }

            }
        }
        
    }

    onExport = () =>     {
        const d = new Date();
        var rows = [
            ["first name", "last name", "email address", 'telephone'],
        ];


        for(var i = 0; i < this.state.customers.length; i++)
        {
            var x = [
                this.state.customers[i].firstname, 
                this.state.customers[i].lastname, 
                this.state.customers[i].email,
                this.state.customers[i].mobile ];
            rows.push(x);
        }
        
        let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");        
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "client-export-" + d.toISOString() + ".csv");
        document.body.appendChild(link); // Required for FF

        link.click();
    }

    onRestoreCustomer( cust )
    {
        this.setState({cust_restore: true, focus: cust});
    }

    onRestoreCancel()
    {
        this.setState({cust_restore: false,  focus: false});
    }

    onDeleteCancel()
    {
        this.setState({cust_delete: false, focus: false});
    }


    onRestoreComplete()
    {
        var chain = '';
        chain = '&id=' + this.state.focus.id;
        var _this = this;
        this.setState({loading: true, cust_restore: false, error: ''}, function(){
            try {
                fetch("/customers/do-ajax-customer-unarchive", {
                    method: "POST",
                    mode: 'same-origin', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'include', // include, *same-origin, omit                            
                    body: this.state.security.name + '=' + this.state.security.token + chain,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-Requested-With": 'XMLHttpRequest',
                        "X_REQUESTED_WITH": 'xmlhttprequest'
                    }
                })
                .then(res => {
                    return res.json();
                })
                .then(response => {
                    //console.log(data);
                    if(response.status === 'OK')
                    {
                        this.setState({loading: false, cust_restore: false, focus: false});
                        this.onRefresh();
                    }
                    else
                    {
                        this.setState({loading: false, error: response.message});
                    }
                }).catch(function (err) {
                    _this.setState({cust_restore: false, focus: false, loading: false, error: 'Network error - please try again'});
                });           
        
            }
            catch(e)
            {
                _this.setState({cust_restore: false, focus: false, error: 'Network error', loading: false});
            }
        });



        // send message to server
    }


    onDeleteComplete()
    {
        // send message to server
        var chain = '';
        chain = '&id=' + this.state.focus.id;
        var _this = this;
        this.setState({loading: true, cust_delete: false, error: ''}, function(){
            try {
                fetch("/customers/do-ajax-customer-destroy", {
                    method: "POST",
                    mode: 'same-origin', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'include', // include, *same-origin, omit                            
                    body: this.state.security.name + '=' + this.state.security.token + chain,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-Requested-With": 'XMLHttpRequest',
                        "X_REQUESTED_WITH": 'xmlhttprequest'
                    }
                })
                .then(res => {
                    return res.json();
                })
                .then(response => {
                    //console.log(data);
                    if(response.status === 'OK')
                    {
                        this.setState({loading: false, cust_delete: false, focus: false});
                        this.onRefresh();
                    }
                    else
                    {
                        this.setState({loading: false, error: response.message});
                    }
                }).catch(function (err) {
                    _this.setState({cust_delete: false, focus: false, loading: false, error: 'Network error - please try again'});
                });           
        
            }
            catch(e)
            {
                _this.setState({cust_delete: false, focus: false, error: 'Network error', loading: false});
            }
        });

    }

    onDeleteCustomer(cust)
    {
        this.setState({cust_delete: true, focus: cust});
    }


    onTabChange( e, v )
    {
        window.localStorage.setItem('rvCustomerTab', parseInt(v));
        this.setState({tab: v}, () =>{
            window.location.reload();
        })
    }

    onManageCustomer(custObject, lock = false)
    {
        //this.setState({customer_panel: true, customer_id: id});

        /* initate the panel and then get the data */
        let x = Object.assign({},custObject);
        let message = {
            customer: x,
            lock: lock
        };
        console.log("onManageCustomer: managing customer", custObject, x, message);
        this.props.manageCustomer(message);
      
    }

    onManageCustomerPanelClose(event)
    {
        this.setState({customer_panel: false, view: false});
    }




    onAddCustomer(event)
    {
        this.setState({cust_add: true})
    }



    handleAddCustomerClose(event)
    {
        this.setState({cust_add: false})
    }    
    
    handleAddCustomerOK(event)
    {
        event.preventDefault();
        event.nativeEvent.stopImmediatePropagation();

        var firstname = event.target.elements.firstname.value || "";
        var lastname = event.target.elements.lastname.value || "";
        var email = event.target.elements.email.value || "";
        var mobile = event.target.elements.telephone.value ||  "";

        var chain = '';
        chain = chain + "&firstname=" + firstname;
        chain = chain +  "&lastname=" + lastname;
        chain = chain +  "&email=" + email;
        chain = chain +  "&telephone=" + mobile;
        let _this = this;
        this.setState({error: ''})

        fetch("/customers/add", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({cust_add: false});
                _this.onRefresh();
            }
            else
            {
                _this.setState({error: response.message})
            }
        });      

        console.log(event);
    }  


    onRefresh()
    {
        fetch("/backenddata/ajax_fetch_customers", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + '&refresh=1',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(!response.hasOwnProperty("error"))
            {
              let o = {
                'settings': response.data
              };
              console.log("loading ajax", o);
              this.props.reloadData( o );

            }
            else
            {
               /* handle error */
            }
        });      
    }


  

    createCustomers(id, firstname, lastname, email, mobile) {
        var name = firstname + ' ' + lastname
        return { id, name, firstname, lastname, email, mobile  };
    }

    




    render()
    {
        var classes = this.props.classes;
        var d = [];
        var a = [];

        for(var i = 0; i < this.state.customers.length; i++)
        {
            console.log(this.state.customers[i]);
            d.push( {   name: this.state.customers[i].name, 
                        email: this.state.customers[i].email, 
                        mobile: this.state.customers[i].mobile, 
                        manage: <ManageButton 
                                    customer={this.state.customers[i]} 
                                    callback={this.onManageCustomer} classes={classes}/>});
        }



        for(var i = 0; i < this.state.acustomers.length; i++)
        {
            console.log(this.state.acustomers[i]);
            a.push( {   name: this.state.acustomers[i].name, 
                        email: this.state.acustomers[i].email, 
                        mobile: this.state.acustomers[i].mobile, 
                        restore: <RestoreButton 
                                    customer={this.state.acustomers[i]} 
                                    callback={this.onRestoreCustomer} classes={classes}/>,
                        delete: <DeleteButton 
                        customer={this.state.acustomers[i]} 
                        callback={this.onDeleteCustomer} classes={classes}/>});

            }

        let size = 25;
        let asize = 25;
        let pages = [0, 50, 100, 500];
        let apages = [0, 50, 100, 500];

        if(this.state.customers.length < 25)
        {
            let x = [];
            size = this.state.customers.length;
            x[0] = size;
            pages = x;
        }

        if(this.state.customers.length >= 25 && this.state.customers.length < 50)
        {

            let x = [];
            x[0] = 25;
            x[1] = size;
            pages = x;
        }

        if(this.state.customers.length >= 50 && this.state.customers.length < 100)
        {
            let x = [];

            x[0] = 25;
            x[1] = 50;
            x[2] = size;
            pages = x;
        }


        if(this.state.customers.length >= 100 && this.state.customers.length < 250)
        {
            let x = [];

            x[0] = 25;
            x[1] = 50;
            x[3] = 100;
            x[2] = size;
            pages = x;
        }



        // archived customers 

        if(this.state.acustomers.length < 25)
        {
            let x = [];
            asize = this.state.acustomers.length;
            x[0] = asize;
            apages = x;
        }

        if(this.state.acustomers.length >= 25 && this.state.acustomers.length < 50)
        {

            let x = [];
            x[0] = 25;
            x[1] = asize;
            apages = x;
        }

        if(this.state.acustomers.length >= 50 && this.state.acustomers.length < 100)
        {
            let x = [];

            x[0] = 25;
            x[1] = 50;
            x[2] = asize;
            apages = x;
        }


        if(this.state.acustomers.length >= 100 && this.state.acustomers.length < 250)
        {
            let x = [];

            x[0] = 25;
            x[1] = 50;
            x[3] = 100;
            x[2] = asize;
            apages = x;
        }


        console.log('data', d);


        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };

        return (
            <div className={classes.root}>


                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                        <Typography variant="h2" className={classes.title}>
                            {window.vocab.topbar}
                        </Typography>
                        
                        <Button  variant="outlined" color="secondary" className="space-right"  startIcon={<DescriptionIcon />} onClick={this.onExport}>Export</Button>
                        <Button  variant="outlined" className="button-green space-right"  startIcon={<PersonAddIcon />} onClick={this.onAddCustomer}>Customer</Button>
                    </Toolbar>
                </AppBar>


                <Grid container direction="row" justifyContent="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >

                    <Grid item xs={12} md={12} > 
                        <Grid container justifyContent="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <h2>{window.vocab.customer}</h2>

                                    <Paper variant="outlined" square>
                                        <Tabs
                                            value={this.state.tab}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            onChange={this.onTabChange}
                                            aria-label="disabled tabs example"
                                        >
                                            <Tab label="Active" />
                                            <Tab label="Archived" />
                                   
                                        </Tabs>
                                    </Paper>
                                    <div className="mt-2">
                                        {this.state.error !== '' && <Alert severity="error">{this.state.error}</Alert>}
                                    </div>

                                    <TabPanel value={this.state.tab} index={0}>
                                        <TableContainer>
                                            <MaterialTable   icons={tableIcons}
                                                columns={[
                                                    { title: "Name", field: "name" },
                                                    { title: "Email", field: "email" },
                                                    { title: "Phone", field: "mobile" },
                                                    {
                                                        title: "Manage",
                                                        field: "manage",
                                                        sorting: false,                                  
                                                        cellStyle:{
                                                            textAlign: "center"
                                                        },
                                                        headerStyle:{
                                                            textAlign: "center"
                                                        }
                                                    }
                                                ]}
                                                data={d}
                                                title=""
                                                options = {{
                                                    pageSize: size  ,
                                                    pageSizeOptions: pages,
                                                    padding: 'dense',
                                                    searchText: sessionStorage.getItem('rvCustSearch') || '',
                                                }}
                                                onSearchChange={(e) => sessionStorage.setItem('rvCustSearch', e)}
                                            />

                                        </TableContainer>                                        
                                    </TabPanel>
                                    <TabPanel value={this.state.tab} index={1}>
                                        {
                                            this.state.loading && (
                                                <React.Fragment>
                                                    <Skeleton />
                                                    <Skeleton animation={false} />
                                                    <Skeleton animation={false} />
                                                    <Skeleton animation={false} />
                                                    <Skeleton animation={false} />
                                                    <Skeleton animation={false} />
                                                    <Skeleton animation={false} />
                                                    <Skeleton animation="wave" />
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            !this.state.loading &&
                                            <TableContainer>
                                            <MaterialTable   icons={tableIcons}
                                                columns={[
                                                    { title: "Name", field: "name" },
                                                    { title: "Email", field: "email" },
                                                    { title: "Number", field: "mobile" },
                                                    {
                                                        title: "Restore",
                                                        field: "restore",
                                                        sorting: false,                                  
                                                        cellStyle:{
                                                            textAlign: "center"
                                                        },
                                                        headerStyle:{
                                                            textAlign: "center"
                                                        }
                                                    },
                                                    {
                                                        title: "Delete",
                                                        field: "delete",
                                                        sorting: false,                                  
                                                        cellStyle:{
                                                            textAlign: "center"
                                                        },
                                                        headerStyle:{
                                                            textAlign: "center"
                                                        }
                                                    }                                                    
                                                ]}
                                                data={a}
                                                title=""
                                                options = {{
                                                    pageSize: asize  ,
                                                    pageSizeOptions: apages,
                                                    padding: 'dense',
                                                    searchText: sessionStorage.getItem('rvArCustSearch') || '',
                                                }}
                                                onSearchChange={(e) => sessionStorage.setItem('rvArCustSearch', e)}
                                            />
                                            </TableContainer>   
                                        }                                        
                                    </TabPanel>
                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>
                <Dialog aria-labelledby="customized-dialog-title" open={this.state.cust_add} fullWidth={true} maxWidth = {'sm'}>
                    <form onSubmit={this.handleAddCustomerOK}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleAddCustomerClose}>
                    Add Customer
                    </DialogTitle>
                        <DialogContent dividers>
                                
                                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                                    {   this.state.error != '' &&
                                        <Grid item xs={12} md={12} > 
                                            <Alert severity="error">{this.state.error}</Alert>
                                         </Grid>
                                    }
                                    <Grid item xs={12} md={12} > 
                                        <TextField name="firstname"  id="first-name" label="First name" variant="outlined" fullWidth={true} autoFocus={true} helperText="Enter the customer's first name. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'" inputProps={{required:true, pattern:"[A-Za-z-._() ]{1,250}"}} />
                                    </Grid>
                                    <Grid item xs={12} md={12} > 
                                        <TextField name="lastname"  id="last-name" label="Last name" variant="outlined" fullWidth={true} helperText="Enter the customer's last or surname. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'" inputProps={{required:true, pattern:"[A-Za-z-._() ]{1,250}"}} />
                                    </Grid>
                                    <Grid item xs={12} md={12} > 
                                        <TextField name="email" id="email"  label="Email address" variant="outlined" type="email" fullWidth={true} helperText="Enter the customer's email address" inputProps={{required:true}} />
                                    </Grid>  
                                    <Grid item xs={12} md={12} > 
                                        <TextField name="telephone" id="telephone" label="Mobile number" variant="outlined" type="tel" pattern="[0-9]{3}" fullWidth={true}  helperText="Enter the customer's mobile number. If you do not know this, please leave blank. Allowed characters '+' and 0-9." inputProps={{required:false, pattern:"^[+]{0,1}[0-9]{8,20}"}}/>
                                    </Grid>                                                               
                                </Grid>

                        <Typography gutterBottom>
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button  key={uuidv1()} color="primary" type="submit">
                            OK
                        </Button>
                     </DialogActions>
                     </form>
                </Dialog>


                {
                    this.state.cust_restore && (
                        <CoreDialog title="Restore customer" body="Warning! You are about to restore this customer. If completed, the customer will no longer be archived. Do you want to continue?" state={true} danger={true} onCancel={this.onRestoreCancel} actions={[{label:"Yes, restore", action:this.onRestoreComplete}]}  />

                    )
                }


                {
                    this.state.cust_delete && (
                        <CoreDialog title="Permanently delete customer" body="Warning! You are about to permanently delete this customer. If completed, the customer will removed and will no longer be able to access their account. This is not recommended. Are you sure that you want to continue?" state={true} danger={true} onCancel={this.onDeleteCancel} actions={[{label:"Yes, delete", action:this.onDeleteComplete}]}  />
                    )
                }

                {
                    this.state.error !== '' &&
                    <CoreDialog title="We have detected a problem" body={this.state.error}  state={true} danger={true} onCancel={()=>{this.setState('')}} actions={[{label:"OK", action:() => {this.setState({error: ''})}}]} />

                }

            </div>            
        );
    }

};



const CustomersManager = connect(null, mapDispatchToProps)(CustomersManagerModule);
export default withStyles(styles)(CustomersManager);
