import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import PassPurchaseDialog from './pass-purchase-dialog';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: 'rgba(0,0,0,0.8)',
      },
    },
  },
}))(MenuItem);

export default function PassWidgets(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cashPayment, setCashPayment] = React.useState(false);
  const [cardPayment, setCardPayment] = React.useState(false);

  const onCashPayment = event => {

    setCashPayment(true);
    setAnchorEl(null);

  }

  const onPassCashPaymentClose = (refresh) => {
    setCashPayment(false);
    if(refresh)
    {
      props.refresh( refresh );
    }
  }

  const onCreditCardPayment = event => {

    setAnchorEl(null);

  }


  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="registration-button"
        aria-haspopup="true"
        variant="outlined"
        color="inherit"
        onClick={handleClick}
        startIcon={<AddCircleOutlineIcon />}

      >
        Assign a pass
      </Button>
      <StyledMenu
        id="registration-button-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemText primary="Payment Cash or other" onClick={onCashPayment} />
        </StyledMenuItem>
      
        <StyledMenuItem  disabled={props.cards.length === 0}>
          <ListItemText primary="Charge registered credit/debit card" onClick={onCreditCardPayment} />
        </StyledMenuItem>
      </StyledMenu>
      {(cashPayment) && (
        <PassPurchaseDialog cust_id={props.customer.id} passes={props.passes} open={cashPayment} security={props.security}  onCancel={(x)=>{onPassCashPaymentClose(x)}}/>
      )}

    </div>
  );  
}