import React, { Component} from 'react';
import { connect } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField  from '@material-ui/core/TextField';

import Slide from '@material-ui/core/Slide';
import Loading from '../core/loading';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl  from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './styles.scss';
import Engine from './Engine';



const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    questionaireField:{
        marginTop: '15px',
        marginBottom: '15px',
    },
    buttonGo:{
        marginTop: '20px',
        marginBottom: '20px',
    }



});






const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });




function mapDispatchToProps(dispatch) {
    return {
    };
  }


class QuestionaireEditorPanel extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            security: props.security,
            open: props.open,
            questionaire_id: props.data.id || uuidv4(),
            questionaire_name: props.data.name ||'',
            questionaire_expires: props.data.expires || 1,
            questionaire_period: props.data.period || '1',
            questionaire: props.data.object || [{
                id: uuidv4(),
                type: 'input',
                text: '',
                options: [{
                    id: uuidv4(),
                    text: ''
                    },
                    {
                    id: uuidv4(),
                    text: ''
                }],
            }],
            updating: false,
            error: null,
        };

        this.onManageQuestionairePanelClose = this.onManageQuestionairePanelClose.bind(this);
        this.onAddName = this.onAddName.bind(this);
        this.onUpdate     = this.onUpdate.bind(this);
        this.onChangePeriodType = this.onChangePeriodType.bind(this);
        this.onUpdateExpiry = this.onUpdateExpiry.bind(this);
        this.jsonEscape     = this.jsonEscape.bind(this)
    }


    onChangePeriodType( event )
    {
        this.setState({'questionaire_period': event.target.value});
    }

    onUpdateExpiry( event )
    {
        this.setState({'questionaire_expires': event.target.value});
    }

    jsonEscape(str)  {

         
        return str.replace(/'/g, '&#39;').replace(/"/, '&#34;').replace(/\\/g, "\\\\").replace(/\n/g, "\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
    }

    onUpdate(event)
    {
        event.preventDefault();
        event.nativeEvent.stopImmediatePropagation();

        var _this = this;

        const questionnaire = this.state.questionaire.map( q =>{
            q.text = this.jsonEscape(q.text);
            q.options = q.options.map( o => {
                o.text = this.jsonEscape( o.text );
                return o;
            });
            return q;
        })

 
        var chain = '';
        chain = chain + "&id=" + this.state.questionaire_id;
        chain = chain + "&name=" + encodeURIComponent( this.state.questionaire_name ); 
        chain = chain + "&period=" + encodeURIComponent( this.state.questionaire_period ); 
        chain = chain + "&expires=" + encodeURIComponent( this.state.questionaire_expires ); 
        chain = chain + "&questionaire=" + encodeURIComponent( JSON.stringify(questionnaire) );
 
        fetch("/questionaires/ajax-questionaire-edit", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({updating: false});
                _this.props.closeCustomerPanel(true);
            }
            else
            {

                this.setState({error: response.reason });
            }
        }).catch(function(err){
            console.error('Failed to update questionaire. Your session might have expired. Please refresh this page.',);
        });  


        console.log(event);
        

    }


    onAddName(event)
    {
        var v = event.target.value || "";    
        this.setState({questionaire_name: v});

    }

  
 

    onManageQuestionairePanelClose(event)
    {
        this.props.closeCustomerPanel(false);
    }



      
  
    render(  )
    {
        var classes = this.props.classes;

        /* OK we need to kick off the loading of the data */
        return (
            <div className={classes.root}>
 

                <Dialog fullScreen open={this.props.open} onClose={this.onManageQuestionairePanelClose} TransitionComponent={Transition} className="rvNS">
                    <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.onManageQuestionairePanelClose} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className="dialogTitle">
                            Questionnaire editor
                        </Typography>
                    </Toolbar>
                    </AppBar> 

                    {this.state.updating && (
                        <div className="top-box fullscreen-panel">
                            <Loading /> 
                        </div>
                    )}
                    {!this.state.updating && (
                        <form id="myForm" onSubmit={this.onUpdate} className="top-box fullscreen-panel">

                            <Grid container justifyContent="flex-start" alignItems="stretch" className="rvQuestionairePanel">  
                                <Grid item xs={12}>
                                <Typography variant="h6" className="mt-2">Step 1: Basic details</Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField name="questionaire_name" className={classes.questionaireField} value={this.state.questionaire_name} onChange={this.onAddName} id="questionaire-name" label="Name"  fullWidth={true} autoFocus={true} helperText="Enter the name for the questionaire. Only you will see this. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'" required inputProps={{pattern:"[A-Za-z-._()-, 0-9]{1,248}"}} />
                                </Grid>  
                                <Grid item xs={12} md={12}>
                                    <TextField name="questionaire_expires" className={classes.questionaireField} value={Number(this.state.questionaire_expires).toFixed(0)} step={1} onChange={this.onUpdateExpiry} id="questionaire-expires" label="# of months or years answers will be saved."  helperText="Enter the number of days or months which the pass will be valid for after purchase. Allowed characters 0-9. Remember to select days or months below." type="number" inputProps={{required:true, pattern:"[1-9]{1}[0-9]+"}} />
                                </Grid> 

                                <Grid item xs={12}>
                                    <FormControl component="fieldset" className={classes.radioSelect} required>
                                        <RadioGroup aria-label="Expiration period" name="period-type" value={this.state.questionaire_period} onChange={this.onChangePeriodType}>
                                            <FormControlLabel key="months" value='0' control={<Radio />} label="Months" />
                                            <FormControlLabel key="years" value='1' control={<Radio />} label="Years" />
                                            <FormHelperText id="period-type-helper-text">Select the expiration period - this is the period answers to a questionaire will be stored by default unless deleted or overwritten.</FormHelperText>
                                        </RadioGroup>
                                    </FormControl>                                     
                                </Grid>          

                                <Grid item xs={12}>
                                    <Typography variant="h6" className="mb-1 mt-2">Step 2: Add questions</Typography>
                                </Grid>


                                <Grid item xs={12} md={12}>          
                                    <Engine questionaire={this.state.questionaire} onUpdate={( q ) => { this.setState({'questionaire': q}); }} />
                                </Grid> 
                                <Grid item xs={12} md={12}>          
                                    <Button type="submit" variant="contained" className="submit mt-2" color="primary" >Save</Button>
                                </Grid> 
                            </Grid>  
   
                        </form>              
                    )}
                    
                </Dialog>




            </div>            
        );
    }

};



const QuestionaireAddPanelManager = connect(null, mapDispatchToProps)(QuestionaireEditorPanel);
export default withStyles(styles)(QuestionaireAddPanelManager);
