import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { CirclePicker } from 'react-color';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './stepper';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";

import  {
    EVENT_APP_CACHE_STATE_WIZARD_1
  } from '../redux/types';

import MuiDialogTitle from '@material-ui/core/DialogTitle';

    const styles = theme => ({
        root: {
            flexGrow: 1,
          },          
          title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
          },
          formControl: {
            margin: '20px',
            minWidth: 120,
          },
          textInput:{
            marginTop:"20px",
            marginBottom:"20px",
          },
          switchLabel:{
              color: 'rgba(0,0,0,0.6)',
          },
          captionText:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',

          },
          colorSelect:{
            marginTop:"10px",
            marginBottom:"10px",
          },
          captionSection:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',
            marginBottom: '10px',
            lineHeight: '1.5em',
          }
});








function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventsStep1Module extends Component{

    constructor( props )
    {
        super( props );

        console.log('cache', this.props.cache);
        this.state = {
            name: this.props.cache.name || '',
            description:  this.props.cache.description || '',
            color:  this.props.cache.color|| "#000000" ,
            waiting_list_active:  this.props.cache.waiting_list_active || false,
            errors: {}
        }
        obj = this.state;

        this.state['errors'] = {
            name: {
                object: 'name',
                rule: /^[a-zA-Z0-9 \W]+$/,
                state: false, 
                ok: "This is the name of your event. It should summarise the event that you are selling. For example, 'Monday Yoga with Henry'", 
                error: 'This information is mandatory. Alpha numeric values only are allowed'
            },
            description: {
                object: obj.description,
                rule: /^[a-zA-Z0-9 \W]*$/,
                state: false, 
                ok: "This is the description of your event. Here you can add more detail about what you are selling", 
                error: 'This information is optional. Alpha numeric values only are allowed'
            }            
        };

        this.state.security = props.security;
        this.myRef = React.createRef()  
        this.onNameChange   = this.onNameChange.bind(this);
        this.onDescriptionChange   = this.onDescriptionChange.bind(this);
        this.onDoNext   = this.onDoNext.bind(this);
        this.onDoPrevious   = this.onDoPrevious.bind(this);
        this.onWaitingListChange   = this.onWaitingListChange.bind(this);
        this.onColorChange          = this.onColorChange.bind(this);


   
        
    }

    onDoPrevious(event)
    {

        if(this.isValid())
        {
            var o = { store_previous: true,
                      data:
                      {
                        name: this.state.name,
                        description: this.state.description,
                        color: this.state.color,
                        waiting_list_active: this.state.waiting_list_active,
                      },
                      state: EVENT_APP_CACHE_STATE_WIZARD_1};
            this.props.rewindStep(o);        
        }
        else
        {
            var o = { 
                state: EVENT_APP_CACHE_STATE_WIZARD_1
            };
            this.props.rewindStep(o);        
        }


        var o = { 
            state: EVENT_APP_CACHE_STATE_WIZARD_1
        };
        this.props.rewindStep(o);        
    }

    onNameChange(event)
    {
        this.setState({'name': event.target.value});
    }
     

    onDescriptionChange(event)
    {
        this.setState({'description': event.target.value});
       // this.state.description = event.target.value;
    }

    onWaitingListChange(event)
    {

        console.log('New state', event.target.checked);
        this.setState({'waiting_list_active': event.target.checked});
        //this.state.waiting_list_active = event.target.value;
    }

    onColorChange(color, event)
    {
       // this.state.color = color.hex;
       
       this.setState({'color': color.hex});
       console.log('form', this.state);

        //var o = {'data': color.hex};
        //console.log('new color', color.hex);
        //this.props.updateColor( o );
    }    

    isValid()
    {
        for(var i in this.state.errors)
        {
            var regex = RegExp(this.state.errors[i].rule);
            var x = Object.assign({}, this.state.errors);


             if(regex.test(this.state[this.state.errors[i].object]) === false)
            {
                window.scrollTo(0, this.myRef.offsetTop);                
                this.state.errors[i].state = true;
                this.setState({'errors': this.state.errors});
                return false;
            }
            else
            {
                console.log('Passes test');
                this.state.errors[i].state = false;
                this.setState({'errors': this.state.errors});
                return true;

            }
            
        }
        return false;

    }

    
    onDoNext(event)
    {
        var regex = RegExp(this.state.errors.name.rule);
       
        var x = Object.assign({}, this.state.errors);

        //if(regex.test(this.state.name))
        if(this.isValid())
        {
            var o = { basic:
                      {
                        name: this.state.name,
                        description: this.state.description,
                        color: this.state.color,
                        waiting_list_active: this.state.waiting_list_active,
                      },
                      state: EVENT_APP_CACHE_STATE_WIZARD_1};
            this.props.setStep(o);
    
        }
        else
        {
            this.state.errors.name.state = true;
            this.setState({'errors': this.state.errors});
        }



    }
  
    




  

    render()
    {
        var classes = this.props.classes;
        console.log('edit data', this.state.edit_data);
        var color = this.state.color;
        var name = this.state.name;
        var description = this.state.description;
        var waiting = this.state.waiting_list_active;
        var classes = this.props.classes;

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Basic information
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >


                <Grid item xs={12} md={12} > 
                    <Grid container justify="flex-start" alignItems="stretch">
                        
                                        
                        <Grid item xs={12} md={12}>
                            <div className="box">
                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                    <Grid item xs={12} md={12}>
                                           <StepperWidget key={uuidv1()} step={0}/>

                                    </Grid>
                                    <Grid item xs={12} md={12} >
                                        <div className="formSection">Event basic information</div>                               
                                        <p className="sectionDescription">
                                          This section enables you to give your event a name, a description and optionally set up a waiting list. If the waiting list is enabled, then in the event of your event becomming fully booked, the system will allow perspective clients to register their interest.                        

                                        </p>

                                        <form className="" ref={this.myRef} noValidate autoComplete="off">
                                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                                <Grid item xs={12} md={12} >
                                                    <TextField
                                                        id="event-name"
                                                        className={classes.textInput}
                                                        value={name}
                                                        onChange={this.onNameChange}
                                                        margin="none"
                                                        variant="outlined"
                                                        fullWidth
                                                        placeholder="Add the name of your event here."
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}     
                                                        error={this.state.errors.name.state}                               
                                                        autoFocus={true}
                                                        label="Event name"
                                                        helperText={this.state.errors.name.state? this.state.errors.name.error: this.state.errors.name.ok}
                                                    />  
                                                </Grid>
                                            </Grid>

                                                <TextField
                                                    id="event-description"
                                                    className={classes.textInput}
                                                    value={description}
                                                    onChange={this.onDescriptionChange}
                                                    margin="none"
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Event description"
                                                    placeholder="Add the description of your event here."
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}                                    
                                                    multiline={true}
                                                    minRows={2}
                                                    maxRows={4}                                  
                                                    helperText="Add a desciption of your event. This is displayed to the customer to give them a better idea of what you are offering."
                                                /> 
                                                <FormControlLabel
                                                        control={
                                                        <Switch
                                                            checked={waiting}
                                                            onChange={this.onWaitingListChange}
                                                            value="1"
                                                            color="primary"
                                                        />
                                                        }
                                                        label={<span className={classes.switchLabel}>Activate waiting list</span>}
                                                    /> 
                                                <Typography variant="caption" className={classes.captionText}>By enabling your waiting list, you can capture people information when an event is fully booked.</Typography>                               
                                                
                                                <div style={{display: 'none'}}>
                                                    <div className="formSection">Class colour</div>                               
                                                    <p className="sectionDescription">
                                                        This section allows you to assign a colour to this event. This can be used for some of the automated scheduling features of reservie to make an event stand out.                        
                                                    </p>

                                                    
                                                    <Box className={classes.colorSelect} m={1}>
                                                        <CirclePicker 
                                                            onChangeComplete={this.onColorChange} 
                                                            color={color} 
                                                            colors={["#000000", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b"]} />
                                                    </Box>                                   
                                                    <Typography variant="caption" className={classes.captionText}>Optional: if selected we use this colour to highlight the class. This can be helpful to distinguish class types. Please note this feature currently only compatible available with the API schedule.</Typography>                               
                                                </div>
                                        </form>

                                    </Grid>
                                    <Grid item xs={5} md={3}>
                                        <Button
                                            variant="contained"
                                            color="default"
                                            size="large"
                                            className="rv-eng-next-button"
                                            startIcon={<NavigateBeforeIcon />}
                                            onClick={this.onDoPrevious}
                                            fullWidth={true}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2} md={6}></Grid>
                                    <Grid item xs={5} md={3}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className="rv-eng-next-button"
                                            endIcon={<NavigateNextIcon />}
                                            onClick={this.onDoNext}
                                            fullWidth={true}
                                        >
                                            Next
                                        </Button>  
                                                            
                                    </Grid>
                                </Grid>
                

                            </div>
                        </Grid>                                               
                    </Grid>

                </Grid>                                    

                </Grid>

                
            </div>            
        );
    }

};



const EventsStep1 = connect(null, mapDispatchToProps)(EventsStep1Module);
export default withStyles(styles)(EventsStep1);
