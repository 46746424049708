import React, { Component } from 'react';
import { connect } from "react-redux";


import Grid from '@material-ui/core/Grid';


import { withStyles } from '@material-ui/core/styles';



import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField  from '@material-ui/core/TextField';


import Slide from '@material-ui/core/Slide';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import Loading from '../core/loading';
import FormControl  from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';


import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem',
        marginTop: '0px'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },
    textField:{
        marginTop: '15px',
        marginBottom: '15px',
    },
    radioSelectTitle:{
        fontSize: '12px',
        color: '#3f51b5',
        marginBottom: '10px',
    },
    alignCenter:
    {
        textAlign: 'center'
    },
    formControl: {
      marginTop: '5px',
      marginBottom: '5px',
      minWidth: 120,
    },  
    radioSelect:{
        marginBottom: '10px',
    },
    buttonGo:{
        backgroundColor:'rgba(145,215, 110, 0.8)',
        color: '#ffffff',
        '&:hover':{
            backgroundColor:'rgba(145,215, 110, 1)',
        },
        marginTop: '20px',
        marginBottom: '20px',
    }



});






const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });




function mapDispatchToProps(dispatch) {
    return {
    };
  }


class SubscriptionAddPlanModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            security: props.security,
            name: '',
            association_type: 'pass',
            open: props.open || false,
            pass_list: props.passes || [],
            selected_pass: '',
            price: 1.00,
            initialprice: 1.00,
            price_error: '',
            term: 0,
            error: '',
        };


      //  this.state.selected.push({id: 1, name:'wibble'});


        this.onManagesubscriptionPanelClose       = this.onManagesubscriptionPanelClose.bind(this);
        this.onUpdatesubscriptionName             = this.onUpdatesubscriptionName.bind(this);
        this.onChangeAssociationType              = this.onChangeAssociationType.bind(this);
        this.onPriceChange                        = this.onPriceChange.bind(this);
        this.onInitialPriceChange                 = this.onInitialPriceChange.bind(this);
        
        this.onTermChange                         = this.onTermChange.bind(this);
        this.onHandlesubscriptionUpdated          = this.onHandlesubscriptionUpdated.bind(this);
        this.onUpdatesubscriptionPrice            = this.onUpdatesubscriptionPrice.bind(this);
        this.onPassOptionSelect                   = this.onPassOptionSelect.bind(this);
    }

    onHandlesubscriptionUpdated(event)
    {
        event.preventDefault();
        event.nativeEvent.stopImmediatePropagation();

        var _this = this;

        var name = this.state.name;
        var association_type = this.state.association_type;
        var selected_pass = this.state.selected_pass.id;
        var term = this.state.term;
        var price = this.state.price;
        var initialprice = this.state.initialprice;
        var chain = '';
        chain = chain + "&name=" + name;
        chain = chain +  "&association_type=" + association_type;
        chain = chain +  "&selected_pass=" + selected_pass;
        chain = chain +  "&price=" + price;
        chain = chain +  "&term=" + term;
        chain = chain +  "&initialprice=" + initialprice;
  
        fetch("/subscriptions/ajax_plan_edit", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({error: ''});
                window.location.reload();
            }
            else
            {
                _this.setState({error: response.message });
            }
        }).catch(function(err){
            console.error('Failed to update subscription', err);
            _this.setState({error: 'Unable to add plan due to network error. Refresh the page and try again.'})
        });  
    
    }


    onUpdatesubscriptionName(event)
    {
        var v = event.target.value || "";    
        this.setState({name: v});

    }
    onChangeAssociationType(event)
    {
        this.setState({association_type: event.target.selected});

    }
    onPassOptionSelect(event, value)
    {
        var price = this.state.price;
        var initialprice = this.state.initialprice||price;
        for(var i = 0; i < this.state.pass_list.length; i++)
        {
            if(value.id === this.state.pass_list[i].id)
            {
                price = value.price;
                initialprice = value.initialprice||value.price;
            }
        }
        this.setState({selected_pass: value, price: price, initialprice: initialprice});

    }
    
  
    onPriceChange(event)
    {
        this.setState({'price': event.target.value});
    }

    onInitialPriceChange(event)
    {
        this.setState({'initialprice': event.target.value});
    }

    onManagesubscriptionPanelClose(event)
    {
        this.props.closeCustomerPanel(false);
    }

    onUpdatesubscriptionPrice(event)
    {
        var v = event.target.value || "";    
        this.setState({subscriptionprice: Number(v).toFixed(2)});

    }
    onTermChange(event)
    {
        var v = event.target.value;    
        this.setState({term: Number(v).toFixed(0)});
    }
  
    
  
    render(  )
    {
        var classes = this.props.classes;

        /* OK we need to kick off the loading of the data */

        console.log('render',this.props, this.state );

        return (
            <div className={classes.root}>
 

                <Dialog fullScreen open={this.state.open} onClose={this.onManagesubscriptionPanelClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.onManagesubscriptionPanelClose} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Subscription Plan Editor
                        </Typography>
                    </Toolbar>
                    </AppBar> 

                    {this.state.updating && (
                        <div className="top-box fullscreen-panel">
                            <Loading /> 
                        </div>
                    )}
                    {!this.state.updating && (
                        <form id="myForm" onSubmit={this.onHandlesubscriptionUpdated} className="top-box fullscreen-panel">
                            <Grid container justify="flex-start" alignItems="stretch">  
                                <Grid item xs={12}>
                                    <TextField name="name" 
                                               className={classes.textField} 
                                               value={this.state.name} 
                                               onChange={this.onUpdatesubscriptionName} 
                                               id="subscription-name" 
                                               label="Name of plan" 
                                               variant="outlined" 
                                               fullWidth={true} 
                                               autoFocus={true} 
                                               placeholder="Name of subscription plan"
                                               helperText="Enter the name of the subscription plan. Allowed characters A-Z, a-z, 0-9, '-', '_', ' ', (' and ')'" inputProps={{required:true, pattern:"[A-Za-z-._(), 0-9]{1,250}"}} />
                                </Grid>
                                <Grid item xs={12}>
                                    <div className={classes.radioSelectTitle}>Associated to</div>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset" className={classes.radioSelect} required>
                                        <RadioGroup aria-label="Subscription type pass" name="subcription-type" value={this.state.association_type} onChange={this.onChangeAssociationType}>
                                            <FormControlLabel key="pass" value='pass' control={<Radio />} label="Passes" />
                                            <FormControlLabel key="eventgroup" disabled value='eventgroup' control={<Radio />} label="Event Group" />
                                            <FormHelperText id="subcription-type-helper-text">Select an association type for this subscription plan</FormHelperText>
                                        </RadioGroup>
                                    </FormControl>                                     
                                </Grid>


                                <Grid item xs={12}>
                                    {this.state.association_type === 'pass' && (
                                            <Autocomplete
                                            id="locations-list"
                                            className={classes.formControl}
                                            options={this.state.pass_list}
                                            getOptionLabel={pass => pass.name}
                                            style={{ width: "100%" }}
                                            autoHighlight
                                            multiple={false}
                                            value={this.state.selected_pass}
                                            onChange={this.onPassOptionSelect}
                                            disableClearable={true}
                                            filterSelectedOptions
                                            getOptionSelected={(option, value)  => {
                                                return (option.id === value.id);  
                                            }}                                                
                                            renderOption={pass => (
                                            <React.Fragment>
                                                {pass.name}
                                            </React.Fragment>
                                            )}                
                                            renderInput={params => <TextField {...params} 
                                                                                label="Associated pass" 
                                                                                variant="outlined"
                                                                                helperText="The subscription plan must be associated to a pass. When a customer subscribes, their plan will automatically renew a pass of this type"
                                                                                required
                                                                                />}
                                        />                                         
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.textField} 
                                        onChange={this.onInitialPriceChange}
                                        margin="none"
                                        variant="outlined"
                                        fullWidth
                                        placeholder={1.00}
                                        type="number" 
                                        value={this.state.initialprice}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}     
                                        inputProps={{required:true, pattern:"[0-9]{1,10}[.]{0,1}[0-9]{0,1}", step:0.01, min:1.00}}                        
                                        label="Initial price"
                                        helperText={'Enter initial price of subscription. This must be greater or equal to 1. This is charged when the subscription is first setup.'}
                                    />                                      
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.textField} 
                                        onChange={this.onPriceChange}
                                        margin="none"
                                        variant="outlined"
                                        fullWidth
                                        placeholder={1.00}
                                        type="number" 
                                        value={this.state.price}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}     
                                        inputProps={{required:true, pattern:"[0-9]{1,10}[.]{0,1}[0-9]{0,1}", step:0.01, min:1.00}}                        
                                        label="Standard Price"
                                        helperText={'Enter standard price of subscription. This must be greater or equal to 1 and is charged for all subsequent payments after the initial'}
                                    />                                      
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.textField} 
                                        onChange={this.onTermChange}
                                        margin="none"
                                        variant="outlined"
                                        fullWidth
                                        placeholder={1.00}
                                        type="number" 
                                        value={this.state.term}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}     
                                        inputProps={{required:true, pattern:"[0-9]{1,10}[.]{0,1}[0-9]{0,1}", step:1, min:0, max:90}}                        
                                        label="Cancellation term (days)"
                                        helperText={"The number of days before the customer can cancel their subscription."}
                                    />                                      
                                </Grid>
                                {this.state.error.length > 0  && (
                                    <Grid item xs={12}>
                                        <Alert severity="error">
                                            {this.state.error}
                                        </Alert>                                
                                    </Grid> 
                                )}
                                                    
                                <Grid item xs={12}>
                                    <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                                        <Button type="submit" className={classes.buttonGo}>Continue >></Button>
                                    </Grid>  
                                    
                                </Grid> 
                            </Grid>  
   
                        </form>              
                    )}
                    
                </Dialog>




            </div>            
        );
    }

};



const SubscriptionAddPlan = connect(null, mapDispatchToProps)(SubscriptionAddPlanModule);
export default withStyles(styles)(SubscriptionAddPlan);
