import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import moment from 'moment'
import Grid from '@material-ui/core/Grid';


import Autocomplete from '@material-ui/lab/Autocomplete';

import Loading from './loading';

import {v1 as uuidv1} from "uuid";
import Alert from '@material-ui/lab/Alert';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonDisabled: {
    color: theme.palette.grey[900]
  },
  formSectionTitle:{
    marginTop: '15px',
    marginBottom: '15px',
    fontWeight: 'bold'
}    
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: 2,
  },
}))(MuiDialogActions);

const selected_default = {
  id: null,
  name: '',
  price: 0,
  currency: 'GBP',
  credits: 0,
  status: 'INACTIVE',
  expires: 0,
  mode: 0,
};

const pid_default      = { id: null, name: null, price: 0, stock: 0, active: false, credits:null};

function PassPurchaseDialogWidget( props ) {
  const [open, setOpen] = React.useState(props.open);
  const  [selected, setSelected] = React.useState(selected_default);
  const  [loading, setLoading] = React.useState(props.loading);
  const  callback  = props.onCancel;
  const  [price, setPrice] = React.useState(0);
  const  [error, setError] = React.useState('');
  const  [date, setDate] = React.useState(null);
  const  [credits, setCredits] = React.useState(0);
  const  today                 = new Date();

  const handleClose = () => {
    console.log('handleClose', props)
    callback(false);
    //setOpen(false);
  };

  const handleRegistration = () => {

    const  d  = new Date(date);
    const  q = d.toISOString().slice(0, 10) + ' ' +  d.toISOString().slice(11, 16);
    var chain = '';
    chain    += '&user_id=' + props.cust_id;
    chain    += '&date=' + q;
    chain    += '&credits=' + credits;
    chain    += '&price=' + price;
    chain    += '&id=' + selected.id;


    setLoading(true);

    var _this = this;
    console.log('Registering customer');
    try {
        fetch("/packages/do-ajax-cash-purchase", {
          method: "POST",
          mode: 'same-origin', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'include', // include, *same-origin, omit                            
          body: props.security.name + '=' + props.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
          if(response.status === 'OK')
          {
            console.log('handleRegistration', props)
            callback(true);
            setOpen(false);
  
          }
          else
          {
            console.log('handleRegistration', props)
            setError(response.message)
          }
        }).catch(function (err) {
          console.error('api called', err);
          setLoading(false);
          setError('Network error. Please retry.')
          //              _this.setState({loading: true});
        });           

    }
    catch(e)
    {
      console.error('api called', e);
      setLoading(false);
      setError('Network error. Please retry.')
    }    

  };

  const handlPassSelect = (event, value) => {

    if(value === null)
    {
      setSelected( selected_default );
      setDate(null)
      //setPrice( 0 );
    }
    else
    {
      let d = new Date();
      if(value.mode === 0)
      {
          d.setDate(d.getDate() + parseInt(value.expires));
      }
      else
      {
          d.setMonth(d.getMonth() + parseInt(value.expires));
      }
      setDate(d.toISOString().slice(0, 16));
      setSelected( value );
      setCredits( value.credits );
      setPrice( value.price );
     // setPrice( date.length * value.pids[0].price || 0 );
    }
    //props.onEventSelect(event, value);
  };

  const onDateSet = (e) => {
    setDate(e.target.value);
  }
  
  const onUpdateCredits = (e) => {
    setCredits(e.target.value);
  }

  const handlePriceSelect = ( event ) => {

      setPrice( parseFloat(event.target.value) );
  }

  React.useEffect(() => {
    setOpen(props.open);
  }, [props.open])

  
  console.log('dialog date: ', date);

  return (
    <div>
      <form>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} maxWidth = {'sm'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} danger={(props.danger?props.danger:false)}>
          Pass purchase
        </DialogTitle>
        <DialogContent dividers>
            <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                <Grid item xs={12} >
                      <div className={props.classes.formSectionTitle}>Step 1: select the event to register them to: </div>
                      <Autocomplete
                        id="company-events-list"
                        options={props.passes}
                        getOptionLabel={option => '(' + option.id + ') ' + option.name}
                        style={{ width: "100%" }}
                        autoHighlight
                        onChange={handlPassSelect}
                        renderOption={option => (
                          <React.Fragment>
                            {'(ID:' + option.id + ') ' + option.name}
                          </React.Fragment>
                        )}                
                        renderInput={params => <TextField {...params} label="Passes" variant="outlined" />}
                      />
                </Grid>
                <Grid item xs={12} >
                      {date !== null && (<div>
                        <div className={props.classes.formSectionTitle}>Step 2: Select the expiry date: </div>
                        <TextField
                              id="datetime-local"
                              label="Expires"
                              type="datetime-local"
                              required
                              defaultValue={date}
                              className={props.classes.textField}
                              onChange={onDateSet}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{
                                // min: "2021-02-20T00:00",
                                min: today.toISOString().slice(0, 16)
                              }}
                          />
                    </div>)}
                </Grid>
                <Grid item xs={12} >
                  {date !== null && (<div>
                      <div className={props.classes.formSectionTitle}>Step 3: Credits to be applied: </div>
                      <TextField name="passcredits" required 
                      step={1} 
                      type="number" 
                      value={credits} 
                      onChange={onUpdateCredits} 
                      id="standard-price" 
                      label="Number of credits allocated" 
                      variant="outlined" 
                      fullWidth
                      helperText="Enter the number of credits allocated. For example, 5. Allowed characters 0-9" 
                      inputProps={{required:true, pattern:"[1-9]+", step:1}} /> 

                  </div>)}
                </Grid>
                <Grid item xs={12} >
                  {date !== null && (<div>
                      <div className={props.classes.formSectionTitle}>Step 3: Select the price paid: </div>
                      <TextField
                              onChange={handlePriceSelect}
                              margin="normal"
                              variant="outlined"
                              fullWidth
                              placeholder="1.00"
                              InputLabelProps={{
                                  shrink: true,
                              }}  
                              inputProps={{ min: "1.00", step: "0.01" }}  
                              type="number" 
                              value={price}                                
                              error={false}                               
                              label="Price"
                              helperText={"Enter the price paid for this event"}
                            /> 
                      </div>)}
                </Grid>
                <Grid item xs={12} >
                  {
                    error !== '' &&  <Alert severity="error">{error}</Alert>
                  }
                </Grid>
            </Grid>
          <Typography gutterBottom />
        </DialogContent>
        <DialogActions>
          { (date !== null) && (
          <Button onClick={handleRegistration} key={uuidv1()} type="submit" color="primary" variant="outlined">
                Complete Registration
          </Button>
          ) }
          { (date === null) && (
          <Button key={uuidv1()} disabled={true}  type="submit"  color="inherit" variant="outlined" className={props.classes.buttonDisabled}>
                Complete Registration
          </Button>
          ) }          
        </DialogActions>
      </Dialog>
      </form>
    </div>
  );
  
}

class PassPurchaseDialog extends Component{
    constructor( props )
    {
        super( props );
      // console.log('TopNav', props);


        this.state = {
            security: props.security,
            loading: false,
            cust_id: props.cust_id,
            callback: props.onCancel,
            passes: props.passes,
            pid: [],
            ready: false

        };

        this.doneCancel = this.doneCancel.bind(this);        
    }  


    doneCancel( refresh ){
      this.state.callback(refresh);
    }

    render()
    {        
        var classes = this.props.classes;


        return (
            <div>
              <PassPurchaseDialogWidget 
                classes={classes} 
                passes={this.state.passes}
                cust_id={this.state.cust_id}   
                onCancel={this.doneCancel} 
                security={this.state.security}
                open={this.props.open}
                />
            </div>
        );
    }
     
}

export default withStyles(styles)(PassPurchaseDialog);
