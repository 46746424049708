
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Moment from 'react-moment';
import Grid from '@material-ui/core/Grid';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },          
  fabIcon:{
    '& .MuiSvgIcon-root':{
        fill: '#fff',
        color: '#fff',
        '&:hover':{
            color: '#ffe',
        }
    }
  }, 
  fabAction:{
      backgroundColor: '#FFA500',
      color: '#fff',
      '&:hover':{
          backgroundColor: '#ea9700',
      }
  },   
}));


class ActionButton extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
          data: props.data,
          callback: props.callback
      }

      console.log('ActionButton', this.state);
      this._handleClick = this._handleClick.bind(this);
  }

  _handleClick = () => {
      var x = Object.assign({}, this.state.customer);
      console.log('Manage button initiated',x);
      this.state.callback(this.state.data);
  }

  render() {
      var classes= this.props.classes;
      return (
          <IconButton name="clone" className={classes.fabAction} aria-label="event register" onClick={this._handleClick}>
              <DescriptionIcon className={classes.fabIcon}/>
          </IconButton>            
      )
  }
}



export default function EventCustomersQuestionairePanelManager( {state, onClose, data} ) {
  const classes = useStyles();
  const fullWidth = 'sm';
  const maxWidth = '100%';
  const [questionnaire, setQuestionnaire] = React.useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleView = ( o ) => {
      setQuestionnaire(o);
  }

  const jsonUnescape = (str) =>{
    return  str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\");
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={state}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Questionnaire viewer</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              questionnaire === false &&
              <Grid container justify="flex-start" alignItems="stretch">
                  <Grid item xs={12} md={12}>
                      <Table>
                          <TableHead>
                              <TableRow>
                                  <TableCell>ID</TableCell>
                                  <TableCell align="left">Created</TableCell>
                                  <TableCell align="left">Expires</TableCell>
                                  <TableCell align="center">View</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                            {
                                data.map( (q, i) =>(
                                  <TableRow>
                                      <TableCell>{q.id}</TableCell>
                                      <TableCell align="left"><Moment format="LLL">{q.date}</Moment></TableCell>
                                      <TableCell align="left"><Moment format="LLL">{q.expires}</Moment></TableCell>
                                      <TableCell align="center"><ActionButton callback={handleView} data={q} classes={classes}/></TableCell>
                                  </TableRow>
                                ))
                            }

                          </TableBody>
                      </Table>
                  </Grid>
              </Grid>                
            }

            {
              questionnaire !== false &&
              <Grid container justify="flex-start" alignItems="stretch">
                  <Grid item xs={12} md={12} pt={3}>
                      <h3>Overview</h3>
                  </Grid>                
                  <Grid item xs={12} md={12} pb={4}>
                      <Grid container justify="flex-start" alignItems="stretch">
                          <Grid item xs={12} md={4}>
                              <Table>
                                  <TableBody>
                                      <TableRow>
                                          <TableCell><b>Questionnaire ID:</b></TableCell>
                                          <TableCell>{questionnaire.id}</TableCell>
                                      </TableRow>
                                      <TableRow>
                                          <TableCell><b>Completed:</b></TableCell>
                                          <TableCell><Moment format="ll">{questionnaire.date}</Moment></TableCell>
                                      </TableRow>
                                      <TableRow>
                                          <TableCell><b>Expires:</b></TableCell>
                                          <TableCell><Moment format="ll">{questionnaire.expires}</Moment></TableCell>
                                      </TableRow>
                                  </TableBody>
                              </Table>
                          </Grid>
                      </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} pt={3}>
                      <h3>Detail</h3>
                  </Grid>
                  <Grid item xs={12} md={12}>
                      <Grid container justify="flex-start" alignItems="stretch">
                          <Grid item xs={12} md={12}>
                              <Table>
                                  <TableBody>
                                      {
                                          questionnaire.qa.map( (qa, i) => (
                                              <React.Fragment>
                                                  <TableRow>
                                                      <TableCell>
                                                      <p><b>{i+1}. {jsonUnescape(qa.q)}</b></p>
                                                      {
                                                          qa.type !== 'multi-checkbox' &&
                                                          <p><i>{jsonUnescape(qa.a)}</i></p>
                                                      }
                                                      {
                                                          qa.type === 'multi-checkbox' &&
                                                          qa.a.map((a) => (
                                                            <p><i>{jsonUnescape(a)}</i></p>
                                                          ))
                                                      }
                                                      </TableCell>
                                                  </TableRow>
                                              </React.Fragment>
                                      ))
                                      }
                                  </TableBody>
                              </Table>
                          </Grid>
                      </Grid>
                  </Grid>

              </Grid>
            }
          </DialogContentText>    
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}