import React, { Component } from 'react';
import { connect } from "react-redux";


import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Bar } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';

import Moment from 'react-moment';

import { withStyles } from '@material-ui/core/styles';

import { Divider } from '@material-ui/core';

import Link from '@material-ui/core/Link';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import CoreDialog from './dialog';
import {reloadData} from '../redux/actions';
import RefreshIcon from '@material-ui/icons/Refresh';




import './dashboard.css';

import {v1 as uuidv1} from "uuid";


const styles = {
    root: {
        flexGrow: 1,
      },
      title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
      },      
      
  };

function mapDispatchToProps(dispatch) {
    return {
        reloadData: spec => dispatch(reloadData(spec)),

      
    };
  }


class DashboardManagerModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            classrows: [],
            classrowsPerPage: 10,
            classpage: 0, 
            emptyRows: 0,
            order: 'desc',
            orderBy: 'date',
            /* Add waiting */
            waitingrows: [],
            waitingrowsPerPage: 10,
            waitingpage: 0,
            waitingemptyrows: 0,
            waitingorder: 'desc',
            waitingorderBy: 'name',
            /* seven days data */
            seven_days:{
                rows:[],
                rowsperpage: 10,
                page:0,
                emptyrows: 0,
                order: 'desc',
                orderby: 'date'
            },
            dialog:{
                id: null,
                state: false, 
                message: '',
                title: ''
            },
            security: {},
            refresh: false,
        };

        this.state.security = props.security;

        for(var a = 0; a < props.data.dialog.messages.length; a++)
        {
            this.state.dialog.message = props.data.dialog.messages[a].text;
            this.state.dialog.title = props.data.dialog.messages[a].name;
            this.state.dialog.id = props.data.dialog.messages[a].tid;
            this.state.dialog.state = true;
            break;
        }


        var wrows = [];

        for(var j = 0; j < props.data.waitinglist.length; j++)
        {
             var address = "mailto:" + props.data.waitinglist[j].email;
             wrows.push(this.createWaitingList(<a href={address}>{props.data.waitinglist[j].firstname + ' ' + props.data.waitinglist[j].lastname}</a> ,
                                               props.data.waitinglist[j].event_name, 
                                               <Moment format="LLL">{props.data.waitinglist[j].datetime}</Moment>))
            
        }

        wrows.sort((a, b) => (a.name < b.name ? -1 : 1));

        this.state.waitingrows = wrows;

        if(props.data.waitinglist.length < 1)
        {
            /* set up classrows */
            this.state.waitingemptyrows = 1;
        }

        var rows = [];
        console.log("event data ", props.data.eventlist);

        for(var i = 0; i < props.data.eventlist.length; i++)
        {
            rows.push(  
                this.createAvailabilityData(props.data.eventlist[i].date, 
                                            <Moment format="LLL">{props.data.eventlist[i].date}</Moment>, props.data.eventlist[i].name, 
                                            props.data.eventlist[i].sold + ' of ' + props.data.eventlist[i].stock,
                                            props.data.eventlist[i].eid,
                                            props.data.eventlist[i].did
                                            ));
        }

        rows.sort((a, b) => (a.date < b.date ? -1 : 1));
        this.state.classrows = rows;



        if(this.state.classrows.length < 1)
        {
            this.state.emptyRows = 1;
        }


        rows = [];

        for(i = 0; i < props.data.sales.seven_days_data.length; i++)
        {
           
            var o = this.createSalesData( props.data.sales.seven_days_data[i].created,  
                        <Moment format="LLL">{props.data.sales.seven_days_data[i].created}</Moment>, 
                        props.data.sales.seven_days_data[i].purchase_item_name,
                        props.data.sales.seven_days_data[i].q,
                        props.data.sales.seven_days_data[i].custid,
                        props.data.sales.seven_days_data[i].customer_first_name,
                        props.data.sales.seven_days_data[i].customer_last_name
                        );
            rows.push( o );
            console.log("objet", o);

                
        }
        console.log("sales data", rows);
        rows.sort((a, b) => (a.sdate < b.sdate ? -1 : 1));
        this.state.seven_days.rows = rows;



        console.log("sales data", this.state.seven_days);

        this.onChangePage   = this.onChangePage.bind(this);
        this.onChangeRowsPerPage   = this.onChangeRowsPerPage.bind(this);
        this.onDoDateSort   = this.onDoDateSort.bind(this);
        this.onDoNameSort   = this.onDoNameSort.bind(this);
        this.onDoWaitingNameSort   = this.onDoWaitingNameSort.bind(this);
        this.onDoWaitingWhatSort   = this.onDoWaitingWhatSort.bind(this);
        this.onWaitingChangePage   = this.onWaitingChangePage.bind(this);
        this.onWaitingChangeRowsPerPage   = this.onWaitingChangeRowsPerPage.bind(this);
        
        this.onChangeSalesPage   = this.onChangeSalesPage.bind(this);
        this.onChangeSalesRowsPerPage   = this.onChangeSalesRowsPerPage.bind(this);
        this.onDoSalesDateSort   = this.onDoSalesDateSort.bind(this);
        this.onDoSalesNameSort   = this.onDoSalesNameSort.bind(this);

        this.onDialogClose   = this.onDialogClose.bind(this);
        this.onSkipClose   = this.onSkipClose.bind(this);
        this.onRefresh   = this.onRefresh.bind(this);
        


       // console.log("Final TopNav",this.state);
    }


    onRefresh()
    {
        fetch("/backenddata/ajax_fetch_dashboard", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + '&refresh=1',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(!response.hasOwnProperty("error"))
            {
              let o = {
                'settings': response.data
              };
              console.log("loading ajax", o);
              this.props.reloadData( o );

            }
            else
            {
               /* handle error */
            }
        });      
    }

    onDialogClose()
    {
        /* Send a message to the sender to let them know the data has been see */
        fetch("/messages/ajax_accept_message", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + '&term=' + this.state.dialog.id,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            if(response.status === 'OK')
            {
                var dialog = Object.assign({}, this.state.dialog);
                dialog.state = false;
                this.setState({dialog: dialog});
                /* Since we are updating an object - kick react to notice change */
            }
            else
            {
                /* Do something */
                console.log('Failed to change state');
            }

            
        });         
        
        
    }

    onSkipClose()
    {
        /* Send a message to the sender to let them know the data has been see */
        
    }

    onDoWaitingNameSort()
    {
        var order = 'asc';
        var clone = null;
        console.log('orig order', this.state.waitingorder);
        if(this.state.waitingorderBy !== 'name')
        {
            clone = this.state.waitingrows.slice(0);
            if(this.state.waitingorder === 'asc')
            {
                clone.sort((a, b) => (a.name > b.name ? -1 : 1))    
            }
            else
            {
                clone.sort((a, b) => (a.name < b.name ? -1 : 1))
            }
            this.setState({waitingorder: order, waitingrows: clone, waitingorderBy: 'name'});

        }
        else
        {
            clone = this.state.waitingrows.slice(0);
            if(this.state.waitingorder === 'asc')
            {
                clone.sort((a, b) => (a.name > b.name ? -1 : 1))
                order = 'desc';
    
            }
            else
            {
                clone.sort((a, b) => (a.name < b.name ? -1 : 1))
    
            }
            
            this.setState({waitingorder: order, waitingrows: clone});
    
        }        
    }


    onDoWaitingWhatSort()
    {
            var order = 'asc';
            var clone = null;
            if(this.state.waitingorderBy !== 'what')
            {
                clone = this.state.waitingrows.slice(0);
                if(this.state.waitingorder === 'asc')
                {
                    clone.sort((a, b) => (a.what > b.what ? -1 : 1))    
                }
                else
                {
                    clone.sort((a, b) => (a.what < b.what ? -1 : 1))
                }
                this.setState({waitingorder: order, waitingrows: clone, waitingorderBy: 'what'});
    
            }
            else
            {
                clone = this.state.waitingrows.slice(0);
                if(this.state.waitingorder === 'asc')
                {
                    clone.sort((a, b) => (a.what > b.what ? -1 : 1))
                    order = 'desc';
        
                }
                else
                {
                    clone.sort((a, b) => (a.what < b.what ? -1 : 1))
        
                }
                
                this.setState({waitingorder: order, waitingrows: clone});
        
            }        
    }    

    onDoDateSort( event )
    {
        var order = 'asc';
        var clone = null;
        if(this.state.orderBy !== 'date')
        {

            clone = this.state.classrows.slice(0);
            if(this.state.order === 'asc')
            {
                clone.sort((a, b) => (a.date > b.date ? -1 : 1))    
            }
            else
            {
                clone.sort((a, b) => (a.date < b.date ? -1 : 1))
            }
            this.setState({order: order, classrows: clone, orderBy: 'date'});

        }
        else
        {
            clone = this.state.classrows.slice(0);
            if(this.state.order === 'asc')
            {
                clone.sort((a, b) => (a.date > b.date ? -1 : 1))
                order = 'desc';
    
            }
            else
            {
                clone.sort((a, b) => (a.date < b.date ? -1 : 1))
    
            }
            
            this.setState({order: order, classrows: clone});
    
        }
              
    }


    onDoNameSort( event )
    {
        var order = 'asc';
        var clone = null;
        if(this.state.orderBy !== 'name')
        {
            clone = this.state.classrows.slice(0);
            if(this.state.order === 'asc')
            {
                clone.sort((a, b) => (a.name < b.name ? -1 : 1))    
            }
            else
            {
                clone.sort((a, b) => (a.name > b.name ? -1 : 1))
            }
            this.setState({order: order, classrows: clone, orderBy: 'name'});

        }
        else
        {
            clone = this.state.classrows.slice(0);
            if(this.state.order === 'asc')
            {
                clone.sort((a, b) => (a.name > b.name ? -1 : 1))
                order = 'desc';
    
            }
            else
            {
                clone.sort((a, b) => (a.name < b.name ? -1 : 1))
    
            }
            
            this.setState({order: order, classrows: clone});
    
        }
              
    }



    onDoSalesDateSort( event )
    {
        var order = 'asc';
        var clone = null;
        var obj   = null;
        if(this.state.seven_days.orderby !== 'date')
        {
            clone = this.state.seven_days.rows.slice(0);
            if(this.state.seven_days.order === 'asc')
            {
                clone.sort((a, b) => (a.date < b.date ? -1 : 1))    
            }
            else
            {
                clone.sort((a, b) => (a.date > b.date ? -1 : 1))
            }

            obj = Object.assign({}, this.state.seven_days);
            //obj.order = order;
            obj.rows = clone;
            obj.orderby = 'date';

            this.setState({seven_days: obj});

        }
        else
        {
            clone = this.state.seven_days.rows.slice(0);
            if(this.state.seven_days.order === 'asc')
            {
                clone.sort((a, b) => (a.date > b.date ? -1 : 1))
                order = 'desc';
    
            }
            else
            {
                clone.sort((a, b) => (a.date < b.date ? -1 : 1))
    
            }
            
            obj = Object.assign({}, this.state.seven_days);
            obj.order = order;
            obj.rows = clone;

            this.setState({seven_days: obj});    
        }
              
    }


    onDoSalesNameSort( event )
    {
        var order = 'asc';
        var clone = null;
        var obj   = null;

        if(this.state.seven_days.orderby !== 'name')
        {

            clone = this.state.seven_days.rows.slice(0);
            if(this.state.seven_days.order === 'asc')
            {
                clone.sort((a, b) => (a.name < b.name ? -1 : 1))    
            }
            else
            {
                clone.sort((a, b) => (a.name > b.name ? -1 : 1))
            }
            obj = Object.assign({}, this.state.seven_days);
            obj.order = order;
            obj.rows = clone;
            obj.orderby = 'name';

            this.setState({seven_days: obj});

        }
        else
        {
            clone = this.state.seven_days.rows.slice(0);
            if(this.state.seven_days.order === 'asc')
            {
                clone.sort((a, b) => (a.name > b.name ? -1 : 1))
                order = 'desc';
    
            }
            else
            {
                clone.sort((a, b) => (a.name < b.name ? -1 : 1))
    
            }
            obj = Object.assign({}, this.state.seven_days);
            obj.order = order;
            obj.rows = clone;

            this.setState({seven_days: obj});
                
        }
              
    }

    

    createWaitingList(name, what, when) {
        return {name, what, when };
    }    

    createAvailabilityData(date, sdate, name, sold, eid, did) {
        var href = "/events/info_redirect/" + eid + '/' + did;
        return { date, sdate, name, sold, eid, did, href };
    }

    createSalesData(date, sdate, name, q, custid, fn, ln) {
        var href = "/customers/manage/" + custid;
        return { date, sdate, name, q, href, fn, ln};
    }

    onChangePage(event, newPage) {
        this.setState({classpage: newPage});
    };
    
    onChangeRowsPerPage(event){
        this.setState({classrowsPerPage: parseInt(event.target.value, 10)});
    };

    onChangeSalesPage(event, newPage) {
        var o = Object.assign({}, this.state.seven_days);
        o.page = newPage;
        this.setState({seven_days: o});
    };
    
    onChangeSalesRowsPerPage(event){
        var o = Object.assign({}, this.state.seven_days);
        o.rowsperpage = parseInt(event.target.value, 10);
        this.setState({seven_days: o});

    };

    

    onWaitingChangePage(event, newPage) {
        this.setState({waitingpage: newPage});
    };
    
    onWaitingChangeRowsPerPage(event){
        this.setState({waitingrowsPerPage: parseInt(event.target.value, 10)});
    };


    render()
    {
        var classes = this.props.classes;


        return (
            <div className={classes.root}>


                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        {window.vocab.topbar}
                    </Typography>
                    <Button  variant="outlined" color="primary" onClick={this.onRefresh}><RefreshIcon/>Refresh</Button>

                    </Toolbar>
                </AppBar>


                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >
                    <Grid item xs={12} md={7} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <h2>availability <small>(7 day view)</small></h2>
                                    <Divider />
                                    <div className="element">
                                        <Bar
                                        data={this.props.data.availability.seven_days}
                                        width={100}
                                        height={200}
                                        options={{ maintainAspectRatio: false }} 
                                        />
                                    </div>
                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>
                    <Grid item xs={12} md={5} > 
                        <div className="box">
                        <h2>availability <small>(12 month view)</small></h2>
                            <Divider />
                            <div className="element">
                                <Pie
                                data={this.props.data.availability.twelve_month}
                                width={100}
                                height={200}
                                options={{ maintainAspectRatio: false }} 
                                />
                            </div>
                        </div>
                    </Grid>
        

                    <Grid item xs={12}>
                        <div className="section"></div>
                    </Grid> 
                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <h2>{window.vocab.event_summary}</h2>
                                    <Divider />
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="Class table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell key={'date'}>
                                                        <TableSortLabel active={this.state.orderBy === 'date'}
                                                                        direction={this.state.orderBy === 'date' ? this.state.order : 'asc'} 
                                                                        onClick={this.onDoDateSort}
                                                        >
                                                            Date of event
                                                        </TableSortLabel>

                                                    </TableCell>
                                                    <TableCell>
                                                        <TableSortLabel key={'name'} active={this.state.orderBy === 'name'}
                                                                        direction={this.state.orderBy === 'name' ? this.state.order : 'asc'} 
                                                                        onClick={this.onDoNameSort}>
                                                            Name of event
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Sold
                                                    </TableCell>                                                    
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {(this.state.classrowsPerPage > 0
                                                ? this.state.classrows.slice(this.state.classpage * this.state.classrowsPerPage, this.state.classpage * this.state.classrowsPerPage + this.state.classrowsPerPage)
                                                : this.state.classrows
                                            ).map(row => (
                                                <TableRow key={uuidv1()}>
                                                <TableCell component="th" scope="row">
                                                    {row.sdate} 
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Link href={row.href}>
                                                        {row.name}
                                                    </Link>
                                                </TableCell>
                                                <TableCell align="center">{row.sold}</TableCell>
                                                </TableRow>
                                            ))}

                                            {this.state.emptyRows > 0 && (
                                                <TableRow style={{ height: 53 * this.state.emptyRows }}>
                                                <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                            </TableBody>
                                            <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                colSpan={3}
                                                count={this.state.classrows.length}
                                                rowsPerPage={this.state.classrowsPerPage}
                                                page={this.state.classpage}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'classrows per page' },
                                                    native: true,
                                                }}
                                                onChangePage={this.onChangePage}
                                                onChangeRowsPerPage={this.onChangeRowsPerPage}
                                                />
                                            </TableRow>
                                            </TableFooter>
                                        </Table>
                                        </TableContainer>
                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    
                    <Grid item xs={12}>
                        <div className="section"></div>
                    </Grid> 
                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <h2>{window.vocab.waiting_list}</h2>
                                    <Divider />
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="Class table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell key={'wait-name'}>
                                                        <TableSortLabel active={this.state.waitingorderBy === 'name'}
                                                                        direction={this.state.waitingorderBy === 'name' ? this.state.waitingorder : 'asc'} 
                                                                        onClick={this.onDoWaitingNameSort}
                                                        >
                                                            Name
                                                        </TableSortLabel>

                                                    </TableCell>
                                                    <TableCell>
                                                        <TableSortLabel key={'wait-what'} active={this.state.waitingorderBy === 'what'}
                                                                        direction={this.state.waitingorderBy === 'what' ? this.state.waitingorder : 'asc'} 
                                                                        onClick={this.onDoWaitingWhatSort}>
                                                            Event
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Event Date / time
                                                    </TableCell>                                                    
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {(this.state.waitingrowsPerPage > 0
                                                ? this.state.waitingrows.slice(this.state.waitingpage * this.state.waitingrowsPerPage, this.state.waitingpage * this.state.waitingrowsPerPage + this.state.waitingrowsPerPage)
                                                : this.state.waitingrows
                                            ).map(row => (
                                                <TableRow key={uuidv1()}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="left">{row.what}</TableCell>
                                                <TableCell align="center">{row.when}</TableCell>
                                                </TableRow>
                                            ))}

                                            {this.state.waitingemptyrows > 0 && (
                                                <TableRow style={{ height: 53 * this.state.waitingemptyrows }}>
                                                <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                            </TableBody>
                                            <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                colSpan={3}
                                                count={this.state.waitingrows.length}
                                                rowsPerPage={this.state.waitingrowsPerPage}
                                                page={this.state.waitingpage}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'waiting clients per page' },
                                                    native: true,
                                                }}
                                                onChangePage={this.onWaitingChangePage}
                                                onChangeRowsPerPage={this.onWaitingChangeRowsPerPage}
                                                />
                                            </TableRow>
                                            </TableFooter>
                                        </Table>
                                        </TableContainer>
                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>

                    <Grid item xs={12}>
                        <div className="section"></div>
                    </Grid> 

                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <h2>{window.vocab.sales}</h2>
                                    <Divider />
                                    <div className="element">
                                        <Bar
                                        data={this.props.data.sales.seven_days}
                                        width={100}
                                        height={200}
                                        options={{ maintainAspectRatio: false }} 
                                        />
                                    </div>
                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>

                    <Grid item xs={12}>
                        <div className="section"></div>
                    </Grid> 
                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <h2>{window.vocab.sales_detail}</h2>
                                    <Divider />
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} aria-label="Class table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell key={'sales-when'}>
                                                        <TableSortLabel active={this.state.seven_days.orderby === 'date'}
                                                                        direction={this.state.seven_days.orderby === 'date' ? this.state.seven_days.order : 'asc'} 
                                                                        onClick={this.onDoSalesDateSort}
                                                        >
                                                            Purchased
                                                        </TableSortLabel>

                                                    </TableCell>
                                                    <TableCell>
                                                        <TableSortLabel key={'sales-what'} active={this.state.seven_days.orderby === 'name'}
                                                                        direction={this.state.seven_days.orderby === 'name' ? this.state.seven_days.order : 'asc'} 
                                                                        onClick={this.onDoSalesNameSort}>
                                                            Event
                                                        </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        Customer
                                                    </TableCell>                                                        
                                                    <TableCell align="center">
                                                        Quantity
                                                    </TableCell>                                                    
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {(this.state.seven_days.rowsperpage > 0
                                                ? this.state.seven_days.rows.slice(this.state.seven_days.page * this.state.seven_days.rowsperpage, ((this.state.seven_days.page * this.state.seven_days.rowsperpage) + this.state.seven_days.rowsperpage)   )
                                                : this.state.seven_days.rows
                                            ).map(row => (
                                                <TableRow key={uuidv1()}>
                                                <TableCell component="th" scope="row">
                                                    {row.sdate} 
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Link href={row.href}>
                                                    {row.name}                
                                                    </Link>                                                    
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Link href={row.href}>
                                                        {row.fn} {row.ln}
                                                    </Link>
                                                </TableCell>                                                

                                                <TableCell align="center">{row.q}</TableCell>
                                                </TableRow>
                                            ))}

                                            {this.state.seven_days.emptyrows > 0 && (
                                                <TableRow style={{ height: 53 * this.state.seven_days.emptyrows }}>
                                                <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                            </TableBody>
                                            <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                colSpan={3}
                                                count={this.state.seven_days.rows.length}
                                                rowsPerPage={this.state.seven_days.rowsperpage}
                                                page={this.state.seven_days.page}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'customer sales per page' },
                                                    native: true,
                                                }}
                                                onChangePage={this.onChangeSalesPage}
                                                onChangeRowsPerPage={this.onChangeSalesRowsPerPage}
                                                />
                                            </TableRow>
                                            </TableFooter>
                                        </Table>
                                        </TableContainer>
                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>


                    <Grid item xs={12}>
                        <div className="section"></div>
                    </Grid> 

                    <Grid item xs={12} md={6} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <h2>{window.vocab.sales}</h2>
                                    <Divider />
                                    <div className="element">
                                        <Bar
                                        data={this.props.data.sales.twelve_month_sales_data.transactions}
                                        width={100}
                                        height={300}
                                        options={{ maintainAspectRatio: false }} 
                                        />
                                    </div>
                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>

                    <Grid item xs={12} md={6} > 
                        <Grid container justify="flex-start" alignItems="stretch">
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">
                                    <h2>{window.vocab.sales}</h2>
                                    <Divider />
                                    <div className="element">
                                        <Bar
                                        data={this.props.data.sales.twelve_month_sales_data.income}
                                        width={100}
                                        height={300}
                                        options={{ maintainAspectRatio: false }} 
                                        />
                                    </div>
                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>
                </Grid>

                {this.state.dialog.state && (<CoreDialog title={this.state.dialog.title} 
                            body={this.state.dialog.message} 
                            actions={[{label:"OK", action:this.onDialogClose} /*, {label:"Skip", action:this.onSkipClose} */]} state={true} />)}
                
            </div>            
        );
    }

};



const ExtensionsManager = connect(null, mapDispatchToProps)(DashboardManagerModule);
export default withStyles(styles)(ExtensionsManager);
