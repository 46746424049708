import React from 'react';
import Button from '@material-ui/core/Button';
import './widgets.css';

export default function ExtensionWidget( props ) {
  const status = React.useState(props.status);

  const onclick = () => {
    props.onclick(status);
  };

  return (
    <div className="extension-widget" style={{display: (props.available?'block':'none')}}>
        <div className="inner">
            <div className="section">{props.icon} {props.label}&nbsp;<span className="light"> {props.descriptor}</span></div>
            <div className="description">{props.description}</div>
            <div className="cost">{props.cost}. Status: {props.status?'active':'inactive'}</div>
            <div className="button">
                {(!props.status)? <Button variant="outlined" color="primary" onClick={onclick}>Activate</Button>: <Button variant="outlined" color="primary" onClick={onclick}>Manage</Button>}
            </div>

        </div>
    </div>
  );
}