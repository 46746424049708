
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Moment from 'react-moment';
import Grid from '@material-ui/core/Grid';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';


export default function CustomersQuestionairePanelManager( {state, onClose, data} ) {
  const fullWidth = 'lg';
  const maxWidth = '100%';

  const handleClose = () => {
    onClose();
  };

  const jsonUnescape = (str) =>{
    if(!!str && typeof str === 'string')
    {
      return  str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\");
    }
    return str;
  }


  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={state}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Questionnaire</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container justify="flex-start" alignItems="stretch">
                <Grid item xs={12} md={12} pt={3}>
                    <h3>Overview</h3>
                </Grid>                
                <Grid item xs={12} md={12} pb={4}>
                    <Grid container justify="flex-start" alignItems="stretch">
                        <Grid item xs={12} md={4}>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell><b>Questionnaire ID:</b></TableCell>
                                        <TableCell>{data.id}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><b>Completed:</b></TableCell>
                                        <TableCell><Moment format="ll">{data.date}</Moment></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><b>Expires:</b></TableCell>
                                        <TableCell><Moment format="ll">{data.expires}</Moment></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} pt={3}>
                    <h3>Detail</h3>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Grid container justify="flex-start" alignItems="stretch">
                        <Grid item xs={12} md={12}>
                            <Table>
                                <TableBody>
                                    {
                                        data.qa.map( (qa, i) => (
                                            <React.Fragment>
                                                <TableRow>
                                                    <TableCell>
                                                        <p><b>{i+1}. {jsonUnescape(qa.q)}</b></p>
                                                        {
                                                          qa.type !== 'multi-checkbox' &&
                                                          <p><i>{jsonUnescape(qa.a)}</i></p>
                                                        }
                                                        {
                                                          qa.type === 'multi-checkbox' &&
                                                          qa.a.map((a) => (
                                                            <p><i>{jsonUnescape(a)}</i></p>
                                                          ))
                                                        }

                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                    ))
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

          </DialogContentText>    
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}