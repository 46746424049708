import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './stepper';
import TextField from '@material-ui/core/TextField';


import Divider from '@material-ui/core/Divider';

import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import Dialog from '../core/dialog';
import AddIcon from '@material-ui/icons/Add';


import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import  {
    EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS
} from '../redux/types';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";


    const styles = theme => ({
        root: {
            flexGrow: 1,
          },          
          title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
          },
          formControl: {
            marginBottom: '20px',
            minWidth: 120,
          },
          textInput:{
            marginTop: '20px'
          },
          switchLabel:{
              color: 'rgba(0,0,0,0.6)',
          },
          captionText:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',

          },
          sectionDescription:{
            color: 'rgba(0,0,0,0.6)',    
          },            
          captionSection:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',
            marginBottom: '10px',
            lineHeight: '1.5em',
          },
          schedTypeSelection:{
              marginLeft: '10px'
          },
          what:{
              width: '50%'
          },
          fabDelete:{
              backgroundColor: '#b80000',
              color: '#fff',
              '&:hover':{
                  backgroundColor: '#940000',
              }
          },        
          fabIcon:{
              '& .MuiSvgIcon-root':{
                  fill: '#fff',
                  color: '#fff',
                  '&:hover':{
                      color: '#ffe',
                  }
              }
          }            
});





function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventsStep5ActionsModule extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            actions: props.data.obj.actions || [],
            purchase: props.cache.purchase || 0,
            cancel: props.cache.cancel || 0,
            available: props.cache.available || 0,
            reminder: props.cache.reminder || 0,

            error: false,
            refresh: false,
            disable_passes: true,
            disable_card: true,
        }      


        obj = this.state;




        console.log('EventsStep5ActionsModule', this.state, props);

 


        this.state.security     = props.security;
        this.onDoPrevious       = this.onDoPrevious.bind(this);
        this.onPurchaseChange   = this.onPurchaseChange.bind(this);
        this.onReminderChange   = this.onReminderChange.bind(this);
        this.onCancelChange     = this.onCancelChange.bind(this);
        this.onWaitChange     = this.onWaitChange.bind(this);
        this.onDoNext           = this.onDoNext.bind(this);

    }

    onReminderChange( event )
    {
        this.setState({'reminder': event.target.value});
    }


    onPurchaseChange( event )
    {
        this.setState({'purchase': event.target.value});
    }
    onCancelChange( event )
    {
        this.setState({'cancel': event.target.value});
    }
    onWaitChange( event )
    {
        this.setState({'available': event.target.value});
    }

    onDoPrevious(event)
    {
        var o = { 
            store_previous: true,
            configuration: { purchase: this.state.purchase, cancel: this.state.cancel, available: this.state.available, reminder: this.state.reminder },
            state: EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS
        };
        this.props.rewindStep(o);        
    }

     


 


    
    onDoNext(event)
    {
        var x = Object.assign({}, this.state.errors);

        //if(regex.test(this.state.name))
    
        var o = {
                configuration: { purchase: this.state.purchase, cancel: this.state.cancel, available: this.state.available, reminder: this.state.reminder },
                state: EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS
            };
        console.log("sending data", o);
        this.props.setStep(o);
    

    }
  
    




  

    render()
    {
        var classes = this.props.classes;

        console.log('render', this.state);

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Event editor
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="center" alignItems="center" spacing={2} >


                    <Grid item xs={12} md={12} > 
                        <Grid container justify="flex-start" alignItems="center" spacing={2}>
                            
                                            
                            <Grid item xs={12} md={12}>
                                <div className="box">

                                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                        
                                        <Grid item xs={12} md={12}>
                                            <StepperWidget key={uuidv1()} step={3}/>

                                        </Grid>
                                        
                                        
                                        <Grid item xs={12}>
                                            <div className="formSection">Notifications</div>                               
                                            <p className="sectionDescription">
                                               This section allows you to configure the notifications that are sent to your clients.   
                                            </p>                                        
                                        </Grid>

      
 
                                        <Grid item  xs={12}>
                                       
                                            <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                            <InputLabel id="purchase-action-select-outlined-label">Event purchase notification</InputLabel>

                                            <Select
                                            id="purchase-action-select-outlined"
                                            value={this.state.purchase}
                                            label="Event purchase notification"
                                            onChange={this.onPurchaseChange}
                                            >
                                            <MenuItem value={0}>Use default notification</MenuItem>
                                            <MenuItem value={-1}>Disable notification</MenuItem>
                                            {this.state.actions.map(el => (
                                                    <MenuItem value={el.id} key={'purchase-' + el.id}>{el.name}</MenuItem>
                                            ))}                                            
                                            </Select>    
                                            <FormHelperText>This notification is sent when the customer makes a purchase</FormHelperText>                                                 
                                            </FormControl>

                                        </Grid>
                                        <Grid item  xs={12}>
                                       
                                            <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                            <InputLabel id="reminder-action-select-outlined-label">Event reminder notification</InputLabel>

                                            <Select
                                            id="purchase-reminder-select-outlined"
                                            value={this.state.reminder}
                                            label="Event reminder notification"
                                            onChange={this.onReminderChange}
                                            >
                                            <MenuItem value={0}>Use default notification</MenuItem>
                                            <MenuItem value={-1}>Disable notification</MenuItem>
                                            {this.state.actions.map(el => (
                                                    <MenuItem value={el.id} key={'reminder-' + el.id}>{el.name}</MenuItem>
                                            ))}                                            
                                            </Select>    
                                            <FormHelperText>This notification is sent to a customer 2 hours before an event</FormHelperText>                                                 
                                            </FormControl>

                                        </Grid>


                                        <Grid item  xs={12}>
                                            <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                                <InputLabel id="cancel-action-select-outlined-label">Event cancellation notification</InputLabel>

                                                <Select
                                                id="cancel-action-select-outlined"
                                                value={this.state.cancel}
                                                label="Event cancellation notification"
                                                onChange={this.onCancelChange}

                                                >
                      
                                                <MenuItem value={0}>Use default notification</MenuItem>
                                                <MenuItem value={-1}>Disable notification</MenuItem>
                                                {this.state.actions.map(el => (
                                                    <MenuItem value={el.id}  key={'cancel-' + el.id}>{el.name}</MenuItem>
                                                ))}                                                
                                                </Select>
                                                <FormHelperText>This notification is sent when the customer space on this event is cancelled.</FormHelperText>                                                                                                      
                                            </FormControl>                                                                                       
                                        </Grid>

                                        <Grid item  xs={12}>
                                            <FormControl variant="outlined" fullWidth={true} className={classes.formControl}>
                                                <InputLabel id="waiting-action-select-outlined-label">Waiting list</InputLabel>

                                                <Select
                                                id="waiting-action-select-outlined"
                                                value={this.state.available}
                                                label="Waiting list"
                                                onChange={this.onWaitChange}
                                                >
                       
                                                <MenuItem value={0}>Use default notification</MenuItem>
                                                <MenuItem value={-1}>Disable notification</MenuItem>
                                                {this.state.actions.map(el => (
                                                    <MenuItem value={el.id}  key={'wait-'+el.id}>{el.name}</MenuItem>
                                                ))}
                                                </Select>
                                                <FormHelperText>This notification is sent to the waiting list in the event that an existing customer's space is cancelled which results is space becoming available.</FormHelperText>                                                                                                      
                                            </FormControl>                                                                                           
                                        </Grid>                                        

                                        <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                size="large"
                                                className="rv-eng-next-button"
                                                startIcon={<NavigateBeforeIcon />}
                                                onClick={this.onDoPrevious}
                                                fullWidth={true}
                                            >
                                                Back
                                            </Button>
                                        </Grid>
                                        <Grid item xs={4} md={6}></Grid>
                                        <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="rv-eng-next-button"
                                                endIcon={<NavigateNextIcon />}
                                                onClick={this.onDoNext}
                                                fullWidth={true}
                                            >
                                                Next
                                            </Button>  
                                                                
                                        </Grid>                      
                                    </Grid>


                                </div>
                            </Grid>                                               
                        </Grid>

                    </Grid>                                    

                </Grid>

                
            </div>            
        );
    }

};



const EventsStep5Actions = connect(null, mapDispatchToProps)(EventsStep5ActionsModule);
export default withStyles(styles)(EventsStep5Actions);
