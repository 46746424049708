import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(0),
    },
  }));


const QuestionaireMultiCheckbox = ({id, text, index, required, options, answer, onAnswer }) => {
    const classes = useStyles();

    const is_array = Array.isArray(answer);
    console.log("parameters", required);
    const jsonUnescape = (str) =>{
        return  str.replace(/&#39;/g, "'").replace(/&#34;/, "\"").replace(/\\\\/g, "\\");
    }

    return ( 
        <React.Fragment>
            <div className='customer_questionnaire_field'>

                <FormControl component="fieldset" className={classes.formControl} key={id}>
                    <FormLabel component="legend">{text}</FormLabel>
                    <FormGroup>
                    {
                        is_array &&
                        options.map( (o, i) => (
                            <FormControlLabel
                                control={<Checkbox id={id} name={id} required={required === true} value={o.id} autoFocus={index ===1? true: false } checked={!!answer.find((ans) => (ans.answer === o.id))} onChange={(e) => {onAnswer(id, e.target.value)}} />}
                                label={jsonUnescape(o.text)}
                            />                        
                        ))
                    }
                    {
                        !!!is_array &&
                        options.map( (o, i) => (
                            <FormControlLabel
                                control={<Checkbox id={id} name={id} required={required === true} value={o.id} autoFocus={index ===1? true: false } checked={false} onChange={(e) => {onAnswer(id, e.target.value)}} />}
                                label={jsonUnescape(o.text)}
                            />                        
                        ))
                    }
                  
                    </FormGroup>
                </FormControl>
            </div>
        </React.Fragment>
        

     );
}
 
export default QuestionaireMultiCheckbox;