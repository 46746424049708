import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";


import Grid from '@material-ui/core/Grid';
import {Container} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';



import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import CloseIcon from '@material-ui/icons/Close';
import {reloadData, closeCustomerPanel} from '../redux/actions';


import Slide from '@material-ui/core/Slide';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {v1 as uuidv1} from "uuid";

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import moment from 'moment'
import Moment from 'react-moment';

import WarningIcon from '@material-ui/icons/Warning';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';


import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CoreDialog  from '../core/dialog'
import TransferDialog from '../core/transferdialog';
import MoneyOffOutlinedIcon from '@material-ui/icons/MoneyOffOutlined';
import EmailEditorDialog    from './event-email-editor';
import CenterFocusWeakSharpIcon from '@material-ui/icons/CenterFocusWeakSharp';
import AllInclusiveSharpIcon from '@material-ui/icons/AllInclusiveSharp';
import WebAssetSharpIcon from '@material-ui/icons/WebAssetSharp';
import VpnKeySharpIcon from '@material-ui/icons/VpnKeySharp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import EventCustomerRegistrationDialog from './event-customer-registration-dialog';

import EmailViewDialog from '../core/email-view-dialog';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import LibraryAddSharpIcon from '@material-ui/icons/LibraryAddSharp';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import DescriptionIcon from '@material-ui/icons/Description';
import EventCustomersQuestionairePanelManager from './event-customer-view-questionaire';
import StarIcon from '@material-ui/icons/Star';
import Hidden from '@material-ui/core/Hidden';
import AddIcon from '@material-ui/icons/Add';


const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    dialog:{
        backgroundColor: 'rgba(0,0,0,0.1)',
        '& .MuiAppBar-root':{
            backgroundColor: 'rgba(60, 141, 188, 1)',
        }
    },
    box:{
        height: '100%',
        border: '1px solid rgba(0,0,0,0.2)',
        borderRadius: '5px',
        backgroundColor: '#fff',
        padding: '18px', 
        marginTop: '100px'       
    },    
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    sectionTitle:{
        width: "100%",

        
    },    
    title: {
        flexGrow: 1,
        color: '#fff',
        fontSize: '1.3rem'
    },
    appBar:{
        backgroundColor: '#3c8dbc'
    },  
    fabRefund:{
        backgroundColor: 'rgba(83, 120, 138, 1)',
        color: '#fff',
        '&:hover':{
            backgroundColor: 'rgba(83, 120, 138, 0.5)',
        },
        '&:disabled':{
            backgroundColor: 'rgba(0,0,0,0.5)',
            color: '#fff',
        }
    },
    fabGo:{
        backgroundColor: '#91d768',
        color: '#fff',
        width: '46px',
        height: '46px',
        '&:hover':{
            backgroundColor: '#91ff68',
        }
    }, 
    fabTransfer:{
        backgroundColor: '#FFA500',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#ea9700',
        }
    },      
    fabGrey:{
        backgroundColor: 'rgba(0,0,0,0.5)',
        color: '#fff',
        width: '46px',
        height: '46px',
        '&:hover':{
            backgroundColor: 'rgba(0,0,0,0.5)',
            cursor: 'not-allowed',
        }
    },        
    fabIcon:{
        '& .MuiSvgIcon-root':{
            fill: '#fff',
            color: '#fff',
            '&:hover':{
                color: '#ffe',
            }
        }
    },
    fabDisabled:{
        "&:hover, &.Mui-focusVisible":{
            backgroundColor: '#fff',
            '&:hover':{
                backgroundColor: '#fff',
            }
        }
    },
    fabDelete:{
        backgroundColor: '#b80000',
        color: '#fff',
        '&:hover':{
            backgroundColor: '#940000',
        }
    }, 
    fabQuestions:{
        padding: '5px',
        marginLeft: '8px'
    },
    fabIconSmall:{
        height: '16px'
    },
    fabReg:{
        backgroundColor: 'rgba(83, 120, 138, 1)',
        color: '#fff',
        '&:hover':{
            backgroundColor: 'rgba(83, 120, 138, 0.5)',
        }
    }, 
    fabStar:{
        color: '#FFA500',
    },        
    exportButton:{
        marginLeft: '5px',
        marginRight: '5px',
        color: '#fff',
        '&:disabled':{
            color: '#d0d0d0',
            cursor: 'not-allowed'
        }
    },  
    tableName:{
        minWidth: '220px',
    },
    alignCenter:
    {
        textAlign: 'center'
    },
    buttonDanger:{
        color: '#d40000',
    },
    progress:{
        color: 'rgba(0,0,0,0.5)',
        fontSize: '18px',
        textAlign: 'center'
    },  
    progressIcon:{
        color: 'rgba(0,0,0,0.5)',

    }, 
    progressError:{
        color: 'rgba(199, 0, 0, 0.829)',

    },          
    section:{
        fontWeight: 600,
        color: 'rgba(0,0,0,0.5)',
    },
    sectionText:{
        fontSize: '1.2em',
        fontWeight: 600,
        color: 'rgba(0,0,0,0.5)',
        lineHeight: '1.6',
        letterSpacing: '0.0098em', 
        paddingBottom: '5px', 
        paddingTop: '15px'         
    },
    shaded:{
        marginBottom: '5px',
  
    },
    editor:{
        border: '1px solid rgba(0,0,0,0.1)',
        padding: '0px 5px 0px 5px',
        minHeight: '150px',
        marginTop: '20px',
        marginBottom: '20px',
    },
    widgetButton:{
        marginLeft: '5px',
        marginRight: '5px',
        borderColor: '#fff',
        color: '#fff',
        '& .MuiButton-outlined':{
            backgroundColor: '#d40000'
        }
    },
});

function a11yProps(index) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }


class TransferButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="clone" className={classes.fabTransfer} aria-label="Clone event" onClick={this._handleClick}>
                <SwapHorizIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
} 




class DeleteButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback,
            disabled: props.disabled|| false
        }

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        var theclass = classes.fabDelete;
        if(this.state.disabled)
        {
            theclass = classes.fabGrey;
        }
        return (
            <IconButton name="delete" className={theclass}  aria-label="Delete event" onClick={this._handleClick}>
                <DeleteForeverIcon className={classes.fabIcon} disabled={this.state.disabled}/>
            </IconButton>            
        )
    }
}


class QuestionnaireButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback,
            disabled: props.disabled|| false,
            show_dialog: false,
        }

        this._handleClick = this._handleClick.bind(this);
        this._handleQuestionnaireClose = this._handleQuestionnaireClose.bind(this);
    }

    _handleQuestionnaireClose = () => {
        this.setState({'show_dialog': false});
    }

    _handleClick = () => {
        //var x = Object.assign({}, this.state.data);
        this.setState({'show_dialog': this.state.data});
        //this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        var theclass = classes.fabQuestions;
        if(this.state.disabled)
        {
            theclass = classes.fabGrey;
        }
        return (
            <React.Fragment>
                {
                    this.state.data.length > 0  && (
                        <Tooltip title="Questionnaire answers available">
                            <IconButton name="questionnaires" className={theclass}  aria-label="Questionnnaires" onClick={this._handleClick}>
                                <DescriptionIcon disabled={this.state.disabled} className={classes.fabIconSmall}/>
                            </IconButton> 
                        </Tooltip>

                    )
                }
           
                {
                    this.state.data.length > 0 && this.state.show_dialog !== false && (
                        <EventCustomersQuestionairePanelManager  data={this.state.show_dialog} state={!!this.state.show_dialog} onClose={this._handleQuestionnaireClose}/>
                    )
                }
            </React.Fragment>
        )
    }
}







class RefundButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback,
            disabled: false
        }

        if(!this.state.data.transaction_rid.startsWith('ch_') || this.state.data.subtransaction_is_refunded === '1')
        {
            this.state.disabled = true;
        }

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="refund" 
                        key={uuidv1()}
                        className={classes.fabRefund} 
                        aria-label="Refund payment" 
                        onClick={this._handleClick} 
                        disabled={this.state.disabled}>
                <MoneyOffOutlinedIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}




class RemoveButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback,
            disabled: false
        }

        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Remove from waiting list initiated.',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        var theclass = classes.fabDelete;

        return (
            <IconButton name="delete" className={theclass}  aria-label="Remove from waiting list. " onClick={this._handleClick}>
                <DeleteForeverIcon className={classes.fabIcon} disabled={this.state.disabled}/>
            </IconButton>            
           
        )
    }
}




function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);
  
function ContactMenu(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = props.classes;
    const onExternalCustomersClick = props.onExternalCustomersClick;
    const onExternalWaitingClick   = props.onExternalWaitingClick;
    const onInternalCustomersClick = props.onInternalCustomersClick;
    const onInternalWaitingClick   = props.onInternalWaitingClick;
    const [disabled, setDisabled] = React.useState(props.disabled);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
    
      setAnchorEl(null);
    };
  
  
    const handleCustomerExternalItemClick = ( event ) => {
        onExternalCustomersClick()
        setAnchorEl(null);

    };
    const handleWaitingExternalItemClick = ( event ) => {
        setAnchorEl(null);
        onExternalWaitingClick();
        
    };
    const handleCustomerInternalItemClick = ( event ) => {
        setAnchorEl(null);
        onInternalCustomersClick();

    };
    const handleWaitingInternalItemClick = ( event ) => {
        setAnchorEl(null);
        onInternalWaitingClick();
        
    };
    return (
      <div>
        <Button
          aria-controls="contact customers"
          aria-haspopup="true"
          variant="outlined"
          onClick={handleClick}
          className={classes.widgetButton}
          disabled ={disabled}
        >
          Contact
        </Button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem onClick={handleCustomerExternalItemClick}>
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Customers via external mail"/>
          </StyledMenuItem>
          <StyledMenuItem onClick={handleCustomerInternalItemClick}>
            <ListItemIcon>
              <DraftsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Customers via reservie mail" />
          </StyledMenuItem>
          <StyledMenuItem onClick={handleWaitingExternalItemClick}>
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Waiting list via external mail" />
          </StyledMenuItem>
          <StyledMenuItem onClick={handleWaitingInternalItemClick}>
            <ListItemIcon>
              <DraftsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Waiting list via reservie mail" />
          </StyledMenuItem>          
        </StyledMenu>
      </div>
    );
  }


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function Loading(props) {
    console.log('Loading', props);
    if (props.open) {
        return <div className="loading"><i className="fa fa-refresh fa-spin fa-5x fa-fw"></i><span className="sr-only">Loading...</span></div>
    }
    return ''
}



class PurchaseOption extends React.Component {

    constructor(props) {
        super(props);
  
        this.state = {
            option_name: '',
        }
  
        for(var i = 0; i < props.payments.configuration.options.length; i++ )
        {
          var id = props.payments.configuration.options[i].id.toString();
          if(id === props.id)
          {
            this.state.option_name = props.payments.configuration.options[i].name;
          }
        }
  
        console.log('PurchaseOption', this.state);
    }
  
  
    render() {
        var classes= this.props.classes;
        return (
          <div>{this.state.option_name}</div>      
        )
    }
}


class SaleState extends React.Component {

    constructor(props) {
        super(props);
  
        this.state = {
            state: '',
        }

        if(props.stx_state === 'ACTIVE')
        {

            switch(props.tx_state)
            {
                case 'CREATED':
                case 'PENDING':
                case 'PROCESSED':
                {
                    this.state.state = 'PENDING';
                }
                break;
                
                case 'COMPLETED':
                {
                    this.state.state = 'CARD';
                }
                break;

                case 'PREPAIDFULL':
                {
                    this.state.state = 'PREPAID';
                }
                break;  

                case 'PREPAIDPARTIAL':
                {
                    this.state.state = 'PREPAID PART';
                }
                break;  

                case 'PACKAGE':
                {
                    this.state.state = 'PASS';
                }
                break; 

                case 'MANUAL':
                {
                    this.state.state = 'ADMIN CASH';
                }
                break; 

                case 'REFUNDED':
                case 'REVERSED':
                {
                    this.state.state = 'REFUNDED';
                }
                break; 

                case 'MANUAL_PACKAGE':
                {
                    this.state.state = 'ADMIN PASS';
                }
                break; 

                case 'VOIDED':
                {
                    this.state.state = 'CANCELLED';
                }
                break;
                case 'MANUAL_CARD':
                {
                    this.state.state = 'ADMIN CARD';
                }
                break;
                default:
                {
                    this.state.state = 'ERROR';

                }


            }
        }
        else
        {
            this.state.state = 'CANCELLED';
        }
        console.log('PurchaseOption', this.state);
    }
  
  
    render() {
        var classes= this.props.classes;
        return (
          <div>{this.state.state}</div>      
        )
    }
}




class InfoButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('CodeButton', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('Manage button initiated',x);
        this.state.callback(this.state.data);
    }

    render() {
        var classes= this.props.classes;
        return (
            <IconButton name="view-emails" className={classes.fabReg} aria-label="View Emails" onClick={this._handleClick}>
                <MailOutlineIcon className={classes.fabIcon}/>
            </IconButton>            
        )
    }
}



class PresentState extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            callback: props.callback
        }

        console.log('Present state', this.state);
        this._handleClick = this._handleClick.bind(this);
    }

    _handleClick = () => {
        var x = Object.assign({}, this.state.customer);
        console.log('present state',x);
        this.state.callback(this.state.data, this.state.events);
    }

    render() {
        var classes= this.props.classes;
        return (
            <FormControlLabel
            control={
            <Switch
                checked={this.state.data.customer_present}
                onChange={this._handleClick}
                value="1"
                color="primary"
            />
            }
            />         
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        reloadData: spec => dispatch(reloadData(spec)),
        closeCustomerPanel: spec => dispatch(closeCustomerPanel(spec)),

      
    };
  }





class EventRegisterDialogModule extends Component{

    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            security: props.security,
            customers: [],
            cust_add: false,
            active_customers: [],
            cancelled_customers: [],
            loading: false,
            add_button_state: false,
            data: props.data,
            selection: 'one',
            onCancel: props.onCancel,
            open: props.open,
            export_disabled: true,
            export_url: null,
            delete_warning: false,
            delete_data: null,
            transfer_prompt: false,
            transfer_data: null,
            events: [],
            refund_warning: false,
            refund_data: null,
            error: false,
            error_data: null,
            customer_editor: false,
            customer_data: null,
            waiting_editor: false,
            waiting_data: props?.waiting || [],
            emails: [],
            message_view: false,
            message_to_view: {obj:{subject:'', message:''}},
            add_client: false,
            all_clients: props.customers || [],
            remove_warning: false,
            remove_data: null,
        };

        var events = props.events || [];
        for(var i = 0; i < events.length; i++)
        {
            var obj = {
                mode: events[i].mode,
                name: events[i].name,
                eid: events[i].eid,
                cid: events[i].cid,
                dates: [],
                object: events[i].payment,
            };

            
            for(var j = 0; j < events[i].dates.length; j++)
            {
                var t = moment(events[i].dates[j].date + ' ' + events[i].dates[j].time);
                var now = moment();
                if(t.isSameOrAfter(now))
                {
                    obj.dates.push(events[i].dates[j]);
                }
            }


            this.state.events.push(obj);
        }


        console.log('EventRegisterDialogModule', this.state.data);


        this.onMenuSelect = this.onMenuSelect.bind(this);
        this.onManageCustomerPanelClose = this.onManageCustomerPanelClose.bind(this);
        this.onAddCustomer  = this.onAddCustomer.bind(this);
        this.onTabSwitch    = this.onTabSwitch.bind(this);
        this.reload         = this.reload.bind(this);
        this.onExport       = this.onExport.bind(this);
        this.onAddClient    = this.onAddClient.bind(this);
        this.onTransfer     = this.onTransfer.bind(this);
        this.onDeleteBooking     = this.onDeleteBooking.bind(this);
        this.onRefundBooking     = this.onRefundBooking.bind(this);
          
        this.onAbortCancel      = this.onAbortCancel.bind(this);
        this.onCompleteCancel   = this.onCompleteCancel.bind(this);
        this.onCompleteRefund   = this.onCompleteRefund.bind(this);
        this.onCompleteRemove   = this.onCompleteRemove.bind(this);
        this.onAbortTransfer    = this.onAbortTransfer.bind(this);
        this.onAbortRefund      = this.onAbortRefund.bind(this);
        this.onAbortRemove      = this.onAbortRemove.bind(this);
        this.onAbortError       = this.onAbortError.bind(this);
        this.onExternalCustomersClick  = this.onExternalCustomersClick.bind(this);
        this.onExternalWaitingClick   = this.onExternalWaitingClick.bind(this);
        this.onInternalCustomersClick = this.onInternalCustomersClick.bind(this);
        this.onInternalWaitingClick   = this.onInternalWaitingClick.bind(this);
        this.onEditorClose            = this.onEditorClose.bind(this);       
        this.onRefreshCustomerEmails      = this.onRefreshCustomerEmails.bind(this);
        this.onRefreshWaitingEmails   = this.onRefreshWaitingEmails.bind(this);
        this.cancelMessage            = this.cancelMessage.bind(this);
        this.viewMessage              = this.viewMessage.bind(this);
        this.onCloseMessageView       = this.onCloseMessageView.bind(this);
        this.onPresentStateChange     = this.onPresentStateChange.bind(this);
        this.onCustomerRegistrationDialogClose = this.onCustomerRegistrationDialogClose.bind(this);
        this.onRemoveFromWaitingList  = this.onRemoveFromWaitingList.bind(this);
               // console.log("Final TopNav",this.state);
    }


    onAddClient()
    {
        this.setState({'add_client': true});
    }


    onCustomerRegistrationDialogClose( refresh )
    {
        var _this = this;
        this.setState({'add_client': false}, function(){
            if(refresh)
            {
                _this.reload();
            }
        });
    }


    onPresentStateChange( obj )
    {
        var x = JSON.parse(JSON.stringify(this.state.active_customers));
        for(var i = 0; i < x.length; i++)
        {
            if(obj.customer_id === x[i].customer_id && obj.customer_present_instance === x[i].customer_present_instance)
            {
                x[i].customer_present = !x[i].customer_present;
                var _this = this;
                var chain = '&id=' + obj.customer_id;
                chain += '&state=' + x[i].customer_present;
                chain += '&instance=' + x[i].customer_present_instance;
                chain += '&eid=' + this.state.data.date.eid;
                chain += '&did=' + this.state.data.date.id;
                chain += '&sub_id=' + x[i].subtransaction_id;
         
                fetch("/api-actions-customers/do-ajax-change-present-state", {
                    method: "POST",
                    mode: 'same-origin', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'include', // include, *same-origin, omit                            
                    body: this.state.security.name + '=' + this.state.security.token + chain,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "X-Requested-With": 'XMLHttpRequest',
                        "X_REQUESTED_WITH": 'xmlhttprequest'
                    }
                })
                .then(res => {
                    return res.json();
                })
                .then(response => {
                    //console.log(data);
                    if(response.status === 'OK')
                    {
                        this.setState({'active_customers': x});
                    }
                    else
                    {
                        /* nothing to do */
                    }
                }).catch(function(err){
                    _this.reload();
                    console.error('Failed to post message to the communicator', err);
                }); 
                break; 
            }
        }
    }

    onCloseMessageView()
    {
        this.setState({'message_view': false, 'message_to_view': {obj:{subject:'', message:''}}});
    }

    decodeHTMLEntities(text) {
        var entities = [
            ['amp', '&'],
            ['apos', '\''],
            ['#x27', '\''],
            ['#x2F', '/'],
            ['#39', '\''],
            ['#47', '/'],
            ['lt', '<'],
            ['gt', '>'],
            ['nbsp', ' '],
            ['quot', '"']
        ];
    
        for (var i = 0, max = entities.length; i < max; ++i) 
            text = text.replace(new RegExp('&'+entities[i][0]+';', 'g'), entities[i][1]);
    
        return text;
    }

    viewMessage(message)
    {
        var b =  this.decodeHTMLEntities(window.atob( message.obj.message));
        b = b.replaceAll(/<p><br><\/p>/gi, '<br><br>');
        b = b.replace(/(<p[^>]+?>|<p>|<\/p>)/img, '');        
        var x = {obj:{subject: message.obj.subject, message: b}};
        this.setState({'message_view': true, 'message_to_view': x});
    }

    cancelMessage( message )
    {
        var _this = this;
        var chain = '&id=' + message.id;
 
        fetch("/communicator/do-ajax-cancel-message", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.reload();
            }
            else
            {
                _this.reload();
            }
        }).catch(function(err){
            _this.reload();
            console.error('Failed to post message to the communicator', err);
        });          
    }


    onRefreshCustomerEmails( email )
    {

        var _this = this;
        var chain = '&id=' + this.state.data.id;
        chain = chain + '&did=' + this.state.data.date.id;
        chain = chain +  "&object=" + JSON.stringify(email);
        console.log(email);
        console.log(JSON.stringify(email));
        fetch("/communicator/ajax-post-customer-email", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({'customer_editor': false}, function(){
                    _this.reload();
                });
            }
            else
            {
                _this.setState({'customer_editor': false}, function(){
                    _this.reload();
                });
                //this.setState({cust_add: false});
            }
        }).catch(function(err){
            _this.setState({'customer_editor': false}, function(){
                _this.reload();
            });
            console.error('Failed to post email to the customers', err);
        });  
  
    }

    onRefreshWaitingEmails( email )
    {
        var _this = this;
        var chain = '&id=' + this.state.data.id;
        chain = chain + '&did=' + this.state.data.date.id;
        chain = chain +  "&object=" + JSON.stringify(email);
 
        fetch("/communicator/ajax-post-waiting-email", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: this.state.security.name + '=' + this.state.security.token + chain,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(response.status === 'OK')
            {
                _this.setState({'waiting_editor': false}, function(){
                    _this.reload();
                });
            }
            else
            {
                _this.setState({'waiting_editor': false}, function(){
                    _this.reload();
                });
                //this.setState({cust_add: false});
            }
        }).catch(function(err){
            _this.setState({'waiting_editor': false}, function(){
                _this.reload();
            });
            console.error('Failed to post email to the waiting list', err);
        });  

    }

    onInternalWaitingClick()
    {
        this.setState({'waiting_editor': true})
    }


    onEditorClose()
    {
        this.setState({'customer_editor': false, 'waiting_editor': false});
    }


    onInternalCustomersClick()
    {
        this.setState({'customer_editor': true})
    }


    onExternalCustomersClick()
    {
        var list = 'mailto:?bcc=';

        for(var i = 0; i < this.state.active_customers.length; i++)
        {
            console.log('checking deleted state', this.state.active_customers[i].deleted);
            if(this.state.active_customers[i].deleted !== 0)
            {
                continue;
            }
            console.log('adding client to list', this.state.active_customers[i].customer_email);

            if(i > 0)
            {
                list = list + ',';
            }
            list = list + this.state.active_customers[i].customer_email;
        }
        window.open(list);

    }

    onExternalWaitingClick()
    {
        var list = 'mailto:?bcc=';

        for(var i = 0; i < this.state.waiting_data.length; i++)
        {
            if(i > 0)
            {
                list = list + ',';
            }
            list = list + this.state.waiting_data[i].email;
        }
        window.open(list);

    }


    onAbortError()
    {
        this.setState({'error': false, 'error_data': null});
    }

    onAbortRefund( refresh )
    {
        var _this = this;
        if(refresh)
        {       
            this.setState({'refund_warning': false, 'refund_data': null}, function(){
                _this.reload();
            });
        }
    }


    onAbortRemove( refresh )
    {
        var _this = this;
        if(refresh)
        {       
            this.setState({'remove_warning': false, 'remove_data': null}, function(){
                _this.reload();
            });
        }        
    }


    onAbortCancel( refresh )
    {
        var _this = this;
        if(refresh)
        {
            this.setState({'delete_warning': false, 'delete_data': null}, function(){
                _this.reload();
            });
        }
    }


    onCompleteRefund(  )
    {
        var _this = this;
        var data  = '&eid=' + this.state.data.id;
        data  = data + '&id=' + this.state.refund_data.subtransaction_id;
        data  = data + '&txid=' + this.state.refund_data.transaction_id;
        
        fetch("/api-actions-customers/ajax-refund-subtransaction-v2", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: window.setup.security_name + '=' + window.setup.security_value + data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(!response.hasOwnProperty("error"))
            {
                _this.setState({'refund_warning': false, 'refund_data': null});
                _this.reload();
            }
            else
            {
              /* handle error */
              _this.setState({'refund_warning': false, 'refund_data': null, 'error': true, 'error_data': response.message });
            }
        }).catch(function(err){
            _this.setState({'refund_warning': false, 'refund_data': null, 'error': true, 'error_data': 'Unable to connect. Please try again later' });
            console.error('Failed to load the event data', err);
        });  
    }


    onCompleteRemove(  )
    {
        var _this = this;
        var data  = '&eid=' + this.state.data.id;
        data  = data + '&did=' + this.state.data.date.id;
        data  = data + '&wid=' + this.state.remove_data.id;
        
        fetch("/waiting/ajax-remove-from-waiting-list", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: window.setup.security_name + '=' + window.setup.security_value + data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(!response.hasOwnProperty("error"))
            {
                _this.setState({'remove_warning': false, 'remove_data': null});
                _this.reload();
            }
            else
            {
              /* handle error */
              _this.setState({'remove_warning': false, 'remove_data': null, 'error': true, 'error_data': response.message });
            }
        }).catch(function(err){
            _this.setState({'remove_warning': false, 'remove_data': null, 'error': true, 'error_data': 'Unable to connect. Please try again later' });
            console.error('Failed to load the event data', err);
        });  
    }



    onCompleteCancel(  )
    {
        var _this = this;
        var data  = '&eid=' + this.state.data.id;
        data  = data + '&id=' + this.state.delete_data.subtransaction_id;
        data  = data + '&txid=' + this.state.delete_data.transaction_id;
        fetch("/api-actions-customers/ajax-void-subtransaction-v2", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: window.setup.security_name + '=' + window.setup.security_value + data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(!response.hasOwnProperty("error"))
            {
                _this.setState({'delete_warning': false, 'delete_data': null});
                _this.reload();
            }
            else
            {
              /* handle error */
            }
        }).catch(function(err){
            _this.setState({'delete_warning': false, 'delete_data': null});
            console.error('Failed to load the event data', err);
        });  
    }

    onExport()
    {
        window.open(this.state.export_url);
    }


    onTransfer( what )
    {
        this.setState({'transfer_prompt': true, 'transfer_data': what});

    }

    onAbortTransfer( refresh )
    {
        var _this  = this;
        this.setState({'transfer_prompt': false, 'transfer_data': null}, function(){
            this.reload();
        });
    }

    onDeleteBooking( what )
    {
        this.setState({'delete_warning': true, 'delete_data': what});
    }

    onRefundBooking( what )
    {
        this.setState({'refund_warning': true, 'refund_data': what});
    }


    onRemoveFromWaitingList( what )
    {
        this.setState({'remove_warning': true, 'remove_data': what});
    }


    reload()
    {
        var _this = this;
        var data  = '&eid=' + this.state.data.id;
        data  = data + '&did=' + this.state.data.date.id;
        this.setState({'loading': true});
        fetch("/backenddata/ajax-fetch-event-register", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: window.setup.security_name + '=' + window.setup.security_value + data,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "X-Requested-With": 'XMLHttpRequest',
                "X_REQUESTED_WITH": 'xmlhttprequest'
            }
        })
        .then(res => {
            return res.json();
        })
        .then(response => {
            //console.log(data);
            if(!response.hasOwnProperty("error"))
            {
                var active = [];
                var cancelled = [];
                var waiting = [];

                if(response.data !== false)
                {
                        for(var i = 0; i < response.data.clients.length; i++)
                        {
                            if(response.data.clients[i].subtransaction_state === 'ACTIVE' && response.data.clients[i].transaction_state !== 'VOIDED')
                            {
                                active.push(response.data.clients[i]);
                            }
                            else
                            {
                                cancelled.push(response.data.clients[i]);
                            }
                        }
                        for(var i = 0; i < response.data.waiting.length; i++)
                        {
                            waiting.push(response.data.waiting[i]);
                        }

                        var export_disabled = true;
                        if(active.length > 0)
                        {
                            export_disabled = false;
                        }
        
                        var data = JSON.parse(JSON.stringify(this.state.data));
        
                        data.date.available = parseInt(response.data.event.active_stock) - parseInt(response.data.event.active_sold);
                        data.date.sold = response.data.event.active_sold;
                        data.date.dsold = response.data.event.archived_sold;
                        data.date.invite = response.data.date.invite;
                        data.date.invite_id = response.data.date.invite_id;
                        data.date.invite_pass = response.data.date.invite_pass;
        
                        _this.setState({    'data': data, 
                                            'active_customers': active, 
                                            'cancelled_customers': cancelled, 
                                            'waiting_data': waiting, 
                                            'export_disabled': export_disabled, 
                                            'export_url': response.data.export_url,
                                            'emails': response.messages,
                                            'loading': false
                                        });        



                }        
            }
            else
            {
              /* handle error */
            }
        }).catch(function(err){
            _this.setState({'loading': false, 'error': true, 'error_data': 'Unable to connect to service. Please try again later'});
            console.error('Failed to load the event data', err);
        });         
    }

    componentDidMount()
    {
        this.reload();
    }

    onTabSwitch(event, newValue)
    {
        console.log("setting selection to", newValue);
        this.setState({'selection': newValue});
    }

    onManageCustomerPanelClose(event)
    {
        this.setState({'open': false}, function(){
            this.state.onCancel();
        });
    }

    onMenuSelect(event, reason)
    {

    }

    onAddCustomer()
    {
        this.setState({add_button_state:!this.state.add_button_state});
    }


    render(  )
    {
        var classes = this.props.classes;

        /* OK we need to kick off the loading of the data */


        console.log('render',this.props, this.state );




        return (
            <div className={classes.root}>

                <Dialog fullScreen open={this.state.open} onClose={this.onManageCustomerPanelClose} TransitionComponent={Transition} >
                    <div className={classes.dialog}>
                        <Container maxWidth="lg">
                                
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={this.onManageCustomerPanelClose} aria-label="close">
                                    <CloseIcon />
                                    </IconButton>
    
                                    <Grid container direction="row" justifyContent="flex-end" alignContent="flex-start" alignItems="stretch" spacing={1} >
                                        <Grid item>
                                            <Button color="inherit" className={classes.widgetButton} onClick={this.onAddClient} variant="outlined">
                                                <AddIcon /><Hidden smDown>Client</Hidden>
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button color="inherit" disabled={this.state.export_disabled} className={classes.widgetButton} onClick={this.onExport} variant="outlined">
                                                Export
                                            </Button>   
                                        </Grid> 
                                        <Grid item>
                                                <ContactMenu    classes={classes}  
                                                    disabled={false} 
                                                    onExternalCustomersClick={this.onExternalCustomersClick} 
                                                    onExternalWaitingClick={this.onExternalWaitingClick}
                                                    onInternalCustomersClick={this.onInternalCustomersClick}
                                                    onInternalWaitingClick={this.onInternalWaitingClick}
                                                    />
                                        </Grid> 
                                        

                                    </Grid>


                                                                
                                   
                                </Toolbar>
                            </AppBar>                   
                            <div className={classes.box}>
                                <Grid container justify="flex-start" alignItems="flex-start" >
                                    <Grid item xs={12} md={6}>

                                        <Grid container justify="flex-start" alignItems="flex-start" >
                                            <Grid item xs={12} md={12}>
                                                <h2>Key information</h2>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Grid container justify="flex-start" alignItems="flex-start" className={classes.shaded}>
                                                    <Grid item xs={3} md={2} className={classes.section}>
                                                        <DateRangeIcon style={{height: 60, width: 60, paddingTop: '10px', paddingBottom: '10px'}}/>
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <Grid container justify="flex-start" alignItems="flex-start" >
                                                            <Grid item xs={12}>
                                                                <div className={classes.sectionText}>
                                                                    Event name
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {this.state.data.basic.name}
                                                            </Grid>                                        
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                            </Grid> 




                                            <Grid item xs={12} md={12}>
                                                <Grid container justify="flex-start" alignItems="flex-start" className={classes.shaded}>
                                                    <Grid item xs={3} md={2} className={classes.section}>
                                                        <EventAvailableIcon style={{height: 60, width: 60, paddingTop: '10px', paddingBottom: '10px'}}/>
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <Grid container justify="flex-start" alignItems="flex-start">
                                                            <Grid item xs={12}>
                                                                <div className={classes.sectionText}>
                                                                    Date and time of event
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Moment format="lll">{this.state.data.date.date + "T" + this.state.data.date.time}</Moment> {this.state.data.date.tz}
                                                            </Grid>                                        
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} md={12}>
                                                <Grid container justify="flex-start" alignItems="flex-start" className={classes.shaded}>
                                                    <Grid item xs={3} md={2} className={classes.section}>
                                                        <CenterFocusWeakSharpIcon style={{height: 60, width: 60, paddingTop: '10px', paddingBottom: '10px'}}/>
                                                    </Grid>
                                                    <Grid item xs={9} md={10}>
                                                        <Grid container justify="flex-start" alignItems="flex-start">
                                                            <Grid item xs={12}>
                                                                <div className={classes.sectionText}>
                                                                    Event type
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {this.state.data.mode.charAt(0).toUpperCase() + this.state.data.mode.slice(1).toLowerCase()}
                                                            </Grid>                                                                                         
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Grid item xs={12} md={12}>
                                            <h2>Access information</h2>
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <Grid container justify="flex-start" alignItems="flex-start" className={classes.shaded}>
                                                <Grid item xs={3} md={2} className={classes.section}>
                                                    <AllInclusiveSharpIcon style={{height: 60, width: 60, paddingTop: '10px', paddingBottom: '10px'}}/>
                                                </Grid>
                                                <Grid item xs={9} md={10}>
                                                    <Grid container justify="flex-start" alignItems="flex-start" >
                                                        <Grid item xs={12}>
                                                            <div className={classes.sectionText}>
                                                                Meeting link: 
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography nowrap={false} style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                                                                {this.state.data.date.invite?(<a href={this.state.data.date.invite} target="_blank">{this.state.data.date.invite}</a>): 'Not available'}
                                                            </Typography>
                                                        </Grid>                                        
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid> 




                                        <Grid item xs={12} md={12}>
                                            <Grid container justify="flex-start" alignItems="flex-start" className={classes.shaded}>
                                                <Grid item xs={3} md={2} className={classes.section}>
                                                    <WebAssetSharpIcon style={{height: 60, width: 60, paddingTop: '10px', paddingBottom: '10px'}}/>
                                                </Grid>
                                                <Grid item xs={9} md={10}>
                                                    <Grid container justify="flex-start" alignItems="flex-start">
                                                        <Grid item xs={12}>
                                                            <div className={classes.sectionText}>
                                                                ID
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.state.data.date.invite_id?this.state.data.date.invite_id:'Not available'}
                                                        </Grid>                                        
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                            <Grid container justify="flex-start" alignItems="flex-start" className={classes.shaded}>
                                                <Grid item xs={3} md={2} className={classes.section}>
                                                    <VpnKeySharpIcon style={{height: 60, width: 60, paddingTop: '10px', paddingBottom: '10px'}}/>
                                                </Grid>
                                                <Grid item xs={9} md={10}>
                                                    <Grid container justify="flex-start" alignItems="flex-start">
                                                        <Grid item xs={12}>
                                                            <div className={classes.sectionText}>
                                                                Password
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {this.state.data.date.invite_pass? this.state.data.date.invite_pass:'Not available'}
                                                        </Grid>                                                                                         
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>     
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <h2 className="formTitle">Sales overview</h2>
                                    </Grid> 
                                    <Grid item xs={12} md={6}>
                                        <Grid container justify="flex-start" alignItems="flex-start" className={classes.shaded}>
                                            <Grid item xs={3} md={2} className={classes.section}>
                                                <AddCircleOutlineSharpIcon style={{height: 60, width: 60, paddingTop: '10px', paddingBottom: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9} md={10}>
                                                <Grid container justify="flex-start" alignItems="flex-start">
                                                    <Grid item xs={12}>
                                                        <div className={classes.sectionText}>
                                                            Total places sold
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {parseInt(this.state.data.date.sold) + parseInt(this.state.data.date.dsold)}
                                                    </Grid>                                                                                         
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>   
                                    <Grid item xs={12} md={6}>
                                        <Grid container justify="flex-start" alignItems="flex-start" className={classes.shaded}>
                                            <Grid item xs={3} md={2} className={classes.section}>
                                                <LibraryAddSharpIcon style={{height: 60, width: 60, paddingTop: '10px', paddingBottom: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9} md={10}>
                                                <Grid container justify="flex-start" alignItems="flex-start">
                                                    <Grid item xs={12}>
                                                        <div className={classes.sectionText}>
                                                            Spaces available (active pricing options)
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {parseInt(this.state.data.date.available)}
                                                    </Grid>                                                                                         
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid> 

                                    <Grid item xs={12} md={12}>
                                        <h2 className="formTitle">Customers</h2>
                                    </Grid>                         
                                    <Grid item xs={12}>     
                                        <Tabs
                                            value={this.state.selection}
                                            onChange={this.onTabSwitch}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            aria-label="Attendees list"
                                        >
                                            <Tab label="Attendees" value='one' {...a11yProps('one')} />
                                            <Tab label="Cancellations" value='two' {...a11yProps('two')} />
                                            <Tab label="Waiting" value='three' {...a11yProps('three')} />
                                        </Tabs>
                                        <TabPanel value={this.state.selection} index="one">
                                            <TableContainer>
                                            <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.tableName} >
                                                                Name
                                                            </TableCell>
                                                            <Hidden smDown>
                                                            <TableCell align="left">
                                                                Pricing option
                                                            </TableCell>                                                                  
                                                            <TableCell  align="center">
                                                                Spaces
                                                            </TableCell>  
                                                            </Hidden>
                                                            <TableCell align="center">
                                                                Paid
                                                            </TableCell>     
                                                            <TableCell align="center">
                                                                Status
                                                            </TableCell>  
                                                            <TableCell align="center">
                                                                Attended
                                                            </TableCell>                                                              
                                                            <TableCell align="center">
                                                                Transfer
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                Cancel
                                                            </TableCell>                                                          
                                                            <TableCell align="center">
                                                                Refund
                                                            </TableCell>                                                                                                                                                                                                                                                                                                                                     
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.active_customers.length === 0 && this.state.loading === false &&  (<TableRow>
                                                            <TableCell colSpan={9} align="center">
                                                                <div className={classes.progress}>
                                                                    <WarningIcon className={classes.progressIcon} style={{ height: 100, width: 100, textAlign: 'center' }}/>                                                                          
                                                                    <p>
                                                                        There are no active customers associated to this event.
                                                                    </p>
                                                                </div>                                                    
                                                            </TableCell>
                                                        </TableRow>)}
                                                        {this.state.loading === true &&  (<TableRow>
                                                            <TableCell colSpan={9} align="center">
                                                                <div className={classes.progress}>
                                                                    <Loading />
                                                                </div>                                                    
                                                            </TableCell>
                                                        </TableRow>)}
                                                        {this.state.active_customers.map( el => (
                                                        <TableRow key={uuidv1()}>
                                                            <TableCell>
                                                                <a href={"/customers/manage/" + el.customer_id} target="_blank">{el.customer_firstname} {el.customer_lastname}</a>         
                                                                <QuestionnaireButton data={el.questionnaires} classes={classes}/> 
                                                             
                                                                {
                                                                    el.questionnaires?.reduce( (t, q) => {
                                                                        if(q?.important_active === true) 
                                                                        {
                                                                            return t+1;
                                                                        }
                                                                        else 
                                                                        {
                                                                            return t;
                                                                        }
                                                                    }, 0) > 0 &&
                                                                    <Tooltip title="Important questionnaire data added">
                                                                        <IconButton className={classes.fabDisabled}> <StarIcon className={classes.fabStar} /></IconButton>
                                                                    </Tooltip>
                                                                }
                                                            </TableCell>
                                                            <Hidden smDown>
                                                            <TableCell align="left">
                                                                <PurchaseOption payments={this.state.data.payments} id={el.subtransaction_pid}/>                                                  
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {el.subtransaction_q}                                                   
                                                            </TableCell>
                                                            </Hidden>
                                                            <TableCell align="center">
                                                                <Hidden smUp> {el.subtransaction_q} x </Hidden>

                                                                {el.subtransaction_currency} {(parseFloat(el.subtransaction_base100net_price)/100).toFixed(2)}                                                 
                                                            </TableCell>                                                             
                                                            <TableCell align="center">
                                                                <SaleState stx_state={el.subtransaction_state}  tx_state={el.transaction_state} />                                                  
                                                            </TableCell> 
                                                            <TableCell align="center">
                                                                <PresentState data={el} callback={this.onPresentStateChange}/>
                                                            </TableCell>                                                                
                                                            <TableCell align="center">
                                                                <TransferButton classes={classes} data={el} callback={this.onTransfer}/>                                                   
                                                            </TableCell>  
                                                            <TableCell align="center">
                                                                <DeleteButton classes={classes} data={el} callback={this.onDeleteBooking} />
                                                            </TableCell>                                                          
                                                            <TableCell align="center">
                                                                <RefundButton classes={classes} data={el} callback={this.onRefundBooking} />
                                                            </TableCell>                                                                                                                                                                                                                                                                                               
                                                        </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </TabPanel>      
                                        <TabPanel value={this.state.selection} index="two">
                                        <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.tableName}>
                                                                Name
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                Pricing option purchased
                                                            </TableCell>  
                                                            <TableCell align="center">
                                                                Spaces
                                                            </TableCell>  
                                                            <TableCell align="center">
                                                                Paid
                                                            </TableCell>     
                                                            <TableCell align="center">
                                                                Status
                                                            </TableCell> 
                                                            <TableCell align="center">
                                                                Refund
                                                            </TableCell>                                                                                                                                                                                                                                                                                                                                                                                              
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.cancelled_customers.length === 0 && (<TableRow>
                                                            <TableCell colSpan={6} align="center">
                                                                <div className={classes.progress}>
                                                                    <WarningIcon className={classes.progressIcon} style={{ height: 100, width: 100, textAlign: 'center' }}/>                                                                          
                                                                    <p>
                                                                        There are no cancelled customers associated to this event.
                                                                    </p>
                                                                </div>                                                    
                                                            </TableCell>
                                                        </TableRow>)}
                                                        {this.state.cancelled_customers.map( el => (
                                                        <TableRow>
                                                            <TableCell>
                                                                {el.customer_firstname} {el.customer_lastname}                                                   
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <PurchaseOption payments={this.state.data.payments} id={el.subtransaction_pid}/>                                                  
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {el.subtransaction_q}                                                   
                                                            </TableCell>                                                        
                                                            <TableCell align="center">
                                                                {el.subtransaction_currency} {parseFloat(el.subtransaction_gross).toFixed(2)}                                                 
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <SaleState stx_state={el.subtransaction_state}  tx_state={el.transaction_state} />                                                  
                                                            </TableCell> 
                                                            <TableCell align="center">
                                                                <RefundButton classes={classes} data={el} callback={this.onRefundBooking} />
                                                            </TableCell>                                                                                                                                                                                                                                                                                                                                                       
                                                        </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </TabPanel>

                                       <TabPanel value={this.state.selection} index="three">
                                        <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.tableName}>
                                                                Name
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                Email
                                                            </TableCell>  
                                                            <TableCell align="center">
                                                                Registered
                                                            </TableCell>      
                                                            <TableCell align="center">
                                                                Remove
                                                            </TableCell>      
                                                                                                                        
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.waiting_data.length === 0 && (<TableRow>
                                                            <TableCell colSpan={4} align="center">
                                                                <div className={classes.progress}>
                                                                    <WarningIcon className={classes.progressIcon} style={{ height: 100, width: 100, textAlign: 'center' }}/>                                                                          
                                                                    <p>
                                                                        The waiting list for this event date is empty. 
                                                                    </p>
                                                                </div>                                                    
                                                            </TableCell>
                                                        </TableRow>)}
                                                        {this.state.waiting_data.map( el => (
                                                        <TableRow>
                                                            <TableCell>
                                                                {el.firstname} {el.lastname}                                                   
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <a href={'mailto:' + el.email} target="_blank">{el.email}</a>                                                  
                                                            </TableCell>                                                            
                                                            <TableCell align="center">
                                                                <Moment format="lll">{el.date}</Moment>                                                  
                                                            </TableCell> 
                                                            <TableCell align="center">
                                                                <RemoveButton classes={classes} data={el} callback={this.onRemoveFromWaitingList} />
                                                            </TableCell> 
                                                        </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </TabPanel>                                                                                                             
                                    </Grid>   


        

                                
                                    <Grid item xs={12} md={12}>
                                        <h2 className="formTitle">Emails</h2>
                                    </Grid>                              
                                    <Grid item xs={12}>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Scheduled
                                                        </TableCell>  
                                                        <TableCell>
                                                            To
                                                        </TableCell> 
                                                        <TableCell>
                                                            Status
                                                        </TableCell> 
                                                        <TableCell>
                                                            View
                                                        </TableCell>                                                          
                                                        <TableCell>
                                                            Cancel
                                                        </TableCell>                                                                                                                                                          
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.emails.length > 0 && this.state.emails.map(el => (
                                                    <TableRow>
                                                        <TableCell>
                                                            <Moment format="lll">{el.send_timestamp}</Moment> 
                                                        </TableCell>
                                                        <TableCell>
                                                            {(el.tkey === '-2')?'Customer list':'Waiting list'} 
                                                        </TableCell>                                                        
                                                        <TableCell>
                                                            {(el.state === '0')?'Pending':(el.state === '1')?'Sent':'Cancelled'} 
                                                        </TableCell> 
                                                        <TableCell>
                                                            <InfoButton data={el} classes={classes} callback={this.viewMessage}/> 
                                                        </TableCell>                                                         
                                                        <TableCell>
                                                            <DeleteButton data={el} classes={classes} callback={this.cancelMessage} disabled={el.state !== '0'}/> 
                                                        </TableCell>                                                                                                                 
                                                    </TableRow>
                                                    ))}
                                                    {this.state.emails.length === 0 && (
                                                        <TableRow>
                                                            <TableCell colSpan={5}>
                                                                <div className={classes.progress}>
                                                                    <p>
                                                                        There are no messages for this event.
                                                                    </p>
                                                                </div>                                                                 
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                    {this.state.loading === true &&  (<TableRow>
                                                        <TableCell colSpan={3} align="center">
                                                            <div className={classes.progress}>
                                                                <Loading />
                                                            </div>                                                    
                                                        </TableCell>
                                                    </TableRow>
                                                    )}                                                    
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>                
                            </div>

                        </Container>
                    </div>
                </Dialog>
                
                {this.state.delete_warning === true && (<CoreDialog title="Cancel booking" body="You are about to cancel this booking. If the client has booked other dates associated to this event these will not be cancelled. Do you want to continue?" state={true} danger={true} onCancel={this.onAbortCancel} actions={[{label:"OK", action:this.onCompleteCancel}]}  />)}
                {this.state.refund_warning === true && (<CoreDialog title="Refund booking" 
                                                                    body="You are about to refund this booking. If the client has booked other dates associated to this event these will not be refunded. Do you want to continue?" state={true} danger={true} onCancel={this.onAbortRefund} actions={[{label:"OK", action:this.onCompleteRefund}]}  />)}


                {this.state.remove_warning === true && (<CoreDialog title="Remove from waiting list" 
                                                                    body="You are about to this person from the waiting list. This person, if removed, will not receive notification of available spaces. Do you want to continue?" state={true} danger={true} onCancel={this.onAbortRemove} actions={[{label:"OK", action:this.onCompleteRemove}]}  />)}


                {this.state.error === true && (<CoreDialog title="Error" 
                                                            body={"The requested operation has failed: " + this.state.error_data} state={true} danger={true} onCancel={this.onAbortError} actions={[]} />)}


                {this.state.transfer_prompt === true && (                    
                    <TransferDialog 
                        state={true}  
                        onCancel={this.onAbortTransfer}
                        eid={this.state.data.id}
                        did={this.state.data.date.id}
                        stx_id={this.state.transfer_data.subtransaction_id}
                        cust_id={this.state.transfer_data.customer_id}
                        security={this.state.security}
                        events={this.state.events}
                    />)
                }
                {this.state.customer_editor === true && (
                    <EmailEditorDialog  onCancel={this.onEditorClose} 
                                        title="Send email to registered clients" 
                                        actions={[{label:"Submit for sending", action:this.onRefreshCustomerEmails, variant:"outlined", color: 'primary'}]}
                                        security={this.state.security}  />
                )}
                {this.state.waiting_editor === true && (
                    <EmailEditorDialog  onCancel={this.onEditorClose}   
                                        title="Send email to the waiting list" 
                                        actions={[{label:"Submit for sending", action:this.onRefreshWaitingEmails, variant:"outlined", color: 'primary'}]}
                                        security={this.state.security}  />
                )}
                {this.state.message_view === true && (
                    <EmailViewDialog subject={this.state.message_to_view.obj.subject} body={this.state.message_to_view.obj.message} callback={this.onCloseMessageView}/>
                )}
                {this.state.add_client === true && (
                    <EventCustomerRegistrationDialog 
                            payments = {this.state.data.payments}
                            eid={this.state.data.id}
                            security={this.state.security}
                            customers={this.state.all_clients}
                            did={this.state.data.date.id}
                            state={true}
                            callback={this.onCustomerRegistrationDialogClose} />
                )}

            </div>            
        );
    }

};



const EventRegisterDialog = connect(null, mapDispatchToProps)(EventRegisterDialogModule);
export default withStyles(styles)(EventRegisterDialog);
