import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {v1 as uuidv1} from "uuid";
import Parser from 'html-react-parser';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonDisabled: {
    color: theme.palette.grey[900]
  },
  passField:{
    marginTop: '20px',
  },
  passFieldCopy:{
    marginTop: '20px',
    '& .MuiOutlinedInput-notchedOutline':{
      borderColor: '#00c0ef',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':{
      borderColor: '#00c0ef',
    },
    '& .MuiFormLabel-root':{

      color:'#00c0ef',
    }
  },

  formSectionTitle:{
    marginTop: '15px',
    marginBottom: '15px',
    fontWeight: 'bold'
}    
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function EmailViewDialog( props ) {
  const  callback  = props.callback;
  const  subject   = props.subject;
  const  body      = props.body;

  const handleClose = () => {
    console.log('handleClose', props)
    callback(false);
  };



  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={true} fullWidth={true} maxWidth = {'sm'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} >
          Email Viewer
        </DialogTitle>
        <DialogContent dividers>
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
            <Grid item xs={12} >
                <b>Subject:</b>
            </Grid>
            <Grid item xs={12} >
                {subject}
            </Grid> 
            <Grid item xs={12} >
                <b>Body:</b>
            </Grid>
            <Grid item xs={12} >
                {Parser(body)}
            </Grid>                         
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
  
}


export default withStyles(styles)(EmailViewDialog);
