import React, { Component, forwardRef } from 'react';
import { connect } from "react-redux";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import {setStep, rewindStep} from '../redux/actions';
import StepperWidget   from './stepper';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';


import  {
    EVENT_APP_CACHE_STATE_WIZARD_2,
    EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL,
    EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_WEEKLY,
    EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_MONTHLY,
    EVENT_APP_CACHE_MODE_VIRTUAL
} from '../redux/types';


import '../core/dashboard.css';
import './events-manager.css';

import {v1 as uuidv1} from "uuid";


import MuiDialogTitle from '@material-ui/core/DialogTitle';

    const styles = theme => ({
        root: {
            flexGrow: 1,
          },          
          title: {
                flexGrow: 1,
                color: '#fff',
                fontSize: '1.3rem'
          },
          formControl: {
            margin: '20px',
            minWidth: 120,
          },
          textInput:{
            marginTop:"20px",
            marginBottom:"20px",
          },
          switchLabel:{
              color: 'rgba(0,0,0,0.6)',
          },
          captionText:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',

          },
          colorSelect:{
            marginTop:"10px",
            marginBottom:"10px",
          },
          sectionTitle:{
            color: 'rgba(0,0,0,0.8)',
            fontSize: '1.2em',
            fontWeight: 'normal',
            paddingTop: '10px',
            paddingBottom: '10px',
          },
          section:{
            color: 'rgba(0,0,0,0.7)',
            fontSize: '1.2em',
            fontWeight: 'bold',
          },
          sectionDescription:{
            color: 'rgba(0,0,0,0.6)',    
          },            
          captionSection:{
            color: 'rgba(0,0,0,0.6)',
            marginTop: '10px',
            marginBottom: '10px',
            lineHeight: '1.5em',
          },
          schedTypeSelection:{
              marginLeft: '10px'
          }
});








function mapDispatchToProps(dispatch) {
    return {
        setStep: spec => dispatch(setStep(spec)),
        rewindStep: spec => dispatch(rewindStep(spec))
    };
  }


  
  var obj = null;


class EventsStep2Module extends Component{

    constructor( props )
    {
        super( props );

        this.state = {
            editmode: this.props.mode || EVENT_APP_CACHE_MODE_VIRTUAL,
            mode: this.props.cache.mode || EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL,
            automation_allowed: true,
            errors: {},
            virtual: this.props.virtual
        }
        obj = this.state;

        if(this.state.editmode === EVENT_APP_CACHE_MODE_VIRTUAL)
        {
            if(this.state.virtual.mode === 'manual')
            {
                this.state.automation_allowed = false;
                this.state.mode = EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL;
            }
        }

        this.state['errors'] = {
            mode: {
                object: 'name',
                rule: /^(EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL)||(EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_WEEKLY) || (EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_MONTHLY)+$/,
                state: false, 
                ok: "If you would like to manage scheduling, click manual. If you would like the system to manage scheduling click one of the automated scheduling options.", 
                error: 'Please select a scheduling type'
            }            
        };

        this.state.security = props.security;
        this.myRef = React.createRef()  
 
        this.onModeChange   = this.onModeChange.bind(this);
        this.onDoPrevious   = this.onDoPrevious.bind(this);
        this.onDoNext   = this.onDoNext.bind(this);

        
    }



 


    onDoPrevious(event)
    {
        if(this.isValid())
        {
            var o = { 
                  store_previous: true,
                  mode: this.state.mode,
                  state: EVENT_APP_CACHE_STATE_WIZARD_2};
            this.props.rewindStep(o);            
        }
        else
        {
            var o = { 
                state: EVENT_APP_CACHE_STATE_WIZARD_2
            };
            this.props.rewindStep(o);            
        }        
    }

    onModeChange(event)
    {
        var o = {'mode': event.target.value};
        console.log("mode change", o);
        this.setState(o);
    }  

    isValid()
    {
        for(var i in this.state.errors)
        {
            var regex = RegExp(this.state.errors[i].rule);
            var x = Object.assign({}, this.state.errors);


             if(regex.test(this.state[this.state.errors[i].object]) === false)
            {
                window.scrollTo(0, this.myRef.offsetTop);                
                this.state.errors[i].state = true;
                this.setState({'errors': this.state.errors});
                return false;
            }
            else
            {
                console.log('Passes test');
                this.state.errors[i].state = false;
                this.setState({'errors': this.state.errors});
                return true;

            }
            
        }
        return false;

    }

    
    onDoNext(event)
    {
        var x = Object.assign({}, this.state.errors);

        //if(regex.test(this.state.name))
        if(this.isValid())
        {
            var o = { scheduling:
                {
                  mode: this.state.mode,
                  configuration: {},
                },
                state: EVENT_APP_CACHE_STATE_WIZARD_2};
            this.props.setStep(o);
    
        }
        else
        {
            this.state.errors.name.state = true;
            this.setState({'errors': this.state.errors});
        }



    }
  
    




  

    render()
    {
        var classes = this.props.classes;
        console.log('edit data', this.state.edit_data);
        var classes = this.props.classes;

        return (
            <div className={classes.root}>
                <AppBar position="sticky" className="appBar">
                    <Toolbar>
                    <Typography variant="h2" className={classes.title}>
                        Scheduling and date management.
                    </Typography>
                    </Toolbar>
                </AppBar>
                <Grid container direction="row" justify="flex-start" alignContent="flex-start" alignItems="stretch" spacing={2} >


                <Grid item xs={12} md={12} > 
                    <Grid container justify="flex-start" alignItems="stretch">
                        
                                        
                        <Grid item xs={12} md={12}>
                            <div className="box">

                                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} className="rv-form-row">
                                    
                                    <Grid item xs={12} md={12}>
                                           <StepperWidget key={uuidv1()} step={1}/>

                                    </Grid>
                                    
                                    
                                    <Grid item xs={12}>
                                        <div className="formSection">Scheduling type</div>                               
                                        <p className={classes.sectionDescription}>
                                            This section enables you to select the scheduling mode. You can select either 'manual scheduling' or 'automated scheduling'.
                                        </p>                                        
                                        
                                    
                                        <FormControl component="fieldset" className={classes.schedTypeSelection}>
                                            <RadioGroup aria-label="rv-option" name="mode" value={this.state.mode} onChange={this.onModeChange} error={this.state.errors.mode.state? this.state.errors.mode.error: this.state.errors.mode.ok} >
                                                <FormControlLabel className="rv-option" value={EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL} control={<Radio />} label="Manual" />
                                                <FormControlLabel className="rv-option"  value={EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_WEEKLY} disabled={!this.state.automation_allowed} control={<Radio />} label="Automated weekly" />
                                                <FormControlLabel className="rv-option"  value={EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_MONTHLY}  disabled={!this.state.automation_allowed}  control={<Radio />} label="Automated monthly" />
                                            </RadioGroup>
                                            <FormHelperText>{this.state.errors.mode.state? this.state.errors.mode.error: this.state.errors.mode.ok}</FormHelperText>

                                            
                                        </FormControl>                        
                                    </Grid>

                                    <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="default"
                                                size="large"
                                                className="rv-eng-next-button"
                                                startIcon={<NavigateBeforeIcon />}
                                                onClick={this.onDoPrevious}
                                                fullWidth={true}
                                            >
                                                Back
                                            </Button>
                                    </Grid>
                                    <Grid item xs={4} md={6}></Grid>
                                    <Grid item xs={4} md={3}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className="rv-eng-next-button"
                                                endIcon={<NavigateNextIcon />}
                                                onClick={this.onDoNext}
                                                fullWidth={true}
                                            >
                                                Next
                                            </Button>  
                                                                
                                    </Grid>  


                                </Grid>


                            </div>
                        </Grid>                                               
                    </Grid>

                </Grid>                                    

                </Grid>

                
            </div>            
        );
    }

};



const EventsStep2 = connect(null, mapDispatchToProps)(EventsStep2Module);
export default withStyles(styles)(EventsStep2);
