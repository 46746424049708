import React, { Component, forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';

import MaterialTable, {MTableToolbar} from 'material-table';

import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import MoneyOffRoundedIcon from '@material-ui/icons/MoneyOffRounded';
import EditIcon from '@material-ui/icons/Edit';

import TableCell from '@material-ui/core/TableCell';
import Fab from '@material-ui/core/Fab';

import TableRow from '@material-ui/core/TableRow';

import moment from 'moment';
import CoreDialog from '../core/dialog';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import PassWidgets from '../core/pass-widget';


import './customers.css';
import PassEditDialog from './pass-edit-dialog';


function Currency(v, c)
{
    //console.log('converting', v, c);
    switch( c )
    {
        case 'GBP':
            //console.log('Returning GBP', c, v, '£' + Number(v).toFixed(2).toString());
            return '£' + Number(v).toFixed(2).toString();
        case 'USD':
            return '$' + Number(v).toFixed(2).toString();
        case 'AUD':
            return 'AS$' + Number(v).toFixed(2).toString();
        case 'NZD':
            return 'NZ$' + Number(v).toFixed(2).toString();
        default:
        {
            console.log('unknow', c, v);
            return  (<span>{c} {Number(v).toFixed(2)}</span>);
        }
    }
}

class DeleteButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.data.id,
            object: props.data,
            callback: props.callback,
            dodelete: false,
            dorefund: false,
            security: props.security,
            disabled: false
        }
        this.onInitiateCancel = this.onInitiateCancel.bind(this);
        this.doCancel = this.doCancel.bind(this);
        this.doClose = this.doClose.bind(this);



        switch(this.state.object?.type)
        {
            case 'AUTO':
            case 'MANUAL':
            {
                switch(this.state.object?.status)
                {
                    case 'COMPLETED':
                    case 'PREPAIDPARTIAL':
                    case 'MANUAL':
                    {
                        this.state.disabled = false;
                    }
                    break;

                    default:
                    {
                        this.state.disabled = true;
                    }
                    break;
                }
            }   
            break; 

            default:
            {
                this.state.disabled = true;
            }   
            break     
        }
 
 

    }

    onInitiateCancel = () => {
        this.setState({dodelete: true});
    }

 


    doCancel = () => {


        var chain = '';
        chain = '&id=' + this.state.id;
        //chain += '&object=' + encodeURI( JSON.stringify(this.state.object) );
 
        var _this = this;
        var callback = this.state.callback;
        try {
            fetch("/api-actions-customers/ajax-void-pass", {
                method: "POST",
                mode: 'same-origin', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'include', // include, *same-origin, omit                            
                body: this.state.security.name + '=' + this.state.security.token + chain,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": 'XMLHttpRequest',
                    "X_REQUESTED_WITH": 'xmlhttprequest'
                }
            })
            .then(res => {
                return res.json();
            })
            .then(response => {
                //console.log(data);
                if(response.status === 'OK')
                {
                    this.setState({dodelete: false, dorefund: false});
                    callback(true);
                }
                else
                {
                     this.setState({dodelete: false, dorefund: false});
                     callback(true);
                 }
            }).catch(function (err) {
             //_this.setState({dodelete: false, dorefund: true});
             console.error('Network error', err);
             _this.setState({dodelete: false, dorefund: false});
                callback(true);
            });           
    
        }
        catch(e)
        {
            console.error('Failed to process request', e);
 //        _this.setState({dodelete: false, dorefund: true});
             //_this.setState({loading: false, dorefund: false});
             //callback(_this.state.id);
         }
 
 
     }





    doClose= () => {
        this.setState({dodelete: false, dorefund: false});

    }

    render() {
        var classes= this.props.classes;
        if(this.state.disabled === false)
        {
            return (
                <div>
                    <Fab className={classes.fabDanger}><DeleteRoundedIcon className={classes.fabIcon} onClick={this.onInitiateCancel}/></Fab>                        
                    <CoreDialog 
                        classes={classes} 
                        state={this.state.dodelete}  
                        danger={false}
                        onCancel={this.doClose}
                        actions={[{action:this.doCancel, label: "Yes, Cancel", className: classes.buttonDanger, variant: "text"}]}
                        body="Are you sure you want to cancel this booking? Note this cannot be undone."
                        title="Cancel Booking"
                         />
                         
                </div>
            );
        }
        else
        {
            return (
                <div>
                    <Fab className={classes.fabGrey}><DeleteRoundedIcon className={classes.fabIcon} /></Fab>                       
                </div>
            );            
        }


    }
}


class RefundButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.id,
            object: props.data,
            callback: props.callback,
            dodelete: false,
            dorefund: false,
            security: props.security,
            disabled: false
        }
        this.onInitiateCancel = this.onInitiateCancel.bind(this);
        this.doRefund = this.doRefund.bind(this);
        this.doClose = this.doClose.bind(this);


        switch(this.state.object?.type)
        {
            case 'AUTO':
            {
                switch(this.state.object?.status)
                {
                    case 'COMPLETED':
                    case 'PREPAIDPARTIAL':
                    {
                        if(!!this.state.object?.refunded)
                        {
                            this.state.disabled = true;
                        }
                        else
                        {
                            this.state.disabled = false;
                        }
                    }
                    break;

                    default:
                    {
                        this.state.disabled = true;
                    }
                    break;
                }
            }   
            break; 

            case 'MANUAL':
            default:
            {
                this.state.disabled = true;
            }   
            break     
        }
 

    }

    onInitiateCancel = () => {
        this.setState({dodelete: true});
    }



    doRefund = () => {


        var chain = '';
        chain += '&id=' + this.state.object.id;
 
        var _this = this;
        var callback = this.state.callback;
        this.setState({loading: true});
        try {
            fetch("/api-actions-customers/ajax-refund-pass", {
                method: "POST",
                mode: 'same-origin', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'include', // include, *same-origin, omit                            
                body: this.state.security.name + '=' + this.state.security.token + chain,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-Requested-With": 'XMLHttpRequest',
                    "X_REQUESTED_WITH": 'xmlhttprequest'
                }
            })
            .then(res => {
                return res.json();
            })
            .then(response => {
                //console.log(data);
                if(response.status === 'OK')
                {

                    this.setState({dorefund: false, loading: false});
                    callback(true);
                }
                else
                {
                     this.setState({dorefund: false, loading: false});
                     callback(true);
                 }
            }).catch(function (err) {
                 _this.setState({dorefund: false, loading: false});
                callback(true);
            });           
    
        }
        catch(e)
        {
 //        _this.setState({dodelete: false, dorefund: true});
             _this.setState({dorefund: false, loading: false});
             callback(true);
         }

    }


    doClose= () => {
        this.setState({dodelete: false, dorefund: false});

    }

    render() {
        var classes= this.props.classes;
        if(this.state.disabled === false)
        {
            return (
                <div>
                    <Fab className={classes.fabWarning}><MoneyOffRoundedIcon className={classes.fabIcon} onClick={this.onInitiateCancel}/></Fab>                        
                    <CoreDialog 
                        classes={classes} 
                        state={this.state.dodelete}  
                        danger={false}
                        onCancel={this.doClose}
                        actions={[{action:this.doRefund, label: "Yes, Refund", className: classes.buttonDanger, variant: "text"}]}
                        body="Are you sure you want to refund the client? Note: if you proceed the client will be refunded however the pass will still retain any available credits. If you want to remove credits, please continue and then click the cancel button."
                        title="Refund"
                         />

                </div>
            );
        }
        else
        {
            return (
                <div>
                    <Fab className={classes.fabGrey}><MoneyOffRoundedIcon className={classes.fabIcon} /></Fab>                       
                </div>
            );            
        }


    }
}

class EditButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            object: props.data,
            callback: props.callback,
            doedit: false,
            security: props.security,
            disabled: false,
        }

        this.onInitiateEdit = this.onInitiateEdit.bind(this);
        this.doClose = this.doClose.bind(this);

        switch(this.state.object?.status)
        {
            case 'VOIDED':
            {
                this.state.disabled = true;
            }
            break;

            default:
            {
                this.state.disabled = false;
            }
            break;
        }      
        //console.log('Generating the disable button', this.state);

    }

    onInitiateEdit = () => {
        this.setState({doedit: true});
    }



    doClose(reload) {
        console.log('closing transfer', reload);
        this.setState({doedit: false});
        if(reload)
        {
            console.log('closing transfer - reloading data', reload);
            this.state.callback();
        }
    }

    render() {
        var classes= this.props.classes;
        if(this.state.disabled === false)
        {
            return (
                <div>
                    <Fab className={classes.fabGo}><EditIcon className={classes.fabIcon} onClick={this.onInitiateEdit}/></Fab>                        
                    <PassEditDialog 
                        expires={this.state.object.expires} 
                        credits={this.state.object.credits} 
                        id={this.state.object.id} 
                        state={this.state.doedit}
                        security={this.state.security} 
                        onCancel={( refresh ) => {this.doClose(refresh)}}/>
                </div>
            );
        }
        else
        {
            return (
                <div>
                    <Fab className={classes.fabGrey}><EditIcon className={classes.fabIcon} /></Fab>                        
                </div>
            );            
        }


    }
}


class PassCustomerSalesTablePanel extends Component{


    
    constructor( props )
    {
        super( props );
       // console.log('TopNav', props);


        this.state = {
            security: props.security,
            loading: true,
            data: props.transactions||[],
            events: props.events||[],
            rows_per_page: 5,
            page: 0,
            refresh: false,
            customer: props.customer,
            availablepasses: props.availablepasses,
            cards: props.cards

        };

        this.handleChangePage           = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage    = this.handleChangeRowsPerPage.bind(this);
        this.state.loading              = false;
        this.getData                     = this.getData.bind(this);
        this.onRefresh                  = props.onRefresh;
        this.titleCase                  =  this.titleCase.bind(this);
      
    }

    handleChangeRowsPerPage(event)
    {
        this.setState({'rows_per_page': parseInt(event.target.value, 10)});

    }
    handleChangePage(event, newPage)
    {
        this.setState({'page': parseInt(newPage, 10)});

    }

    getData()
    {
        //console.log('initial object', this.state.data);
        
        return this.state.data.map( d => {
            let x = Object.assign({}, d);
            x.paid = Currency(d.paid, d.currency);
            if(!!d.purchased)
            {
                if(!!d?.expires)
                {
                    x.expires = moment(d.expires).format("lll"); // "Sunday, February 14th 2010, 3:25:50 pm"
                }
                else
                {
                    x.expires = '-'; // "Sunday, February 14th 2010, 3:25:50 pm"
                }
                x.purchased = moment(d.purchased).format("ll"); // "Sunday, February 14th 2010, 3:25:50 pm"
            }
            else
            {
                x.purchased = '';
                x.expires = '-'; // "Sunday, February 14th 2010, 3:25:50 pm"
            }
            //console.log('Adjusted object', x);
            return x;
        }) || [];

    }

    titleCase(str){
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        // Directly return the joined string
        return splitStr.join(' '); 
     }


    render()
    {
        console.log('Loading customer data', this.state);
        var classes = this.props.classes;
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
          };

        const l = this.getData().length;
        const c = [];
        if(l <= 25)
        {
            c.push(l);
        }
        if(l > 25)
        {
            c.push(25);
        }
        if(l > 50)
        {
            c.push(50);
        }  
        if(l > 100)
        {
            c.push(100);
        }  
        if(l > 200)
        {
            c.push(200);
        }  
        return (
            <div>
                <Grid container justify="flex-start" alignItems="stretch">
                    <Grid item xs={12} md={12}>
                        <MaterialTable icons={tableIcons} title=""
                                // other props
                                
                            data={this.getData()}
                            columns={[
                                    { title: 'ID', field: 'id', maxWidth: "50px" },
                                    { title: 'Purchased', field: 'purchased' },
                                    { title: 'Pass', field: 'name' },
                                    { title: 'Expires', field: 'expires' },
                                    { title: 'Credits', field: 'credits' },
                                    { title: 'Used', field: 'used' },
                                    { title: 'Price', field: 'paid',  maxWidth: "80px" },
                                    { title: 'State', field: 'state', sorting: false, maxWidth: "80px" },
                                    { title: 'Edit', field: 'edit', sorting: false,  maxWidth: "50px"  },
                                    { title: 'Cancel', field: 'cancel', sorting: false,  maxWidth: "50px"  },
                                    { title: 'Refund', field: 'refund', sorting: false,  maxWidth: "50px"  },
                            ]}
                            options={{
                                selection: false,
                                pageSize: l,
                                pageSizeOptions: c,
                                padding: 'dense',
                                search: this.props.fullwidth
                            }}
                            components={{
                                Container: props => <div>{props.children}</div>, 
                                Toolbar: props => (
                                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "flex-end", alignItems:"center" }} > 
                                      <PassWidgets 
                                        security={this.state.security}
                                        customer={this.state.customer}
                                        passes={this.state.availablepasses}
                                        cards={this.state.cards}
                                        refresh={this.onRefresh}/>  
                                      <MTableToolbar {...props} />
                                    </div>),                                  
                                Row: ({ data }) => {
                                    return (
                                        <React.Fragment>
                                            <TableRow key={data.id}>  
                                                <TableCell>{data.id}</TableCell>
                                                <TableCell>{data.purchased}</TableCell>
                                                <TableCell>{data.name}</TableCell>
                                                <TableCell>{data?.expires ? data?.expires : 'Not set'}</TableCell>
                                                <TableCell>{data.credits}</TableCell>
                                                <TableCell>{data.used}</TableCell>
                                                <TableCell key={'paid-' + data.id}>{data.paid}</TableCell>
                                                <TableCell>{this.titleCase(data.status)}</TableCell>
                                                <TableCell><EditButton data={data} events={this.state.events}  classes={classes} callback={this.onRefresh} security={this.state.security}/> </TableCell>
                                                <TableCell><DeleteButton data={data} classes={classes}  callback={this.onRefresh}  security={this.state.security}/> </TableCell>
                                                <TableCell><RefundButton data={data} classes={classes}  callback={this.onRefresh}  security={this.state.security}/> </TableCell>
                                            </TableRow>
            

                                        </React.Fragment>
                                    )
                                }
                            }}
                            
                        />
                    </Grid>
                </Grid>


            </div>
        );
    }
}


export default PassCustomerSalesTablePanel;
