import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Moment from 'react-moment';
import moment from 'moment'
import DialogPrompt from '../core/dialog';


import Autocomplete from '@material-ui/lab/Autocomplete';

import Loading from '../core/loading';

import {v1 as uuidv1} from "uuid";


const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttonDisabled: {
    color: theme.palette.grey[900]
  },
  formSectionTitle:{
    marginTop: '15px',
    marginBottom: '15px',
    fontWeight: 'bold'
  },
  selectCardBox:{
    border: '1px dashed rgba(0,0,0,0.1)',
    marginTop: '5px',
    marginBottom: '5px',
    minHeight: "100px",
  },
  noCardInner:{
    marginTop: '40px',
    textAlign: 'center',
    fontSize: '1.1em',
    color: 'rgba(0,0,0,0.5)',
  },  
  optionsCardInner:{
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '20px',
 
  },
  help:{
    fontSize: '0.7em',
    marginTop: '5px',
    marginBottom: '10px', 
    textAlign: 'right',
  }



});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, danger, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" color={(danger?'error':'inherit')}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  } 
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function EventCustomerRegistrationDialogWrapper( props ) {
  const [open, setOpen] = React.useState(props.state);
  const  [selected, setSelected] = React.useState(null);
  const  [pid, setPid] = React.useState({ id: null, name: null, price: 0, stock: 0, active: false, credits:null});
  const  [date, setDate] = React.useState({ id: null, eid: null, cid: null, date: null, time: null, duration: null, formatted_date: null});
  const  [loading, setLoading] = React.useState(props.loading);
  const  [passes, setPasses] = React.useState([]);
  const  [cards, setCards] = React.useState([]);
  const  callback  = props.onCancel;
  const  eid  = props.eid;
  const  did  = props.did;
  const  payments = props.payments;
  const  [customers, setCustomers] = React.useState(props.customers);
  const  [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const  [selectedPayment, setSelectedPayment] = React.useState("cash");
  const  [selectedCard, setSelectedCard] = React.useState(null);
  const  [price, setPrice] = React.useState(0);
  const  [paymentOption, setPaymentOption] = React.useState(payments.configuration.options[0]);
  const  [pass, setPass] = React.useState( null );
  const  [error, setError] = React.useState( null );
  const handleClose = () => {
    console.log('handleClose', props)
    callback(false);
    setOpen(false);
  };


  const cancelError = ( ) => {
    setError(null);
  }

  const handleCustomerSelect = (event, value) => {


    //setSelectedCustomer(value);
    setPaymentOption(payments.configuration.options[0]);
    setPrice(Number(payments.configuration.options[0].price).toFixed(2));
    setSelectedPayment("cash");
    setPass( null );
    setSelectedCustomer(null);
    setPasses([]);
    setSelectedCard(null);
    setCards([]);
    setError(null)

    if(value !== null)
    {
      /* Get the customer details */
      var chain = '&customer='    + JSON.stringify(value);

      var _this = this;
      try {
          fetch("/api-actions-customers/do-ajax-customer-properties-fetch-v2", {
            method: "POST",
            mode: 'same-origin', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'include', // include, *same-origin, omit                            
            body: props.security.name + '=' + props.security.token + chain,
              headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  "X-Requested-With": 'XMLHttpRequest',
                  "X_REQUESTED_WITH": 'xmlhttprequest'
              }
          })
          .then(res => {
              return res.json();
          })
          .then(response => {
            if(response.status === 'OK')
            {
              if(response.has_pass)
              {
                var passes = [];
                for(var i = 0; i < response.passes.length; i++)
                {
                  var obj = JSON.parse(JSON.stringify(response.passes[i]));
                  var expires = moment(obj.expires);
                  obj.description = obj.name + ' ( Expires: ' + expires.format('lll') + ' credits: ' + obj.credits + ' )';
                  passes.push(obj);
                }
                setPasses(passes);
                setSelectedCustomer(value);
                if(response.cards.length)
                {
                  setCards(response.cards);
                  setSelectedCard(response.cards[0].id);
                  setSelectedCustomer(value);
                }                
                
              }
              else if(response.cards.length)
              {
                setCards(response.cards);
                setSelectedCard(response.cards[0].id);
                setSelectedCustomer(value);
              }
              else
              {
                setSelectedCustomer(value);
              }
            }
            else
            {
              setSelectedCustomer(null);
            }
  
  
          }).catch(function (err) {
            setSelectedCustomer(null);
       
          });           
  
      }
      catch(e)
      {
        console.error('api called', e);
        setSelectedCustomer(null);

      } 
    }
    else
    {

    }

    console.log("Customer object", value);


    //props.onEventSelect(event, value);
  };
  const handleDateSelect = (event, value) => {

    console.log('date selected ', value);
    setDate(value === null ? { id: null, eid: null, cid: null, date: null, time: null, duration: null, formatted_date: null}: value);

  };
  

  const handlePaymentChange = (event, value) => {
    setSelectedPayment(event.target.value);
    setPaymentOption(payments.configuration.options[0]);

    if(value === 'cash' || value === 'creditcard')
    {
      setPrice(Number(payments.configuration.options[0].price).toFixed(2));
    }
    else
    {
      setPrice(Number(payments.configuration.options[0].credits).toFixed(0));
    }


  }
  const handleCardChange = (event, value) => {
    setSelectedCard(event.target.value);
  }

    

  const handlePaymentOptionChange = (event, value) => {

    if(value === null)
    {
      return;
    }
    setPaymentOption(value);

    for(var i = 0; i < payments.configuration.options.length; i++ )
    {
      if(payments.configuration.options.id === event.target.value.id)
      {
          setPrice(Number(payments.configuration.options[i].price).toFixed(2));
      }
    }
  }

  const handleCreditPaymentOptionChange = (event, value) => {
    setPrice(0).toFixed(0);
    if(value === null)
    {
      return;
    }

    setPaymentOption(value);

    for(var i = 0; i < payments.configuration.options.length; i++ )
    {
      if(payments.configuration.options.id === event.target.value.id)
      {
          setPrice(Number(payments.configuration.options[i].credits).toFixed(0));
      }
    }
  }  

  const handleSetPrice0Decimal= (event, value) => {
    if(event.target.value)
    {
      setPrice(Number(event.target.value));
    }
    else
    {
      setPrice(null);
    }
  }

  const handleSetPrice2Decimal= (event, value) => {
    if(event.target.value)
    {
      setPrice(Number(event.target.value));
    }
    else
    {
      setPrice(null);
    }
}


const handleSet0Price2Decimal= (event, value) => {
    setPrice(Number(price).toFixed(2));
}

const handleSet0Price0Decimal= (event, value) => {
  setPrice(Number(price).toFixed(0));
}

  const handleOptionSelect = (event, value) => {
    if(value !== null)
    {
      setPid( value );
    }
  }; 

  const handlePassSelect = (event, value) => {

    if(value !== null)
    {
      setPass( value );
    }
    else
    {
      setPass( null );
    }
  }; 



  const onRegister = (event) => {
 
     props.obscure(true);
     /* Get the customer details */
      var registration_object = {
          customer: selectedCustomer,
          eid: eid,
          did: did,
          payment: {
            type: selectedPayment,
            record: {
              po: paymentOption,
              price: price,
              pass: pass,
              card: selectedCard,
            }
          }
      };

     var chain = '&registration='    + encodeURIComponent(JSON.stringify(registration_object));

     var _this = this;
     try {
         fetch("/api-actions-customers/do-ajax-customer-event-register-v2", {
          method: "POST",
          mode: 'same-origin', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'include', // include, *same-origin, omit                            
          body: props.security.name + '=' + props.security.token + chain,
             headers: {
                 "Content-Type": "application/x-www-form-urlencoded",
                 "X-Requested-With": 'XMLHttpRequest',
                 "X_REQUESTED_WITH": 'xmlhttprequest'
             }
         })
         .then(res => {
             return res.json();
         })
         .then(response => {
           if(response.status === 'OK')
           {
              callback(true);
              setOpen(false);
           }
           else
           {
              if(response.error_type === 'stripe')
              {
                  var reason = '';
                  var whatnext = '';
                  switch(response.stripe_decline_code)
                  {
                    case 'authentication_required':
                    {
                      reason = 'The card was declined as the transaction requires authentication by the card holder.';
                      whatnext = 'The card holder should re-authorise their card.';
                    }
                    break;
                    case 'approve_with_id':
                    {
                        reason = 'The payment could not be authorised.';
                        whatnext = 'Please try again, if this continues the card holder should contact their bank.';
                    }
                    break; 
                    case 'call_issuer':
                    {
                        reason = 'The card has been declined for an unknown reason.';
                        whatnext = 'The card holder should contact their bank';
                    }
                    break; 
                    case 'card_not_supported':
                    {
                        reason = 'The card does not support this type of purchase.';
                        whatnext = 'The card holder should contact their bank for further information';
                    }
                    break;                     
                    case 'card_velocity_exceeded':
                    {
                        reason = 'The customer has exceeded the balance or credit limit available on their card.';
                        whatnext = 'The card holder should check their credit limit or contact their bank';
                    }
                    break;  
                    case 'currency_not_supported':
                    {
                        reason = 'The card does not support the specified currency.';
                        whatnext = 'The card holder should contact their bank for further information';
                    }
                    break;   
                    case 'do_not_honor':
                    {
                        reason = 'The card has been declined for an unknown reason.';
                        whatnext = 'The card holder should contact their bank for further information';
                    }
                    break;                       
                    case 'do_not_try_again':
                    {
                        reason = 'The card has been declined for an unknown reason. Future attempts will be declined.';
                        whatnext = 'The card holder should contact their bank for further information';
                    }
                    break;                        
                    case 'duplicate_transaction':
                    {
                        reason = 'A transaction with identical amount and credit card information was submitted very recently.';
                        whatnext = 'Check the customers account to ensure this is not a duplicate and try again later. If this error continues, the card holder will need to contact their bank.';
                    }
                    break;                    
                    case 'expired_card':
                    {
                        reason = 'The card has expired.';
                        whatnext = 'The customer will need to log into their account and add a new card.';
                    }
                    break; 
                    case 'fraudulent':
                    {
                        reason = 'The payment has been declined as Stripe suspects it is fraudulent.';
                        whatnext = 'Contact stripe support for your account to discuss.';
                    }
                    break; 

                    case 'stolen_card':
                    case 'no_action_taken':
                    case 'merchant_blacklist':
                    case 'lost_card': 
                    case 'generic_decline':
                    {
                        reason = 'The card has been declined for an unknown reason.';
                        whatnext = 'The card holder should contact their bank for further information';
                    }
                    break; 
                    
                    case 'invalid_number':
                    case 'incorrect_number':
                    {
                        reason = '	The card number is incorrect.';
                        whatnext = 'The card holder should log into their account and check the registered credit card.';
                    }
                    break; 
                    
                    case 'invalid_cvc':
                    case 'incorrect_cvc':
                    {
                        reason = '	The card CVC number is incorrect.';
                        whatnext = 'The card holder should log into their account and check the registered credit card.';
                    }
                    break;  
                    
                    case 'invalid_pin':
                    case 'incorrect_pin':
                    {
                        reason = 'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.';
                        whatnext = 'The card holder should check their PIN number';
                    }
                    break; 
                    case 'incorrect_zip':
                    {
                        reason = '	The ZIP or postal code is incorrect.';
                        whatnext = 'The card holder should log into their account and check the registered credit card.';
                    }
                    break; 
                    
                    case 'withdrawal_count_limit_exceeded':
                    case 'insufficient_funds':
                    {
                        reason = '	The card has insufficient funds to complete the purchase.';
                        whatnext = 'Contact the card holder to discuss the payment';
                    }
                    break;  
                    
                    case 'new_account_information_available':
                    case 'invalid_account':
                    {
                        reason = 'The card, or account the card is connected to, is invalid.';
                        whatnext = 'The card holder should log into their account and check the registered credit card.';
                    }
                    break;  
                    case 'invalid_amount':
                    {
                        reason = 'The payment amount is invalid, or exceeds the amount that is allowed.';
                        whatnext = 'Contact the card holder to discuss the payment';
                    }
                    break;  

                    case 'invalid_expiry_year':
                    {
                      reason = 'The expiration year invalid.';
                      whatnext = 'The card holder should log into their account and check the registered credit card.';

                    }
                    break;

                    case 'issuer_not_available':
                    {
                      reason = 'The card issuer could not be reached, so the payment could not be authorised.';
                      whatnext = 'The payment should be attempted again. If it still cannot be processed, the customer needs to contact their card issuer.';

                    }
                    break;

                    case 'not_permitted':
                    {
                      reason = 'The payment is not permitted.';
                      whatnext = 'The payment should be attempted again. If it still cannot be processed, the customer needs to contact their card issuer.';

                    }
                    break;

                    case 'online_or_offline_pin_required':
                    case 'offline_pin_required':
                    {
                      reason = 'The card has been declined as it requires a PIN.';
                      whatnext = 'The customer will need to complete the transaction.';

                    }
                    break;
                    case 'pickup_card':
                    {
                      reason = 'The card cannot be used to make this payment.';
                      whatnext = 'The card holder should contact their bank for further information';

                    }
                    break;
                    case 'pin_try_exceeded':
                    {
                      reason = 'The allowable number of PIN tries has been exceeded.';
                      whatnext = 'The card holder should contact their bank for further information';

                    }
                    break;
                    case 'processing_error':
                    {
                      reason = 'An error occurred while processing the card.';
                      whatnext = 'Please try again, if this continues the card holder should contact their bank.';

                    }
                    break;

                    case 'try_again_later':
                    case 'reenter_transaction':
                    {
                      reason = 'The payment could not be processed by the issuer for an unknown reason.';
                      whatnext = 'Please try again, if this continues the card holder should contact their bank.';
                    }
                    break;
                    case 'restricted_card':
                    {
                      reason = 'The card cannot be used to make this payment.';
                      whatnext = 'The card holder should contact their bank for further information';
                    }
                    break;

                    case 'revocation_of_authorization':
                    case 'revocation_of_all_authorizations':
                    case 'security_violation':
                    case 'service_not_allowed':
                    case 'stop_payment_order':
                    case 'transaction_not_allowed':
                    {
                      reason = 'The card has been declined for an unknown reason.';
                      whatnext = 'The card holder should contact their bank for further information';
                    }
                    break;
                    case 'testmode_decline':
                    {
                      reason = 'A Stripe test card number was used.';
                      whatnext = 'The card holder should log into their account and check the registered credit card. Test cards cannot be used for live transactions.';
                    }
                    break;

                    default:
                    {
                      reason = 'Unknown error: ' + response.decline_code;
                      whatnext = 'Please try again later.';

                    }
                    break;
                  }
                  var str = '<b>Reason:</b> ' + reason + '<br><br>';
                  str += '<b>Recommendation:</b> ' + whatnext + '<br><br>';
                  props.obscure(false);
                  setError(str);
              }
              else
              {
                  props.obscure(false);
                  setError(response.message);
              }

              //callback(true);
              //setOpen(false);
           }
         }).catch(function (err) {
          console.error('api called', err);
          props.obscure(false);
          setError('Connection error - please check your internet connection and try again.');
    
         });           

     }
     catch(e)
     {
       console.error('api called', e);
       props.obscure(false);
       setError('Connection error - please check your internet connection and try again.');

     } 
  }

  React.useEffect(() => {
    setOpen(props.state);
  }, [props.state])

  
//  console.log('selectedCustomer ', selectedCustomer, selectedCustomer === null);

  var allow_po_select = false;
  if(selectedCustomer === null)
  {
    allow_po_select = true;
  }

  var pass_max = "0";
  if(pass !== null)
  {
    pass_max = Number(pass.credits_available).toString();
  }


  var registration_ok = false;

  if(selectedCustomer !== null)
  {
    if(price !== null)
    {
        if(selectedPayment === 'cash')
        {
            if(paymentOption !== null)
            {
                registration_ok = true;
            }
        }
        else if(selectedPayment === 'pass')
        {
            if(paymentOption !== null)
            {
                if(pass !== null)
                {
                    registration_ok = true;
                }
            }
        }
        else if(selectedPayment === 'creditcard')
        {
          if(paymentOption !== null)
          {
              if(selectedCard !== null)
              {
                  registration_ok = true;
              }
          }
        }
    }
  }

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} maxWidth = {'sm'}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} danger={(props.danger?props.danger:false)}>
          Customer Registration
        </DialogTitle>
        <DialogContent dividers>
          { loading === true && (<div style={{ minHeight: 150  }}><Loading /></div>) }
          { loading === false && (
            <div>
              <div className={props.classes.formSectionTitle}>Step 1: select the customer you would like to register: </div>
              <Autocomplete
                id="company-events-list"
                options={props.customers}
                getOptionLabel={option => (option.firstname + ' ' + option.lastname + ' (' + option.email + ')' )}
                style={{ width: "100%" }}
                autoHighlight
                disableClearable={true}
                onChange={handleCustomerSelect}
                renderOption={option => (
                  <React.Fragment>
                    {option.firstname} {option.lastname} {'(' + option.email + ')'} 
                  </React.Fragment>
                )}                
                renderInput={params => <TextField {...params} label="Customer" variant="outlined" />}
              />

            </div>) }

            {selectedCustomer === null && (
              <React.Fragment>
                <div className={props.classes.formSectionTitle}>Step 2: select the payment type: </div>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="payment-type" name="payment-type" value={selectedPayment} onChange={handlePaymentChange}>
                    <FormControlLabel value="cash" disabled control={<Radio />} label="Cash" />
                    <FormControlLabel value="pass" disabled control={<Radio />} label="Pass" />
                    <FormControlLabel value="savedcard" disabled control={<Radio />} label="Saved Credit or Debit Card" />
                  </RadioGroup>
                </FormControl> 

                <div className={props.classes.selectCardBox}>
                  <div className={props.classes.noCardInner}>No associated cards</div>
                </div>
                <a href="https://www.reservie.net/redirect-saved-card-help" target="_blank" className={props.classes.help}>What's this?</a>
              </React.Fragment>

            )}
            {selectedCustomer !== null && (
              <React.Fragment>
                <div className={props.classes.formSectionTitle}>Step 2: select the payment type: </div>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="payment-type" name="payment-type" value={selectedPayment} onChange={handlePaymentChange}>
                    <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                    {passes.length > 0 && (
                      <FormControlLabel value="pass"  control={<Radio />} label="Pass" />
                    )}
                    {passes.length == 0 && (
                      <FormControlLabel value="pass" disabled control={<Radio />} label="Pass" />
                    )}
                    {cards.length == 0 && (
                    <FormControlLabel value="creditcard" disabled control={<Radio />} label="Saved Credit or Debit Card" />
                    )}
                    {cards.length > 0 && (
                    <FormControlLabel value="creditcard" control={<Radio />} label="Saved Credit or Debit Card" />
                    )}
                  </RadioGroup>
                </FormControl> 
                {cards.length == 0 && (

                  <React.Fragment>
                    <div className={props.classes.selectCardBox}>
                      <div className={props.classes.noCardInner}>No associated cards</div>
                    </div>  
                    <a href="https://www.reservie.net/redirect-saved-card-help" target="_blank" className={props.classes.help}>What's this?</a>
                  </React.Fragment>

                )}
                {cards.length > 0 && (
                  <React.Fragment>

                    <div className={props.classes.selectCardBox}>
                      <FormControl component="fieldset" className={props.classes.optionsCardInner}>
                        <RadioGroup aria-label="card-select" name="card-select" value={selectedCard} onChange={handleCardChange}>
                          {selectedPayment !== 'creditcard' && cards.map(el => (
                              <FormControlLabel key={el.id} disabled value={el.id} control={<Radio />} label={ el.card.brand.charAt(0).toUpperCase() +  el.card.brand.slice(1) + ": Card: xxxx xxxx xxxx " + el.card.last4 + "( Expires: " + el.card.exp_month + "/" + el.card.exp_year + " )"} />
                          ))}
                          {selectedPayment === 'creditcard' && cards.map(el => (
                              <FormControlLabel key={el.id} value={el.id} control={<Radio />} label={ el.card.brand.charAt(0).toUpperCase() +  el.card.brand.slice(1) + ": Card: xxxx xxxx xxxx " + el.card.last4 + "( Expires: " + el.card.exp_month + "/" + el.card.exp_year + " )"} />
                              ))}
                          </RadioGroup>
                      </FormControl> 
                    </div> 
                    <a href="https://www.reservie.net/redirect-saved-card-help" target="_blank" className={props.classes.help}>What's this?</a>
                  </React.Fragment>

                )}
              </React.Fragment>
            )}


              <React.Fragment>
                <div className={props.classes.formSectionTitle}>Step 3: select the price: </div>
                {selectedPayment === "cash" && (
                  <React.Fragment>
                      <Autocomplete
                        id="pricing-option-cash"
                        options={payments.configuration.options}
                        getOptionLabel={option => (option.name)}
                        style={{ width: "100%" }}
                        disabled={allow_po_select}
                        autoHighlight
                        defaultValue={ paymentOption }
                        disableClearable={true}
                        onChange={handlePaymentOptionChange}
                        renderOption={option => (
                          <React.Fragment>
                            {option.name}
                          </React.Fragment>
                        )}                
                        renderInput={params => <TextField {...params} label="Pricing option" variant="outlined" />}
                      />

                    <TextField
                      onChange={handleSetPrice2Decimal}
                      onBlur={handleSet0Price2Decimal}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      disabled={(paymentOption === null)?true:false}
                      placeholder="1.00"
                      InputLabelProps={{
                          shrink: true,
                      }}  
                      inputProps={{ min: "0.00", step: "0.01" }}  
                      type="number" 
                      value={price}                                
                      error={false}                               
                      label="Price"
                      helperText={"Enter the price paid for this event"}
                    /> 
                  </React.Fragment>
                )} 
                {selectedPayment === "pass" && (
                  <React.Fragment>
                      <Autocomplete
                        id="pricing-option-pass"
                        options={payments.configuration.options}
                        getOptionLabel={option => (option.name)}
                        style={{ width: "100%" }}
                        disabled={allow_po_select}
                        autoHighlight
                        defaultValue={ paymentOption }
                        disableClearable={true}
                        onChange={handleCreditPaymentOptionChange}
                        renderOption={option => (
                          <React.Fragment>
                            {option.name}
                          </React.Fragment>
                        )}                
                        renderInput={params => <TextField {...params} label="Pricing option" variant="outlined" />}
                      />


                    <Autocomplete
                      id="passes-option-credits"
                      options={passes}
                      getOptionLabel={option => (option.description)}
                      style={{ width: "100%", marginTop: "10px" }}
                      autoHighlight
                      disabled={ (paymentOption === null)?true:false }
                      disableClearable={false}
                      onChange={handlePassSelect}
                      renderOption={option => (
                        <React.Fragment>
                          {option.description}
                        </React.Fragment>
                      )}                
                      renderInput={params => <TextField {...params} label="Pass" variant="outlined" />}
                    />  


                    <TextField
                      onChange={handleSetPrice0Decimal}
                      onBlur={handleSet0Price0Decimal}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      disabled={ (pass === null)?true:false }
                      placeholder="1"
                      InputLabelProps={{
                          shrink: true,
                      }}  
                      inputProps={{ min: "0", step: "1", max: pass_max }}  
                      type="number" 
                      value={price}                                
                      error={false}                               
                      label="Credits"
                      helperText={"Enter the price paid for this event in credits"}
                    /> 

                  </React.Fragment>               
                )}  
                {selectedPayment === "creditcard" && (
                  <React.Fragment>
                      <Autocomplete
                        id="pricing-option-creditcard"
                        options={payments.configuration.options}
                        getOptionLabel={option => (option.name)}
                        style={{ width: "100%" }}
                        disabled={allow_po_select}
                        autoHighlight
                        defaultValue={ paymentOption }
                        disableClearable={true}
                        onChange={handlePaymentOptionChange}
                        renderOption={option => (
                          <React.Fragment>
                            {option.name}
                          </React.Fragment>
                        )}                
                        renderInput={params => <TextField {...params} label="Pricing option" variant="outlined" />}
                      />

                    <TextField
                      onChange={handleSetPrice2Decimal}
                      onBlur={handleSet0Price2Decimal}
                      margin="normal"
                      variant="outlined"
                      fullWidth
                      disabled={(paymentOption === null)?true:false}
                      placeholder="1.00"
                      InputLabelProps={{
                          shrink: true,
                      }}  
                      inputProps={{ min: "1.00", step: "0.01" }}  
                      type="number" 
                      value={price}                                
                      error={false}                               
                      label="Price"
                      helperText={"Enter the price paid for this event"}
                    /> 
                  </React.Fragment>
                )}                                
              </React.Fragment>

          <Typography gutterBottom />
        </DialogContent>
        <DialogActions>
              <Button onClick={onRegister} disabled={!registration_ok}>
                    Register now
              </Button>        
        </DialogActions>
      </Dialog>
      {error !== null && (<DialogPrompt title="Error" body={"<b>Unable to complete registration.</b><br><br>" + error} state={true} danger={true} onCancel={cancelError} actions={[{label:"OK", action:cancelError}]}  />)}
    </div>
  );
  
}

class EventCustomerRegistrationDialog extends Component{
    constructor( props )
    {
        super( props );
      // console.log('TopNav', props);


        this.state = {
            security: props.security,
            loading: false,
            eid: props.eid,
            did: props.did,
            payments: props.payments,
            callback: props.callback,
            customers: props.customers,
            pid: [],
            ready: false,
            overlay: false,

        };


        this.doneEventCustomerRegistration = this.doneEventCustomerRegistration.bind(this);
        this.doneCancel = this.doneCancel.bind(this);
        this.obscure    = this.obscure.bind(this);
        console.log('EventCustomerRegistrationDialog', this.state);
        
    }  

    obscure( v ){
      this.setState({overlay: v})
    }



    doneEventCustomerRegistration(){

    }

    doneCancel( refresh ){
      this.state.callback(refresh);
    }

    render()
    {
        console.log('Loading customer data', this.state);
        console.log('The state is', this.props.state);
        
        var classes = this.props.classes;


        var payments = { configuration: {
                                          options: []
                                        },
                          mode: this.state.payments.mode,
                          discounts: this.state.payments.discounts
                        };

        if(this.state.payments !== null)
        {
            for(var i = 0; i < this.state.payments.configuration.options.length; i++ )
            {
                if(this.state.payments.configuration.options[i].disabled || this.state.payments.configuration.options[i].deleted)
                {
                    continue;
                }
                payments.configuration.options.push(this.state.payments.configuration.options[i]);
            }
        }      
        return (
            <div>
              <EventCustomerRegistrationDialogWrapper 
                classes={classes} 
                customers={this.state.customers} 
                eid={this.state.eid}
                did={this.state.did}
                loading={this.state.loading} 
                state={this.props.state} 
                pid={this.state.pid} 
                ready={this.state.ready} 
                onCancel={this.state.callback} 
                security={this.state.security}
                payments={payments}
                obscure={this.obscure}
                />
             {this.state.overlay === true && (
                <div className="overlay">
                  <div className="loading">
                      <i className="fa fa-refresh fa-spin fa-5x fa-fw"></i>
                      <span className="sr-only">Loading...</span>
                      <div className="instruct">Please wait. Processing...</div>
                  </div>                
                </div>
              )}                
          </div>

        );
    }
     
}

export default withStyles(styles)(EventCustomerRegistrationDialog);
