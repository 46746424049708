

export const ACTION_TYPE_LOAD_DATA = "LOAD_DATA";
export const ACTION_TYPE_RELOAD_DATA = "RELOAD_DATA";
export const ACTION_TYPE_MANAGE_CUSTOMER = "ACTION_TYPE_MANAGE_CUSTOMER";
export const ACTION_TYPE_MANAGE_CUSTOMER_CLOSE_PANEL = "ACTION_TYPE_MANAGE_CUSTOMER_CLOSE_PANEL";
export const ACTION_TYPE_SET_STATE = "ACTION_TYPE_SET_STATE";
export const ACTION_TYPE_REWIND_STATE = "ACTION_TYPE_REWIND_STATE";
export const ACTION_TYPE_SET_CLONE_STATE = "ACTION_TYPE_SET_CLONE_STATE";

export const VIEW_TYPE_NONE = "VIEW-TYPE-NONE";
export const VIEW_TYPE_EXTENSION_MANAGER = "VIEW-TYPE-EXTENSION-MANAGER";
export const VIEW_TYPE_HOME_CONTROL_PANEL = "VIEW-TYPE-HOME-CONTROL-PANEL";
export const VIEW_TYPE_CUSTOMER_PANEL = "VIEW-TYPE-HOME-CUSTOMER-PANEL";
export const VIEW_TYPE_HOME_PASSES_PANEL = "VIEW-TYPE-HOME-PASSES-PANEL";
export const VIEW_TYPE_EVENTS_PANEL = "VIEW-TYPE-EVENTS-PANEL";
export const VIEW_TYPE_SUBSCRIPTIONS_PANEL = "VIEW-TYPE-SUBSCRIPTIONS-PANEL";
export const VIEW_TYPE_MANDATES_PANEL = "VIEW-TYPE-MANDATES-PANEL";
export const VIEW_TYPE_REPORTS_PANEL = "VIEW-TYPE-REPORTS-PANEL";
export const VIEW_TYPE_SCHEDULES_PANEL = "VIEW-TYPE-SCHEDULES-PANEL";
export const VIEW_TYPE_QUESTIONAIRE_PANEL = "VIEW-TYPE-QUESTIONAIRE-PANEL";
export const VIEW_TYPE_POLICY_PANEL = "VIEW-TYPE-POLICY-PANEL";
export const VIEW_TYPE_VIDEO_PANEL = "VIEW-TYPE-VIDEO-PANEL";

export const VIEW_TYPE_INVOICE = "VIEW-TYPE-INVOICE";

export const EVENT_APP_CACHE_MODE_NOT_SET = 'NOT_SET';
export const EVENT_APP_CACHE_MODE_PHYSICAL = 'PHYSICAL';
export const EVENT_APP_CACHE_MODE_VIRTUAL = 'VIRTUAL';

export const EVENT_APP_CACHE_STATE_DEFAULT = 'EVENT_APP_CACHE_STATE_DEFAULT';
export const EVENT_APP_CACHE_STATE_WIZARD_0 = 'EVENT_APP_CACHE_STATE_WIZARD_0';
export const EVENT_APP_CACHE_STATE_WIZARD_1 = 'EVENT_APP_CACHE_STATE_WIZARD_1';
export const EVENT_APP_CACHE_STATE_WIZARD_2 = 'EVENT_APP_CACHE_STATE_WIZARD_2';
export const EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP = 'EVENT_APP_CACHE_STATE_WIZARD_2_VIRTUAL_SETUP';

export const EVENT_APP_CACHE_STATE_WIZARD_2_A = 'EVENT_APP_CACHE_STATE_WIZARD_2_A';
export const EVENT_APP_CACHE_STATE_WIZARD_2_B = 'EVENT_APP_CACHE_STATE_WIZARD_2_B';
export const EVENT_APP_CACHE_STATE_WIZARD_2_C = 'EVENT_APP_CACHE_STATE_WIZARD_2_C';
export const EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE = 'EVENT_APP_CACHE_STATE_WIZARD_2_BOOK_TYPE';
export const EVENT_APP_CACHE_STATE_WIZARD_2_MANUALLY_ADJUST_DATES = 'EVENT_APP_CACHE_STATE_WIZARD_2_MANUALLY_ADJUST_DATES';
export const EVENT_APP_CACHE_STATE_WIZARD_3 = 'EVENT_APP_CACHE_STATE_WIZARD_3';
export const EVENT_APP_CACHE_STATE_WIZARD_4 = 'EVENT_APP_CACHE_STATE_WIZARD_4';
export const EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT = 'EVENT_APP_CACHE_STATE_WIZARD_4_PASS_SELECT';
export const EVENT_APP_CACHE_STATE_WIZARD_4_DISCOUNTS   = 'EVENT_APP_CACHE_STATE_WIZARD_4_DISCOUNTS';



export const EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS = 'EVENT_APP_CACHE_STATE_WIZARD_5_ACTIONS';
export const EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES = 'EVENT_APP_CACHE_STATE_WIZARD_5_PROPERTIES';
export const EVENT_APP_CACHE_STATE_WIZARD_SUMMARY = 'EVENT_APP_CACHE_STATE_WIZARD_SUMMARY';





export const EVENT_APP_CACHE_SCHEDULING_TYPE_UNDEFINED = 'SCHEDULING_TYPE_UNDEFINED';
export const EVENT_APP_CACHE_SCHEDULING_TYPE_MANUAL = 'SCHEDULING_TYPE_MANUAL';
export const EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_WEEKLY = 'SCHEDULING_TYPE_AUTO_WEEKLY';
export const EVENT_APP_CACHE_SCHEDULING_TYPE_AUTO_MONTHLY = 'SCHEDULING_TYPE_AUTO_MONTHLY';

export const EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_PASSES = 0x01;
export const EVENT_APP_CACHE_PAYMENT_TYPE_ACCEPT_CREDIT_CARD = 0x02;

export const EVENT_APP_CACHE_BOOKING_MODE_TYPE_INDIVIDUAL = 'BOOKING_MODE_TYPE_INDIVIDUAL';
export const EVENT_APP_CACHE_BOOKING_MODE_TYPE_BLOCK = 'BOOKING_MODE_TYPE_BLOCK';
export const EVENT_APP_CACHE_BOOKING_MODE_TYPE_FLEXIBLE = 'BOOKING_MODE_TYPE_FLEXIBLE';

export const EVENT_MANAGER_VIEW_DATA_MODE_COURSE = "EVENT_MANAGER_VIEW_DATA_MODE_COURSE";
export const EVENT_MANAGER_VIEW_DATA_MODE_WEEK = "EVENT_MANAGER_VIEW_DATA_MODE_WEEK";


export const EVENT_APP_CACHE_STATE_CLONE_SELECT_MODE = 'EVENT_APP_CACHE_STATE_CLONE_SELECT_MODE';
export const EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE = 'EVENT_APP_CACHE_STATE_CLONE_SELECT_TYPE';
export const EVENT_APP_CACHE_STATE_CLONE_SETUP_VIRTUAL = 'EVENT_APP_CACHE_STATE_CLONE_SETUP_VIRTUAL';
export const EVENT_APP_CACHE_STATE_CLONE_SAVE = 'EVENT_APP_CACHE_STATE_CLONE_SAVE';
export const EVENT_APP_CACHE_STATE_CLONE_MANUAL  = "EVENT_APP_CACHE_STATE_CLONE_MANUAL";
export const EVENT_APP_CACHE_STATE_CLONE_WEEKLY  = "EVENT_APP_CACHE_STATE_CLONE_WEEKLY";
export const EVENT_APP_CACHE_STATE_CLONE_MONTHLY  = "EVENT_APP_CACHE_STATE_CLONE_MONTHLY";